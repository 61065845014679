import React, { useState } from 'react';

import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomSwitch = withStyles({
  root: {
    transform: 'translateX(10px)',
  },
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#01a3b2',
    },
    '&$checked + $track': {
      backgroundColor: '#006973',
    },
  },
  thumb: {},
  checked: {},
  track: {},
})(Switch);

const SwitchWrapper = ({ checked, onChange, name = 'switch' }) => {
  const [ _checked, set_checked ] = useState(checked ? checked : false);
  
  const handleChange = (evt) => {
    set_checked(evt.target.checked);
    
    if (onChange) onChange(evt.target.checked);
  }

  return (
    <CustomSwitch
      checked={_checked}
      onChange={handleChange}
      name={name}
    />
  )
}

export default SwitchWrapper;
