import _ from 'lodash';
import Moment from 'moment';

import { database } from '../../firebase';

import {
  CONVERSATIONS_FETCH,
  CONVERSATION_FETCH_START,
  CONVERSATION_FETCH,
  CHECK_CONTACT_STATUS
} from '../../constraints/types';

export const conversationsFetch = (currentUser) => {
  return dispatch => {
    database.ref(`conversations/${currentUser}`)
      .on('value', snapshot => {
        const chatsNotification = _.filter(snapshot.val(), function(o) { return !o.visualized }).length;

        const conversationsAndUid = _.filter(snapshot.val(), (val, uid) => {
          val.uid = uid
          return val
        });

        dispatch({
          type: CONVERSATIONS_FETCH,
          payload: _.orderBy(conversationsAndUid, ['visualized', 'lastMessageTime'], ['asc', 'desc']),
          chatsNotification
        });
      });
  };
};

export const conversationFetch = (currentUser, recipientUser, serviceOrderId, companyId, messagesAmount) => {
  return dispatch => {
    dispatch({ type: CONVERSATION_FETCH_START });

    database.ref(`messages/${currentUser}/${recipientUser}/${companyId}`).off();

    database.ref(`messages/${currentUser}/${recipientUser}/${companyId}`)
      .limitToLast(messagesAmount)
        .on('value', snapshot => {
          database.ref(`conversations/${currentUser}`)
            .orderByChild('companyId')
              .equalTo(companyId)
                .once('value', function(snapshot) {
                  const snapFiltered = _.filter(snapshot.val(), (val, uid) => {
                    if (val.userId == recipientUser) {
                      val.uid = uid
                      return val
                    }
                  });
                  database.ref(`conversations/${currentUser}/${snapFiltered[0].uid}`)
                    .update({
                      visualized: true,
                      open: true,
                      openTime: Moment.now()
                    });
                })
                .then(() => {
                  database.ref(`messages/${recipientUser}/${currentUser}/${companyId}`)
                    .orderByChild('visualized')
                      .equalTo(false)
                        .once('value', function(messagesUnseen) {
                          _.map(messagesUnseen.val(), (val, uid) => {
                            database.ref(`messages/${recipientUser}/${currentUser}/${companyId}/${uid}`)
                              .update({ visualized: true });
                          });
                        });
                })
                .then(() => {
                  //storeConversation(currentUser, recipientUser, companyId, snapshot.val());
                  dispatch({ type: CONVERSATION_FETCH, payload: snapshot.val() });
                });
        });
  };
};

export const checkContactStatus = (recipientId) => {
  return dispatch => {
    database.ref(`status/${recipientId}`)
      .on('value', snapshot => {
        dispatch({ type: CHECK_CONTACT_STATUS, payload: (snapshot.val()) ? snapshot.val().status : snapshot.val() });
      });
  }
}
