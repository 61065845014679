import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import reducers from './store/reducers';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import './i18n';
import ThemeProvider from './context/Theme';
import SearchingProvider from './context/Searching';

const persistConfig = {
  key: 'root',
  storage,
  timeout: 0
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer, applyMiddleware(thunk));

const persistedStore = persistStore(store);

ReactDOM.render(
  <>
    <Provider store={store}>
      <ThemeProvider>
        <SearchingProvider>
          <PersistGate persistor={persistedStore} loading={false}>
            <App />
          </PersistGate>
        </SearchingProvider>
      </ThemeProvider>
    </Provider>
  </>,
  document.getElementById('root')
);

serviceWorker.register();
