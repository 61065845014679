import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Heading = styled.p`
  color: ${props => props.theme.text.primary};
  margin: 20px 0 10px 0;
  text-align: center;
`;

export const GoBack = styled.span`
  color: ${props => props.theme.text.secondary};
  text-align: center;

  @media screen and (min-width: 1024px) {
    cursor: pointer;
    transition: all .2s ease;

    &:hover {
      color: ${props => props.theme.text.primary};
    }
  }
`;