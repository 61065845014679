import React, { useContext, useState, useRef, useEffect } from 'react';

import MDIcon from '@mdi/react';
import { mdiMonitorScreenshot } from '@mdi/js';
import { InlineIcon } from '@iconify/react';
import roundLocationOn from '@iconify/icons-ic/round-location-on';
import roundCalendarToday from '@iconify/icons-ic/round-calendar-today';
import roundAccessTime from '@iconify/icons-ic/round-access-time';
import { useSpring } from 'framer-motion';
import { ThemeContext } from 'styled-components';

import useWindowSize from '../../utils/hooks/useWindowSize';
import { SERVER_ADDRESS } from '../../constraints/types';

import { Container, StationImages, StationImagesSlider, StationDetail, StationDetailText, StationDetails, StationInner, StationLocation, StationLocationText, StationSubTitle, StationSubTitleText, StationName, StationImage } from './styles';

const SearchStationsItem = ({ station, toggleSelectStation, goToStation, checked, selecting, filter }) => {
  const [ activeSnapPoint, setActiveSnapPoint ] = useState(0);
  const [ stImages ] = useState([{storage_ref: `${SERVER_ADDRESS}${station.attributes['avatar-original']}`}, ...station.attributes['image-stations']]);
  const [ imgHeight, setImgHeight ] = useState(188);
  const size = useWindowSize();
  const theme = useContext(ThemeContext);
  const x = useSpring(0, { stiffness: 300, damping: 200, ease: [0.6, 0.05, -0.01, 0.99] });
  const imgRef = useRef(null);

  useEffect ( () => {
    if (imgRef.current) {  
      const h = Math.ceil(imgRef.current.offsetWidth/(16/9));
      setImgHeight(h);
    }
  }, [imgRef]);

  const handleDragEnd = (evt, info, count) => {
    evt.preventDefault();
    evt.stopPropagation();

    let snapPoints = [];
    const endPoint = Math.round(info.point.x);

    if (endPoint >= 0) return;

    for (let i = 0; i < count; i++) {
      snapPoints.push(-(size.width - 40) * i);
    }

    if (info.velocity.x < -500 && endPoint > snapPoints[snapPoints.length - 1]) {
      setActiveSnapPoint(activeSnapPoint + 1);
      x.set(snapPoints[activeSnapPoint + 1]);
    }
    else if (info.velocity.x > 500 && endPoint < 0) {
      setActiveSnapPoint(activeSnapPoint - 1);
      x.set(snapPoints[activeSnapPoint - 1]);
    }
    else {
      const closest = snapPoints.reduce((prev, curr) => {
        return (Math.abs(curr - endPoint) < Math.abs(prev - endPoint) ? curr : prev);
      });

      setActiveSnapPoint(snapPoints.indexOf(closest));
      x.set(Math.round(closest));
    }
  }

  const handleClick = () => {
    if (selecting) {
      if (checked) {
        toggleSelectStation(station, 'uncheck');
      }
      else {
        toggleSelectStation(station, 'check');
      }
    }
    else {
      goToStation(station);
    }
  }

  return (
    <Container
      key={station.id}
      className='noSelect'
      checked={checked}
      selecting={selecting}
      onClick={handleClick}
      onContextMenu={(evt) => evt.preventDefault()}
    >
      <StationInner checked={checked}>
        <StationImages checked={checked} ref={imgRef} imgHeight={imgHeight}>
          {
            size.width >= 1024 && stImages.map((img, index) => (
              <img src={img.storage_ref} key={index} style={{ width: '100%', height: '100%' }} />
            ))
          }
          { size.width < 1024 && 
            <StationImagesSlider
              drag='x'
              dragMomentum={false}
              dragElastic={0.05}
              dragConstraints={{ left: -stImages.length * (size.width - 40) + (size.width - 40), right: 0 }}
              onDragEnd={(evt, info) => handleDragEnd(evt, info, stImages.length)}
              style={{ x }}
            >
              {
                stImages.map((img, index) => (
                  <StationImage bg={img.storage_ref} key={index} />
                ))
              }
            </StationImagesSlider>
          }
        </StationImages>
        <StationName>{ filter === 'keywords' ? station.attributes.name : filter === 'tags' ? `@${station.attributes.uniquename}` : station.attributes['category-name']}</StationName>
        { filter !== 'keywords' &&
          <StationSubTitle>
            <StationSubTitleText>{station.attributes.name}</StationSubTitleText>
          </StationSubTitle>
        }
        <StationLocation>
          <InlineIcon
            icon={roundLocationOn}
            width={18}
            height={18}
            color={theme.text.secondary}
          />
          <StationLocationText>{station.attributes.address}</StationLocationText>
        </StationLocation>
        <StationDetails>
          <StationDetail checked={checked}>
            <InlineIcon
              icon={roundCalendarToday}
              width={14}
              height={14}
              color={theme.text.secondary}
            />
            <StationDetailText>{`${station.attributes['week-day-begin'].substring(0, 3)} - ${station.attributes['week-day-end'].substring(0, 3)}`}</StationDetailText>
          </StationDetail>
          <StationDetail checked={checked}>
            <InlineIcon
              icon={roundAccessTime}
              width={14}
              height={14}
              color={theme.text.secondary}
            />
            <StationDetailText>{`${station.attributes['open-start']}h - ${station.attributes['open-end']}h`}</StationDetailText>
          </StationDetail>
          <StationDetail checked={checked}>
            <MDIcon
              path={mdiMonitorScreenshot}
              size='14px'
              color={theme.text.secondary}
            />
            <StationDetailText>{`${station.attributes.inches}"`}</StationDetailText>
          </StationDetail>
        </StationDetails>
      </StationInner>
    </Container>
  );
}

export default SearchStationsItem;
