import React, { useEffect, useContext, useState } from 'react';

import { connect } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { Icon } from '@iconify/react';
import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import roundSearch from '@iconify/icons-ic/round-search';
import { useHistory, useParams } from 'react-router-dom';
import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import StationItem from '../../components/StationItem';
import useWindowSize from '../../utils/hooks/useWindowSize';

import { Container, Header, BtnBack, Title, List, BtnSearch, Inputs } from './styles';

const ListStations = ({ lastStations, userStations, userAds, type = null, parentClickHandling = null }) => {

  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const history = useHistory();
  const size = useWindowSize();
  let params = useParams();

  const [ sort, setSort ] = useState('+id');
  const [ items, setItems ] = useState([]);

  useEffect(() => {
    if (type === 'select-ad') setItems(userAds)
    else if (params.type === 'featured') setItems(lastStations.data)
    else setItems(userStations.data)
  }, []);

  const handleChangeSort = (evt) => {
    setSort(evt.target.value);

    switch (evt.target.value) {
      case '+id':
        setItems(_.orderBy(items, ['id'], ['desc']));
        break;
      case '-id':
        setItems(_.orderBy(items, ['id'], ['asc']));
        break;
      case '+az':
        setItems(_.orderBy(items, [function(o) { return o.attributes.name }], ['asc']));
        break;
      case '-za':
        setItems(_.orderBy(items, [function(o) { return o.attributes.name }], ['desc']));
        break;
      default:
        setItems(_.orderBy(items, ['id'], ['desc']));
        break;
    }
  }

  const componentClickHandling = () => {
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  return (
    <Container
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
      width={size.width}
      height={size.height}
    >
      <Header>
        <BtnBack onClick={() => history.goBack()}>
          <Icon
            icon={roundArrowBack}
            color={theme.iconTintColor.active}
            width={24}
            height={24}
          />
        </BtnBack>
        <Title>{ (type === 'select-ad') ? 'Selecionar anúncio' : (params.type === 'featured') ? t('dashboard.featured') : t('listStations.myStations') }</Title>
        <BtnSearch>
          <Icon
            icon={roundSearch}
            color={theme.iconTintColor.active}
            width={24}
            height={24}
          />
        </BtnSearch>
      </Header>

      <List>
        <Inputs>
          <FormControl variant="filled" style={{ marginRight: 15 }}>
            <InputLabel id='sort-label'>{ t('listStations.orderBy') }</InputLabel>
            <Select
              labelId='sort-label'
              id='sort-select'
              value={sort}
              onChange={handleChangeSort}
              style={{
                minWidth: '180px'
              }}
            >
              <MenuItem value='+id'>{ t('listStations.orderBy.recent') }</MenuItem>
              <MenuItem value='-id'>{ t('listStations.orderBy.older') }</MenuItem>
              <MenuItem value='+az'>{ t('listStations.orderBy.alphabetical') }</MenuItem>
              <MenuItem value='-za'>{ t('listStations.orderBy.alphabeticalInverse') }</MenuItem>
            </Select>
          </FormControl>
        </Inputs>

        {
          items.map(station => (
            <StationItem
              key={station.id}
              station={station}
              parentClickHandling={parentClickHandling}
            />
          ))
        }
      </List>
    </Container>
  )
}

const mapStateToProps = state => ({
  lastStations: state.StationsReducer.lastStations,
  userStations: state.StationsReducer.userStations,
  userAds: state.AdsReducer.userAds
});

export default connect(mapStateToProps, { })(ListStations);
