import React, { useEffect, useState } from 'react';

import { interpolate } from 'react-spring';
import useZoom from '../../utils/hooks/useZoom';

import { Container, Image } from './styles';

const GalleryImage = ({ url, onScale, minScale = 1, maxScale = 4, active, setZoomMode }) => {
  const [ mode, setMode ] = useState('landscape');

  const [ element, scale, translateX, translateY, middleTouchOnElement ] = useZoom({
    minScale,
    maxScale,
    onScale
  })

  useEffect(() => {
    document.addEventListener('gesturestart', preventDefault)
    document.addEventListener('gesturechange', preventDefault)

    if (element) {
      const width = element.current.naturalWidth;
      const height = element.current.naturalHeight;

      if (height > width) {
        setMode('portrait');
      }
    }

    return () => {
      document.removeEventListener('gesturestart', preventDefault)
      document.removeEventListener('gesturechange', preventDefault)
    }
  }, [element]);

  useEffect(() => {
    if (scale.value > 1) {
      setZoomMode(true);
    }
    else {
      setZoomMode(false);
    }
  }, [onScale]);

  const preventDefault = (evt) => {
    evt.preventDefault();
  }

  return (
    <Container active={active ? 1 : 0}>
      <Image
        ref={element}
        mode={mode}
        src={url}
        draggable={false}
        style={{
          transform: interpolate(
            [scale, translateX, translateY],
            (sc, x, y) => `translate3d(${x}px, ${y}px, 0) scale3d(${sc}, ${sc}, 1)`
          ),
          transformOrigin: middleTouchOnElement.interpolate((x, y) => `${x}px ${y}px 0`),
        }}
      />
    </Container>
  );
}

export default GalleryImage;