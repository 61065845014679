import React, { createContext, useContext, useState } from 'react';

const SearchingContext = createContext();

export default function SearchingProvider ({ children }) {
  const [ searching, setSearching ] = useState({
    active: false,
    mapMode: false,
    componentMode: false
  });

  return (
    <SearchingContext.Provider value={{ searching, setSearching }}>
      { children }
    </SearchingContext.Provider>
  )
}

export function useSearching () {
  const context = useContext(SearchingContext);

  if (!context)
    throw new Error('useSearching must be used within a SearchingProvider');

  const { searching, setSearching } = context;
  return { searching, setSearching };
}
