import _ from 'lodash';

import {
  GET_COMPANY_ADS,
  GET_COMPANY_ADS_SUCCESS,
  GET_COMPANY_ADS_ERROR,
  SET_USER_ADS,
  SIGN_OUT_SUCCESS,
  SEND_AD_TO_STATION,
  SEND_AD_TO_STATION_SUCCESS,
  SEND_AD_TO_STATION_ERROR,
  UPDATE_SEND_AD_TO_STATION,
  CREATE_AD,
  CREATE_AD_SUCCESS,
  CREATE_AD_ERROR,
  UPDATE_CREATE_AD,
  UPDATE_CREATED_AD_ALERT,
  UPDATE_ST_SERVICE_DATA,
  APPROVE_SERVICE_STATION,
  APPROVE_SERVICE_STATION_SUCCESS,
  APPROVE_SERVICE_STATION_ERROR,
  UPDATE_APPROVE_SERVICE_STATION,
  PAY_SERVICE_STATION,
  PAY_SERVICE_STATION_SUCCESS,
  PAY_SERVICE_STATION_ERROR,
  UPDATE_PAID_SERVICE_STATION,
  GET_STATION_ADS,
  GET_STATION_ADS_SUCCESS,
  GET_STATION_ADS_ERROR,
  DELETE_OWNER_AD,
  DELETE_OWNER_AD_SUCCESS,
  DELETE_OWNER_AD_ERROR,
  UPDATE_DELETE_OWNER_AD,
  UPDATE_ORIGIN,
  UPDATE_APP
} from '../../constraints/types';

const INITIAL_STATE = {
  companyAds: {
    userAds: [],
    loading: false,
    data: [],
    error: null
  },
  sendAdToStation: {
    data: [],
    loading: false,
    finish: false,
    error: null
  },
  createAd: {
    data: [],
    loading: false,
    finish: false,
    error: null
  },
  createdAdAlert: false,
  campaign: [],
  stServiceData: {
    img: null,
    serviceId: null,
    selectedStations: [],
    weeks: 1
  },
  approveServiceStation: {
    data: [],
    loading: false,
    error: false
  },
  paidServiceStation: {
    data: [],
    loading: false,
    error: false
  },
  stationAds: {
    data: [],
    loading: false,
    error: false
  },
  deleteOwnerAd: {
    loading: false,
    error: false,
    success: false
  },
  addOrigin: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_ADS:
        return { ...state, companyAds: { loading: true, data: state.companyAds.data, error: false } };
    case GET_COMPANY_ADS_SUCCESS:
        return { ...state, companyAds: action.payload, userAds: action.payload };
    case GET_COMPANY_ADS_ERROR:
        return { ...state, companyAds: action.payload };
    case SET_USER_ADS:
        return { ...state, userAds: action.payload };
    case SEND_AD_TO_STATION:
        return { ...state, sendAdToStation: { data: [], loading: true, finish: false, error: false } }
    case SEND_AD_TO_STATION_SUCCESS:
        state.campaign.push(action.payload);
        return { ...state, sendAdToStation: action.payload }
    case SEND_AD_TO_STATION_ERROR:
        return { ...state, sendAdToStation: action.payload }
    case UPDATE_SEND_AD_TO_STATION:
        return { ...state, sendAdToStation: action.payload }
    case CREATE_AD:
        return { ...state, createAd: { data: [], loading: true, finish: false, error: false } }
    case CREATE_AD_SUCCESS:
        return { ...state, createAd: action.payload, createdAdAlert: true }
    case CREATE_AD_ERROR:
        return { ...state, createAd: action.payload }
    case UPDATE_CREATE_AD:
        return { ...state, createAd: action.payload }
    case UPDATE_CREATED_AD_ALERT:
        return { ...state, createdAdAlert: action.payload }
    case UPDATE_ST_SERVICE_DATA:
        return { ...state, stServiceData: action.payload }
    case APPROVE_SERVICE_STATION:
        return { ...state, approveServiceStation: { data: [], loading: true, error: false } }
    case APPROVE_SERVICE_STATION_SUCCESS:
        return { ...state, approveServiceStation: action.payload }
    case APPROVE_SERVICE_STATION_ERROR:
        return { ...state, approveServiceStation: action.payload }
    case UPDATE_APPROVE_SERVICE_STATION:
        return { ...state, approveServiceStation: action.payload }
    case PAY_SERVICE_STATION:
        return { ...state, paidServiceStation: { data: [], loading: true, error: false } }
    case PAY_SERVICE_STATION_SUCCESS:
        return { ...state, paidServiceStation: action.payload }
    case PAY_SERVICE_STATION_ERROR:
        return { ...state, paidServiceStation: action.payload }
    case UPDATE_PAID_SERVICE_STATION:
        return { ...state, paidServiceStation: action.payload }
    case GET_STATION_ADS:
        return { ...state, stationAds: { data: [], loading: true, error: false } }
    case GET_STATION_ADS_SUCCESS:
        return { ...state, stationAds: action.payload }
    case GET_STATION_ADS_ERROR:
        return { ...state, stationAds: action.payload }
    case DELETE_OWNER_AD:
        return { ...state, deleteOwnerAd: { loading: true, error: false, success: false } }
    case DELETE_OWNER_AD_SUCCESS:
        _.remove(state.stationAds.data, function(o) {
          return o.service_station_id === action.payload
        });
        return { ...state, deleteOwnerAd: { loading: false, error: false, success: true } }
    case DELETE_OWNER_AD_ERROR:
        return { ...state, deleteOwnerAd: action.payload }
    case UPDATE_DELETE_OWNER_AD:
        return { ...state, deleteOwnerAd: action.payload }
    case UPDATE_ORIGIN:
        return { ...state, addOrigin: action.payload }
    case SIGN_OUT_SUCCESS:
        return INITIAL_STATE;
    case UPDATE_APP:
        return INITIAL_STATE;
    default:
        return state;
  }
}
