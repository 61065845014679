import React, { useState, useContext } from 'react';

import _ from 'lodash';
import { CircularProgress } from '@material-ui/core';
import { useSpring } from 'framer-motion';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiCheckBold, mdiAlertCircleOutline, mdiTrashCanOutline } from '@mdi/js';
import { ThemeContext } from 'styled-components';

import useWindowSize from  '../../utils/hooks/useWindowSize';
import animationData from '../../assets/lotties/upload-arrow.json';

import { Container, Bubble, Screen, Title, UploadList, UploadItem, UploadItemImage, UploadItemTitle, UploadItemProgress, BtnMinimize, VideoContainer, ExtText } from './styles';

const ManageStationImgsFeedback = ({ manageStationImages, operationToken }) => {
  const theme = useContext(ThemeContext);
  const [ dragX, ] = useState(0);
  const [ dragY, ] = useState(0);
  const [ screenShow, setScreenShow ] = useState(true);

  const { t } = useTranslation();
  const { stImgsStatuses } = manageStationImages;
  const sentImgs = _.filter(stImgsStatuses, (img) => { return img.hasOwnProperty('sent') });
  const deletedImgs = _.filter(stImgsStatuses, (img) => { return img.hasOwnProperty('deleted') });

  const size = useWindowSize();
  const x = useSpring(dragX, { stiffness: 600, damping: 200, ease: [0.6, 0.05, -0.01, 0.99] });
  const y = useSpring(dragY, { stiffness: 600, damping: 200, ease: [0.6, 0.05, -0.01, 0.99] });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const handleBubbleDragEnd = (evt) => {
    if (evt.pageX > (size.width / 2 + 30)) {
      x.set(0);
    }
    else {
      x.set(-size.width + 65);
    }
  }

  // não é necessário renderizar nada se a pilha de uploads estiver vazia
  if (manageStationImages.status !== 'sending' && manageStationImages.status !== 'success') return false

  return (
    <Container>
      {
        size.width < 768 && (
          <>
            <Bubble
              drag
              dragConstraints={{
                top: 0,
                left: -size.width + 55,
                right: 0,
                bottom: (size.height - 200),
              }}
              whileTap={{ scale: 0.9 }}
              dragElastic={0.2}
              dragMomentum={false}
              onDragEnd={handleBubbleDragEnd}
              style={{ x, y }}
              screenShow={screenShow}
              onClick={() => setScreenShow(true)}
            >
              <Lottie
                options={defaultOptions}
                width={80}
                height={80}
                isClickToPauseDisabled={true}
              />
            </Bubble>

            <Screen screenShow={screenShow}>
              { sentImgs.length &&
                <>
                  <Title>Arquivos enviados</Title>

                  <UploadList>
                  { // este componente apenas percorre cada item da pilha de uploads, montando uma tabela exibindo seus progressos
                    sentImgs.map((uploadItem, index) => {
                      return (
                        <UploadItem key={index}>
                          <UploadItemImage src={uploadItem.url} />
                          <UploadItemTitle>{ uploadItem.name }</UploadItemTitle>
                          <UploadItemProgress>
                            { uploadItem.sent &&
                              <Icon
                                path={mdiCheckBold}
                                size='24px'
                                color={theme.colors.primary}
                              />
                            }
                            { !uploadItem.sent &&
                              <Icon
                                path={mdiAlertCircleOutline}
                                size='24px'
                                color={theme.colors.error}
                              />
                            }
                          </UploadItemProgress>
                        </UploadItem>
                      )
                    })
                  }
                  </UploadList>
                </>
              }
              { deletedImgs.length && 
                <>
                  <Title>Arquivos excluídos</Title>

                  <UploadList>
                  { // este componente apenas percorre cada item da pilha de uploads, montando uma tabela exibindo seus progressos
                    deletedImgs.map((uploadItem, index) => {
                      return (
                        <UploadItem key={index}>
                          <UploadItemImage src={uploadItem.url} />
                          <UploadItemTitle>{ uploadItem.mime_type }</UploadItemTitle>
                          <UploadItemProgress>
                            { uploadItem.deleted &&
                              <Icon
                                path={mdiTrashCanOutline}
                                size='24px'
                                color={theme.colors.primary}
                              />
                            }
                            { !uploadItem.deleted &&
                              <Icon
                                path={mdiTrashCanOutline}
                                size='24px'
                                color={theme.colors.error}
                              />
                            }
                          </UploadItemProgress>
                        </UploadItem>
                      )
                    })
                  }
                  </UploadList>
                </>
              }
              <BtnMinimize onClick={() => setScreenShow(false)}> Fechar </BtnMinimize>
            </Screen>
          </>
        )
      }
    </Container>
  );
}

const mapStateToProps = state => ({
  manageStationImages: state.StationsReducer.manageStationImages,
  operationToken: state.StationsReducer.operationToken
});

export default connect(mapStateToProps, { })(ManageStationImgsFeedback);
