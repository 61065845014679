import {
  ADD_NOTIFICATION,
  SIGN_OUT_SUCCESS,
  GET_NOTIFICATIONS,
  SET_NOTIFICATION_READ,
  CURRENT_NEW_REQUEST_NOTIFICATION,
  CURRENT_SUCCESS_NOTIFICATION,
  UPDATE_APP
} from '../../constraints/types';

const INITIAL_STATE = {
  userNotifications: [],
  currentNotification: null,
  currentNotificationSuccess: null,
  unreadNotifications: 0
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
        return { ...state };
    case GET_NOTIFICATIONS:
        return { ...state, userNotifications: action.payload, unreadNotifications: action.unreadNotifications };
    case SET_NOTIFICATION_READ:
        return { ...state };
    case CURRENT_NEW_REQUEST_NOTIFICATION:
        return { ...state, currentNotification: action.payload };
    case CURRENT_SUCCESS_NOTIFICATION:
        return { ...state, currentNotificationSuccess: action.payload };
    case SIGN_OUT_SUCCESS:
        return INITIAL_STATE;
    case UPDATE_APP:
        return INITIAL_STATE;
    default:
        return state;
  }
}
