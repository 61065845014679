import _ from 'lodash';

import { database } from '../../firebase';

import {
  ADD_NOTIFICATION,
  GET_NOTIFICATIONS,
  SET_NOTIFICATION_READ,
  CURRENT_NEW_REQUEST_NOTIFICATION,
  CURRENT_SUCCESS_NOTIFICATION
} from '../../constraints/types';

import {
  development
} from '../../constraints/utils';

//////////////////////// ADD NOTIFICATION BEGIN ////////////////////////
/*
  This function is called when a new notification arrived
  adding to the store a new notification and inserting it
  at firebase RTDB
*/

export const addNotification = (notificationData, userId) => {
  const RTDBUrl = development ? `devMiNotifications/${userId}` : `miNotifications/${userId}`;

  return dispatch => {
    database.ref(RTDBUrl)
      .push(notificationData)
      .then(() => {
        dispatch({ type: ADD_NOTIFICATION });
      });
  }
};

/////////////////////// ADD NOTIFICATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
//////////////////////// GET NOTIFICATIONS BEGIN ////////////////////////
/*
  This function is called when the notification component is mounted
  creating a observer that will refresh the userNotifications information
  through the firebase RTDB
*/

export const getNotifications = (userId, userStations) => {
  return dispatch => {
    const RTDBUrl = development ? `devMiNotifications/${userId}` : `miNotifications/${userId}`;

    database.ref(RTDBUrl)
      .on('value', snapshot => {

        const notificationsAndUid = _.filter(snapshot.val(), (val, uid) => {
          val.uid = uid
          return val
        });

        let existingStations = [];
        // SECTION TO FILTER ACTIVATION NOTIFICATIONS
        // Checks if there'is no more existing station activation notification
        // or the existing activation notification refers to an already activated
        // station this one has to be removed from the user notifications
        ////////////////////////////////////////////////////////////////////////////
        if (userStations.hasOwnProperty('data') && userStations.data.length > 0) {
          const auxNotifications = [ ...notificationsAndUid ];
          const activationNotifications = _.filter(auxNotifications, function(o) {
            return o.type === 'activation';
          });
  
          if (activationNotifications.length > 0) {        
            userStations.data.forEach(station => {
              existingStations = [ ...existingStations, ..._.filter(activationNotifications, function(o) { return o.id === station.id && station.attributes.status === 'AA'; }) ];
            });

            _.remove(notificationsAndUid, function(o) {
              return o.type === 'activation'
            });
          }
        }
        ////////////////////////////////////////////////////////////////////////////

        const notificationsToRender = [ ...notificationsAndUid, ...existingStations ];

        const unreadNotifications = _.filter(notificationsToRender, function(o) { return !o.read }).length;

        dispatch({
          type: GET_NOTIFICATIONS,
          payload: _.orderBy(notificationsToRender, ['date'], ['desc']),
          unreadNotifications: unreadNotifications
        });
      });
  }
};

/////////////////////// GET NOTIFICATIONS END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
//////////////////////// SET NOTIFICATION READ BEGIN ////////////////////////
/*
  This function is called when the user taps on not visualized notification to
  turning the read field to true and updating it at firebase RTDB
*/

export const setNotificationRead = (notificationUid, userId) => {
  return dispatch => {
    const RTDBUrl = development ? `devMiNotifications/${userId}/${notificationUid}` : `miNotifications/${userId}/${notificationUid}`;

    database.ref(RTDBUrl)
      .update({
        read: true,
        important: false
      })
      .then(() => {
        dispatch({ type: SET_NOTIFICATION_READ });
      });
  }
};

/////////////////////// SET NOTIFICATION READ END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
//////////////////////// CURRENT NEW REQUEST NOTIFICATION BEGIN ///////////////
/*
  This function is used to handling the current new request is showing
  to user at AdApproval
*/

export const currentNewRequestNotification = (notification) => ({
  type: CURRENT_NEW_REQUEST_NOTIFICATION,
  payload: notification
});

/////////////////////// CURRENT NEW REQUEST NOTIFICATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
//////////////////////// CURRENT SUCCESS NOTIFICATION BEGIN ///////////////
/*
  This function is used to handling the approved annoucement
  made by the user, moving user to starting payment proccess
*/

export const currentSuccessNotification = (notification) => ({
  type: CURRENT_SUCCESS_NOTIFICATION,
  payload: notification
});
