import _ from 'lodash';

import {
  UPLOAD_START,
  UPLOAD_PROGRESS,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  FORCE_TOKEN,
  REMOVE_FROM_STACK,
  DELETE_FROM_BUCKET,
  DELETE_FROM_BUCKET_SUCCESS,
  DELETE_FROM_BUCKET_ERROR,
  UPDATE_DELETE_FROM_BUCKET,
  LIST_FOLDER,
  LIST_FOLDER_SUCCESS,
  LIST_FOLDER_ERROR,
  SIGN_OUT_SUCCESS
} from '../../constraints/types';

const INITIAL_STATE = {
  uploadStack: [],
  uploadToken: '',
  stationFiles: {
    loading: false,
    error: false,
    data: []
  },
  adsFiles: {
    loading: false,
    error: false,
    data: []
  },
  deleteFromBucket: {
    item: null,
    loading: false,
    error: false
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case UPLOAD_START:
          return { ...state, uploadStack: action.payload, uploadToken: action.uploadToken }
      case UPLOAD_PROGRESS:
          return { ...state, uploadStack: action.payload, uploadToken: action.uploadToken }
      case UPLOAD_SUCCESS:
          return { ...state, uploadStack: action.payload, uploadToken: action.uploadToken }
      case UPLOAD_ERROR:
          return { ...state, uploadStack: action.payload, uploadToken: action.uploadToken }
      case REMOVE_FROM_STACK:
          return { ...state, uploadStack: action.payload, uploadToken: action.uploadToken }
      case FORCE_TOKEN:
          return { ...state, uploadToken: action.uploadToken }
      case DELETE_FROM_BUCKET_ERROR:
          return { ...state, deleteFromBucket: action.payload }
      case DELETE_FROM_BUCKET:
          if (action.folder === 'ads') return { ...state, deleteFromBucket: { item: null, loading: true, error: false }, adsFiles: { loading: true, error: false, data: state.adsFiles.data }}
          return { ...state, deleteFromBucket: { item: null, loading: true, error: false }, stationFiles: { loading: true, error: false, data: state.stationFiles.data }}
      case DELETE_FROM_BUCKET_SUCCESS:
          if (action.folder === 'ads') {
            _.remove(state.adsFiles.data, { url: action.payload.url })
            return { ...state, deleteFromBucket: { item: action.payload, loading: false, error: false }, adsFiles: { loading: false, error: false, data: state.adsFiles.data }}
          }
          _.remove(state.stationFiles.data, { url: action.payload.url })
          return { ...state, deleteFromBucket: { item: action.payload, loading: false, error: false }, stationFiles: { loading: false, error: false, data: state.stationFiles.data }}
      case DELETE_FROM_BUCKET_ERROR:
          return { ...state, deleteFromBucket: action.payload }
      case UPDATE_DELETE_FROM_BUCKET:
          return { ...state, deleteFromBucket: action.payload }
      case LIST_FOLDER:
          if (action.folder === 'ads') return { ...state, adsFiles: action.payload }
          return { ...state, stationFiles: action.payload }
      case LIST_FOLDER_SUCCESS:
          if (action.folder === 'ads') return { ...state, adsFiles: action.payload }
          return { ...state, stationFiles: action.payload }
      case LIST_FOLDER_ERROR:
          if (action.folder === 'ads') return { ...state, adsFiles: action.payload }
          return { ...state, stationFiles: action.payload }
      case SIGN_OUT_SUCCESS:
          return INITIAL_STATE;
      default:
        return state;
  }
};
