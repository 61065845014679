import styled, { keyframes } from 'styled-components';
import { ButtonBase, Menu, Drawer, ListItemIcon, ListItemText, IconButton } from '@material-ui/core';
import { motion } from 'framer-motion';
import { rgba, darken } from 'polished';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};

  @media screen and (min-width: 1024px) {
    position: relative;
    padding: 80px 75px;
    width: 100%;
    overflow: hidden auto;

    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.background};
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.text.secondary};
      border-radius: 5px;
    }
  }
`;

export const Header = styled(motion.div)`
  position: fixed;
  top: ${props => !props.active ? '40px' : '0px'};
  left: 0;
  width: 100%;
  height: 60px;
  background: ${props => props.theme.colors.background};
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 55;
  pointer-events: none;
  transform: ${props => props.moveUp && !props.active ? 'translateY(-40px)' : 'none'};
  transition: all .3s cubic-bezier(.785,.135,.15,.86);

  @media screen and (min-width: 1024px) {
    pointer-events: auto;
    top: 0;
    transform: none;
  }
`;

export const BackButton = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: ${props => rgba(props.theme.colors.background, .9)} !important;
  pointer-events: auto;

  @media screen and (min-width: 1024px) {
    background: ${props => props.theme.title === 'dark' ? rgba(props.theme.colors.background, .8) : props.theme.colors.secondary} !important;
  }
`;

export const OptionsButton = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: ${props => rgba(props.theme.colors.background, .9)} !important;
  pointer-events: auto;

  @media screen and (min-width: 1024px) {
    background: ${props => props.theme.title === 'dark' ? rgba(props.theme.colors.background, .8) : props.theme.colors.secondary} !important;
  }
`;

export const BackButtonText = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 600;
  margin-left: 10px;
  cursor: pointer;
`;

export const HeaderTitle = styled(motion.p)`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  text-align: center;
  margin: auto;
  flex: 1;
  transition: all .15s cubic-bezier(.785,.135,.15,.86);
`;

export const EditImageBtn = styled(ButtonBase)`
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  background: ${props => rgba(props.theme.colors.background, .9)} !important;
  position: absolute !important;
  top: ${props => props.active ? '20px' : '10px'};
  right: 10px;
  z-index: 40;
`;

export const ActivateAlert = styled(ButtonBase)`
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 40px;
  text-align: center !important;
  background: #FEDFDD !important;
  z-index: 40 !important;
  color: #950405 !important;
  font-size: 0.875rem !important;
  transition: all .15s ease !important;

  @media screen and (min-width: 1024px) {
    right: 75px !important;
    top: 80px !important;
    width: 360px !important;
    border-radius: 5px !important;
    cursor: pointer !important;

    &:hover {
      background: ${darken(.05, '#FEDFDD')} !important;
    }
  }
`;

export const BlockAlert = styled(ButtonBase)`
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 40px;
  text-align: center !important;
  background: #ffd947 !important;
  z-index: 40 !important;
  color: #000 !important;
  font-size: 0.875rem !important;
  transition: all .15s ease !important;
  border-bottom-width: 4px !important;
  border-top-width: 4px !important;
  border-color: #000 !important;
  border-style: dashed !important;
  font-weight: 600 !important;

  @media screen and (min-width: 1024px) {
    right: 75px !important;
    top: 80px !important;
    width: 360px !important;
    border-radius: 5px !important;
    cursor: pointer !important;

    &:hover {
      background: ${darken(.05, '#ffd947')} !important;
    }
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => `url(${props.bg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .15s ease;
`;

const handleDisplayType = num => {
  switch (num) {
    case 2:
      return `display: flex;
              flex-direction: row;
              align-items: flex-start;`;
    case 3:
      return `display: grid;
              grid-gap: 15px;
              grid-template-columns: 1fr 1fr;
              grid-template-rows: 1fr 1fr;
              grid-template-areas: 'first second'
                                   'first third'`;
    case 4:
      return `display: grid;
              grid-gap: 15px;
              grid-template-columns: 1fr 1fr 1fr;
              grid-template-rows: 1fr 1fr;
              grid-template-areas: 'first second second'
                                   'first third fourth'`;
    default:
      return `display: flex;
              flex-direction: row;
              align-items: flex-start;`;
  }
}

export const ImageContainer = styled.div`
  position: absolute;
  top: ${props => !props.active ? '40px' : '0px'};
  left: 0;
  width: 100%;
  height: 360px;
  overflow: hidden;

  &:active ${Image} {
    filter: brightness(75%);
  }

  @media screen and (min-width: 1024px) {
    position: relative;
    width: calc(100% - 390px);
    top: 0px;

    ${({ numberOfImages }) => handleDisplayType(numberOfImages)};
  }
`;

const handleAreaName = index => {
  switch (index) {
    case 0:
      return 'first';
    case 1:
      return 'second';
    case 2:
      return 'third';
    case 3:
      return 'fourth';
    default:
      return '';
  }
}

export const DesktopImage = styled.div`
  background: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px;
  height: 100%;
  flex: 1;
  border: 1px solid ${props => props.theme.separator};
  grid-area: ${({ index }) => handleAreaName(index)};
  transition: all .15s ease;
  cursor: pointer;

  &:hover {
    filter: brightness(75%);
  }
`;

export const Overlay = styled(motion.div)`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
`;

export const Content = styled(motion.div)`
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  border-radius: 10px 10px 0 0;
  padding: 20px 20px 80px 20px;
  z-index: 30;
  position: absolute;
  top: 350px;
  right: 0;
  width: 100%;

  @media screen and (min-width: 1024px) {
    position: fixed;
    right: 75px;
    top: ${props => props.active ? '80px' : '135px'};
    width: 360px;
    border-radius: 5px;
    padding: 20px;
    background: ${props => rgba(props.theme.separator, .35)};
    border: 1px solid ${props => rgba(props.theme.separator, .35)};
  }
`;

export const Title = styled.p`
  color: ${props => props.theme.text.primary};
  font-size: 1.5rem;
  font-weight: 700;
`;

export const Tag = styled.p`
  color: ${props => props.theme.text.secondary};
  margin-top: 5px;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
`;

export const SecondaryText = styled.p`
  color: ${props => props.theme.text.secondary};
  margin-left: 5px;
`;

export const Separator = styled.div`
  height: 1px;
  background: ${props => props.theme.separator};
  margin: 20px 0 15px 0;

  @media screen and (min-width: 1024px) {
    margin: 15px 0;
  }
`;


export const AdsCarouselContainer = styled.div`
  padding-top: 26px;
`;

export const AdsCarousel = styled.div`
  position: relative;
  width: calc(100% - 390px);
  top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const DescriptionContainer = styled.div`
  @media screen and (min-width: 1024px) {
    position: relative;
    width: calc(100% - 430px);
    top: 0px;
    margin-top: 30px;
  }
`;

export const DescriptionTitle = styled.div`
  font-weight: 700;
  margin-bottom: 5px;
  color: ${props => props.theme.text.primary};
  display: flex;
  align-items: center;

  > svg {
    margin-left: 5px;
  }
`;

export const Description = styled.p`
  color: ${props => props.theme.text.secondary};
  line-height: 1.5rem;
  margin-bottom: 20px;
`;

export const LocationTitle = styled.p`
  font-weight: 700;
  margin-bottom: 10px;
  color: ${props => props.theme.text.primary};
`;

export const Location = styled.p`
  color: ${props => props.theme.text.secondary};
  margin-bottom: 20px;

  > svg {
    margin-right: 5px;
  }
`;

export const OwnerContainer = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  padding: 0 15px !important;
  background: ${props => props.theme.title === 'dark' ? rgba(props.theme.colors.background, .4) : props.theme.colors.secondary} !important;
  border-radius: 5px !important;
  height: 70px;
  margin-bottom: 20px !important;
  width: 100%;

  > svg {
    margin-left: auto !important;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 0 !important;
    background: ${props => props.theme.title === 'dark' ? rgba(props.theme.colors.background, .8) : props.theme.colors.secondary} !important;
  }
`;

export const OwnerImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
`;

export const OwnerName = styled.p`
  color: ${props => props.theme.text.primary};
  text-align: left;
  margin-right: 15px;
`;

export const StationDetails = styled.div`
  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const DetailsTitle = styled.p`
  font-weight: 700;
  margin-bottom: 5px;
  color: ${props => props.theme.text.primary};
`;

export const Details = styled.p`
  color: ${props => props.theme.text.secondary};
  margin-bottom: 20px;
  white-space: pre-wrap;
  line-height: 1.5rem;
`;

export const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  transform: ${props => props.hide ? 'translateY(100%)' : 'translateY(0)'};
  z-index: 35;
  transition-delay: ${props => props.hide ? '0s' : '.15s'};

  @media screen and (min-width: 1024px) {
    position: relative;
    background: transparent;
    padding: 0;
    height: auto;
    margin-top: 20px;
    transform: none;
  }
`;

export const BtnRealTime = styled(ButtonBase)`
  border: 1px solid ${props => props.theme.separator} !important;
  margin-right: 15px !important;
  border-radius: 5px !important;
  height: 40px;
  width: 40px;
  display: ${props => props.active ? 'flex !important' : 'none !important'};
`;

export const RightButton = styled(ButtonBase)`
  flex: 1 !important;
  font-weight: 700 !important;
  background: ${props => props.disabled ? props.theme.text.secondary : props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
`;

export const CustomMenu = styled(Menu)`
  .MuiPaper-root {
    background: ${props => props.theme.colors.background} !important;
  }
`;

export const BtnEdit = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: ${props => props.theme.title === 'dark' ? rgba(props.theme.colors.secondary, .8) : props.theme.colors.background} !important;
  margin-left: auto !important;
  transform: translateX(10px) !important;

  @media screen and (min-width: 1024px) {
    background: ${props => rgba(props.theme.separator, .35)} !important;
  }
`;

export const CustomDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background: ${props => props.theme.colors.secondary} !important;
    border-radius: 10px 10px 0 0 !important;
  }

  @media screen and (min-width: 1024px) {
    .MuiDrawer-paper {
      margin: 0 320px !important;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      max-height: 90% !important;
      overflow-y: auto !important;
    }
  }
`;

export const DrawerContainer = styled.div`
  width: 100%;
  padding: 30px 20px 80px 20px;
`;

export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  display: flex;
  align-items: center;
  padding: 0 20px;
  transform: ${props => props.hide ? 'translateY(100%)' : 'translateY(0)'};
  z-index: 35;
  transition-delay: ${props => props.hide ? '0s' : '.15s'};
`;

export const DrawerBtnActionLeft = styled(ButtonBase)`
  border: 1px solid ${props => props.theme.separator} !important;
  margin-right: 15px !important;
  border-radius: 5px !important;
  height: 40px;
  flex: 1 !important;
`;

export const DrawerBtnActionRight = styled(RightButton)``;

export const TagContainer = styled.div`
  position: relative;

  .MuiInputBase-input,
  .MuiFilledInput-input,
  .MuiInputBase-inputAdornedStart,
  .MuiFilledInput-inputAdornedStart {
    padding-right: 60px;
  }
`;


// Spinner keyframes and elements
const svgRotate = keyframes`
  0% {
		transform: translateY(-55%) rotateZ(0deg);
  }
  100% {
		transform: translateY(-55%) rotateZ(360deg);
  }
`;

const spinCircle = keyframes`
  0%, 25% {
		stroke-dashoffset: 55;
		transform: rotate(0deg);
  }
	50%, 75% {
		stroke-dashoffset: 25;
		transform: rotate(45deg);
  }
	100% {
		stroke-dashoffset: 55;
		transform: rotate(360deg);
  }
`;

export const Spinner = styled(motion.svg)`
  position: absolute;
  top: 50%;
  right: 8px;
  z-index: 5;
  transform: translateY(-55%) rotateZ(0deg);
  width: 30px;
  height: 30px;
  animation: ${svgRotate} 2s linear infinite;
`;

export const CircleLoader = styled.circle`
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dashoffset: 55;
  stroke-dasharray: 63;
  transform-origin: 50% 50%;
  animation: ${spinCircle} 1.4s cubic-bezier(.785,.135,.15,.86) infinite both;
`;

export const TagStatusBadge = styled(motion.div)`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-55%);
  opacity: 1;
`;

export const OptionsMenu = styled(Menu)`
  .MuiMenu-paper {
    margin-top: 5px !important;
    border-radius: 15px !important;
    background: ${props => props.theme.colors.background} !important;
  }
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.separator};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CustomListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 40px;
    padding-right: 10px;
  }
`;
/*
export const CustomListItemText = styled(ListItemText)`
  .MuiTypography-body1 {
    font-size: 5px !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
  }
`;
*/

export const CustomListItemWrapper = styled.div`
`;

export const CustomListItemText = styled.p`
  font-size: 1rem !important;
  font-weight: 700 !important;
`;

export const CustomListItemTextSmall = styled.p`
  font-size: .8rem !important;
  font-weight: 600 !important;
`;

export const BtnClose = styled(IconButton)`
  width: 40px;
  height: 40px;
  transform: translateX(10px);
  margin-left: auto !important;

  > span > svg > path {
    fill: ${props => props.theme.iconTintColor.inactive} !important;
  }
`;