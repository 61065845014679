import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: absolute;
  bottom: 140px;
  right: 20px;
  z-index: 3;
  @media screen and (min-width: 1024px) {
    bottom: 90px;
    right: 30px;
  }
`;

export const WhatsappIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
`;

export const Link = styled.a`
  padding-top: 25px;
`;
