import React, { useContext } from 'react';

import { Tooltip } from '@material-ui/core';
import { ThemeContext } from 'styled-components';
import { Icon } from '@iconify/react';
import roundEdit from '@iconify/icons-ic/round-edit';
import roundDelete from '@iconify/icons-ic/round-delete';
import roundError from '@iconify/icons-ic/round-error';
import { useSpring, useTransform } from 'framer-motion';
import { useHistory } from 'react-router-dom';

import { SERVER_ADDRESS } from '../../constraints/types';

import { Container, StationInner, StationContainer, StationImg, StationContent, StationTitle, StationAddress, InactiveAlert, StationActions, BtnEditStation, BtnEditStationInner, BtnDeleteStation, BtnDeleteStationInner } from './styles';

const StationItem = ({ station, parentClickHandling = () => { return false; } }) => {
  const theme = useContext(ThemeContext);
  const history = useHistory();

  const x = useSpring(0, { stiffness: 300, damping: 200, ease: [0.6, 0.05, -0.01, 0.99] });
  const scale = useTransform(x, [0, -120], ['scale(0.85)', 'scale(1)']);
  const opacity = useTransform(x, [0, -120], [0, 1]);

  const handleDragEnd = (_, info) => {
    const endPoint = Math.round(info.point.x);

    if (info.velocity.x < -500) {
      x.set(-150);
    }
    else if (info.velocity.x > 500) {
      x.set(0);
    }
    else {
      if (endPoint <= -120) {
        x.set(-150);
      }
      else {
        x.set(0);
      }
    }
  }

  const handleSelect = (station) => {
    if (parentClickHandling) parentClickHandling(station);
    else {
      history.push({
        pathname: `/station/${station.id}`,
        state: {
          station: station,
        }
      });
    }
    if (station.type === 'professional-services') history.goBack();
  }

  const { type, id } = station;
  const { 'avatar-thumb': avatarThumb, 'avatar-medium': avatarMedium, name, description, address, status } = station.attributes;

  return (
    <Container
      key={id}
      onContextMenu={(e) => e.preventDefault()}
    >
      <StationInner>
        <StationContainer onClick={() => handleSelect(station)}>
          <StationImg
            bg={(type === 'stations') ? `${SERVER_ADDRESS}${avatarThumb}` : `${SERVER_ADDRESS}${avatarMedium}` }
            type={type}
          />
          <StationContent>
            <StationTitle>
              {(name.length > 30) ? `${name.substring(0,30)}...` : name }
            </StationTitle>
            <StationAddress>
              {(type === 'stations') ? address : (description.length > 30) ? `${description.substring(0,30)}...` : description }
            </StationAddress>
          </StationContent>

          {
            (status === 'AA' && type === 'stations') && (
              <InactiveAlert>
                <Icon
                  icon={roundError}
                  color='#fff'
                  width={16}
                  height={16}
                />
              </InactiveAlert>
            )
          }
        </StationContainer>
      </StationInner>
    </Container>
  );
}

export default StationItem;
