import axios from 'axios';

import {
  SERVER_ADDRESS,
  UPDATE_TOKEN,
  GET_USER_PIX,
  GET_USER_PIX_SUCCESS,
  GET_USER_PIX_ERROR,
  ADD_USER_PIX,
  ADD_USER_PIX_SUCCESS,
  ADD_USER_PIX_ERROR,
  DELETE_USER_PIX,
  DELETE_USER_PIX_SUCCESS,
  DELETE_USER_PIX_ERROR,
  GET_ORDER_STATUS,
  GET_ORDER_STATUS_SUCCESS,
  GET_ORDER_STATUS_ERROR,
  UPDATE_ORDER_STATUS,
  CLEAR_MANAGE_PIX,
} from '../../constraints/types';

// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
////////////////////////////// GET USER PIX ///////////////////////////////
/*
  Endpoint to get all user pix keys
*/

export const getUserPix = (credentials, previousData) => {
    return dispatch => {
      const payload = {
          data: previousData,
          loading: true,
          error: false
      }
      dispatch({ type: GET_USER_PIX, payload });
      const url = `${SERVER_ADDRESS}/api/v1/pix_users`
      axios.get(url, {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          'X-Access-Token': credentials.token,
          'X-User-Email': credentials.email,
        }
      })
      .then(res => {
        // getting the new token if is present
        var newToken = credentials.token;
        if (res.headers['x-access-token']) {
          newToken = res.headers['x-access-token'];
          dispatch({ type: UPDATE_TOKEN, newToken });
        }

        const payload = {
          data: res.data,
          error: false,
          loading: false
        }
        dispatch({ type: GET_USER_PIX_SUCCESS, payload });
      })
      .catch(error => {
        const payload = {
          data: previousData.data,
          error: error,
          loading: false
        }
        dispatch({ type: GET_USER_PIX_ERROR, payload });
      });
    }
}

////////////////////////////// GET USER PIX END ///////////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
////////////////////////////// GET USER PIX KEYS ///////////////////////////////
/*
  Endpoint to get all user pix keys
*/

export const addUserPix = (credentials, pixPayload) => {
  return dispatch => {
    const payload = {
        data: [],
        loading: true,
        error: false
    }
    dispatch({ type: ADD_USER_PIX, payload });
      if (pixPayload.name === 'cel'
          || pixPayload.name === 'cpf'
          || pixPayload.name === 'cnpj') pixPayload.value = pixPayload.value.replace(/\D/g,'');
          
      var data = JSON.stringify(
        {"data":
          {
            "type":"pix_users",
            "attributes":{
              "pix_key":pixPayload.value,
              "pix_type":pixPayload.label,
            }
          }
        }
      );
      var config = {
        method: 'post',
        url: `${SERVER_ADDRESS}/api/v1/pix_users`,
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/json',
          'X-User-Email': credentials.email,
          'X-Access-Token': credentials.token
        },
        data : data
      };
    axios(config)
    .then(res => {
      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const payload = {
        data: [res.data.data],
        error: false,
        loading: false
      }
      dispatch({ type: ADD_USER_PIX_SUCCESS, payload });
    })
    .catch(error => {
      const payload = {
        data: [],
        error: error,
        loading: false
      }
      dispatch({ type: ADD_USER_PIX_ERROR, payload });
    });
  }
}

export const deleteUserPix = (credentials, keyId) => {
  return dispatch => {
    const payload = {
        data: [],
        loading: true,
        error: false
    }
    dispatch({ type: DELETE_USER_PIX, payload });

    var data = '';
    var newToken = credentials.token;

    var config = {
      method: 'delete',
      url: `${SERVER_ADDRESS}/api/v1/pix_users/${keyId}`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': newToken
      },
      data : data
    };

    axios(config)
    .then(res => {
      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const payload = {
        data: [res.data.data],
        error: false,
        loading: false
      }
      dispatch({ type: DELETE_USER_PIX_SUCCESS, payload });
    })
    .catch(error => {
      const payload = {
        data: [],
        error: error,
        loading: false
      }
      dispatch({ type: DELETE_USER_PIX_ERROR, payload });
    });
  }
}

export const clearManagePix = () => {
  return ({ type: CLEAR_MANAGE_PIX });
}

////////////////////////////// GET USER PIX KEYS END ///////////////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
////////////////////////////// GET ORDER STATUS BEGIN ////////////////////////////////////
/*
  Endpoint for checking if service station has paid order
*/

export const getOrderStatus = (credentials, serviceStationId) => {
  return dispatch => {
    const payload = {
        status: '',
        loading: true,
        error: false
    }
    dispatch({ type: GET_ORDER_STATUS, payload });
    const url = `${SERVER_ADDRESS}/api/v1/status_orders/${serviceStationId}`
    axios.get(url, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Access-Token': credentials.token,
        'X-User-Email': credentials.email,
      }
    })
    .then(res => {
      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const payload = {
        status: res.data.status,
        error: false,
        loading: false
      }
      dispatch({ type: GET_ORDER_STATUS_SUCCESS, payload });
    })
    .catch(error => {
      const payload = {
        status: '',
        error: error,
        loading: false
      }
      dispatch({ type: GET_ORDER_STATUS_ERROR, payload });
    });
  }
}

export const updateOrderStatus = (orderStatus) => ({
  type: UPDATE_ORDER_STATUS,
  payload: orderStatus
});
