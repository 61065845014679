import React, { useState } from "react";

import { Container, ThumbsGrid, Thumb, CropContainer, AvatarTitle, AvatarWrapper, Avatar } from "./styles";
import useWindowSize from '../../utils/hooks/useWindowSize';

import { v4 as uuidv4 } from 'uuid';

// react-doka
import { DokaImageEditor } from 'react-doka';

// doka
import 'doka/doka.css';
import {
    // editor
    locale_en_gb,
    createDefaultImageReader,
    createDefaultImageWriter,

    plugin_crop_locale_en_gb,
    plugin_crop_defaults,
    plugin_finetune_locale_en_gb,
    plugin_finetune_defaults,
    plugin_filter_locale_en_gb,
    plugin_filter_defaults,
    plugin_decorate_defaults,
    plugin_decorate_locale_en_gb,
    component_shape_editor_locale_en_gb,
} from 'doka';


// Setting a high pixel ratio avoids blurriness in the canvas crop preview.

// We resize the canvas down when saving on retina devices otherwise the image
// will be double or triple the preview size.

const editorSize = {
    height: '584px',
};

const editorDefaults = {
    imageReader: createDefaultImageReader(),
    imageWriter: createDefaultImageWriter(),
    imageCropAspectRatio: 16/9,
    ...plugin_crop_defaults,
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    ...plugin_decorate_defaults,
    locale: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...plugin_filter_locale_en_gb,
        ...plugin_decorate_locale_en_gb,
        ...component_shape_editor_locale_en_gb,
    },
};

export default function ImageCrop({ setStationThumb, setCropState, cropState, imgs, avatar = null }) {

  const size = useWindowSize();
  const [ srcImage, setSrcImage ] = useState(null);

  const handleThumbClick = (image, cropState) => {
    setSrcImage(image);
    setCropState(cropState);
  }

  const handleKeyPress = evt => {
    if (evt.key === 'Enter') {
      if (cropState === 'confirm') {
        //generateCroppedImage(previewCanvasRef.current, completedCrop);
      }
      else {
        setCropState('confirm');
      }
    }
  }


  const processEditedImg = (blob) => {
     var reader = new FileReader();
     reader.readAsDataURL(blob);
     reader.onloadend = function() {
         var base64data = reader.result;
         var extension = blob.type.split("/")[1];
         if (extension === undefined) extension = 'jpeg';
         var fileName = `${uuidv4()}.${extension}`;

         setStationThumb({ b64: base64data, fileName: fileName });
     }
  }

  const images = imgs.map((image, index) => {
    return (
      <Thumb
        key={index}
        src={image.url}
        onClick={() => handleThumbClick(image, 'crop')}
      />
    )
  });

  return (
    <Container>
      { avatar &&
        <AvatarWrapper>
          <Avatar
            src={avatar} width={size.width}
            onClick={() => handleThumbClick({url: avatar}, 'crop')}
          />
          <AvatarTitle>
              imagem atual
          </AvatarTitle>
        </AvatarWrapper>
      }
        <ThumbsGrid
        className={cropState === 'select' ? '' : 'hide'}
        >
          { images }
        </ThumbsGrid>
      {
        (cropState === 'crop' || cropState === 'confirm') && (
          <CropContainer
            className={cropState === 'crop' ? 'show' : ''}
            onKeyPress={handleKeyPress}
          >
            <div style={editorSize}>
              <DokaImageEditor
                  {...editorDefaults}
                  src={srcImage.url}
                  onProcess={({ dest }) => processEditedImg(dest)}
              />
            </div>
          </CropContainer>
        )
      }
    </Container>
  )
}