import React, { useState, useContext, useEffect } from 'react';

import _ from 'lodash';
import {
    CustomDrawer, DrawerHeading, DrawerSubheading, EmptyContainer, EmptyIllustration, EmptyMessage, DrawerFooter, DrawerActionButton, DrawerMain, DrawerFormAdd, DrawerList, DrawerListItem, DrawerListItemButton, DrawerListItemButtons, SnackbarUndoButton } from '../../pages/Profile/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { TextField, Snackbar, SnackbarContent, FormControl } from '@material-ui/core';
import { getUserPix, addUserPix, deleteUserPix, clearManagePix } from '../../store/actions/PaymentsActions';
import useStateWithCallback from '../../utils/hooks/useStateWithCallback';
import { ThemeContext } from 'styled-components';
import { Icon } from '@iconify/react';
import roundDelete from '@iconify/icons-ic/round-delete';
import { connect } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManagePixKeys = ({ activation = false, email, token, userPix, addPix, deletePix, pixDrawerOpen, setPixDrawerOpen, handleCloseDrawer, getUserPix, addUserPix, deleteUserPix, clearManagePix }) => {

    const theme = useContext(ThemeContext);
    const [ snackMessage, setSnackMessage ] = useState(undefined);
    const [ snackOpen, setSnackOpen ] = useStateWithCallback(false);
    const [ snackPack, setSnackPack ] = useState([]);
    const [ addingData, setAddingData ] = useState(false);
    const [ itemToDelete, setItemToDelete ] = useState(null);
    const [ lastPix, setLastPix ] = useState(null);
    const [ allPix, setAllPix ] = useState([]);
    const [ pixData, setPixData ] = useState([
      {
        name: 'email',
        type: 'text',
        label: 'E-mail',
        value: ''
      },
      {
        name: 'cel',
        type: 'number',
        label: 'Celular',
        value: ''
      },
      {
        name: 'cpf',
        type: 'text',
        label: 'CPF',
        value: ''
      },
      {
        name: 'cnpj',
        type: 'text',
        label: 'CNPJ',
        value: ''
      },
      {
        name: 'random',
        type: 'text',
        label: 'Chave aleatória',
        value: ''
      }
  ]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getUserPix({ email, token }, userPix);

    return () => clearManagePix();
  }, []);

  useEffect(() => {
    let allPix = [];

    if (Array.isArray(userPix.data.data)) {
      userPix.data.data.forEach(item => {
        const pixKey = convertPixKey(item);
        allPix.push(pixKey);
      });
    }
    setAllPix(allPix);
  }, [userPix]);


  useEffect(() => {
    if (addPix.data.length > 0) {
      const pixKey = convertPixKey(addPix.data[0]);
      addPixItem([pixKey]);
    }
  }, [addPix]);

  useEffect(() => {
    if (snackPack.length && !snackMessage) {
      setSnackMessage({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setSnackOpen(true);
    }
    else if (snackPack.length && snackMessage && snackOpen) {
      setSnackOpen(false);
    }
  }, [snackPack, snackMessage, snackOpen, setSnackOpen]);

  const convertPixKey = (pixKey) => {
    let name = '';
    if (pixKey.attributes['pix-type'] === 'E-mail') {
      name = 'email';
    } else if (pixKey.attributes['pix-type'] === 'Celular') {
      name = 'cel';
    } else if (pixKey.attributes['pix-type'] === 'CPF') {
      name = 'cpf';
    } else if (pixKey.attributes['pix-type'] === 'CNPJ') {
      name = 'cnpj';
    } else if (pixKey.attributes['pix-type'] === 'Chave aleatória') {
      name = 'random';
    }

    const payload = {
      id: pixKey.id,
      name: name,
      type: pixKey.attributes['pix-type'] === 'Celular' ? 'number' : 'text',
      label: pixKey.attributes['pix-type'],
      value: pixKey.attributes['pix-key'],
    }

    return payload 
  }

  const openSnack = message => {
      setSnackPack((prev) => [ ...prev, { message, key: new Date().getTime() } ]);
  }

  const undoItemDelete = () => {
      addUserPix({ email, token }, itemToDelete);
      handleCloseSnack();
  }

  const handleCloseSnack = (evt, reason) => {
      if (reason === 'clickaway') return;
      setSnackOpen(false);
      setItemToDelete(null);
  }
  
  const handleExited = () => {
      setSnackMessage(undefined);
  }

  const addPixItem = (pix) => {
      if (Array.isArray(pix) && Number.isInteger(pix[1])) {
          const curr = [ ...allPix ];

          curr.splice(pix[1], 0, pix[0]);

          setAllPix(curr);
      }
      else if (Array.isArray(pix) && typeof pix[1] === 'object') {
          setAllPix([...allPix, ...pix]);
          openSnack('Pix adicionado');
      }
      else {
          setAllPix([...allPix, pix[0]]);
          openSnack('Pix adicionado');
      }
  }

  const deletePixItem = (pix) => {
      const curr = [ ...allPix ];

      const foundIndex = curr.findIndex(item => item.value === pix.value);

      openSnack('Item removido');
      setItemToDelete(pix);

      _.remove(curr, function(o) {
        return o.id === pix.id
      });

      setAllPix(curr);
  }

  const handleChangeField = (evt, name) => {
      if (/\s/.test(evt.target.value)) return;
  
      const curr = [...pixData];
  
      const el = curr.find(item => item.name === name);
      el.value = evt.target.value;
  
      setPixData(curr);
  }

  const handleDrawerLeftButton = () => {
      if (addingData) {
        setAddingData(false);
      }
      else {
        togglePixDrawer(false)();
      }
  }
  
  const handleDrawerRightButton = () => {
    if (addingData) {
      if (pixData.some(item => item.value)) {
        const result = allPix.map(item => {
          return pixData.map(sub => {
            return item.value === sub.value;
          })
        });  
          let toAdd = [];
  
          pixData.forEach((item) => {
            if (item.value.trim()) {
              toAdd.push(item);
            }
          });
  
          toAdd.forEach((key) => {
            addUserPix({ email, token }, key);
          }); 
          setPixData(pixData.map(item => item.value ? { ...item, value: '' } : item));
  
          setAddingData(false);
        }
      }
      else {
        setAddingData(true);
      }
    }

    const handleDeletePix = (item) => {
      deleteUserPix({ email, token }, item.id);
      deletePixItem(item);
    }

    const handleLastKeyDelete = (item) => {
      setOpen(true);
      setLastPix(item);
    }

    const togglePixDrawer = state => event => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
  
      setPixDrawerOpen(state);
    }

    const cancelDeletePix = () => {
      setLastPix(null);
      setOpen(false);
    }

    const confirmDeletePix = () => {
      handleDeletePix(lastPix);
      setLastPix(null);
      setOpen(false);
    }

    return (
        <CustomDrawer
        anchor='right'
        open={pixDrawerOpen}
        onClose={handleCloseDrawer}
        className='noSelect'
      >
        <Snackbar
          key={snackMessage ? snackMessage.key : undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          color='primary'
          open={snackOpen}
          autoHideDuration={itemToDelete ? 4000 : 1000}
          onClose={handleCloseSnack}
          onExit={handleExited}
        >
          <SnackbarContent
            style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
            message={snackMessage ? snackMessage.message : undefined}
            action={
              itemToDelete &&
              <SnackbarUndoButton onClick={undoItemDelete}>
                DESFAZER
              </SnackbarUndoButton>
            }
          />
        </Snackbar>

        <DrawerHeading>Chaves Pix</DrawerHeading>
        { !activation && <DrawerSubheading addingData={addingData}>{addingData ? 'Pelo menos 1 chave é obrigatória' : 'Certifique-se da existência da chave, ela será usada para receber os lucros do painel'}</DrawerSubheading> }
        { activation && <DrawerSubheading addingData={addingData}>{addingData ? 'Pelo menos 1 chave é obrigatória' : ' Você precisa informar pelo menos uma chave pix válida para desbloquear a ativação do seu painel'}</DrawerSubheading> }

        <DrawerMain hide={addingData}>
          {
            allPix.length > 0 ? (
              <DrawerList>
                {
                  allPix.map((item, index) => (
                    <DrawerListItem
                      key={index}
                    >
                      <TextField
                        variant='filled'
                        readOnly
                        label={item.label}
                        value={item.value}
                        multiline={true}
                        style={{ flex: 1 }}
                      />
                      <DrawerListItemButtons>
                        <DrawerListItemButton onClick={() => allPix.length <= 1 ? handleLastKeyDelete(item) : handleDeletePix(item) }>
                          <Icon
                            icon={roundDelete}
                            width={24}
                            height={24}
                            color={theme.text.secondary}
                          />
                        </DrawerListItemButton>
                      </DrawerListItemButtons>
                    </DrawerListItem>
                  ))
                }
              </DrawerList>
            ) : (
              <EmptyContainer>
                <EmptyIllustration src={require('../../assets/images/empty.svg')} draggable={false} />
                <EmptyMessage>Nenhuma chave Pix por aqui...</EmptyMessage>
              </EmptyContainer>
            )
          }
        </DrawerMain>

        <DrawerFormAdd show={addingData}>
          {
            pixData.map((item, index) => !_.find(allPix, {name: item.name}) && (
              <FormControl key={index} style={{ marginBottom: '15px' }}>
                <TextField
                  variant='filled'
                  readOnly
                  type={item.type}
                  label={item.label}
                  value={item.value}
                  multiline={true}
                  onChange={(evt) => handleChangeField(evt, item.name)}
                />
              </FormControl>
            ))
          }
        </DrawerFormAdd>

        <DrawerFooter>
          <DrawerActionButton onClick={handleDrawerLeftButton}>{addingData ? 'Cancelar' : 'Voltar'}</DrawerActionButton>
          <DrawerActionButton onClick={handleDrawerRightButton}>Adicionar</DrawerActionButton>
        </DrawerFooter>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={cancelDeletePix}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">
            Remover chave pix
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Você tem certeza que deseja remover a única chave? Você não conseguirá receber pagamentos dos anúncios do seu painel, ao menos que cadastre pelo menos 1 chave pix em seguida.
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={cancelDeletePix} color="primary">
              Cancelar
          </Button>
          <Button onClick={confirmDeletePix} color="primary">
              Confirmar
          </Button>
          </DialogActions>
      </Dialog>
      </CustomDrawer>
    )
}

const mapStateToProps = state => ({
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token,
  userPix: state.PaymentsReducer.userPix,
  addPix: state.PaymentsReducer.addPix,
  deletePix: state.PaymentsReducer.deletePix,
});


export default connect(mapStateToProps, { getUserPix, addUserPix, deleteUserPix, clearManagePix })(ManagePixKeys);