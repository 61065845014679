import React from 'react';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';

import useWindowSize from '../../utils/hooks/useWindowSize';

import animationData from '../../assets/lotties/stations-loader.json';

import { Container, Text, Char } from './styles';

const MapLoader = () => {
  const size = useWindowSize();
  const { t } = useTranslation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  return (
    <Container
      className='noSelect'
      initial={{ opacity: 0, transition: { duration: .4 }}}
      animate={{ opacity: 1, transition: { duration: .4 }}}
      exit={{ opacity: 0, transition: { duration: .4 }}}
    >
      <Lottie
        options={defaultOptions}
        width={size.width >= 1024 ? 300 : size.width}
        height={350}
        isClickToPauseDisabled={true}
      />
      <Text>
        {
          Array.from(t('stations.loading')).map((char, index) => (
            <Char
              key={index}
              index={index}
            >
              {char}
            </Char>
          ))
        }
      </Text>
    </Container>
  )
}

export default MapLoader;