import {
  CONVERSATIONS_FETCH,
  CONVERSATION_FETCH,
  CONVERSATION_FETCH_START,
  CHECK_CONTACT_STATUS,
} from '../../constraints/types';


const INITIAL_STATE = {
  conversationsList: [],
  conversationList: [],
  chatsNotification: null,
  conversationFetchLoading: false,
  contactStatus: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CONVERSATIONS_FETCH:
        return { ...state, conversationsList: action.payload, chatsNotification: action.chatsNotification };
    case CONVERSATION_FETCH_START:
        return { ...state, conversationFetchLoading: true };
    case CONVERSATION_FETCH:
        return { ...state, conversationList: action.payload, conversationFetchLoading: false };
    case CHECK_CONTACT_STATUS:
        return { ...state, contactStatus: action.payload };
    default:
        return state;
  }
}
