import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: absolute;
  width: 100%;
  z-index: 4;
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  padding-left: 10px;
  touch-action: none;
  -ms-touch-action: none;
  
  &.show {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0);
  }
`;

export const SwiperWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  width: ${props => (20 * props.numberOfChildren) + (250 * props.numberOfChildren) + 20}px;
  height: 100%;
  pointer-events: auto;
`;