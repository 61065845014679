import React, { useContext, useEffect, useState } from 'react';

import { ThemeContext } from 'styled-components';
import { ptBR, enUS, pl, ru } from 'date-fns/locale'
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import NotificationItem from '../../components/NotificationItem';
import { getNotifications, setNotificationRead } from '../../store/actions/NotificationsActions';
import { getLastStations } from '../../store/actions/StationsActions';

import { Container, Header, BtnBack, Title, List, NotificationsCount } from './styles';

const Notifications = ({ getNotifications, setNotificationRead, userId, userNotifications, userStations, getLastStations, token, memberData }) => {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [ locale, setLocale ] = useState(ptBR);

  const [ notificationData, setNotificationData ] = useState([]);
  /*
  const [ fakeData, setFakeData ] = useState([
    {
      id: 1,
      content: 'Dê uma resposta à solicitação em Gabe Studio',
      date: new Date(2020, 10, 6),
      img: 'https://www.telesapp.net/system/professional/companies/avatars/000/000/095/medium/BHzvlTCs.jpg?1597238045',
      type: 'alert',
      stationName: 'Gabe Studio',
      important: true,
      read: false
    }
  ]);
  */

  useEffect(() => {
    getNotifications(userId, userStations);
    const credentials = { email: memberData.email, token };
    getLastStations(credentials, userStations.data);
  }, []);

  useEffect(() => {
    setNotificationData(userNotifications);
  }, [userNotifications]);

  useEffect(() => {
    switch (i18n.language.toLowerCase()) {
      case 'pt-br':
        setLocale(ptBR);
        break;
      case 'en' || 'enUS':
        setLocale(enUS);
        break;
      case 'pl':
        setLocale(pl);
        break;
      case 'ru':
        setLocale(ru);
        break;
      default:
        setLocale(ptBR);
        break;
    }
  }, [i18n.language]);

  const handleReadNotification = (notification, index) => {

    if (notification.type === 'activation' && notification.hasOwnProperty('cdStation') && notification.uniquename) {
      const codStation = atob(atob(notification.cdStation));
      history.push({
        pathname: `/station/${notification.uniquename}/activation`,
        codStation
      });
    }
    if (notification.type === 'new-request') {
      history.push({
        pathname: '/ad-approval',
        screenData: notification
      });
    }
    if (notification.type === 'success') {
      history.push({
        pathname: '/ad-approved',
        screenData: notification
      });
    }

    if (!notification.read) setNotificationRead(notification.uid, userId)
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  return (
    <Container
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
    >
      <Header
        initial={{ opacity: 0, y: 20, transition: { duration: .3, easing: 'cubic-bezier(.785,.135,.15,.86)' }}}
        animate={{ opacity: 1, y: 0, transition: { delay: .15, duration: .3, easing: 'cubic-bezier(.785,.135,.15,.86)' }}}
      >
        <BtnBack onClick={() => history.goBack()}>
          <Icon
            icon={roundArrowBack}
            color={theme.iconTintColor.active}
            style={{ width: '24px', height: '24px' }}
          />
        </BtnBack>
        <Title>{ t('menu.notifications.label') }</Title>
        <NotificationsCount hide={notificationData.filter(item => !item.read).length > 0 ? 0 : 1}>{notificationData.filter(item => !item.read).length || 1}</NotificationsCount>
      </Header>

      <List
        initial={{ opacity: 0, y: 20, transition: { duration: .3, easing: 'cubic-bezier(.785,.135,.15,.86)' }}}
        animate={{ opacity: 1, y: 0, transition: { delay: .3, duration: .3, easing: 'cubic-bezier(.785,.135,.15,.86)' }}}
      >
        {
          notificationData.map((item, index) => (
            <NotificationItem
              key={index}
              notification={item}
              index={index}
              handleReadNotification={handleReadNotification}
              locale={locale}
            />
          ))
        }
      </List>
    </Container>
  )
}

const mapStateToProps = state => ({
  userId: btoa(state.AuthenticationReducer.memberData.data.id),
  userNotifications: state.NotificationsReducer.userNotifications,
  userStations: state.StationsReducer.userStations,
  token: state.AuthenticationReducer.token,
  memberData: state.AuthenticationReducer.memberData.data.attributes,
});

export default connect(mapStateToProps, { getNotifications, setNotificationRead, getLastStations })(Notifications);
