import styled from 'styled-components';
import { Button, FormControlLabel } from '@material-ui/core';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  background: rgb(0 56 62 / 90%);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TermsContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.secondary};
  border-radius: 3px;
  padding: 1%;

  @media screen and (min-width: 1024px) {
    width: 50%;
  }
`;

export const Header = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 2% 2% 0 2%;

  @media screen and (min-width: 1024px) {
    position: unset;
    padding: 0px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  margin: 1% 0;
  color: ${props => props.theme.text.secondary};
  font-weight: 700;
`;

export const Indicator = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  margin-top: 3px;
  background: ${props => props.theme.iconTintColor.inactive};
`;

export const TextWrapper = styled.div`
  height: calc(100% - 200px);
  overflow: auto;
  white-space: pre-line;
  color: ${props => props.theme.text.secondary};
  padding: 25px 15px 15px 15px;
  font-weight: 600;
  padding-right: 2%;
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
    height: 90%;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.secondary};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.text.secondary};
    border-radius: 5px;
  }

  @media screen and (min-width: 1024px) {
    height: 90%;
    overflow: auto;
    white-space: pre-line;
    color: ${props => props.theme.text.secondary};
    padding-top: 2%;
    padding-bottom: 10%;
    font-weight: 600;
    padding-right: 2%;
  }
`;

export const TermParagraph = styled.p`
  color: ${props => props.theme.text.secondary};
  padding-top: 1%;
`;

export const TermsActions = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  padding: 10px 10px 20px 10px;

  @media screen and (min-width: 1024px) {
    background: ${props => props.theme.colors.secondary};
  }
`;

export const TermsControlLabel = styled(FormControlLabel)`
  background: ${props => props.theme.colors.background};
  padding: 3% 3% 3% 3%;
  border-radius: 5px;
  margin: auto !important;
`;

export const TermsControlLabelText = styled.p`
  color: ${props => props.theme.text.primary};
  margin-left: 5px;
`;

export const RefreshButton = styled(Button)`
  font-weight: 700 !important;
  color: #fff !important;
  height: 100%;
  width: 80px;
  background: #27b27a !important;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;

  .MuiButton-startIcon {
    margin: 0 !important;
  }

  &:disabled {
    background: #27b27a !important;
  }
`;

export const ContinueWrapper = styled(motion.div)`
  display: inline;
  margin-left: 1%;

  @media screen and (min-width: 1024px) {
    position: unset;
  }
`;
