import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';
import { rgba } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  padding: 0 16px;
  user-select: none;
  height: 70px;
  pointer-events: ${props => props.searching ? 'none' : 'auto'};
  background: ${props => props.searching ? 'transparent' : (props.theme.title === 'dark' ? `linear-gradient(180deg, ${props.theme.colors.secondary}, ${rgba(props.theme.colors.secondary, 0)})` : `linear-gradient(180deg, ${props.theme.colors.background}, ${rgba(props.theme.colors.background, 0)})`)};
  transition: all .15s ease;

  @media screen and (max-width: 1023px) {
    transition: all .3s cubic-bezier(.785,.135,.15,.86);
    opacity: 1;

    &.hide {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    padding: 0 20px;
    border-bottom: 1px solid ${props => props.theme.separator};
    background: ${props => props.theme.colors.background};
    height: 70px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 1023px) {
    transition: all .3s cubic-bezier(.785,.135,.15,.86);
    transform: ${props => props.searching || props.hasSearchValue ? 'translateX(-30%)' : 'translateX(0)'};
    opacity: ${props => props.searching || props.hasSearchValue ? 0 : 1};
    transition-delay: ${props => props.searching || props.hasSearchValue ? '.0s' : '.3s'};
  }
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Logo = styled.img`
  transform: scale(.7);
  transform-origin: left center;
`;

export const SearchIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  opacity: ${props => props.show ? 1 : 0};
  align-items: center;
  justify-content: center;
  transition: opacity .15s ease, transform .6s cubic-bezier(.785,.135,.15,.86);
  pointer-events: ${props => props.show ? 'auto' : 'none'};
  z-index: 10;

  &:focus,
  &:active {
    outline: none;
  }

  @media screen and (max-width: 1023px) {
    transform: ${props => props.searching || props.hasSearchValue ? `translateX(-${props.left - 10}px)` : 'translateX(0)'};
  }

  @media screen and (min-width: 1024px) {
    cursor: ${props => props.searching || props.hasSearchValue ? 'auto' : 'pointer'};
    transform: ${props => props.searching || props.hasSearchValue ? `translateX(-300px)` : 'translateX(0)'};

    &:hover {
      > svg {
        filter: brightness(90%);
      }
    }
  }
`;

export const Notification = styled(ButtonBase)`
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  width: 40px !important;
  height: 40px !important;
  justify-content: center !important;
  border-radius: 50% !important;

  > svg {
    transform: ${props => props.change ? 'rotate(15deg)' : 'rotate(0)'} !important;
    transition: transform .15s ease-out !important;

    > path {
      transition: all .15s ease;
      fill: ${props => props.change ? props.theme.iconTintColor.active : props.theme.iconTintColor.inactive} !important;
    }
  }

  &:active > svg > path {
    fill: ${props => props.theme.iconTintColor.active} !important;
  }

  @media screen and (max-width: 1023px) {
    transition: all .3s cubic-bezier(.785,.135,.15,.86);
    transform: ${props => props.searching || props.hassearchvalue ? 'translateX(30%)' : 'translateX(0)'};
    opacity: ${props => props.searching || props.hassearchvalue ? 0 : 1};
    transition-delay: ${props => props.searching || props.hassearchvalue ? '.0s' : '.3s'};
  }

  @media screen and (min-width: 1024px) {
    cursor: pointer;
    transition: all .2s ease;

    &:hover {
      > svg > path {
        fill: ${props => props.theme.iconTintColor.active} !important;
      }
    }
  }
`;

export const NotificationCircle = styled.div`
  position: absolute;
  top: 9px;
  right: 9px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: all .1s ease;
  background-color: ${props => props.theme.colors.error};
  z-index: 2;
  border: 2px solid ${props => window.innerWidth < 1024 ? (props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background) : (props.theme.colors.background)};
  animation: blinker .85s linear infinite;

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

export const ProfileImageContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-image: url('${props => props.img}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin-left: 10px;

  @media screen and (max-width: 1023px) {
    transform: ${props => props.searching || props.hasSearchValue ? 'translateX(30%)' : 'translateX(0)'};
    opacity: ${props => props.searching || props.hasSearchValue ? 0 : 1};
    transition: ${props => `filter .15s ease 0s, transform .3s cubic-bezier(.785,.135,.15,.86) ${props.searching || props.hasSearchValue ? '0s' : '.3s'}, opacity .3s cubic-bezier(.785,.135,.15,.86) ${props.searching || props.hasSearchValue ? '0s' : '.3s'}`};

    &:active {
      filter: brightness(50%);
    }
  }

  @media screen and (min-width: 1024px) {
    cursor: pointer;
    width: 40px;
    height: 40px;
    transition: all .2s ease;

    &:hover {
      filter: brightness(110%);
    }
  }
`;

export const ProfileImageIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -2px;
  right: -2px;
  z-index: 2;
  background: ${props => props.theme.separator};
  border: 2px solid ${props => props.theme.colors.background};
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
  }
`;

export const Tabs = styled.ul`
  display: flex;
  height: 100%;
  margin-left: 20px;
  position: relative;
`;

export const Tab = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  color: ${props => props.activeTab === +props.tabIndex ? props.theme.text.primary : props.theme.text.secondary};
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin-right: 10px;
  padding: 0 10px;
  position: relative;
  transition: all .2s ease;

  &:hover {
    filter: brightness(120%);
  }

  &:focus {
    outline: none;
  }
`;

export const TabNotificationBadge = styled.div`
  padding: 1px 5px;
  border-radius: 15px;
  background: #BAEDF1;
  margin-left: 5px;
  font-size: 12px;
  color: ${props => props.theme.colors.primary};
  transition: all .2s ease;
`;

export const Indicator = styled.li`
  position: absolute;
  bottom: -1px;
  left: ${props => props.translation}px;
  width: ${props => props.width}px;
  height: 2px;
  border-radius: 5px;
  background: ${props => props.theme.iconTintColor.active};
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
`;

export const SearchContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${props => props.show ? 'flex' : 'none'};
  align-items: center;
  z-index: 10;
  justify-content: center;
  background: transparent;
  pointer-events: ${props => props.searching || props.hasSearchValue ? 'auto' : 'none'};
  perspective: 1000px;

  @media screen and (min-width: 1024px) {
    position: relative;
    transform: translateX(45px);
    width: 300px;
  }
`;

export const SearchInput = styled.input`
  flex: 1;
  background: transparent;
  border: none;
  height: 60%;
  display: block;
  font-size: 18px;
  margin-left: 60px;
  transition: ${props => props.searching ? 'opacity .2s ease .6s, background .2s ease' : 'opacity .2s ease 0s, background .1s ease'};
  color: ${props => props.theme.text.primary};
  opacity: ${props => props.searching || props.hasSearchValue ? 1 : 0};

  &:focus {
    outline: none;
  }

  @media screen and (min-width: 1024px) {
    margin: 0;
    font-size: 16px;
    padding-left: 10px;
    border-radius: 5px;

    &:focus {
      background: ${props => props.theme.colors.secondary};
    }
  }
`;

export const CloseSearchContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 10px;
  margin-left: auto;

  @media screen and (min-width: 1024px) {
    margin-left: auto;
    margin-right: none;
    cursor: pointer;
    transition: all .2s ease;

    &:hover {
      > svg {
        filter: brightness(90%);
      }
    }
  }
`;

export const CloseSarchLine = styled.div`
  position: absolute;
  width: 18px;
  height: 2px;
  background: ${props => props.theme.text.secondary};
  border-radius: 1px;
  transition: ${props => props.searching || props.hasSearchValue ? 'all .6s cubic-bezier(.785,.135,.15,.86)' : 'all .3s cubic-bezier(.785,.135,.15,.86)'};
  transition-delay: ${props => props.searching || props.hasSearchValue ? '.3s' : '0s'};
  opacity: ${props => props.searching || props.hasSearchValue ? 1 : 0};

  &:first-of-type {
    transform: ${props => props.searching || props.hasSearchValue ? 'rotate(225deg)' : 'rotate(0deg)'};
  }

  &:last-of-type {
    background: ${props => props.theme.text.secondary};
    transform: ${props => props.searching || props.hasSearchValue ? 'rotate(315deg)' : 'rotate(90deg)'};
    transition-delay: ${props => props.searching || props.hasSearchValue ? '.5s' : '0s'};
  }
`;

export const ContextMenu = styled.div`

`;

export const ContextMenuOption = styled.div``;

export const ContextMenuLogout = styled.a``;

export const Overlay = styled.div``;
