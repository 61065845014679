import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  z-index: 999;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
  @media screen and (min-width: 1024px) {
    position: relative;
    width: 1000px;
    height: 800px;
    border-radius: 15px;
  }
`;

export const BtnBack = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  color: #000000 !important;
  background-color: #01a3b2 !important;
  margin: 15px !important;
`;

export const PageTitle = styled.p`
  font-size: 1.4rem;
  text-align: center;
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 30px;
  white-space: pre-line;
`;

export const RotateAnimationContainer = styled.div`
  margin-top: 27%;
`;

export const StationAdContainer = styled.div``;

export const StationAdImage = styled.img`
  max-width: 498px;
  max-height: 280px;
`;

export const StationAdImageThumb = styled.img`
  height: 40px;
  width: 70px;
`;

export const StationAdVideo = styled.video`
  width: 498px;
  height: 280px;
`;

export const StationAdVideoThumb = styled.div`
  height: 40px;
  width: 70px;
`;
