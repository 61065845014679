import React, { useState, useEffect, useContext } from 'react';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeContext } from 'styled-components';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import useWindowSize from '../../utils/hooks/useWindowSize';
import { sendReport } from '../../store/actions/StationsActions';

import { motion, AnimatePresence } from "framer-motion";

import MDIcon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import {
    Container,
    HeaderOp,
    RowOp,
    TitleOp,
    BtnClose,
    Indicator,
    Pages,
    Page,
    PageTitle,
    CustomRadio,
    CustomFormControlLabel,
    ReportOptions,
    RadioContainer,
    Footer,
    SendButton
} from './styles';

const ReportStation = ({ setReportIsOpen, station, sendReport, reportStationData }) => {

    const theme = useContext(ThemeContext);
    const size = useWindowSize();
    const [value, setValue] = React.useState('O painel não estava em funcionamento dentro do horário especificado.');

    const [isOpen, setIsOpen] = useState(false);
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (reportStationData.sent || reportStationData.error) setReportIsOpen(false);
    }, [reportStationData]);

    const handleChange = (event) => {
      if (event.target.value === 'Especificar outro motivo:') setIsOpen(true);
      else setIsOpen(false);

      setValue(event.target.value);
    };

    const handleReport = () => {
        const { id } = station.data;
        const { name, address } = station.data.attributes;
        const report = (value === 'Especificar outro motivo:') ? description : value;

        const reportData = { id, name, address, report }

        sendReport(reportData);
    }

    const mobileVariants = {
        initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
      }
    
    const desktopVariants = {
        initial: { opacity: 0, scale: .95, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        visible: { opacity: 1, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        hidden: { opacity: 0, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    }

    const variants = {
        open: {
            opacity: 1,
            height: "auto",
            x: 0
        },
        collapsed: { opacity: 0, height: 0, x: 30 }
    };
    return (
        <Container
            variants={size.width < 1024 ? mobileVariants : desktopVariants}
            initial='initial'
            animate='visible'
            exit='hidden'
        >
            <HeaderOp>
                <RowOp>
                    <TitleOp> Reportar painel MI </TitleOp>
                    <BtnClose size='small' onClick={()=> setReportIsOpen(false)}>
                        <MDIcon
                            path={mdiClose}
                            size='24px'
                        />
                    </BtnClose>
                </RowOp>
                <Indicator step={100} />
            </HeaderOp>
            <Pages>
                <Page mobile={size.width < 1024 ? true : false}>
                    <ReportOptions>
                        <PageTitle>Motivo</PageTitle>                    
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                <RadioContainer onClick={() => { let evt = { target: { value: 'O painel não estava em funcionamento dentro do horário especificado.' }}; handleChange(evt); }}>
                                    <CustomFormControlLabel
                                        value="O painel não estava em funcionamento dentro do horário especificado."
                                        control={<CustomRadio />}
                                        label="O painel não estava em funcionamento dentro do horário especificado."
                                    />
                                </RadioContainer>
                                <RadioContainer onClick={() => { let evt = { target: { value: 'O painel não existe no local físico.' }}; handleChange(evt); }}>
                                    <CustomFormControlLabel
                                        value="O painel não existe no local físico."
                                        control={<CustomRadio />}
                                        label="O painel não existe no local físico."
                                    />
                                </RadioContainer>
                                <RadioContainer
                                    onClick={() => { let evt = { target: { value: 'Especificar outro motivo:' }}; handleChange(evt); }}
                                    isOpen={isOpen}
                                >
                                    <CustomFormControlLabel
                                        value="Especificar outro motivo:"
                                        control={<CustomRadio />}
                                        label="Especificar outro motivo:"
                                    />
                                    <AnimatePresence>
                                        {isOpen &&
                                            <motion.div
                                                layout
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                            >
                                                <div style={{ width: '100%', height: '100px' }}>
                                                    <FormControl variant="filled" fullWidth style={{ marginTop: 15 }}>
                                                        <TextField
                                                            label={'Descreva a situação'}
                                                            variant='filled'
                                                            value={description}
                                                            onChange={(evt) => setDescription(evt.target.value)}
                                                            fullWidth
                                                            multiline
                                                            rowsMax={3}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </motion.div>
                                        }
                                    </AnimatePresence>
                                </RadioContainer>
                            </RadioGroup>
                        </FormControl>
                    </ReportOptions>
                </Page>
            </Pages>
            <Footer>
                <SendButton
                    disabled={isOpen && !description}
                    onClick={handleReport}
                >
                    Enviar informações
                </SendButton>
            </Footer>
            { /* BACKDROP PARA SOBREPOR A TELA ENQUANTO ESTIVER ENVIANDO O REPORT */}
            <Backdrop style={{ zIndex: 999, color: theme.colors.primary, position: 'absolute' }} open={reportStationData.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}

const mapStateToProps = state => ({
    reportStationData: state.StationsReducer.sendReport
});

export default connect(mapStateToProps, { sendReport })(ReportStation);