/* SERVER */
//export const SERVER_ADDRESS = 'http://localhost:3000';
export const SERVER_ADDRESS = 'https://www.telesapp.net';
//export const SERVER_ADDRESS = 'http://192.168.1.108:3000';
/* AUTHENTICATION ACTIONS */
export const UPDATE_TOKEN = 'update_token';
export const SIGN_IN = 'sign_in';
export const SIGN_IN_SUCCESS = 'sign_in_success';
export const SIGN_IN_ERROR = 'sign_in_error';
export const ACCEPT_TERMS = 'accept_terms';
export const SIGN_OUT_SUCCESS = 'sign_out_success';
export const UPDATE_APP = 'update_app';
/* STATIONS ACTIONS */
export const ASYNC_GET_ALL_STATIONS = 'async_get_all_stations';
export const GET_ALL_STATIONS = 'get_all_stations';
export const GET_ALL_STATIONS_ERROR = 'get_all_stations_error';
export const ASYNC_SEARCH_STATIONS = 'async_search_stations';
export const ASYNC_SEARCH_STATIONS_WITH_TAG = 'async_search_stations_with_tag';
export const ASYNC_SEARCH_STATIONS_WITH_CATEGORY = 'async_search_stations_with_category';
export const SEARCH_STATIONS = 'search_stations';
export const SEARCH_STATIONS_WITH_TAG = 'search_stations_with_tag';
export const SEARCH_STATIONS_WITH_CATEGORY = 'search_stations_with_category';
export const SEARCH_STATIONS_ERROR = 'search_stations_error';
export const SEARCH_STATIONS_WITH_TAG_ERROR = 'search_stations_with_tag_error';
export const SEARCH_STATIONS_WITH_CATEGORY_ERROR = 'search_stations_with_category_error';
export const REMOVE_STATION_HISTORY = 'remove_station_history';
export const UPDATE_ADDRESS_VALUE = 'update_address_value';
export const UPDATE_ADDRESS_SUGESTIONS = 'update_address_sugestions';
export const UPDATE_ADD_STATION_DATA = 'update_add_station_data';
export const ADD_STATION = 'add_station';
export const ADD_STATION_SUCCESS = 'add_station_success';
export const ADD_STATION_ERROR = 'add_station_error';
export const EDIT_STATION = 'edit_station';
export const EDIT_STATION_SUCCESS = 'edit_station_success';
export const EDIT_STATION_ERROR = 'edit_station_error';
export const SEND_THUMB_TO_STATION = 'send_thumb_to_station';
export const SEND_THUMB_TO_STATION_SUCCESS = 'send_thumb_to_station_success';
export const SEND_THUMB_TO_STATION_ERROR = 'send_thumb_to_station_error';
export const SENT_THUMB = 'sent_thumb';
export const GET_STATION = 'get_station';
export const GET_STATION_SUCCESS = 'get_station_success';
export const GET_STATION_ERROR = 'get_station_error';
export const GET_CATEGORIES = 'get_categories';
export const GET_CATEGORIES_SUCCESS = 'get_categories_success';
export const GET_CATEGORIES_ERROR = 'get_categories_error';
export const CHECK_UNIQUENAME = 'check_uniquename';
export const CHECK_UNIQUENAME_SUCCESS = 'check_uniquename_success';
export const CHECK_UNIQUENAME_ERROR = 'check_uniquename_error';
export const GET_LAST_STATIONS = 'get_last_stations';
export const GET_LAST_STATIONS_SUCCESS = 'get_last_stations_success';
export const GET_LAST_STATIONS_ERROR = 'get_last_stations_error';
export const GET_USER_STATIONS = 'get_user_stations';
export const GET_USER_STATIONS_SUCCESS = 'get_user_stations_success';
export const GET_USER_STATIONS_ERROR = 'get_user_stations_error';
export const SELECT_STATIONS = 'select_stations';
export const CALCULATE_PRICE = 'calculate_price';
export const CALCULATE_PRICE_SUCCESS = 'calculate_price_success';
export const CALCULATE_PRICE_ERROR = 'calculate_price_error';
export const UPDATE_VIEW_ORIGIN = 'update_view_origin';
export const UPDATE_SELECTING = 'update_selecting';
export const LISTEN_STATION_ACTIVATION = 'listen_station_activation';
export const STOP_LISTENING_ACTIVATION = 'stop_listening_activation';
export const MANAGE_STATION_IMAGES = 'manage_station_images';
export const MANAGE_STATION_IMAGES_SUCCESS = 'manage_station_images_success';
export const MANAGE_STATION_IMAGES_ERROR = 'manage_station_images_error';
export const UPDATE_THUMB_STATION = 'update_thumb_station';
export const UPDATE_EDIT_STATION = 'update_edit_station';
export const UPDATE_SYNC_PLAYER = 'update_sync_player';
export const UPDATE_DELETE_STATION = 'update_delete_station';
export const SEND_REPORT = 'send_report';
export const SEND_REPORT_SUCCESS = 'send_report_success';
export const SEND_REPORT_ERROR = 'send_report_error';
export const UPDATE_SEND_REPORT = 'update_send_report';
export const SYNC_PLAYER = 'sync_player';
export const SYNC_PLAYER_SUCCESS = 'sync_player_success';
export const SYNC_PLAYER_ERROR = 'sync_player_error';
export const DELETE_STATION = 'delete_station';
export const DELETE_STATION_SUCCESS = 'delete_station_success';
export const DELETE_STATION_ERROR = 'delete_station_error';
export const BLOCK_STATION = 'block_station';
export const BLOCK_STATION_SUCCESS = 'block_station_success';
export const BLOCK_STATION_ERROR = 'block_station_error';
export const UPDATE_BLOCK_STATION = 'update_block_station';
export const UNBLOCK_STATION = 'unblock_station';
export const UNBLOCK_STATION_SUCCESS = 'unblock_station_success';
export const UNBLOCK_STATION_ERROR = 'unblock_station_error';
export const UPDATE_UNBLOCK_STATION = 'update_unblock_station';
/* UPLOADS ACTIONS */
export const UPLOAD_START = 'upload_start';
export const UPLOAD_PROGRESS = 'upload_progress';
export const UPLOAD_SUCCESS = 'upload_success';
export const UPLOAD_ERROR = 'upload_error';
export const FORCE_TOKEN = 'force_token';
export const REMOVE_FROM_STACK = 'remove_from_stack';
export const UPDATE_DELETE_FROM_BUCKET = 'update_delete_from_bucket';
export const DELETE_FROM_BUCKET = 'delete_from_bucket';
export const DELETE_FROM_BUCKET_SUCCESS = 'delete_from_bucket_success';
export const DELETE_FROM_BUCKET_ERROR = 'delete_from_bucket_error';
export const LIST_FOLDER = 'list_folder';
export const LIST_FOLDER_SUCCESS = 'list_folder_success';
export const LIST_FOLDER_ERROR = 'list_folder_error';
/* COMPANIES ACTIONS */
export const ASYNC_GET_USER_COMPANIES = 'async_get_user_companies';
export const GET_USER_COMPANIES = 'get_user_companies';
export const GET_USER_COMPANIES_ERROR = 'get_user_companies_error';
/* NOTIFICATIONS ACTIONS */
export const ADD_NOTIFICATION = 'add_notification';
export const GET_NOTIFICATIONS = 'get_notifications';
export const SET_NOTIFICATION_READ = 'set_notification_read';
export const CURRENT_NEW_REQUEST_NOTIFICATION = 'current_new_request_notification';
export const CURRENT_SUCCESS_NOTIFICATION = 'current_success_notification';
/* ADS ACTIONS */
export const GET_COMPANY_ADS = 'get_company_ads';
export const GET_COMPANY_ADS_SUCCESS = 'get_company_ads_success';
export const GET_COMPANY_ADS_ERROR = 'get_company_ads_error';
export const SET_USER_ADS = 'set_user_ads';
export const SEND_AD_TO_STATION = 'send_ad_to_station';
export const SEND_AD_TO_STATION_SUCCESS = 'send_ad_to_station_success';
export const SEND_AD_TO_STATION_ERROR = 'send_ad_to_station_error';
export const UPDATE_SEND_AD_TO_STATION = 'update_send_ad_to_station';
export const CREATE_AD = 'create_ad';
export const CREATE_AD_SUCCESS = 'create_ad_success';
export const CREATE_AD_ERROR = 'create_ad_error';
export const UPDATE_CREATE_AD = 'update_create_ad';
export const UPDATE_CREATED_AD_ALERT = 'update_created_ad_alert';
export const UPDATE_ST_SERVICE_DATA = 'update_st_service_data';
export const APPROVE_SERVICE_STATION = 'approve_service_station';
export const APPROVE_SERVICE_STATION_SUCCESS = 'approve_service_station_success';
export const APPROVE_SERVICE_STATION_ERROR = 'approve_service_station_error';
export const UPDATE_APPROVE_SERVICE_STATION = 'update_approve_service_station';
export const PAY_SERVICE_STATION = 'pay_service_station';
export const PAY_SERVICE_STATION_SUCCESS = 'pay_service_station_success';
export const PAY_SERVICE_STATION_ERROR = 'pay_service_station_error';
export const UPDATE_PAID_SERVICE_STATION = 'update_paid_service_station';
export const GET_STATION_ADS = 'get_station_ads';
export const GET_STATION_ADS_SUCCESS = 'get_station_ads_success';
export const GET_STATION_ADS_ERROR = 'get_station_ads_error';
export const UPDATE_ORIGIN = 'update_origin';
export const DELETE_OWNER_AD = 'delete_owner_ad';
export const DELETE_OWNER_AD_SUCCESS = 'delete_owner_ad_success';
export const DELETE_OWNER_AD_ERROR = 'delete_owner_ad_error';
export const UPDATE_DELETE_OWNER_AD = 'update_delete_owner_ad';
/* PAYMENT ACTIONS */
export const GET_USER_PIX = 'get_user_pix';
export const GET_USER_PIX_SUCCESS = 'get_user_pix_success';
export const GET_USER_PIX_ERROR = 'get_user_pix_error';
export const ADD_USER_PIX = 'add_user_pix';
export const ADD_USER_PIX_SUCCESS = 'add_user_pix_success';
export const ADD_USER_PIX_ERROR = 'add_user_pix_error';
export const DELETE_USER_PIX = 'delete_user_pix';
export const DELETE_USER_PIX_SUCCESS = 'delete_user_pix_success';
export const DELETE_USER_PIX_ERROR = 'delete_user_pix_error';
export const GET_ORDER_STATUS = 'get_order_status';
export const GET_ORDER_STATUS_SUCCESS = 'get_order_status_success';
export const GET_ORDER_STATUS_ERROR = 'get_order_status_error';
export const UPDATE_ORDER_STATUS = 'update_order_status';
export const CLEAR_MANAGE_PIX = 'clear_manage_pix';
/* CHAT ACTIONS */
export const CONVERSATIONS_FETCH = 'conversations_fetch';
export const CONVERSATION_FETCH = 'conversation_fetch';
export const CONVERSATION_FETCH_START = 'conversation_fetch_start';
export const CHECK_CONTACT_STATUS = 'check_contact_status';
/* USER DATA ACTIONS */
export const SAVE_CAMPAIGN = 'save_campaign';
export const SET_APPROVED = 'set_approved';
export const REMOVE_APPROVED = 'remove_approved';
export const SET_PAID = 'set_paid';
export const BUMP_VERSION = 'bump_version';
