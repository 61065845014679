import React, { useState, useEffect } from 'react';

import qs from 'query-string';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { mdiMagnify, mdiPlus, mdiMonitor, mdiTextBoxPlusOutline, mdiOrderBoolAscendingVariant, mdiConsoleLine } from '@mdi/js';
import { connect } from 'react-redux';

import useActiveTab from '../../utils/hooks/useActiveTab';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { updateOrigin } from '../../store/actions/AdsActions';
import { updateSelecting } from '../../store/actions/StationsActions';
import { useSearching } from '../../context/Searching';

import { Container, Fab, BtnAddStation, BtnAddStationText, BtnAddStationCircle, BtnAddService, BtnAddServiceCircle, BtnAddServiceText, SubmenuOverlay } from './styles';

const MainFab = ({ updateOrigin, updateSelecting, selecting, setModalShown }) => {
  const { t } = useTranslation();
  const { activeTab } = useActiveTab();
  const [ submenuOpen, setSubmenuOpen ] = useState(false);
  const { searching, setSearching } = useSearching();
  const size = useWindowSize();
  const history = useHistory();

  useEffect(() => {
    if (submenuOpen) {
      setSubmenuOpen(false);
    }
    //if (searching.active) {
    //setSearching({ ...searching, active: false });
    //}
  }, [activeTab]);

  const handleFabClick = () => {
    if (activeTab === 0) {
      setSubmenuOpen(!submenuOpen);
    }
    else if (activeTab === 2 && size.width < 1024) {
      setSearching({ ...searching, active: !searching.active, componentMode: true });
    }
    else if (activeTab === 1 && size.width < 1024) {
      if (selecting) {
        setSearching({ ...searching, active: true, componentMode: true });
        updateSelecting(false);
      } else {
        setSearching({ ...searching, active: true, componentMode: false });
        updateSelecting(true);
      }
    }
  }

  const handleSubmenuOverlayClick = () => {
    if (submenuOpen) {
      setSubmenuOpen(false);
    }
  }

  const handleAddStationTrigger = () => {
    history.push(`${t('routes.addMediaPanel')}/0`);
    setSubmenuOpen(false);
  }

  const handleAddServiceTrigger = () => {
    updateOrigin(true);
    setSubmenuOpen(false);
    history.push('announce/0');
  }

  return (
    <Container>
      <SubmenuOverlay {...{ submenuOpen }} onClick={handleSubmenuOverlayClick} />
      <Fab
        aria-label={activeTab === 0 ? t('fab.main') : (activeTab === 2 ? t('fab.search.station') : t('fab.search.ad'))}
        title={activeTab === 0 ? t('fab.main') : (activeTab === 2 ? t('fab.search.station') : t('fab.search.ad'))}
        className={activeTab === 3 || searching.mapMode || (size.width < 1024 && selecting) || (size.width >= 1024 && activeTab !== 0) ? 'hidden' : ''}
        onClick={handleFabClick}
        searching={(searching.active && activeTab === 2)}
        {...{ submenuOpen }}
      >
        <Icon
          path={mdiPlus}
          size='26px'
          className={activeTab === 0 || (activeTab === 2 && searching.active) ? 'show' : ''}
        />
        <Icon
          path={mdiMagnify}
          size='26px'
          className={size.width < 1024 && (activeTab === 2 && (!searching.active || activeTab === 1)) ? 'show' : ''}
        />
        <Icon
          path={mdiOrderBoolAscendingVariant}
          size='26px'
          className={size.width < 1024 && (activeTab === 1) ? 'show' : ''}
        />
      </Fab>

      { activeTab === 0 && (
        <>
          <BtnAddStation
            className={submenuOpen ? 'fab-item show' : 'fab-item'}
            onClick={handleAddStationTrigger}
          >
          <BtnAddStationText>{ t('fab.add.station') }</BtnAddStationText>
          <BtnAddStationCircle>
            <Icon
              path={mdiMonitor}
              size='20px'
              color='#fff'
            />
          </BtnAddStationCircle>
          </BtnAddStation>
          <BtnAddService
            className={submenuOpen ? 'fab-item show' : 'fab-item'}
            onClick={handleAddServiceTrigger}
          >
            <BtnAddServiceText>{ t('fab.add.ad') }</BtnAddServiceText>
            <BtnAddServiceCircle>
              <Icon
                path={mdiTextBoxPlusOutline}
                size='24px'
                color='#fff'
              />
            </BtnAddServiceCircle>
          </BtnAddService>
        </>
      ) }
    </Container>
  );
}

const mapStateToProps = state => {
  return ({ selecting: state.StationsReducer.selecting })
};

export default connect(mapStateToProps, { updateOrigin, updateSelecting })(MainFab);
