import {
  ASYNC_GET_USER_COMPANIES,
  GET_USER_COMPANIES,
  SIGN_OUT_SUCCESS,
  GET_USER_COMPANIES_ERROR,
  UPDATE_APP
} from '../../constraints/types';

const INITIAL_STATE = {
  userCompanies: {
    error: false,
    loading: false,
    data: []
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ASYNC_GET_USER_COMPANIES:
        return { ...state, userCompanies: action.payload };
    case GET_USER_COMPANIES:
        return { ...state, userCompanies: action.payload };
    case GET_USER_COMPANIES_ERROR:
        return { ...state, userCompanies: action.payload };
    case SIGN_OUT_SUCCESS:
        return INITIAL_STATE;
    case UPDATE_APP:
        return INITIAL_STATE;
    default:
        return state;
  }
}
