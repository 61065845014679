import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 80px;
`;

export const Title = styled.p`
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: ${props => props.theme.text.primary};
  font-size: 24px;
  margin-left: 16px;
`;

export const Fields = styled.div`
  margin-top: 25px;
`;

export const Field = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  transition: all .15s ease;
  padding: 0 16px;
  position: relative;
  pointer-events: ${props => props.showMenu ? 'none !important' : 'auto'};

  &:active {
    background: ${props => props.theme.separator};
  }
`;

export const Label = styled.span`
  color: ${props => props.theme.text.primary};
`;

export const Select = styled.span`
  color: ${props => props.theme.text.secondary};
`;

export const LogoutField = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  transition: all .15s ease;
  padding: 0 16px;
  color: #FF6C7E;
  
  &:active {
    background: ${props => props.theme.separator};
  }
`;

export const Menu = styled.div`
  position: absolute;
  right: 16px;
  top: 15px;
  padding: 10px;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
  border-radius: 10px;
  transform: scale(0);
  opacity: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  transform-origin: right top;
  z-index: 1000;
  transition: transform .2s cubic-bezier(.785,.135,.15,.86) .2s, opacity .2s cubic-bezier(.785,.135,.15,.86) 0s;

  &.show {
    transform: scale(1);
    opacity: 1;
    pointer-events: auto;
    transition: transform .2s cubic-bezier(.785,.135,.15,.86) 0s, opacity .2s cubic-bezier(.785,.135,.15,.86) 0s;
  }
`;

export const Option = styled.div`
  font-size: 16px;
  color: ${props => props.theme.text.primary};
  padding: 10px 40px 10px 10px;
  border-radius: 5px;
  transition: all .2s ease;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &:active {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
  }

  > img {
    width: 24px;
    height: auto;
    margin-right: 10px;
    pointer-events: none;
  }

  > span {
    pointer-events: none;
  }
`;

export const MenuOverlay = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: ${props => props.showMenu ? 'auto' : 'none'};
`;