import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';
import Icon from '@mdi/react';
import { mdiArrowTopLeft, mdiMagnify } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { useSearching } from '../../context/Searching';

import { Container, Item, ItemText, SetSearchValueBtn } from './styles';

function AddressList ({ data = {}, searchValue = '', setValueAndSearch = () => {}, handleInputChange = () => {} }) {
  const { t } = useTranslation();
  const { searching } = useSearching();

  const theme = useContext(ThemeContext);

  function handleArrowClick (description) {
    const event = { target: { value: description } };
    handleInputChange(event);
  }

  return (
    <Container
      theme={theme}
      show={searchValue.length > 0 && searching.active}
    >
      {
        data.map((item, index) => {
          const { description } = item;

          return (
            <Item
              key={index}
              onClick={() => setValueAndSearch(description)}
              title={`${t('addresslist.search')} ${description}`}
              tabIndex='0'
            >
              <Icon
                path={mdiMagnify}
                size='18px'
              />
              <ItemText>{ description }</ItemText>
              <SetSearchValueBtn
                onClick={() => handleArrowClick(description)}
                title={t('addresslist.put')}
                role='button'
                tabIndex='1'
              >
                <Icon
                  path={mdiArrowTopLeft}
                  size='18px'
                />
              </SetSearchValueBtn>
            </Item>
          )
        })
      }
    </Container>
  );
}

export default AddressList;
