import {
  UPDATE_TOKEN,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_OUT_SUCCESS,
  ACCEPT_TERMS
} from '../../constraints/types';

const INITIAL_STATE = {
  token: null,
  loading: false,
  loggedIn: false,
  termsAccepted: false,
  memberData: {
    loading: false,
    data: [],
    error: null
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_TOKEN:
        return { ...state, token: action.newToken };
    case SIGN_IN:
        return { ...state, loading: true };
    case SIGN_IN_SUCCESS:
        return { ...state, memberData: action.payload, loggedIn: true, loading: false };
    case SIGN_IN_ERROR:
        return { ...state, memberData: action.payload, loggedIn: false, loading: false };
    case ACCEPT_TERMS:
        return { ...state, termsAccepted: action.payload };
    case SIGN_OUT_SUCCESS:
        return INITIAL_STATE;
    default:
        return state;
  }
}
