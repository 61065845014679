export default {
  title: 'dark',

  colors: {
    primary: '#01a3b2',
    secondary: '#0f1112',
    background: '#232626',
    warning: '#FFA95C',
    error: '#FF6E65'
  },

  text: {
    primary: '#ffffff',
    secondary: '#aaaaaa'
  },

  iconTintColor: {
    active: '#ffffff',
    inactive: '#999999'
  },

  separator: '#313636',

  skeleton: {
    color: '#313636',
    highlightColor: '#444'
  },

  doka: {
    background: '15, 17, 18',
    foreground: '255, 255, 255'
  }
}
