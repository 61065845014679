import React from 'react';

import { Container } from './styles';

const EditService = () => {
  return (
    <Container>
      EditService Page
    </Container>
  )
}

export default EditService;