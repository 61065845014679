import React, { useState } from 'react';

import Icon from '@mdi/react';
import { mdiBackspace, mdiArrowLeft } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useSearching } from '../../context/Searching';

import { asyncSearchStations, removeFromHistory, updateAddressValue } from '../../store/actions/StationsActions';
import { SERVER_ADDRESS } from '../../constraints/types';
import AddressList from '../AddressList';

import { Container, SearchInput, BtnClear, SearchOverlay, SearchContainer, LeftButton } from './styles';

const MobileSearch = ({ asyncSearchStations, removeFromHistory, updateAddressValue, memberData, token, searchStationsData, addressSugestions }) => {
  const { searching, setSearching } = useSearching();
  const [ searchValue, setSearchValue ] = useState('');

  const handleInputChange = evt => {
    updateAddressValue(evt.target.value);
    setSearchValue(evt.target.value);
  }

  const setValueAndSearch = value => {
    const credentials = { email: memberData.email, token }

    setSearchValue(value);
    setSearching({ active: false, mapMode: true, componentMode: false });

    asyncSearchStations(credentials, `${SERVER_ADDRESS}/api/v1/search-stations`, searchStationsData, null, null, value, null, 'distance');
  }

  const handleInputFocus = () => {
    setSearching({ ...searching, active: true });
  }

  const handleInputBlur = () => {
    if (searching.active && searching.mapMode) {
      setTimeout(() => {
        setSearching({ ...searching, active: false });
      }, 100);
    }
  }

  const handleLeftButton = () => {
    if (searching.active) {
      setSearching({ ...searching, active: false });
    }
    else if (searching.mapMode) {
      setSearchValue('');
      setSearching({ ...searching, mapMode: false });
    }
  }

  const localRemoveFromHistory = (index, type) => {
    if (type === 'stations') {
      alert('ta na hora de remover nenem');
      removeFromHistory(index);
    }
  }

  return (
    <Container>
      <SearchContainer className={searching.active ? 'active' : (searching.mapMode ? 'map-mode' : '')} withoutMapMode={!searching.mapMode}>
        <LeftButton onClick={handleLeftButton}>
          <Icon
            path={mdiArrowLeft}
            size='24px'
          />
        </LeftButton>
        <SearchInput
          type='text'
          placeholder='Pesquisar por localização'
          value={searchValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <BtnClear onClick={() => setSearchValue('')} className={searching.active && searchValue.trim() !== '' ? 'show' : ''}>
          <Icon
            path={mdiBackspace}
            size='22px'
          />
        </BtnClear>

        <AddressList
          data={addressSugestions}
          searchValue={searchValue}
          setValueAndSearch={setValueAndSearch}
          handleInputChange={handleInputChange}
        />
      </SearchContainer>

      { window.innerWidth < 1024 && <SearchOverlay className={searching.active ? 'show' : ''} withoutClipPath={!searching.mapMode} /> }
    </Container>
  )
}

const mapStateToProps = state => ({
  memberData: state.AuthenticationReducer.memberData.data.attributes,
  token: state.AuthenticationReducer.token,
  searchStationsData: state.StationsReducer.searchStations.data,
  searchStationsHistory: state.StationsReducer.searchStationsHistory,
  addressValue: state.StationsReducer.addressValue,
  addressSugestions: state.StationsReducer.addressSugestions
});

export default connect(mapStateToProps, { asyncSearchStations, removeFromHistory, updateAddressValue })(MobileSearch);
