import axios from 'axios';

import {
  SERVER_ADDRESS,
  ASYNC_GET_USER_COMPANIES,
  GET_USER_COMPANIES,
  GET_USER_COMPANIES_ERROR,
  UPDATE_TOKEN
} from '../../constraints/types';

///////////// LIST COMPANIES FROM USER ////////////////

export const asyncGetUserCompanies = (credentials, data) => {
  return dispatch => {
    const userCompanies = {
      error: false,
      loading: true,
      data
    };
    dispatch({ type: ASYNC_GET_USER_COMPANIES, payload: userCompanies });
    getUserCompanies(dispatch, credentials, data);
  };
};

const getUserCompanies = (dispatch, credentials, data) => {
  const url = `${SERVER_ADDRESS}/api/v1/professional/companies`;
  axios.get(url, {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      'X-Access-Token': credentials.token,
      'X-User-Email': credentials.email,
    }
  })
  .then(res => {
    const userCompanies = {
      error: false,
      loading: false,
      data: res.data.data
    };

    if (res.headers['x-access-token']) {
      dispatch({ type: UPDATE_TOKEN, newToken: res.headers['x-access-token'] });
    }

    dispatch({
      type: GET_USER_COMPANIES,
      payload: userCompanies
    });
  })
  .catch(error => {
    const userCompanies = {
      error: error,
      loading: false,
      data
    };
    dispatch({ type: GET_USER_COMPANIES_ERROR, payload: userCompanies });
  });
};
