import styled from 'styled-components';
import { Drawer } from '@material-ui/core';

export const SwipeableDrawer = styled(Drawer)`
  .MuiBackdrop-root {
    background: rgb(37 234 255 / 10%) !important;
  }
  .MuiDrawer-paper {
    padding: 0 !important;
    background: ${props => props.theme.colors.secondary} !important;
  }
`;
