import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  padding: 20px;
  transition: all .15s ease-out;
  margin-bottom: 10px;
  cursor: ${props => props.selecting ? 'copy' : 'pointer'};
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
    z-index: -1;
    opacity: ${props => props.checked ? 1 : 0};
    transition: all .15s ease-out;
    pointer-events: none;
  }

  &:active {
    &::before {
      opacity: 1;
    }
  }
`;

export const StationInner = styled.div`
  transition: all .15s ease;
  transform: ${props => props.checked ? 'scale(.98)' : 'scale(1)'};
`;

export const StationImages = styled.div`
  width: 100%;
  height: ${props => props.imgHeight}px;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  border: 2px solid transparent;
  border-color: ${props => props.checked ? props.theme.colors.primary : 'transparent'};
  transition: all .15s ease;
`;

export const StationImagesSlider = styled(motion.div)`
  height: 100%;
  display: flex;
  align-items: center;
  transform: none;
`;

export const StationImage = styled.div`
  flex: 0 0 calc(100vw - 40px);
  height: 100%;
  background: ${props => `url(${props.bg})`};
  background-size: cover;
  background-position: center;
`;

export const StationName = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 10px;
`;

export const StationLocation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const StationLocationText = styled.p`
  color: ${props => props.theme.text.secondary};
  margin-left: 5px;
`;

export const StationSubTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const StationSubTitleText = styled.p`
  color: ${props => props.theme.text.secondary};
  font-size: 14px;
  text-decoration: overline;
`;

export const StationDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const StationDetail = styled.div`
  display: flex;
  align-items: center;
  padding: 0 6px;
  height: 30px;
  border-radius: 5px;
  background: ${props => props.theme.separator};
  margin-right: 15px;
  transition: all .15s ease;
`;

export const StationDetailText = styled.p`
  font-size: 0.875rem;
  color: ${props => props.theme.text.secondary};
  margin-left: 4px;
`;
