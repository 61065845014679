import React, { useState, useEffect, useRef } from 'react';

import { useSpring, useTransform } from 'framer-motion';
import useWindowSize from '../../utils/hooks/useWindowSize';

import { Container, Sheet } from './styles';

export default function BottomSheet ({ show = false, children, onOpen, onClose, offsetY = 150 }) {
  const size = useWindowSize();

  const [ mount, setMount ] = useState(false);
  const [ height, setHeight ] = useState(size.height);
  const bottomSheet = useRef();
  const [ open, setOpen ] = useState(show);
  const y = useSpring(height, { stiffness: 300, damping: 200, ease: [0.6, 0.05, -0.01, 0.99] });
  const background = useTransform(y, [height, 0], ['rgba(0,0,0,0)', 'rgba(0,0,0,.5)']);

  useEffect(() => {
    setMount(true);
    setHeight(bottomSheet.current.offsetHeight);

    return () => {
      setMount(false);
    }
  }, []);

  useEffect(() => {
    if (mount) {
      if (show) {
        setOpen(true);
        handleOpen();

        openSheet();
      }
      else {
        setOpen(false);
        handleClose();

        closeSheet();
      }
    }
  }, [show]);

  function handleOpen () {
    if (onOpen) onOpen();
  }

  function handleClose() {
    if (onClose) onClose();
  }

  function handleDrag (evt, info) {
    if (open) {
      if (info.point.y > offsetY || info.velocity.y > 140) {
        y.set(height);
        setOpen(false);
        handleClose();
      }
      else {
        y.set(0);
      }

    }
    else {
      if (info.point.y < height || info.velocity.y < -140) {
        y.set(0);
      }
      else {
        y.set(height);
      }

      setOpen(true);
      handleOpen();
    }
  }

  function openSheet () {
    setOpen(true);
    y.set(0);
  }

  function closeSheet (evt) {
    if (evt) {
      if (evt.target.classList.contains('bottomsheet-overlay')) {
        if (open) {
          setOpen(false);
          handleClose();
          y.set(height);
        }
        else {
          setOpen(true);
          handleOpen();
          y.set(0);
        }
      }
    }
    else {
      setOpen(false);
      y.set(height);
    }
  }

  return (
    <Container
      {...{ open, size }} 
      style={{ background }}
      onClick={closeSheet}
      className='bottomsheet-overlay'
    >
      <Sheet
        ref={bottomSheet}
        drag='y'
        dragElastic={0.01}
        dragDirectionLock={true}
        dragConstraints={{ top: 0, bottom: height }}
        dragMomentum={false}
        onDragEnd={handleDrag}
        style={{ y }}
      >
        { children }
      </Sheet>
    </Container>
  )
}