import React, { useContext, useEffect, useState } from 'react';

import _ from 'lodash';
import { ThemeContext } from 'styled-components';
import { Icon } from '@iconify/react';
import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import useWindowSize from '../../utils/hooks/useWindowSize';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import VideoThumbnail from 'react-video-thumbnail';
import Lottie from 'react-lottie';

import { SERVER_ADDRESS } from '../../constraints/types';
import rotateDevice from '../../assets/lotties/rotate_device.json';
import rotateDeviceDark from '../../assets/lotties/rotate_device_dark.json';
import { getStationAds } from '../../store/actions/AdsActions';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import { Container, BtnBack, PageTitle, RotateAnimationContainer, StationAdContainer, StationAdVideo, StationAdImage, StationAdVideoThumb, StationAdImageThumb } from './styles';

import { connect } from 'react-redux';

const ViewAdsAtStation = ({ email, token, stationAds, getStationAds }) => {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const params = useParams();
  const size = useWindowSize();
  const location = useLocation();
  const [screenAds, setScreenAds] = useState([]);
  const [handledFirstVideo, setHandledFirstVideo] = useState(false);

  // Chamada do endpoint para buscar os anúncios da estação, na montagem do componente
  useEffect(() => {
    const credentials = { email, token };
    getStationAds(credentials, params.id);
  }, []);

  useEffect(() => {
    const { loading, error, data } = stationAds;
    const { serviceStationId } = location;
    // verificando se foi carregado os anuncios do painel corretamente
    if (!loading && !error && data.length) {
       //  e caso tenha um serviceStationId como parâmetro,
       // significa que o cliente entrou na visualização do painel após realizar o pagamento e quer ver seu anúncio,
       // portanto nesse momento é feito a ordenação colocando este anúncio na primeira posição (pro usuário entrar e já ver)
      if (serviceStationId) {
        let sortedStationAds = _.sortBy(data, ({service_station_id}) => service_station_id == serviceStationId ? 0 : 1);
        setScreenAds(sortedStationAds);
      } else {
        setScreenAds(data);
      }
    }
  }, [stationAds]);

  const rotateDeviceOptions = {
    loop: true,
    autoplay: true,
    animationData: theme.title === 'dark' ? rotateDeviceDark : rotateDevice,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const playCurrentVideo = (index) => {
    const allVideos = document.getElementsByTagName("VIDEO");
    for (var i = 0, max = allVideos.length; i < max; i++) {
     allVideos[i].pause();
    }

    const tag = document.getElementById(index);

    if (tag && tag.tagName === 'VIDEO') {
      tag.load();
      tag.play();
    }
  };

  const playFirstVideo = () => {
    if (screenAds.length && screenAds[0].video) {
      const firstVideo = document.getElementsByTagName("VIDEO")[0];
      if (firstVideo) {
        firstVideo.play();
        setHandledFirstVideo(true);
      }
    }
  }

  const renderCustomThumbs = () => {

    !handledFirstVideo && playFirstVideo();

    const thumbList = screenAds.map((stationAd, index) =>
      <div key={index}>
        { !stationAd.video &&
          <StationAdImageThumb src={`${SERVER_ADDRESS}${stationAd.image}`} />
        }
        { stationAd.video &&
          <StationAdVideoThumb>
            <VideoThumbnail videoUrl={stationAd.video} />
          </StationAdVideoThumb>
        }
      </div>
    )
    return thumbList;
  }

  const formatStatus = (currentItem, total) => {
    return `${currentItem} de ${total}`
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  return (
    <Container
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
    >
      <DeviceOrientation lockOrientation={'landscape'}>
        <Orientation orientation='landscape' alwaysRender={false}>
          { size.width < 1024 &&
            <BtnBack onClick={() => history.goBack()} style={{ position: 'absolute', zIndex: '9'}}>
              <Icon
                icon={roundArrowBack}
                color={'#fff'}
                style={{ width: '24px', height: '24px' }}
              />
            </BtnBack>
          }
          { screenAds.length &&
            <Carousel
              autoPlay={true}
              interval={screenAds[0].time*1000}
              infiniteLoop={true}
              renderThumbs={renderCustomThumbs}
              onChange={(i) => playCurrentVideo(i)}
              dynamicHeight={true}
              statusFormatter={(currentItem, total) => formatStatus(currentItem, total)}
            >
              {
                screenAds.map((stationAd, i) => {
                  return (
                    <StationAdContainer key={i}>
                      { (!stationAd.video) &&
                        <StationAdImage src={`${SERVER_ADDRESS}${stationAd.image}`} />
                      }
                      { (stationAd.video) &&
                        <StationAdVideo id={i}>
                          <source src={stationAd.video} type={stationAd.video_content_type} />
                          Your browser does not support the video tag.
                        </StationAdVideo>
                      }
                    </StationAdContainer>
                  );
                })
              }
            </Carousel>
          }
        </Orientation>
        <Orientation
          style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '70%' }}
          orientation='portrait'
          alwaysRender={false}
        >
          <BtnBack onClick={() => history.goBack()}>
            <Icon
              icon={roundArrowBack}
              color={'#fff'}
              style={{ width: '24px', height: '24px' }}
            />
          </BtnBack>
          <RotateAnimationContainer>
            <PageTitle>Rotacione o aparelho na horizontal</PageTitle>
            <Lottie
              options={rotateDeviceOptions}
              isClickToPauseDisabled={true}
              width={280}
              height={251}
              style={{ paddingTop: '10px' }}
            />
          </RotateAnimationContainer>
        </Orientation>
      </DeviceOrientation>
      { /* BACKDROP PARA SOBREPOR A TELA ENQUANTO ESTIVER ENVIANDO O ANUNCIO PARA VÁRIOS PAINEIS */}
      <Backdrop style={{ zIndex: 999, color: theme.colors.primary }} open={stationAds.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}

const mapStateToProps = state => ({
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token,
  stationAds: state.AdsReducer.stationAds
});

export default connect(mapStateToProps, { getStationAds })(ViewAdsAtStation);
