import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';

import Icon from '@mdi/react';
import { mdiClose, mdiInformation, mdiCheckboxMarkedCircle, mdiAlertCircle } from '@mdi/js';
import { connect } from 'react-redux';
import _ from 'lodash';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';
import { TextField, InputLabel, Select, MenuItem, FormControl, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, InputAdornment } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import { set, format } from 'date-fns';
import { ThemeContext } from 'styled-components';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import Lottie from 'react-lottie';

import useWindowSize from '../../utils/hooks/useWindowSize';
import { SERVER_ADDRESS } from '../../constraints/types';
import { addStation, updateAddStationData, getCategories, checkUniquename, setSentThumb } from '../../store/actions/StationsActions';
import { asyncGetUserCompanies } from '../../store/actions/CompaniesActions';
import { addNotification } from '../../store/actions/NotificationsActions';

import UploadFeedback from '../../components/UploadFeedback';
import FilePicker from '../../components/FilePicker';
import ImageCrop from '../../components/ImageCrop';
import successAnimation from '../../assets/lotties/success.json';
import errorAnimation from '../../assets/lotties/error-face.json';

import {
  Container,
  Header,
  Row,
  Title,
  BtnClose,
  Indicator,
  Footer,
  LeftButton,
  RightButton,
  Pages,
  Page,
  PageTitle,
  CompanyBtns,
  BtnCompany,
  CompanyImg,
  CompanyName,
  PageInfo,
  PageInfoText,
  PageRow,
  TermsControlLabel,
  TermsControlLabelText,
  TermsLink,
  BtnFeedback,
  AlertBtnExit,
  AlertBtnConfirm,
  ThumbsSlider,
  ThumbsInner,
  Thumb,
  TagContainer,
  Spinner,
  CircleLoader,
  TagStatusBadge
} from './styles';
import { AnimatePresence } from 'framer-motion';

const AddStation = ({ addStation, updateAddStationData, addStationLoading, addStationData, addStationError, addStationImgs, addStationFinish, asyncGetUserCompanies, addNotification, email, token, userCompaniesData, userCompaniesLoading, userCompaniesError, sentThumb, setSentThumb, userId, userNotifications, getCategories, checkUniquename, categories, uniquename }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  const recaptchaRef = useRef();
  const termsContentRef = useRef();
  const thumbsSliderRef = useRef();
  const theme = useContext(ThemeContext);
  const size = useWindowSize();

  const [ cropState, setCropState ] = useState('select');
  const [ thumbsSliderWidth, setThumbsSliderWidth ] = useState(360);
  const [ imageRemoved, setImageRemoved ] = useState(null);
  const [ alertOpen, setAlertOpen ] = useState(false);
  const [ termsAlertOpen, setTermsAlertOpen ] = useState(false);
  const [ stepIncomplete, setStepIncomplete ] = useState(true);
  const [ completedForm, setCompletedForm ] = useState(false);
  const [ tagError, setTagError ] = useState(false);
  const [ tagErrorMessage, setTagErrorMessage ] = useState('');

  const [ recaptchaValue, setRecaptchaValue] = useState(false); // anti bot
  const [ acceptedTerms, setAcceptedTerms] = useState(false); // termos de uso
  const [ companyName, setCompanyName ] = useState(''); // nome da estação
  const [ categoryId, setCategoryId ] = useState('');
  const [ tag, setTag ] = useState('');
  const [ periodBegin, setPeriodBegin ] = useState(set(new Date(), { hours: 8, minutes: 0 })); // horário abertura painel
  const [ periodEnd, setPeriodEnd ] = useState(set(new Date(), { hours: 17, minutes: 30 })); // horário encerramento painel
  const [ weekDaysBegin, setWeekDaysBegin ] = useState('mon'); // inicio semanal funcionamento do painel
  const [ weekDaysEnd, setWeekDaysEnd ] = useState('fri'); // fim semanal funcionamento do painel
  const [ description, setDescription ] = useState(''); // texto medio para vantagens da estação
  const [ inches, setInches ] = useState(''); // polegadas do televisor (opcional)
  const [ resolution, setResolution ] = useState(''); // resolução do televisor (opcional)
  const [ stationThumb, setStationThumb ] = useState(''); // avatar da estação
  const [ stationImgs, setStationImgs ] = useState([]); // imagens da estação
  const [ usedImages, setUsedImages ] = useState([]); // already used images
  let match = useRouteMatch('/add-media-panel/:step');
  if ((match !== null) && (!params.hasOwnProperty('step'))) {
    params.step = match.params.step;
  }

  // Company selected to add station
  const [ companyId, setCompanyId ] = useState(-1);
  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  }

  const debounceFn = useCallback(_.debounce(q => checkTagDuplicity(q), 500), []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    setSentThumb('');
    const credentials = { email, token }
    asyncGetUserCompanies(credentials, userCompaniesData);
    getCategories(credentials, categories);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  useEffect(() => {
    let usedImagesArray = [];
    userCompaniesData.map((item, index) => {
      item.attributes.stations.map((item, index) => {
        item['image-station'].map((item, index) => {
          item['storage-ref'] = item['storage-ref'].split('?')[0];
          usedImagesArray = [ item, ...usedImagesArray ];
        });
      });
    });

    setUsedImages(usedImagesArray);

  }, [userCompaniesData]);

  useEffect(() => {
    if (tag === '') return;

    if (params.step === '1') {
      if (!uniquename.isUnique && !uniquename.loading) {
        setTagError(true);
        setTagErrorMessage(t('form.addStation.tag.error'));
        setStepIncomplete(true);
      } else {
        setTagError(false);
        setTagErrorMessage('');

        if (uniquename.loading) setStepIncomplete(true);
        else if (categoryId) setStepIncomplete(false);
      }
    }
  }, [uniquename]);

  const handleKeyDown = evt => {
    if (evt.key === 'Escape' && addStation) {
      const status = window.confirm('Tem certeza que deseja cancelar?');

      if (status) {
        handleClose();
      }
    }
  }

  const handleClose = () => {
    history.push('/');
  }

  useEffect(() => {
    if (params.step === '1') {
      const credentials = { email, token }
      checkUniquename(credentials, tag);
      if (!categoryId || !uniquename.isUnique) setStepIncomplete(true);
    }
    else if (params.step === '2') {
      setStepIncomplete(false);
    }
    else if (params.step === '3') {
      if (inches && inches > 10) setStepIncomplete(false);
      else setStepIncomplete(true);
    }
    else if (params.step === '4') {
      if (stationImgs.length > 0) setStepIncomplete(false); else setStepIncomplete(true);
    }
    else if (params.step === '6') {
      if (!acceptedTerms || !recaptchaValue) setStepIncomplete(true);
    }
    else if (params.step === '5' || params.step === '6') setStepIncomplete(true);
  }, [params.step, inches, stationImgs.length, categoryId]);

  useEffect(() => {
    if (params.step === '5' && stationThumb) setStepIncomplete(false);
    else if (params.step === '5' && !stationThumb) setStepIncomplete(true);
  }, [stationThumb]);

  useEffect(() => {
    if (size.width < 1024 && thumbsSliderRef.current) {
      setThumbsSliderWidth(thumbsSliderRef.current.offsetWidth);
    }
  }, [size.width, stationImgs.length]);

  useEffect(() => {
    if (termsAlertOpen) {
      if (termsContentRef.current) {
        termsContentRef.current.focus();
      }
    }
  }, [termsAlertOpen]);

  useEffect(() => {
    if (stationImgs.length > 0) setStepIncomplete(false);
    else setStepIncomplete(true);
  }, [stationImgs.length]);

  useEffect(() => {
    if (completedForm) {
      const notificationData = {
        content: t('notification.activation', { companyName }),
        date: Date.now(),
        img: `${SERVER_ADDRESS}${addStationData.data.attributes['avatar-thumb']}`,
        id: addStationData.data.id,
        uniquename: addStationData.data.attributes.uniquename,
        cdStation: btoa(btoa(addStationData.data.attributes['cd-station'])),
        type: 'activation',
        stationName: companyName,
        important: true,
        read: false
      }
      // convert userid to b64
      const userIdB64 = btoa(userId);
      // insert the new notification to avoid user for activate the fresh station
      addNotification(notificationData, userIdB64);
      history.push(`/${t('routes.addMediaPanel')}/success`);
    }
  }, [completedForm])

  useEffect(() => {
    if (!addStationLoading && addStationFinish && !addStationError) {
      const updateAddStation = {
        data: addStationData,
        finish: false,
        loading: false,
        error: false,
        stImgsData: addStationImgs,
        stImgsError: false
      }
      updateAddStationData(updateAddStation);
      setCompletedForm(true);
    }

    if (addStationError) {
      history.push(`/${t('routes.addMediaPanel')}/error`);
    }
  }, [addStationData, addStationLoading])

  useEffect(() => {
    if (params.step === '6') {
      if (acceptedTerms && recaptchaValue) setStepIncomplete(false);
      else setStepIncomplete(true);
    }
  }, [acceptedTerms, recaptchaValue]);

  // Function to prevent duplicity in the tag on input change, called with a debounce of 500 milliseconds
  // Change this function to make the request to the endpoint
  const checkTagDuplicity = value => {
    const credentials = { email, token }
    checkUniquename(credentials, value);
  }

  const handleSelectCompany = (company) => {
    setCompanyName(company.attributes.name);
    setCompanyId(company.id);

    // We need to generate a tag based on the company name, just so it's easier to the user to complete the station register
    let tag = `${company.attributes.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '').toLowerCase()}`;

    // Checking for name duplicity on the tag generation (before the user types). This can happen on a situation where a user wants to register more than one station per company. So we append a random 3 digit integer value to the end of the string, to create randomness

    checkTagDuplicity(tag);

    if (!uniquename.isUnique) {
      tag = tag.concat(Math.floor(100 + Math.random() * 900));
    }

    setTag(tag);

    setStepIncomplete(false);
  }

  const handleBack = () => {
    if (params.step === '1' && companyName) {
      setStepIncomplete(false);
      history.push(`/${t('routes.addMediaPanel')}/${parseInt(params.step) - 1}`);
    }
    else if (params.step === '5') {
      if (cropState === 'crop') {
        setCropState('select');
        setStationThumb(null);
      }
      else {
        history.push(`/${t('routes.addMediaPanel')}/${parseInt(params.step) - 1}`);
        setStationThumb(null);
      }
    }
    else if (params.step === '6') {
      setCropState('select');
      setRecaptchaValue(false);
      setAcceptedTerms(false);
      history.push(`/${t('routes.addMediaPanel')}/${parseInt(params.step) - 1}`);
    }
    else {
      history.push(`/${t('routes.addMediaPanel')}/${parseInt(params.step) - 1}`);
    }
  }

  const handleContinue = () => {
    if (params.step < 6) {
      history.push(`/${t('routes.addMediaPanel')}/${parseInt(params.step) + 1}`);
    }
    else if (!stepIncomplete && params.step === '6') {
      setStepIncomplete(true);

      // envio dos dados para o servidor (criação de fato da station)
      //if (SERVER_ADDRESS !== 'https://www.telesapp.net') {
        const stationData = {
          name: companyName,
          periodBegin: format(periodBegin, 'HH:mm'),
          periodEnd: format(periodEnd, 'HH:mm'),
          weekDaysBegin,
          weekDaysEnd,
          description,
          inches,
          resolution,
          companyId,
          categoryId,
          stationImgs,
          stationThumb,
          tag
        }
        const credentials = { email, token }
        addStation(credentials, stationData);
      // } else {
      //   alert('Você não pode cadastrar estações em produção');
      //   setStep('success');
      // }
    }
  }

  const openStationScreen = () => {
    history.push({
      pathname: `/station/${addStationData.data.id}`
    });
  }

  const handleStartDayChange = evt => {
    setWeekDaysBegin(evt.target.value);
  }

  const handleEndDayChange = evt => {
    setWeekDaysEnd(evt.target.value);
  }

  const handleCategoryChange = evt => {
    if (evt.target.value) {
      setCategoryId(evt.target.value);

      if (tag) {
        setStepIncomplete(false);
      }
    }
  }

  const updateRecaptchaValue = value => {
    setRecaptchaValue(value);
  }

  const handleAcceptedTerms = evt => {
    setAcceptedTerms(evt.target.checked);

    if (stepIncomplete && recaptchaValue && evt.target.checked) {
      setStepIncomplete(false);
    }
  }

  // Função passada ao file picker, que é adicionado um item (metadata) array de imagens da estação
  // Quando o checkbox da imagem em questão é checked no interior do file picker é chamada esta função
  // para então adicionar o novo item no vetor
  const handleStationImages = (operation, metaData) => {
    if (operation === 'add') {
      // novo array com spread do antigo mais o novo item (apenas 1) a ser adicionado
      let newStationImgs = [ ...stationImgs, metaData];
      // filtragem para não repetir o mesmo arquivo, usando como identificador único a url da imagem
      // porém é necessário realizar o split pois a url do arquivo não é única após o "?" como a seguir
      // "localizacao_unica_do_arquivo?alt=media&token=e6a39046-ca5d-4057-8681-533c1c9ffe47"
      // "localizacao_unica_do_arquivo?alt=media&token=36ae32ff-cd72-45ec-a570-37b4623ca9d2"
      // veja que após o "?" a informação pode mudar, portanto o uso do split

      newStationImgs = _.uniqBy(newStationImgs, function (img) { return img.url.split('?')[0]; });

      setStationImgs([ ...newStationImgs]);
    } else if (operation === 'add-multiple') {
      // novo array com spread do antigo mais o spread do array das múltiplas imagens a serem adicionadas
      let newStationImgs = [ ...stationImgs, ...metaData];
      newStationImgs = _.uniqBy(newStationImgs, function (img) { return img.url.split('?')[0]; });

      setStationImgs([ ...newStationImgs]);
    } else {
      // remoção das imagens selecionadas, usando o método remove do lodash, encontrando um item que possui a url
      // igual à url dos arquivos da galeria (usada para selecionar ou remover seleção ou excluir)
      _.remove(stationImgs, function(item) { return item.url.split('?')[0] === metaData.url.split('?')[0] });;
      setStationImgs([ ...stationImgs ]);
    }
  }

  const handleAlertOpen = () => {
    setAlertOpen(true);
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  }

  const handleTermsAlertOpen = evt => {
    evt.stopPropagation();

    setTermsAlertOpen(true);
  }

  const handleTermsAlertClose = () => {
    setTermsAlertOpen(false);
  }

  const handleCancelUpload = () => {
    handleAlertClose();
    history.push()
  }

  const handleRemoveFromArray = (img) => {
    setTimeout(() => {
      let status = window.confirm(`${t('form.addStation.images.remove')}`);

      if (status) {
        handleStationImages('remove', img);
        setImageRemoved(img);
      }
    }, 150);
  }

  const handleTagChange = evt => {
    if (evt.target.value === '') {
      evt.preventDefault();

      setTag(evt.target.value);
      setTagError(true);
      setTagErrorMessage(t('form.addStation.tag.empty'));
      setStepIncomplete(true);

      return;
    }
    else if (!/^[a-zA-Z0-9]+$/.test(evt.target.value)) {
      evt.preventDefault();

      return;
    }
    else {
      setTag(evt.target.value);
      debounceFn(evt.target.value);
    }
  }

  const mobileVariants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  const desktopVariants = {
    initial: { opacity: 0, scale: .95, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  const spinnerVariants = {
    initial: { opacity: 0, transition: { duration: .15 } },
    visible: { opacity: 1, transition: { duration: .15 } },
  }

  const tagBadgeVariants = {
    initial: { opacity: 0, transition: { duration: .15 } },
    visible: { opacity: 1, transition: { duration: .15 } },
  }

  return (
    <Container
      variants={size.width < 1024 ? mobileVariants : desktopVariants}
      initial='initial'
      animate='visible'
      exit='hidden'
      width={size.width}
      height={size.height}
    >
      <Header>
        <Row>
          <Title hidden={params.step === 'success' || params.step === 'error'}>{t('form.addStation.title')}</Title>
          <BtnClose size='small' onClick={handleClose}>
            <Icon
              path={mdiClose}
              size='24px'
            />
          </BtnClose>
        </Row>
        <Indicator step={params.step} hidden={params.step === 'success' || params.step === 'error'} />
      </Header>

      <Pages>
        {
          params.step === '0' && (
            <Page>
              <PageTitle>{t('form.addStation.selectPP')}</PageTitle>
              <CompanyBtns>
                {
                  userCompaniesData.map((item, i) => (
                    <BtnCompany
                      key={i}
                      selected={item.id === companyId}
                      onClick={() => handleSelectCompany(item)}
                    >
                      <CompanyImg src={`${SERVER_ADDRESS}${item.attributes['avatar-medium']}`} />
                      <CompanyName>{item.attributes.name}</CompanyName>
                    </BtnCompany>
                  ))
                }
              </CompanyBtns>
            </Page>
          )
        }

        {
          params.step === '1' && (
            <Page>
              <PageTitle>{t('form.addStation.name.title')}</PageTitle>
              <PageInfo>
                <Icon
                  path={mdiInformation}
                  size='36px'
                />
                <PageInfoText>{t('form.addStation.name.hint')}</PageInfoText>
              </PageInfo>
              <TextField
                label={t('form.addStation.name.label')}
                required
                variant='filled'
                value={companyName}
                onChange={(evt) => setCompanyName(evt.target.value)}
                aria-required={true}
                fullWidth
              />

              <FormControl variant="filled" fullWidth style={{ marginTop: 15 }}>
                <InputLabel id='category-label'>{ t('form.addStation.category.label') }</InputLabel>
                <Select
                  labelId='category-label'
                  id='category'
                  value={categoryId}
                  onChange={handleCategoryChange}
                >
                  {
                    categories.data.map((item, i) => (
                      <MenuItem
                        value={item.id}
                        key={i}
                      >
                        {item.attributes.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

              <TagContainer>
                <TextField
                  label={t('form.addStation.tag.label')}
                  placeholder='meupainel'
                  variant='filled'
                  value={tag}
                  onChange={handleTagChange}
                  fullWidth
                  helperText={tagError ? tagErrorMessage : t('form.addStation.tag.description')}
                  error={tagError}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <p style={{ color: theme.text.secondary }}>@</p>
                      </InputAdornment>
                    ),
                  }}
                  style={{ marginTop: 15 }}
                />

                <AnimatePresence exitBeforeEnter>
                  {
                    uniquename.loading ? (
                      <Spinner
                        variants={spinnerVariants}
                        initial='initial'
                        animate='visible'
                        exit='initial'
                      >
                        <CircleLoader cx="15" cy="15" r="10" stroke={theme.colors.primary} fill="none" />
                      </Spinner>
                    ) : (
                      <TagStatusBadge
                        variants={tagBadgeVariants}
                        initial='initial'
                        animate='visible'
                        exit='initial'
                      >
                        <Icon
                          path={tagError ? mdiAlertCircle : mdiCheckboxMarkedCircle}
                          size='24px'
                          color={tagError ? theme.colors.error : theme.colors.primary}
                        />
                      </TagStatusBadge>
                    )
                  }
                </AnimatePresence>
              </TagContainer>
            </Page>
          )
        }

        {
          params.step === '2' && (
            <Page>
              <PageTitle>{t('form.addStation.operation')}</PageTitle>
              <PageRow>
                <FormControl variant="filled" fullWidth style={{ marginRight: 15 }}>
                  <InputLabel id='start-day-label'>{t('form.addStation.operation.days.begin')}</InputLabel>
                  <Select
                    labelId='start-day-label'
                    id='weeksDayBegin'
                    value={weekDaysBegin}
                    onChange={handleStartDayChange}
                  >
                    <MenuItem value='mon'>{t('week.mon')}</MenuItem>
                    <MenuItem value='tue'>{t('week.tue')}</MenuItem>
                    <MenuItem value='wed'>{t('week.wed')}</MenuItem>
                    <MenuItem value='thu'>{t('week.thu')}</MenuItem>
                    <MenuItem value='fri'>{t('week.fri')}</MenuItem>
                    <MenuItem value='sat'>{t('week.sat')}</MenuItem>
                    <MenuItem value='sun'>{t('week.sun')}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl variant="filled" fullWidth>
                  <InputLabel id='end-day-label'>{t('form.addStation.operation.days.end')}</InputLabel>
                  <Select
                    labelId='end-day-label'
                    id='weeksDayEnd'
                    value={weekDaysEnd}
                    onChange={handleEndDayChange}
                  >
                    <MenuItem value='mon'>{t('week.mon')}</MenuItem>
                    <MenuItem value='tue'>{t('week.tue')}</MenuItem>
                    <MenuItem value='wed'>{t('week.wed')}</MenuItem>
                    <MenuItem value='thu'>{t('week.thu')}</MenuItem>
                    <MenuItem value='fri'>{t('week.fri')}</MenuItem>
                    <MenuItem value='sat'>{t('week.sat')}</MenuItem>
                    <MenuItem value='sun'>{t('week.sun')}</MenuItem>
                  </Select>
                </FormControl>
              </PageRow>
              <PageRow>
              <TimePicker
                style={{ marginRight: 15 }}
                fullWidth
                inputVariant='filled'
                ampm={false}
                showTodayButton={true}
                todayLabel={t('form.addStation.timepicker.now')}
                minutesStep={5}
                name='periodBegin'
                label={t('form.addStation.periodBegin.label')}
                value={periodBegin}
                placeholder='12:00'
                invalidDateMessage={t('form.addStation.timepicker.error')}
                mask="__:__"
                okLabel='Ok'
                cancelLabel={t('form.addStation.timepicker.cancel')}
                onChange={(value) => setPeriodBegin(value)}
              />
              <TimePicker
                fullWidth
                inputVariant='filled'
                ampm={false}
                name='periodEnd'
                minutesStep={5}
                label={t('form.addStation.periodEnd.label')}
                value={periodEnd}
                placeholder='18:00'
                invalidDateMessage={t('form.addStation.timepicker.error')}
                mask="__:__"
                okLabel='Ok'
                cancelLabel={t('form.addStation.timepicker.cancel')}
                onChange={(value) => setPeriodEnd(value)}
              />
              </PageRow>
            </Page>
          )
        }

        {
          params.step === '3' && (
            <Page>
              <PageTitle>{t('form.addStation.infos')}</PageTitle>
              <PageInfo>
                <Icon
                  path={mdiInformation}
                  size='36px'
                />
                <PageInfoText>{t('form.addStation.infos.hint')}</PageInfoText>
              </PageInfo>
              <TextField
                label={t('form.addStation.inches.label')}
                required
                type='number'
                variant='filled'
                value={inches}
                aria-required={true}
                onChange={(evt) => { setInches(evt.target.value); evt.target.value > 10 ? setStepIncomplete(false) : setStepIncomplete(true) }}
                fullWidth
                inputProps={{ min: '1', max: '120', step: '1' }}
                style={{ marginBottom: 15 }}
              />
              <TextField
                label={t('form.addStation.resolution.label')}
                variant='filled'
                value={resolution}
                onChange={(evt) => setResolution(evt.target.value)}
                fullWidth
                style={{ marginBottom: 15 }}
              />
              <TextField
                label={t('form.addStation.promotionalText.label')}
                variant='filled'
                value={description}
                onChange={(evt) => setDescription(evt.target.value)}
                fullWidth
                helperText={t('form.addStation.promotionalText.title')}
                multiline
                rowsMax={3}
                style={{ marginBottom: 15 }}
              />
            </Page>
          )
        }

        {
          params.step === '4' && (
            <Page>
              <PageTitle>{(stationImgs.length > 0) ? `${stationImgs.length} ${(stationImgs.length > 1) ? `${t('form.addStation.images.selected.multiple')}` : `${t('form.addStation.images.selected.single')}`}` : `${t('form.addStation.images.title')}`}</PageTitle>
              {
                (stationImgs.length > 0) && (
                  <ThumbsSlider>
                    <ThumbsInner
                      ref={thumbsSliderRef}
                      drag='x'
                      dragElastic={0.2}
                      dragDirectionLock={true}
                      dragMomentum={true}
                      dragConstraints={{ left: thumbsSliderWidth > size.width ? -thumbsSliderWidth + size.width : 0, right: 0 }}
                      numberOfChildren={stationImgs.length}
                    >
                      {
                        stationImgs.map((img, index) => (
                          <Thumb
                            key={index}
                            bg={img.url}
                            onClick={() => handleRemoveFromArray(img)}
                          />
                        ))
                      }
                    </ThumbsInner>
                  </ThumbsSlider>
                )
              }

              <FilePicker
                folder={'station'}
                parentImagesHandling={handleStationImages}
                parentRemovedImage={imageRemoved}
                parentUsedImages={usedImages}
                showGallery={true}
                showPicker={true}
                showTakePhoto={true}
              />
              {/* Componente para visualização de andamento de uploads */}
              <UploadFeedback
                folder={'station'}
              />
            </Page>
          )
        }

        {
          params.step === '5' && (
            <Page>
              <PageTitle>{t('form.addStation.crop')}</PageTitle>
              <ImageCrop
                setStationThumb={setStationThumb}
                setCropState={setCropState}
                cropState={cropState}
                imgs={stationImgs}
                exportedWidth={100}
                exportedHeight={100}
              />
            </Page>
          )
        }

        {
          params.step === '6' && (
            <Page>
              <PageTitle>{t('form.addStation.finish')}</PageTitle>
              <TermsControlLabel
                className='noSelect'
                control={<Checkbox color='primary' onChange={handleAcceptedTerms} />}
                label={
                  <TermsControlLabelText>{t('form.addStation.terms1')}<TermsLink onClick={handleTermsAlertOpen}>{t('form.addStation.terms2')}</TermsLink>{t('form.addStation.terms3')}</TermsControlLabelText>
                }
              />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6LffEswZAAAAALjPceGobsniBRyH99w_OzM2K1_T"
                onChange={updateRecaptchaValue}
                theme={theme.title}
              />

              <Dialog
                open={termsAlertOpen}
                onClose={handleTermsAlertClose}
                scroll='paper'
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
              >
                <DialogTitle id='scroll-dialog-title'>Termos de Uso</DialogTitle>
                <DialogContent dividers>
                  <DialogContentText
                    id='scroll-dialog-description'
                    ref={termsContentRef}
                    tabIndex={-1}
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {
                      `Obrigações Iniciais do Proprietário de Painel de Anúncios Mídia Indoor\n
                      1 - Zelar pelo cumprimento dos horários de funcionamento informados durante o cadastro e atualização do painel de anúncios;\n
                      2 - Manter o painel sempre conectado à internet para o recebimento de novos anúncios em tempo real.\n
                      3 - Manter-se atento às notificações para atender aos pedidos de inserção de anúncios feitos pelos clientes, aceitando ou rejeitando a adição do novo anúncio no menor prazo possível (recomenda-se ter o app do telesapp instalado no celular);\n
                      4 - Caso o painel de mídia indoor não estabeleça a conexão por mais de 24 horas, o painel se tornará inelegível para receber novos anunciantes sendo necessária reativação do mesmo (não sei como vai funcionar isso). Se por força maior o painel não puder ser re-conectado no prazo de 72horas contendo anúncios ativos ele será banido e o proprietário deverá arcar com os prejuízos de estornar o débito aos anunciantes que já realizaram o pagamento mas não lhe foi proporcionado o número de aparições desejada;\n
                      5 - Reportar anúncios que não seguem os termos de uso, contendo violência, pornografia, propaganda enganosa ou outros subgrupos de anúncios proibidos contidos nos termos de uso;\n
                      6 - Certificar-se que a conta provida para o recebimento dos pagamentos de anunciantes esteja ativa e pronta para receber novas transferências e depósitos. Caso não seja possível ser realizado o pagamento o proprietário do painel deverá entrar em contato diretamente com o Telesapp para requisitar a obtenção de forma alternativa dos pagamentos retidos;\n
                      7 - O estabelecimento privado que possui o painel deve estar devidamente cadastrado no endereço correto, uma vez que você terá reivindicado o local, bloqueará outros proprietários de painéis cadastrarem os seus no endereço em questão.\n
                      8 - Promover a notificação de desativação do painel com antecedência se por ventura o proprietário do painel desejar por si o encerramento da utilização e disponibilização do painel no país, cumprindo assim os prazos dos últimos anúncios recebidos para então promover a desativação de fato do painel de anúncios.\n`
                    }
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleTermsAlertClose} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
            </Page>
          )
        }

        {
          params.step === 'success' && (
            <Page>
              <PageTitle style={{ textAlign: 'center' }}>{t('form.addStation.success')}</PageTitle>
              <Lottie
                options={successOptions}
                isClickToPauseDisabled={true}
                width={280}
                height={251}
                style={{ marginTop: -20 }}
              />
              <BtnFeedback onClick={openStationScreen}>{t('form.addStation.access')}</BtnFeedback>
            </Page>
          )
        }

        {
          params.step === 'error' && (
            <Page>
              <PageTitle style={{ textAlign: 'center' }}>{t('form.addStation.error')}</PageTitle>
              <Lottie
                options={errorOptions}
                isClickToPauseDisabled={true}
                width={180}
                height={180}
                style={{ margin: '15px auto' }}
              />
              <BtnFeedback onClick={() => {setCropState('select'); setStationThumb(null); history.push(`/${t('routes.addMediaPanel')}/0`)} }>{t('form.addStation.retry')}</BtnFeedback>
            </Page>
          )
        }
      </Pages>
      {
        params.step !== 'success' && params.step !== 'error' && (
          <Footer>
            <LeftButton disabled={params.step === '0'} onClick={handleBack}>{(cropState === 'crop' && params.step === '5') ? t('imagecrop.chooseAnother') : t('form.addStation.button.back')}</LeftButton>
            <RightButton disabled={stepIncomplete} onClick={handleContinue}>{(params.step < 6) ? t('form.addStation.button.continue') : t('form.addStation.button.finish')}</RightButton>
          </Footer>
        )
      }

      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText>{ (stationImgs.length > 1) ? t('form.addStation.images.cancel.multiple') : t('form.addStation.images.cancel.single') }</DialogContentText>
        </DialogContent>
        <DialogActions>
          <AlertBtnExit onClick={handleAlertClose}>
            Cancelar
          </AlertBtnExit>
          <AlertBtnConfirm onClick={handleCancelUpload}>
            Sim
          </AlertBtnConfirm>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

const mapStateToProps = state => ({
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token,
  userCompaniesData: state.CompaniesReducer.userCompanies.data,
  userCompaniesLoading: state.CompaniesReducer.userCompanies.loading,
  userCompaniesError: state.CompaniesReducer.userCompanies.error,
  addStationLoading: state.StationsReducer.addStation.loading,
  addStationData: state.StationsReducer.addStation.data,
  addStationError: state.StationsReducer.addStation.error,
  addStationFinish: state.StationsReducer.addStation.finish,
  addStationImgs: state.StationsReducer.addStation.stImgsData,
  sentThumb: state.StationsReducer.sentThumb,
  userId: state.AuthenticationReducer.memberData.data.id,
  userNotifications: state.NotificationsReducer.userNotifications,
  categories: state.StationsReducer.categories,
  uniquename: state.StationsReducer.uniquename
});

export default connect(mapStateToProps, { addStation, updateAddStationData, asyncGetUserCompanies, addNotification, getCategories, checkUniquename, setSentThumb })(AddStation);
