import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Function used to change the tab, in both the top and bottom bar
export default function useActiveTab () {
  const location = useLocation();
  function getTab () {
    switch (location.pathname) {
      case '/':
        return 0;
      case '/panels':
        return 1;
      case '/stations':
        return 2;
      case '/settings':
        return 3;
      default:
        return 0;
    }
  }

  const [ activeTab, setActiveTab ] = useState(getTab);

  useEffect(() => {
    function handleRefresh () {
      setActiveTab(getTab());
    }

    window.addEventListener('beforeunload', handleRefresh);

    return function cleanup () {
      window.removeEventListener('beforeunload', handleRefresh);
    }
  }, []);

  useEffect(() => {
    setActiveTab(getTab());
  }, [location.pathname]);

  return { activeTab, setActiveTab };
}
