import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import useWindowSize from '../utils/hooks/useWindowSize';
import useActiveTab from '../utils/hooks/useActiveTab';
import useCurrentPage from '../utils/hooks/useCurrentPage';

import Stations from '../pages/Stations';
import Dashboard from '../pages/Dashboard';
import ChatMessages from '../pages/ChatMessages';
import AddStation from '../pages/AddStation';
import ManageStationImgs from '../pages/ManageStationImgs';
import AddService from '../pages/AddService';
import Settings from '../pages/Settings';
import EditService from '../pages/EditService';
import Station from '../pages/Station';
import NotFound from '../pages/NotFound';
import Notifications from '../pages/Notifications';
import ListStations from '../pages/ListStations';
import StationActivation from '../pages/StationActivation';
import SearchStations from '../pages/SearchStations';
import Profile from '../pages/Profile';
import AdApproval from '../pages/AdApproval';
import AdApprovedPayment from '../pages/AdApprovedPayment';
import AnimatedSwitch from './AnimatedSwitch';
import ViewAdsAtStation from '../pages/ViewAdsAtStation';

const Routes = () => {
  const size = useWindowSize();
  const location = useLocation();
  const { activeTab } = useActiveTab();
  const { currentPage } = useCurrentPage();

  if (size.width < 1024) {
    if (location.pathname === '/add-media-panel/:step') {
      return (
        <AnimatedSwitch>
          <Route exact path='/auth/:email/:token'>
            <Redirect to='/' />
          </Route>
          <Route exact path='/'>
            <Dashboard />
          </Route>
          <Route exact path='/stations'>
            <Stations />
          </Route>
          <Route path='/panels'>
            <SearchStations />
          </Route>
          <Route path='/settings' component={Settings} />
          <Route exact path='/chat' component={ChatMessages} />
          <Route exact path='/notifications' component={Notifications} />
          <Route exact path='/edit-service/:id' component={EditService} />
          <Route exact path='/station/:id' component={Station} />
          <Route exact path='/list-stations/:type' component={ListStations} />
          <Route exact path='/add-media-panel/:step' component={AddStation} />
          <Route exact path='/manage-station-images/:step' component={ManageStationImgs} />
          <Route exact path='/station/:id/activation' component={StationActivation} />
          <Route exact path='/search-stations' component={SearchStations} />
          <Route exact path='/profile/:id' component={Profile} />
          <Route exact path='/ad-approval' component={AdApproval} />
          <Route exact path='/ad-approved' component={AdApprovedPayment} />
          <Route exact path='/announce/:step' component={AddService} />
          <Route exact path='/view-ads/:id' component={ViewAdsAtStation} />
          <Route path='*' component={NotFound} />
        </AnimatedSwitch>
      )
    }
    else {
      return (
        <Switch>
          <Route exact path='/auth/:email/:token'>
            <Redirect to='/' />
          </Route>
          <Route exact path='/'>
            <Dashboard />
          </Route>
          <Route exact path='/stations'>
            <Stations />
          </Route>
          <Route path='/panels'>
            <SearchStations />
          </Route>
          <Route path='/settings' component={Settings} />
          <Route exact path='/chat' component={ChatMessages} />
          <Route exact path='/notifications' component={Notifications} />
          <Route exact path='/edit-service/:id' component={EditService} />
          <Route exact path='/station/:id' component={Station} />
          <Route exact path='/list-stations/:type' component={ListStations} />
          <Route exact path='/add-media-panel/:step' component={AddStation} />
          <Route exact path='/manage-station-images/:step' component={ManageStationImgs} />
          <Route exact path='/station/:id/activation' component={StationActivation} />
          <Route exact path='/search-stations' component={SearchStations} />
          <Route exact path='/profile/:id' component={Profile} />
          <Route exact path='/ad-approval' component={AdApproval} />
          <Route exact path='/ad-approved' component={AdApprovedPayment} />
          <Route exact path='/announce/:step' component={AddService} />
          <Route exact path='/view-ads/:id' component={ViewAdsAtStation} />
          <Route path='*' component={NotFound} />
        </Switch>
      )
    }
  }

  return (
    <Switch>
      <Route exact path='/auth/:email/:token'>
        <Redirect to='/' />
      </Route>
      <Route exact path='/'>
        <Dashboard />
      </Route>
      <Route exact path='/stations'>
        <Stations />
      </Route>
      <Route path='/panels' render={() => {
        return <Redirect to='/' />
      }}/>
      <Route path='/settings' render={() => {
        return <Redirect to='/' />
      }}/>
      <Route exact path='/notifications' render={() => {
        return <Redirect to='/' />
      }}/>
      <Route exact path='/chat/:recipientId/:companyId/:serviceOrderId' render={() => {
        return <Redirect to='/' />
      }}/>
      <Route exact path='/edit-service/:id' component={EditService} />
      <Route exact path='/station/:id' component={Station} />
      <Route exact path='/add-media-panel/:step' render={() => {
        switch (activeTab) {
          case 0:
            return <Dashboard />
          default:
            return <Dashboard />
        }
      }}/>
      <Route exact path='/announce/:step' render={() => {
        if (currentPage === 1) {
          return <Station />
        } else {
          if (activeTab === 0) {
            return <Dashboard />
          }
        }
      }}/>
      <Route exact path='/station/:id/activation' render={() => {
        if (currentPage === 1) {
          return <Station />
        } else {
          if (activeTab === 0) {
            return <Dashboard />
          }
        }
      }}/>
      <Route exact path='/manage-station-images/:step' render={() => {
        return <Station />
      }}/>
      <Route exact path='/ad-approval' render={() => {
        return <Dashboard />
      }}/>
      <Route exact path='/ad-approved' render={() => {
        return <Dashboard />
      }}/>
      <Route exact path='/profile/:id' render={() => {
        return <Dashboard />
      }}/>
      <Route exact path='/about' render={() => {
        return <Dashboard />
      }}/>
      <Route exact path='/help' render={() => {
        return <Dashboard />
      }}/>
      <Route exact path='/search-stations' component={SearchStations} />
      <Route exact path='/view-ads/:id' component={ViewAdsAtStation} />
      <Route path='*' component={NotFound} />
    </Switch>
  )
}

export default Routes;
