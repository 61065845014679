import {
    GET_USER_PIX,
    GET_USER_PIX_SUCCESS,
    GET_USER_PIX_ERROR,
    ADD_USER_PIX,
    ADD_USER_PIX_SUCCESS,
    ADD_USER_PIX_ERROR,
    DELETE_USER_PIX,
    DELETE_USER_PIX_SUCCESS,
    DELETE_USER_PIX_ERROR,
    GET_ORDER_STATUS,
    GET_ORDER_STATUS_SUCCESS,
    GET_ORDER_STATUS_ERROR,
    UPDATE_ORDER_STATUS,
    CLEAR_MANAGE_PIX,
} from '../../constraints/types';

const INITIAL_STATE = {
    userPix: {
        data: [],
        loading: false,
        error: false
    },
    addPix: {
        data: [],
        loading: false,
        error: false
    },
    deletePix: {
        data: [],
        loading: false,
        error: false
    },
    orderStatus: {
        status: '',
        loading: false,
        error: false
    },
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_PIX:
            return { ...state, userPix: action.payload };
        case GET_USER_PIX_SUCCESS:
            return { ...state, userPix: action.payload };
        case GET_USER_PIX_ERROR:
            return { ...state, userPix: action.payload };
        case ADD_USER_PIX:
            return { ...state, addPix: action.payload };
        case ADD_USER_PIX_SUCCESS:
            return { ...state, addPix: action.payload };
        case ADD_USER_PIX_ERROR:
            return { ...state, addPix: action.payload };
        case DELETE_USER_PIX:
            return { ...state, deletePix: action.payload };
        case DELETE_USER_PIX_SUCCESS:
            return { ...state, deletePix: action.payload };
        case DELETE_USER_PIX_ERROR:
            return { ...state, deletePix: action.payload };
        case GET_ORDER_STATUS:
            return { ...state, orderStatus: action.payload };
        case GET_ORDER_STATUS_SUCCESS:
            return { ...state, orderStatus: action.payload };
        case GET_ORDER_STATUS_ERROR:
            return { ...state, orderStatus: action.payload };
        case UPDATE_ORDER_STATUS:
            return { ...state, orderStatus: action.payload };
        case CLEAR_MANAGE_PIX:
            return { ...state, deletePix: INITIAL_STATE.deletePix, addPix: INITIAL_STATE.addPix };
        default:
            return state;
        };
}