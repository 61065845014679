import React, { useState } from 'react';
import Moment from 'moment';

import { SERVER_ADDRESS } from '../../constraints/types';
import Popover from '@material-ui/core/Popover';

import {
  Container,
  StationInner,
  StationContainer,
  StationImg,
  StationContent,
  StationTitle,
  StationAddress,
  StationAdVideo,
  PopLine
} from './styles';

const AdItem = ({ ad, id }) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Container
      key={id}
      onContextMenu={(e) => e.preventDefault()}
    >
      <StationInner>
        <StationContainer onClick={handlePopoverClick}>
          { !ad.attributes['st-service'].storage_ref &&
            <StationImg
              bg={`${SERVER_ADDRESS}${ad.attributes['avatar-original']}`}
            />
          }
          { ad.attributes['st-service'].storage_ref &&
            <StationAdVideo
              autoPlay={true}
              muted={true}
              controls={false}
            >
              <source
                src={ad.attributes['st-service'].storage_ref}
                type={ad.attributes['st-service'].video_content_type}
              />
              Your browser does not support the video tag.
            </StationAdVideo>
          }
          <StationContent>
            <StationTitle>
              Data de expiração
            </StationTitle>
            <StationAddress>
              { Moment(ad.attributes['expiration-date']).format('DD/MM/YYYY') }
            </StationAddress>
          </StationContent>
        </StationContainer>
      </StationInner>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <PopLine>
          Execute o MI player no seu painel para exibir o anúncio e para que ele seja removido devidamente.
        </PopLine>
      </Popover>
    </Container>
  );
}

export default AdItem;
