import React, { useContext, useState, useEffect } from 'react';

import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import eyeOpen from '@iconify-icons/akar-icons/eye-open';
import contentCopy from '@iconify-icons/mdi/content-copy';
import bxCheckCircle from '@iconify-icons/bx/bx-check-circle';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import LinearProgress from '@material-ui/core/LinearProgress';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ThemeContext } from 'styled-components';
import Card from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Lottie from 'react-lottie';
import Moment from 'moment';
import _ from 'lodash';

import useWindowSize from '../../utils/hooks/useWindowSize';
import successAnimation from '../../assets/lotties/success.json';
import { currentSuccessNotification } from '../../store/actions/NotificationsActions';
import { payServiceStation, updatePaidServiceStation } from '../../store/actions/AdsActions';
import { getOrderStatus, updateOrderStatus } from '../../store/actions/PaymentsActions';
import { setPaid } from '../../store/actions/UserDataActions';

import {
  Container,
  Header,
  BackButton,
  HeaderTitle,
  GhostButton,
  Content,
  Heading,
  SubHeading,
  MediaInfo,
  MediaContainer,
  Footer,
  PayButtonPix,
  PixIcon,
  CustomDrawer,
  DrawerHeading,
  DrawerSubheading,
  DrawerMain,
  DrawerList,
  CardContainer,
  EmptyContainer,
  EmptyIllustration,
  EmptyMessage,
  DrawerFooter,
  DrawerActionButton,
  CardSelectedWrapper,
  QrCodeContainer,
  QrCodeBox,
  QrCodeTitle,
  QrCodeSubtitle,
  QrCodeFooterTitle,
  QrCodeCopyToClipboard,
  QrCode,
  PaidDrawer,
  PaidDrawerContainer,
  WatingPaymentText,
  PageTitle,
  PaymentSuccessButton,
  VideoContainer
} from './styles';

const MAX_REQUEST_ATTEMPTS = 30;
var attempts = 0;
var clock;

const AdApprovedPayment = ({ currentSuccessNotification, currentNotificationSuccess, setPaid, paidAdStatuses, paidData, user, payServiceStation, updatePaidServiceStation, email, token, getOrderStatus, orderStatus, updateOrderStatus }) => {
  const history = useHistory();
  const size = useWindowSize();
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const [screenData, setScreenData] = useState(null);
  const [lightBox, setLightBox] = useState(false);
  const [cardsDrawerOpen, setCardsDrawerOpen] = useState(false);
  const [pixDrawerOpen, setPixDrawerOpen] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [paidState, setPaidState] = useState(false);
  const [copyButton, setCopyButton] = useState({ value: "", copied: false });
  const [qrCode, setQrcode] = useState('');
  const [price, setPrice] = useState('');

  const [ allCards ] = useState([
    {
      cvc: 777,
      expiry: '10/27',
      name: 'jose adilson da silva',
      number: '•••• •••• •••• 4545',
      focused: '',
      issuer: 'visa',
      preview: true
    },
    {
      cvc: 777,
      expiry: '10/27',
      name: 'jose adilson da silva',
      number: '•••• •••• •••• 5214',
      focused: '',
      issuer: 'mastercard',
      preview: true
    },
    {
      cvc: 777,
      expiry: '10/27',
      name: 'jose adilson da silva',
      number: '•••• •••• •••• 9422',
      focused: '',
      issuer: 'maestro',
      preview: true
    },
  ]);

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  useEffect(() => {
    updatePaidServiceStation({ loading: false, error: false, data: [] });
    updateOrderStatus({ loading: false, error: false, status: '' });
    if (location.screenData) {
      currentSuccessNotification(location.screenData);
      setScreenData(location.screenData);
      if (location.screenData.pix) {
        setCopyButton({ value: location.screenData.pix.code, copied: false });
        setQrcode(location.screenData.pix.img);
        setPrice(location.screenData.pix.price);
      }
    } else {
      setScreenData(currentNotificationSuccess);
      if (currentNotificationSuccess.pix) {
        setCopyButton({ value: currentNotificationSuccess.pix.code, copied: false });
        setQrcode(currentNotificationSuccess.pix.img);
        setPrice(currentNotificationSuccess.pix.price);
      }
    }
  }, []);

  useEffect(() => {
    if (screenData) {
      const checkAlreadyHandled = _.filter(paidAdStatuses,
        function(o) {
          return ((o.id === screenData.id) && (o.stationId === screenData.stationId) && (o.userId === user.id))
      })[0];

      if (checkAlreadyHandled) {
        if (checkAlreadyHandled.paid === true) {
          setExpirationDate(checkAlreadyHandled.expirationDate);
          setPaymentSuccess(true);
        }
      } else {
        setPaymentSuccess(false);
      }
    }
  }, [screenData]);

  useEffect(() => {
    if (!paidData.loading && !paidData.error && paidData.data.hasOwnProperty('data')) {
      if (screenData) {
        const { id, stationId } = screenData;
        const { data } = paidData.data;
        const announceData = { id, stationId, userId: user.id, paid: true, expirationDate: data.attributes['expiration-date'] };
        setExpirationDate(data.attributes['expiration-date']);
        setPaid(announceData);
        setPaymentSuccess(true);
      }
    }
  }, [paidData]);

  useEffect(() => {
    if (pixDrawerOpen) {
      attempts = 0;
      getOrderStatus({ email, token }, screenData.id);
    }
    !pixDrawerOpen && clearTimeout(clock);
  }, [pixDrawerOpen]);

  useEffect(() => {
    if ((orderStatus !== undefined) && orderStatus.status === 'APURADO' || orderStatus.status === 'FINALIZADO') {
      setPaidState(true);
    } else if (pixDrawerOpen) {
      setPaidState(false);
      if (attempts <= MAX_REQUEST_ATTEMPTS) {
        clock = setTimeout(function () {
          attempts++;
          getOrderStatus({ email, token }, screenData.id);
        }, 4000);
      }
    }
    return () => {
      clearTimeout(clock);
    };
  }, [orderStatus]);

  const viewAnnounce = (serviceStationId, stationId) => {

    if (size.width < 1024) {
      history.push({
        pathname: `/view-ads/${stationId}`,
        serviceStationId: serviceStationId
      });
    } else {
      history.push({
        pathname: `/station/${stationId}`,
        serviceStationId: serviceStationId
      });
    }
  }

  const handleBackButton = () => {
    history.goBack();
  }

  const finishPayment = () => {
    const credentials = { email, token }

    payServiceStation(credentials, screenData.id);
  }

  const afterCopyCode = () => {
    setCopyButton({value: copyButton.value, copied: true });
    setTimeout(
      () => setCopyButton({value: copyButton.value, copied: false }),
      2000
    );
  }

  const handleOpenStation = () => {
    history.push(`/station/${screenData.stationId}`);
  }

  const payWithPix = () => {
    setPixDrawerOpen(true);
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }
  if (screenData === null) return false;

  return (
    <Container
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
    >
      <Header>
        <BackButton onClick={handleBackButton}>
          <Icon
            icon={roundArrowBack}
            width={24}
            height={24}
            color={theme.text.primary}
          />
        </BackButton>
        <HeaderTitle>Anúncio Aprovado</HeaderTitle>
        <GhostButton />
      </Header>
      <MediaInfo>
        { (screenData.contentType.split('/')[0] === 'image') && <MediaContainer avatar={screenData.img} onClick={() => size.width < 1024 ? setLightBox(true) : false} /> }
        { (screenData.contentType.split('/')[0] === 'video') &&
          <VideoContainer controls autoPlay loop>
            <source src={screenData.img} type={screenData.contentType} />
            Your browser does not support the video tag.
          </VideoContainer>
        }
      </MediaInfo>
      <Content>
        <Heading>
          { price && `Realize o pagamento de R$ ${price.replace('.', ',')} para a veiculação do seu anúncio no painel`}
        </Heading>
        <Heading>
          <span onClick={handleOpenStation} style={{ color: '#01a3b2' }}>{screenData.stationName}</span>
        </Heading>
        <Heading>
          {`durante ${screenData.weeks} semanas.`}
        </Heading>
        <SubHeading>
          Após confirmado o pagamento, seu anúncio será veiculado imediatamente.
        </SubHeading>
      </Content>
      <Footer>
        { /*
        <PayButtonCard
          onClick={() => setCardsDrawerOpen(true)}
          startIcon={<Icon icon={roundCreditCard} width={24} height={24} color={'#fff'} />}
        >
          Usar cartão
        </PayButtonCard>
        */ }
        <PayButtonPix
          onClick={payWithPix}
          startIcon={<PixIcon src={require('../../assets/images/pix_icon_white.png')} />}
        >
          Realizar pagamento com PIX
        </PayButtonPix>
      </Footer>
      <CustomDrawer
        anchor='right'
        open={cardsDrawerOpen}
        className='noSelect'
      >
        <DrawerHeading>Pagamento com cartão de crédito</DrawerHeading>
        <DrawerSubheading>Selecione o cartão de crédito para inserir seu anúncio no painel</DrawerSubheading>

        <DrawerMain hide={false}>
          {
            allCards.length > 0 ? (
              <DrawerList>
                {
                  allCards.map((card, index) => (
                    <CardContainer
                      onClick={() => setSelectedCard({ index, ...card })}
                      key={index}
                    >
                      <CardSelectedWrapper
                        selected={(selectedCard) ? selectedCard.index === index : false}
                      >
                        <Card
                          cvc={card.cvc}
                          expiry={card.expiry}
                          name={card.name}
                          number={card.number}
                          issuer={card.issuer}
                          preview={card.preview}
                        />
                      </CardSelectedWrapper>
                    </CardContainer>
                  ))
                }
              </DrawerList>
            ) : (
              <EmptyContainer>
                <EmptyIllustration src={require('../../assets/images/empty.svg')} draggable={false} />
                <EmptyMessage>Nenhum cartão de crédito por aqui...</EmptyMessage>
              </EmptyContainer>
            )
          }
        </DrawerMain>
        <DrawerFooter>
          <DrawerActionButton onClick={() => { setCardsDrawerOpen(false); setSelectedCard(null); }}>Voltar</DrawerActionButton>
          <DrawerActionButton disabled={!selectedCard || paidData.loading} onClick={finishPayment}>Finalizar</DrawerActionButton>
        </DrawerFooter>
      </CustomDrawer>
      <CustomDrawer
        anchor='right'
        open={pixDrawerOpen}
        className='noSelect'
      >
        <DrawerHeading>Pagamento com PIX</DrawerHeading>
        <DrawerSubheading>Envie seu anúncio instantâneamente para o painel</DrawerSubheading>

        <DrawerMain hide={false}>
          <QrCodeContainer>
            <QrCodeBox>
              <QrCodeTitle>
                Use o QR Code do Pix para pagar
              </QrCodeTitle>
              <QrCodeSubtitle>
                Abra o app em que vai fazer a transferência, escaneie a imagem ou cole o código do QR Code
              </QrCodeSubtitle>
              { qrCode && <QrCode src={qrCode} alt="código QR para pagamento" /> }
              <QrCodeFooterTitle>
                { price &&
                  <strong>
                    { `R$ ${price.replace('.', ',')}` }
                  </strong>
                }
              </QrCodeFooterTitle>
              <CopyToClipboard text={copyButton.value}
                onCopy={afterCopyCode}
              >
                <QrCodeCopyToClipboard
                  style={{ fontSize: '1rem', margin: '2.1rem 0rem 2.1rem 0rem'}}
                  endIcon={!copyButton.copied && <Icon icon={contentCopy} width={24} height={24} color={'#fff'} /> || copyButton.copied && <Icon icon={bxCheckCircle} width={24} height={24} color={'#fff'} /> }
                >
                  { !copyButton.copied && 'Copiar código do QR Code' }
                  { copyButton.copied && 'Copiado' }
                </QrCodeCopyToClipboard>
              </CopyToClipboard>
              { !paidState &&
                <>
                  <WatingPaymentText>
                    Aguardando pagamento...
                  </WatingPaymentText>
                  <LinearProgress />
                </>
              }
              { paidState &&
                <>
                  <WatingPaymentText>
                    Pagamento realizado! Seu anúncio foi veiculado.
                  </WatingPaymentText>
                  <LinearProgress variant="determinate" value={100} />
                </>
              }
            </QrCodeBox>
          </QrCodeContainer>
        </DrawerMain>
        <DrawerFooter>
          <DrawerActionButton onClick={() => setPixDrawerOpen(false)}>Voltar</DrawerActionButton>
          <DrawerActionButton disabled={!paidState || paidData.loading} onClick={finishPayment}>
            Finalizar
          </DrawerActionButton>
        </DrawerFooter>
      </CustomDrawer>
      <PaidDrawer
        anchor='bottom'
        open={paymentSuccess}
        onClose={() => false}
      >
        <PaidDrawerContainer>
          <PageTitle style={{ textAlign: 'center' }}>
            { (expirationDate) && `Pagamento realizado! Seu anúncio será exibido até ${Moment(expirationDate).format('DD/MM/YYYY')}.` }
          </PageTitle>
          <Lottie
            options={successOptions}
            isClickToPauseDisabled={true}
            width={280}
            height={251}
            style={{ marginBottom: '20px', borderRadius: '150px' }}
          />
          <PaymentSuccessButton
            onClick={() => viewAnnounce(screenData.id, screenData.stationId)}
            endIcon={<Icon icon={eyeOpen} width={30} height={30} />}
          >
            Visualizar seu anúncio no painel
          </PaymentSuccessButton>
        </PaidDrawerContainer>
      </PaidDrawer>
      { lightBox && (
        <Lightbox
          mainSrc={screenData.img}
          onCloseRequest={() => setLightBox(false)}
        />
      )}
    </Container>
  );
}

const mapStateToProps = state => ({
  currentNotificationSuccess: state.NotificationsReducer.currentNotificationSuccess,
  paidAdStatuses: state.UserDataReducer.paidAdStatuses,
  user: state.AuthenticationReducer.memberData.data,
  paidData: state.AdsReducer.paidServiceStation,
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token,
  orderStatus: state.PaymentsReducer.orderStatus,
});

export default connect(mapStateToProps, { currentSuccessNotification, setPaid, payServiceStation, updatePaidServiceStation, getOrderStatus, updateOrderStatus })(AdApprovedPayment);
