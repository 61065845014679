// Top bar component, used in both desktop and mobile. In desktop only shows logo, bell icon and profile picture, since the bottom bar is present.
import React, { useContext, useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SERVER_ADDRESS } from '../../constraints/types';
import { signOut } from '../../store/actions/AuthenticationActions';
import { asyncSearchStations, removeFromHistory, updateAddressValue } from '../../store/actions/StationsActions';
import { connect } from 'react-redux';
import Icon from '@mdi/react';
import { mdiChevronDown, mdiMagnify, mdiArrowLeft } from '@mdi/js';
import { ThemeContext } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu, MenuItem } from '@material-ui/core';
import roundNotifications from '@iconify/icons-ic/round-notifications';
import { Icon as IconifyIcon } from '@iconify/react';

import { useTheme } from '../../context/Theme';
import { useSearching } from '../../context/Searching';
import useWindowSize from '../../utils/hooks/useWindowSize';

import useActiveTab from '../../utils/hooks/useActiveTab';
import ContextMenu, { Option, OptionLabel } from '../../components/ContextMenu';
import Switch from '../../components/Switch';

import dark from '../../styles/themes/dark';
import light from '../../styles/themes/light';

import { Container, LeftSide, RightSide, Logo, Notification, NotificationCircle, ProfileImageContainer, ProfileImageIcon, Indicator, Tabs, Tab, TabNotificationBadge, SearchIcon, SearchContainer, CloseSearchContainer, CloseSarchLine, SearchInput } from './styles';
import AddressList from '../AddressList';
import AutoCompleteList from '../AutoCompleteList';

const logo = require('../../assets/images/logo.svg');

const NavBar = ({ signOut, asyncSearchStations, removeFromHistory, updateAddressValue, userId, memberData, token, searchStationsData, allStations, searchStationsHistory, addressSugestions, toggleNotifications, notificationsOpen, unreadNotifications }) => {
  const currTheme = useContext(ThemeContext);
  const tab0 = useRef();
  const tab1 = useRef();
  const tab2 = useRef();
  const searchIcon = useRef();
  const size = useWindowSize();
  const [ hide, setHide ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ serchIconLeft, setSerchIconLeft ] = useState({});
  const [ snapPoints, setSnapPoints ] = useState([]);
  const [ searchValue, setSearchValue ] = useState('');
  const searchIpt = useRef();
  const [ showMenu, setShowMenu ] = useState(false);
  const [ showSubMenu, setShowSubMenu ] = useState(false);
  const [ language, setLanguage ] = useState({});
  const [ hasSearchValue, setHasSearchValue ] = useState(false);
  const { t, i18n } = useTranslation();
  const { activeTab } = useActiveTab();
  const { theme, setTheme } = useTheme();
  const { searching, setSearching } = useSearching();
  const history = useHistory();
  const location = useLocation();

  const locationsToShow = ['/', '/stations', '/panels', '/settings'];

  useEffect(() => {
    let lngName = '';

    switch (i18n.language) {
      case 'pt-BR':
        lngName = 'Português';
        break;
      case 'rn':
        lngName = 'English';
        break;
      case 'ru':
        lngName = 'Pусский';
        break;
      case 'pl':
        lngName = 'Polskie';
        break;
      default:
        lngName = 'Português';
        break;
    }

    setLanguage({ lng: i18n.language, lngName });
  }, [i18n.language]);

  useEffect(() => {
    if (locationsToShow.includes(location.pathname)) {
      setHide(false);
    }
    else {
      setHide(true);
    }
  }, [location.pathname, locationsToShow]);

  useEffect(() => {
    // componentDidMount and componentDidUpdate do recalculate the dimensions of the snap points. If we only do the mount lifecycle, resizing the screen would give us errors and/or position the indicator in the wrong place w/ wrong width.
     if (size.width >= 1024) {
      measureSnapPoints();
      setSerchIconLeft(searchIcon.current.getBoundingClientRect().left);
    }
  }, [activeTab, size.width, i18n.language]);

  const handleLogout = evt => {
    const confirmLogout = window.confirm(t('menu.signout.confirm'));

    if (confirmLogout) {
      signOut();
    }
    else {
      evt.preventDefault();
      return;
    }
  }

  // Function to measure snap points on init and on window resize event
  // This is used to move the green line indicator above the tabs
  // Snap point is where the indicator should move to, regarding the left translation and the width
  const measureSnapPoints = () => {
    // Define a empty array to later set the state
    let snapPoints = [];

    // Calculate the snap point of first tab and push to the array
    let tab0Width = tab0.current.getBoundingClientRect().width - 20;
    let tab0Left = tab0.current.offsetLeft + 10;

    snapPoints.push({
      width: tab0Width, translation: tab0Left
    });

    // Calculate the snap point of second tab and push to the array
    let tab1Width = tab1.current.getBoundingClientRect().width - 20;
    let tab1Left = tab1.current.offsetLeft + 10;

    snapPoints.push({
      width: tab1Width, translation: tab1Left
    });

    snapPoints.push({
      width: tab1Width, translation: tab1Left
    });

    // Set the previous created and populated array to the state to reflect the changes in the component
    setSnapPoints(snapPoints);
  }

  const openSearch = () => {
    searchIpt.current.focus();
  }

  const closeSearch = () => {
    setSearching({ ...searching, active: false });
    setHasSearchValue(false);
    setSearchValue('');
  }

  const handleTabPress = tab => {
    switch (tab) {
      case 0:
        history.replace('/');
        break;
      case 1:
        history.replace('/stations');
        break;
      case 3:
        history.replace('/settings');
        break;
      default:
        return;
    }
  }

  const handleInputChange = evt => {
    updateAddressValue(evt.target.value);
    setSearchValue(evt.target.value);
  }

  const setValueAndSearch = value => {
    setSearchValue(value);
    setSearching({ ...searching, active: false });
    setHasSearchValue(true);
    searchIpt.current.blur();

    const endpoint = `${SERVER_ADDRESS}/api/v1/search-stations`;
    asyncSearchStations({ email: memberData.email, token }, endpoint, searchStationsData, null, '', value, '', 'distance');
    // AND SEARCH
  }

   const localRemoveFromHistory = (index, type) => {
     if (type === 'stations') {
       alert('ta na hora de remover nenem');
       removeFromHistory(index);
     }
   }

  const handleLanguageChange = (lng, lngName) => {
    setAnchorEl(null);

    setLanguage({ lng, lngName });
    i18n.changeLanguage(lng);
  }

  const openProfileScreen = () => {
    history.push(`/profile/${userId}`);
  }

  const openAbout = () => {
    history.push(`/about`);
  }

  const openHelp = () => {
    history.push(`/help`);
  }

  return (
    <Container
      searching={searching.active || searching.mapMode}
      activeTab={activeTab}
      className={hide ? 'hide' : ''}
    >
      <LeftSide searching={searching.active || searching.mapMode} {...{ hasSearchValue }}>
        <Logo src={logo} />
        { size.width >= 1024 && (
          <Tabs>
            { snapPoints.length > 0 && (
              <Indicator
                translation={snapPoints[activeTab].translation}
                width={snapPoints[activeTab].width}
              />
            ) }
            <Tab
              onClick={() => handleTabPress(0)}
              ref={tab0}
              {...{ activeTab }}
            >
              {t('dashboard.label')}
            </Tab>
            <Tab
              onClick={() => handleTabPress(1)}
              ref={tab1}
              {...{ activeTab }}
            >
              {t('stations.label')}
            </Tab>
          </Tabs>
        )}
      </LeftSide>
      <RightSide>
        { size.width >= 1024 && (
          <>
            <SearchContainer searching={searching.active || searching.mapMode} {...{ hasSearchValue }} show={activeTab === 2 ? true : false}>
              <SearchInput
                {...{ hasSearchValue }}
                searching={searching.active}
                ref={searchIpt}
                type='text'
                value={searchValue}
                onChange={handleInputChange}
                placeholder={activeTab === 1 ? `${t('menu.search.st.placeholder')}` : null}
                onFocus={() => setSearching({ ...searching, active: true })}
                onBlur={() => {
                  setTimeout(() => {
                    setSearching({ ...searching, active: false });
                  }, 100);
                }}
              />
              { (activeTab === 2) &&
                <AddressList
                  {...{ hasSearchValue }}
                  data={addressSugestions}
                  searchValue={searchValue}
                  setValueAndSearch={setValueAndSearch}
                  handleInputChange={handleInputChange}
                />
              }
              <CloseSearchContainer
                onClick={closeSearch}
                title={t('menu.search.close')}
              >
                <CloseSarchLine searching={searching.active} {...{ hasSearchValue }} />
                <CloseSarchLine searching={searching.active} {...{ hasSearchValue }} />
              </CloseSearchContainer>
            </SearchContainer>
            <SearchIcon
              title={t('menu.search.label')}
              show={activeTab === 2 ? true : false}
              searching={searching.active}
              hasSearchValue={hasSearchValue}
              left={serchIconLeft}
              tabIndex='0'
              onClick={openSearch}
              ref={searchIcon}
            >
              <Icon
                path={mdiMagnify}
                size='24px'
                color={theme.iconTintColor.inactive}
              />
            </SearchIcon>
          </>
        ) }
        <Notification
          title={t('menu.notifications.label')}
          searching={searching.active || searching.mapMode ? 1 : 0}
          hassearchvalue={hasSearchValue ? 1 : 0}
          onClick={() => size.width < 768 ? history.push('/notifications') : toggleNotifications(true)}
          change={notificationsOpen ? 1 : 0}
        >
          <IconifyIcon
            icon={roundNotifications}
            style={{ color: '#cccccc', fontSize: '24px' }}
          />
          { (unreadNotifications > 0) &&
              <NotificationCircle />
          }
          { /*(unreadNotifications > 0) && <NotificationCircle />*/ }
        </Notification>
        {
          memberData && (
            <ProfileImageContainer
              {...{ hasSearchValue }}
              searching={searching.active || searching.mapMode}
              img={`${SERVER_ADDRESS}${memberData['avatar-medium']}`}
              title={t('menu.options.label')}
              onClick={() => size.width >= 1024 ? setShowMenu(!showMenu) : openProfileScreen()}
              className='context-menu-trigger'
            >
              <ProfileImageIcon>
                <Icon
                  path={mdiChevronDown}
                  size='12px'
                  color={currTheme.iconTintColor.inactive}
                />
              </ProfileImageIcon>
            </ProfileImageContainer>
          )
        }
        {
          size.width >= 1024 && (
            <ContextMenu
              show={showMenu}
              hasSubMenu={true}
              showSubMenu={showSubMenu}
              onClose={() => {
                setShowSubMenu(false);
                setShowMenu(false);
              }}
            >
              <React.Fragment key='root'>
                <Option
                  onClick={openProfileScreen}
                >
                  Perfil e pagamentos
                </Option>
                <Option onClick={() => setShowSubMenu(true)}>
                  {t('menu.preferences.label')}
                </Option>
                <Option onClick={openHelp}>
                  {t('menu.help.label')}
                </Option>
                <Option onClick={openAbout}>
                  {t('menu.about.label')}
                </Option>
                <Option style={{ padding: 0 }} className='btn-error'>
                  <a
                    rel="nofollow"
                    data-method="delete"
                    href={`${SERVER_ADDRESS}/mi-logout`}
                    onClick={handleLogout}
                    style={{ display: 'block', color: '#FF6E65', padding: '10px 40px 10px 10px', width: '100%' }}
                  >
                    {t('menu.signout.label')}
                  </a>
                </Option>
              </React.Fragment>
              <React.Fragment key='submenu'>
                <Option onClick={() => setShowSubMenu(false)}>
                  <Icon
                    path={mdiArrowLeft}
                    size='18px'
                    color={currTheme.iconTintColor.inactive}
                    style={{ marginLeft: -5 }}
                  />
                </Option>
                <OptionLabel>
                  <span>{t('menu.preferences.theme')}</span>
                  <Switch
                    checked={theme.title === 'dark' ? true : false}
                    onChange={(checked) => setTheme(checked ? dark : light)}
                  />
                </OptionLabel>
                <OptionLabel htmlFor='languageSelect' onClick={(evt) => setAnchorEl(evt.currentTarget)}>
                  <span>{t('menu.preferences.idiom')}</span>
                  <span style={{ color: theme.text.secondary }}>{language.lngName}</span>
                </OptionLabel>

                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                  variant='selectedMenu'
                  style={{ transform: 'translateX(100px)' }}
                >
                  <MenuItem onClick={() => handleLanguageChange('pt-BR', 'Português')}>Português</MenuItem>
                  <MenuItem onClick={() => handleLanguageChange('en', 'English')}>English</MenuItem>
                  <MenuItem onClick={() => handleLanguageChange('ru', 'Pусский')}>Pусский</MenuItem>
                  <MenuItem onClick={() => handleLanguageChange('pl', 'Polskie')}>Polskie</MenuItem>
                </Menu>
              </React.Fragment>
            </ContextMenu>
          )
        }
      </RightSide>
    </Container>
  );
}

const mapStateToProps = state => ({
  memberData: state.AuthenticationReducer.memberData.data.attributes,
  userId: state.AuthenticationReducer.memberData.data.id,
  token: state.AuthenticationReducer.token,
  allStations: state.StationsReducer.stations.data,
  searchStationsData: state.StationsReducer.searchStations.data,
  searchStationsHistory: state.StationsReducer.searchStationsHistory,
  addressValue: state.StationsReducer.addressValue,
  addressSugestions: state.StationsReducer.addressSugestions,
  unreadNotifications: state.NotificationsReducer.unreadNotifications
});


export default connect(mapStateToProps, { signOut, asyncSearchStations, removeFromHistory, updateAddressValue })(NavBar);
