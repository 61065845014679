import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div``;

export const SubmenuOverlay = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  height: 100vh;
  pointer-events: ${props => props.submenuOpen ? 'auto' : 'none'};
  z-index: 36;
  background: ${props => props.submenuOpen ? 'rgba(0,0,0,.8)' : 'rgba(0,0,0,0)'};
  transition: all .15s ease;

  @media screen and (min-width: 1024px) {
    background: transparent;
  }
`;

export const Fab = styled.button`
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${props => props.submenuOpen || props.searching ? '#006973' : props.theme.colors.primary};
  transition: all .3s cubic-bezier(.2,1.2,.2,1.3);
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(1, 163, 178, 0.13)' : 'none'};
  transform: ${props => props.submenuOpen || props.searching ? 'rotate(-135deg) scale(.9)' : 'rotate(0) scale(1)'};
  z-index: 50;
  pointer-events: auto;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;

  &.hidden {
    transform: translateY(20px);
    opacity: 0;
    pointer-events: none;
  }
  
  > svg {
    position: absolute;
    transform: scale(0);
    opacity: 0;
    transition: all .3s cubic-bezier(.785,.135,.15,.86);

    &.show {
      transform: scale(1);
      opacity: 1;
    }
  }

  > svg > path {
    fill: #fff !important;
  }

  &:active {
    background: ${props => darken(0.1, props.theme.colors.primary)};
  }

  @media screen and (min-width: 1024px) {
    bottom: 30px;
    right: 30px;
    cursor: pointer;

    &:hover {
      background: ${props => darken(0.1, props.theme.colors.primary)};
    }
  }
`;

export const BtnAddStation = styled.button`
  position: fixed;
  bottom: 80px;
  right: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  transform: translateY(0);
  opacity: 0;
  pointer-events: none;
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  z-index: 45;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;

  &.show {
    transform: translateY(-65px);
    opacity: 1;
    pointer-events: auto;

    span {
      opacity: 1;
      transform: translateX(0);
      transition-delay: .3s;
    }
  }

  @media screen and (min-width: 1024px) {
    bottom: 30px;
    right: 30px;
    cursor: pointer;
  }

  &:active > div {
    background: ${props => darken(0.1, props.theme.colors.primary)};
  }

  @media screen and (min-width: 1024px) {
    &:hover > div {
      background: ${props => darken(0.1, props.theme.colors.primary)};
    }
  }
`;

export const BtnAddStationCircle = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${props => props.theme.colors.primary};
  transition: all .15s ease;
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(1, 163, 178, 0.13)' : 'none'};

  > svg {
    opacity: 1 !important;
    transform: scale(1) !important;
  }
`;

export const BtnAddStationText = styled.span`
  color: ${props => props.theme.text.primary};
  padding: 8px 10px;
  margin-right: 15px;
  border-radius: 5px;
  background: ${props => props.theme.colors.background};
  opacity: 0;
  transition: all .15s ease;
  transition-delay: 0s;
  transform: translateX(15px);
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};

  @media screen and (min-width: 1024px) {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  }
`;

export const BtnAddService = styled.button`
  position: fixed;
  bottom: 80px;
  right: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  transform: translateY(0);
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  opacity: 0;
  pointer-events: none;
  z-index: 40;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;

  &.show {
    transform: translateY(-130px);
    opacity: 1;
    pointer-events: auto;

    span {
      opacity: 1;
      transform: translateX(0);
      transition-delay: .3s;
    }
  }

  @media screen and (min-width: 1024px) {
    bottom: 30px;
    right: 30px;
    cursor: pointer;
  }

  &:active > div {
    background: ${props => darken(0.1, props.theme.colors.primary)};
  }

  @media screen and (min-width: 1024px) {
    &:hover > div {
      background: ${props => darken(0.1, props.theme.colors.primary)};
    }
  }
`;

export const BtnAddServiceCircle = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${props => props.theme.colors.primary};
  transition: all .15s ease;
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(1, 163, 178, 0.13)' : 'none'};

  > svg {
    opacity: 1 !important;
    transform: scale(1) !important;
  }
`;

export const BtnAddServiceText = styled.span`
  color: ${props => props.theme.text.primary};
  padding: 8px 10px;
  margin-right: 15px;
  border-radius: 5px;
  background: ${props => props.theme.colors.background};
  transition: all .15s ease;
  opacity: 0;
  transform: translateX(15px);
  transition-delay: 0s;
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};

  @media screen and (min-width: 1024px) {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  }
`;