import React, { useEffect, useState } from 'react';

import * as SC from './styles';

export default function AcceptCookies() {
  const [open, setOpen] = useState(0);

  const handleOpen = () => {
    setOpen(1);
  };

  const handleClose = () => {
    localStorage.setItem('@userOptIn', 'true');
    setOpen(0);

    if (window.insider_object?.user) {
      window.insider_object.user = {
        ...window.insider_object.user,
        gdpr_optin: true,
      };
    }
  };

  useEffect(() => {
    const alreadyAgreed = localStorage.getItem('@userOptIn');
    if (!alreadyAgreed) {
      handleOpen();
    }
  }, []);

  return (
    <SC.Container yAxis={open}>
      <SC.Description>
        <SC.Title>
          Aviso de Cookies
        </SC.Title>
        <SC.Paragraph>
          O Telesapp utiliza Cookies, pequenos arquivos para aprimorar e
          proteger a sua experiência.
        </SC.Paragraph>
        <SC.Paragraph>
          Tá curtindo e quer continuar navegando? Clique em “
          <strong>Estou de acordo</strong>
          ”
          <br />
          ou saiba mais acessando nossa&nbsp;
          <SC.Link target="_blank" href="/politica-de-privacidade">
            Política de privacidade
          </SC.Link>
          .
        </SC.Paragraph>
      </SC.Description>
      <SC.Agree onClick={handleClose}> Estou de acordo </SC.Agree>
    </SC.Container>
  );
}
