import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  flex: 0 0 calc(100vw - 40px);
  height: 100px;
  border-radius: 8px;
  margin: 0 10px;
  transition: all .15s ease;
  background: ${props => props.theme.colors.background};
  padding: 10px;
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.1)' : 'none'};

  &:active {
    background: ${props => props.theme.title === 'dark' ? '#000' : '#eee'};
  }
`;

export const Image = styled(motion.img)`
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
`;

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  flex: 1;
`;

export const Title = styled.span`
  color: ${props => props.theme.text.primary};
  font-family: 'Quicksand', Arial, Helvetica, sans-serif;
  margin-bottom: 5px;
`;

export const Subtitle = styled.span`
  font-size: ${props => props.type === 'grid' ? '14px' : '16px'};
  color: ${props => props.theme.text.secondary};
`;