import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  background: rgb(0 56 62 / 90%);
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column
`;

export const RefreshButton = styled(Button)`
  font-weight: 700 !important;
  color: #fff !important;
  margin-right: 15px !important;
  border-radius: 80px !important;
  height: 80px;
  width: 80px;
  background: #27b27a !important;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;

  .MuiButton-startIcon {
    margin: 0 !important;
  }
`;

export const Title = styled.p`
  font-size: 26px;
  margin-bottom: 20px;
  text-align: center;
  padding: 0px 10px 0px 10px;
  color: #fff;
`;
