import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  z-index: 999;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Header = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 10px;
`;

export const BtnBack = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
`;

export const Title = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  font-size: 16px;
`;

export const NotificationsCount = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.error};
  font-size: 14px;
  color: #fff;
  text-align: center;
  padding-bottom: 1px;
  font-weight: 600;
  transition: all .15s ease;
  transform: ${props => props.hide ? 'scale(.8)' : 'scale(1)'};
  opacity: ${props => props.hide ? 0 : 1};
`;

export const List = styled(motion.div)`
  flex: 1;
  width: 100%;
  overflow-y: auto;
`;
