import styled from 'styled-components';
import { darken } from 'polished';
import { ButtonBase } from '@material-ui/core';

export const SearchTermsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonSearchTerm = styled.div`
  width: 100%;
  min-height: 60px;
  padding: 0 10px 0 20px;
  display: flex;
  align-items: center;
  transition: all .15s ease-out;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};

  &:active {
    background: ${props => props.theme.title === 'dark' ? darken(0.3, props.theme.colors.secondary) : darken(0.3, props.theme.colors.background)};
  }
`;

export const SearchTerm = styled.p`
  color: ${props => props.theme.text.primary};
  margin-left: 15px;
  cursor: pointer;
  flex: 1;
`;

export const ButtonEditSearchTerm = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  margin-left: 15px;
`;
