import Moment from 'moment';
import _ from 'lodash';

import {
  SAVE_CAMPAIGN,
  SET_APPROVED,
  REMOVE_APPROVED,
  SET_PAID,
  UPDATE_APP,
  BUMP_VERSION
} from '../../constraints/types';

const INITIAL_STATE = {
  currentBuild: '0',
  build: '0',
  campaigns: [],
  announceStatuses: [],
  paidAdStatuses: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_CAMPAIGN:
      const campaignToSave = {
        data: {
          userId: btoa(action.userId),
          time: Moment.now(),
          campaign: action.payload
        }
      }
      if (state.campaigns === undefined) {
        state.campaigns = [];
      }
      state.campaigns.push(campaignToSave);
      return { ...state, campaigns: state.campaigns };
    case SET_APPROVED:
      if (state.announceStatuses === undefined) {
        state.announceStatuses = [];
      }
      state.announceStatuses.push(action.payload);
      return { ...state, announceStatuses: state.announceStatuses };
    case SET_PAID:
      if (state.paidAdStatuses === undefined) {
        state.paidAdStatuses = [];
      }
      state.paidAdStatuses.push(action.payload);
      return { ...state, paidAdStatuses: state.paidAdStatuses };
    case REMOVE_APPROVED:
      _.remove(state.announceStatuses, function(o) {
        return ((o.id === action.payload.id) && (o.stationId === action.payload.stationId) && (o.userId === action.payload.userId));
      });
      return { ...state, announceStatuses: state.announceStatuses };
    case UPDATE_APP:
      return { ...state, build: action.version };
    case BUMP_VERSION:
      return { ...state, currentBuild: state.build }
    default:
      return state;
  }
}
