export default {
  title: 'light',

  colors: {
    primary: '#01a3b2',
    secondary: '#f9f9f9',
    background: '#ffffff',
    warning: '#FFA95C',
    error: '#FF6E65'
  },

  text: {
    primary: '#333333',
    secondary: '#696969'
  },

  iconTintColor: {
    active: '#01a3b2',
    inactive: '#999999'
  },

  separator: '#ededed',

  skeleton: {
    color: '#eee',
    highlightColor: '#f5f5f5'
  },

  doka: {
    background: '249, 249, 249',
    foreground: '0, 0, 0'
  }
}
