import React, { useContext, useState, useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import { ThemeContext } from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';
import _ from 'lodash';

import useWindowSize from '../../utils/hooks/useWindowSize';
import CircularProgress from '@material-ui/core/CircularProgress';
import successAnimation from '../../assets/lotties/success.json';
import rejectAnimation from '../../assets/lotties/error-animation.json';
import { currentNewRequestNotification } from '../../store/actions/NotificationsActions';
import { setApproved, removeApproved } from '../../store/actions/UserDataActions';
import { approveServiceStation, updateApprovedServiceStation } from '../../store/actions/AdsActions';

import {
  Container,
  Header,
  BackButton,
  HeaderTitle,
  GhostButton,
  MediaInfo,
  MediaContainer,
  Content,
  Heading,
  SubHeading,
  Footer,
  RightButton,
  LeftButton,
  LeftButtonUndo,
  CustomDrawer,
  DrawerContainer,
  PageTitle,
  BtnFeedback,
  VideoContainer,
  LoadingApprovalContainer,
  LoadingApprovalText
} from './styles';

const AdApproval = ({ currentNotification, currentNewRequestNotification, userId, email, token, announceStatuses, setApproved, removeApproved, approveServiceStation, updateApprovedServiceStation, approvedData }) => {
  const history = useHistory();
  const location = useLocation();
  const size = useWindowSize();
  const theme = useContext(ThemeContext);
  const [screenData, setScreenData] = useState(null);
  const [rejected, setRejected] = useState(false);
  const [allowed, setAllowed] = useState(false);
  const [canAllowOrDeny, setCanAllowOrDeny] = useState(false);
  const [lightBox, setLightBox] = useState(false);


  useEffect(() => {
    updateApprovedServiceStation({ data: [], loading: false, error: false });
    if (location.screenData) {
      currentNewRequestNotification(location.screenData);
      setScreenData(location.screenData);
    } else {
      setScreenData(currentNotification);
    }
  }, []);

  useEffect(() => {
    if (!approvedData.loading && !approvedData.error && approvedData.data.hasOwnProperty('data')) {
      if (screenData) {
        const { id, stationId } = screenData;
        const announceData = { id, stationId, userId, approved: true };
        setApproved(announceData);
        setAllowed(true);
        setRejected(false);
      }
    }
  }, [approvedData]);

  useEffect(() => {
    if (screenData) {
      const checkAlreadyHandled = _.filter(announceStatuses,
        function(o) {
          return ((o.id === screenData.id) && (o.stationId === screenData.stationId) && (o.userId === userId))
      })[0];

      if (checkAlreadyHandled) {
        if (checkAlreadyHandled.approved === true) {
          setAllowed(true);
          setRejected(false);
        } else if (checkAlreadyHandled.approved === false) {
          setAllowed(false);
          setRejected(true);
        }
      } else {
        setCanAllowOrDeny(true);
      }
    }
  }, [screenData]);

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const rejectOptions = {
    loop: false,
    autoplay: true,
    animationData: rejectAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const approve = () => {

    const credentials = { email, token }

    let notificationData = { ...screenData };

    notificationData.type = 'success';
    notificationData.read = false;
    notificationData.date = Date.now();
    notificationData.content = `Seu anúncio foi aprovado no painel ${notificationData.stationName}`;
    delete notificationData.uid;

    approveServiceStation(credentials, screenData.id, notificationData);
  }

  const reject = () => {
    const { id, stationId } = screenData;

    const announceData = { id, stationId, userId, approved: false };
    setApproved(announceData);
    setAllowed(false);
    setRejected(true);
  }

  const undoReject = () => {
    const { id, stationId } = screenData;

    const announceData = { id, stationId, userId, approved: false };
    removeApproved(announceData);
    setAllowed(false);
    setRejected(false);
    setCanAllowOrDeny(true);
  }

  const handleBackButton = () => {
    history.goBack();
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  if (screenData === null) return false;

  return (
    <Container
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
    >
      <Header>
        <BackButton onClick={handleBackButton}>
          <Icon
            icon={roundArrowBack}
            width={24}
            height={24}
            color={theme.text.primary}
          />
        </BackButton>
        <HeaderTitle>Solicitação de anúncio</HeaderTitle>
        <GhostButton />
      </Header>
      <MediaInfo>
        { (screenData.contentType.split('/')[0] === 'image') && <MediaContainer avatar={screenData.img} onClick={() => size.width < 1024 ? setLightBox(true) : false} /> }
        { (screenData.contentType.split('/')[0] === 'video') &&
          <VideoContainer controls autoPlay loop>
            <source src={screenData.img} type={screenData.contentType} />
            Your browser does not support the video tag.
          </VideoContainer>
        }
      </MediaInfo>

      <Content>
        <Heading>
          {`Você autoriza a veiculação deste anúncio no seu painel`}
        </Heading>
        <Heading>
          <span style={{ color: '#01a3b2' }}> {screenData.stationName}</span>
        </Heading>
        <Heading>
          {`durante ${screenData.weeks} semanas?`}
        </Heading>
        <SubHeading>
          Se autorizado, mediante o pagamento feito pelo anunciante, o anúncio será automaticamente inserido em seu painel.
        </SubHeading>
        { approvedData.loading &&
          <LoadingApprovalContainer>
            <LoadingApprovalText> Gerando ordem de pagamento...</LoadingApprovalText>
            <CircularProgress />
          </LoadingApprovalContainer>
        }
      </Content>
      { canAllowOrDeny &&
        <Footer>
          <LeftButton onClick={reject}> Recusar </LeftButton>
          <RightButton disabled={approvedData.loading} onClick={approve}> Autorizar </RightButton>
        </Footer>
      }
      { lightBox && (
        <Lightbox
          mainSrc={screenData.img}
          onCloseRequest={() => setLightBox(false)}
        />
      )}
      <CustomDrawer
        anchor='bottom'
        open={rejected || allowed}
        onClose={() => false}
      >
        <DrawerContainer>
          { allowed &&
            <>
              <PageTitle style={{ textAlign: 'center' }}>Anúncio autorizado! Aguardando pagamento para veiculação.</PageTitle>
              <Lottie
                options={successOptions}
                isClickToPauseDisabled={true}
                width={280}
                height={251}
                style={{ marginBottom: '20px', borderRadius: '150px' }}
              />
              <BtnFeedback onClick={handleBackButton}>Sair</BtnFeedback>
            </>
          }
          { rejected &&
            <>
              <PageTitle style={{ textAlign: 'center' }}>O anúncio foi recusado e não será veiculado.</PageTitle>
              <Lottie
                options={rejectOptions}
                isClickToPauseDisabled={true}
                width={280}
                height={251}
                style={{ marginBottom: '20px', borderRadius: '150px' }}
              />
              <Footer>
                <LeftButtonUndo onClick={undoReject}> Desfazer </LeftButtonUndo>
                <RightButton onClick={handleBackButton}> Sair </RightButton>
              </Footer>
            </>
          }
        </DrawerContainer>
      </CustomDrawer>
    </Container>
  );
}

const mapStateToProps = state => ({
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token,
  currentNotification: state.NotificationsReducer.currentNotification,
  userId: state.AuthenticationReducer.memberData.data.id,
  announceStatuses: state.UserDataReducer.announceStatuses,
  approvedData: state.AdsReducer.approveServiceStation
});


export default connect(mapStateToProps, { currentNewRequestNotification, setApproved, removeApproved, approveServiceStation, updateApprovedServiceStation })(AdApproval);
