import firebase from 'firebase/app';

import 'firebase/storage';
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyBg6CtiGPkT7W2VaI4tB4cDzchxsycXZaU",
  authDomain: "telesapp-1507903140837.firebaseapp.com",
  databaseURL: "https://telesapp-1507903140837.firebaseio.com",
  projectId: "telesapp-1507903140837",
  storageBucket: "telesapp-1507903140837.appspot.com",
  messagingSenderId: "819669549809",
  appId: "1:819669549809:web:33d4a76d2481ef1b731f50"
};

firebase.initializeApp(firebaseConfig);

const storage = firebase.storage();
const database = firebase.database();

export { storage, database, firebase as default };
