import React, { useEffect, useState, useContext } from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeContext } from 'styled-components';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import useWindowSize from '../../utils/hooks/useWindowSize';
import { getStationAds, updateDeleteOwnerAd } from '../../store/actions/AdsActions';
import ListAdsOwner from '../ListAdsOwner';


import MDIcon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import {
    Container,
    HeaderOp,
    RowOp,
    TitleOp,
    BtnClose,
    Indicator,
    Pages,
    Page,
    Footer,
    SendButton} from './styles';

const DeleteAdsFromStation = ({ station, credentials, stationAds, setDeleteAdsIsOpen, getStationAds, deleteOwnerAdData, updateDeleteOwnerAd }) => {

    const theme = useContext(ThemeContext);
    const size = useWindowSize();

    useEffect(() => {
      updateDeleteOwnerAd({ loading: false, error: false, success: false });
      const { id } = station.data;
      getStationAds(credentials, id);
    }, []);

    const handleExit = () => {
        setDeleteAdsIsOpen(false);
    }

    const mobileVariants = {
        initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
      }
    
    const desktopVariants = {
        initial: { opacity: 0, scale: .95, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        visible: { opacity: 1, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        hidden: { opacity: 0, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    }

    return (
        <Container
            variants={size.width < 1024 ? mobileVariants : desktopVariants}
            initial='initial'
            animate='visible'
            exit='hidden'
        >
            <HeaderOp>
                <RowOp>
                    <TitleOp> Anúncios em exibição no painel </TitleOp>
                    <BtnClose size='small' onClick={()=> setDeleteAdsIsOpen(false)}>
                        <MDIcon
                            path={mdiClose}
                            size='24px'
                        />
                    </BtnClose>
                </RowOp>
                <Indicator step={100} />
            </HeaderOp>
            <Pages>
                <Page mobile={size.width < 1024 ? true : false}>
                    { stationAds !== undefined && stationAds.data.length > 0 && <ListAdsOwner activeAds={stationAds.data} /> }
                </Page>
            </Pages>
            <Footer>
                <SendButton
                    onClick={handleExit}
                >
                    Sair
                </SendButton>
            </Footer>
            { /* DIÁLOGO PARA CONFIMAÇÃO DE BLOQUEIO */}
            { deleteOwnerAdData !== undefined && 
            <>
                <Snackbar
                    key={0}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                    }}
                    color='primary'
                    open={(!deleteOwnerAdData.loading && (deleteOwnerAdData.success || deleteOwnerAdData.error))}
                    autoHideDuration={4000}
                    onClose={() => updateDeleteOwnerAd({ loading: false, error: false, success: false })}
                    onExit={() => updateDeleteOwnerAd({ loading: false, error: false, success: false })}
                >
                    <SnackbarContent
                    style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
                    message={ deleteOwnerAdData.error ? 'Problema na exclusão' : 'Anúncio excluído' }
                    action={
                        <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={() => updateDeleteOwnerAd({ loading: false, error: false, success: false })}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                        </React.Fragment>
                    }
                    />
                </Snackbar>
                { /* BACKDROP PARA SOBREPOR A TELA ENQUANTO ESTIVER BLOQUEANDO OU EXCLUINDO O PAINEL */}
                { stationAds !== undefined && deleteOwnerAdData !== undefined &&
                    <Backdrop style={{ zIndex: 999, color: theme.colors.primary, position: 'absolute' }} open={stationAds.loading || deleteOwnerAdData.loading}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            </>
            }
        </Container>
    )
}

const mapStateToProps = state => ({
    stationAds: state.AdsReducer.stationAds,
    deleteOwnerAdData: state.AdsReducer.deleteOwnerAd
});

export default connect(mapStateToProps, { getStationAds, updateDeleteOwnerAd })(DeleteAdsFromStation);