import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { useSpring } from 'framer-motion';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useWindowSize from  '../../utils/hooks/useWindowSize';
import animationData from '../../assets/lotties/upload-arrow.json';

import { Container, Bubble, Screen, Title, UploadList, UploadItem, UploadItemImage, UploadItemTitle, UploadItemProgress, BtnMinimize, VideoContainer, ExtText } from './styles';

const UploadFeedback = ({ uploadStack }) => {
  const [ dragX, ] = useState(0);
  const [ dragY, ] = useState(0);
  const [ screenShow, setScreenShow ] = useState(true);

  const { t } = useTranslation();

  const size = useWindowSize();
  const x = useSpring(dragX, { stiffness: 600, damping: 200, ease: [0.6, 0.05, -0.01, 0.99] });
  const y = useSpring(dragY, { stiffness: 600, damping: 200, ease: [0.6, 0.05, -0.01, 0.99] });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const handleBubbleDragEnd = (evt) => {
    if (evt.pageX > (size.width / 2 + 30)) {
      x.set(0);
    }
    else {
      x.set(-size.width + 65);
    }
  }

  // não é necessário renderizar nada se a pilha de uploads estiver vazia
  if (!uploadStack.length) return false

  return (
    <Container>
      <>
        <Bubble
          drag
          dragConstraints={{
            top: 0,
            left: -size.width + 55,
            right: 0,
            bottom: (size.height - 200),
          }}
          whileTap={{ scale: 0.9 }}
          dragElastic={0.2}
          dragMomentum={false}
          onDragEnd={handleBubbleDragEnd}
          style={{ x, y }}
          screenShow={screenShow}
          onClick={() => setScreenShow(true)}
        >
          <Lottie
            options={defaultOptions}
            width={80}
            height={80}
            isClickToPauseDisabled={true}
          />
        </Bubble>

        <Screen screenShow={screenShow}>
          <Title>{ t('uploadfeedback.title') }</Title>

          <UploadList>
          { // este componente apenas percorre cada item da pilha de uploads, montando uma tabela exibindo seus progressos
            uploadStack.map((uploadItem, index) => {
              const absoluteURL = URL.createObjectURL(uploadItem.file);
              return (
                <UploadItem key={index}>
                  {(uploadItem.type.split('/')[0] === 'image') && <UploadItemImage src={absoluteURL} /> }
                  {(uploadItem.type.split('/')[0] === 'video') && <VideoContainer type={uploadItem.type.split('/')[1]} ><ExtText>{ `${uploadItem.type.split('/')[1].toUpperCase()}` }</ExtText></VideoContainer> }
                  <UploadItemTitle>{ uploadItem.name }</UploadItemTitle>
                  <UploadItemProgress>
                    <CircularProgress
                      variant='static'
                      value={uploadItem.progress}
                      size='24px'
                    />
                  </UploadItemProgress>
                </UploadItem>
              )
            })
          }
          </UploadList>

          <BtnMinimize onClick={() => setScreenShow(false)}>{ t('uploadfeedback.button.hide') }</BtnMinimize>
        </Screen>
      </>
    </Container>
  );
}

const mapStateToProps = state => ({
  uploadStack: state.UploadsReducer.uploadStack,
  uploadToken: state.UploadsReducer.uploadToken
});

export default connect(mapStateToProps, { })(UploadFeedback);
