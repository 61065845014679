import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SERVER_ADDRESS,
  UPDATE_TOKEN,
  SIGN_OUT_SUCCESS,
  ACCEPT_TERMS,
  UPDATE_APP,
  BUMP_VERSION
} from '../../constraints/types';

import {
  development
} from '../../constraints/utils';

import { database } from '../../firebase';

import axios from 'axios';

export const signIn = (credentials, memberDataAtomic, endpoint) => {
  return dispatch => {
    dispatch({ type: SIGN_IN });
    const url = `${SERVER_ADDRESS}${endpoint}`;
    axios.get(url, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Access-Token': credentials.token,
        'X-User-Email': credentials.email,
      }
    })
    .then(res => {
      const memberData = {
        loading: false,
        data: res.data.data,
        error: false
      };

      let newToken = credentials.token;

      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
      }
      dispatch({ type: UPDATE_TOKEN, newToken });
      dispatch({ type: SIGN_IN_SUCCESS, payload: memberData });
    })
    .catch(error => {
      if (error.response !== undefined) {
        if (error.response.status === 401) {
          const memberData = {
            loading: false,
            data: memberDataAtomic,
            error: error.response
          };
          dispatch({ type: SIGN_IN_ERROR, payload: memberData });
        } else {
          const memberData = {
            loading: false,
            data: memberDataAtomic,
            error: 'Connectivity error'
          };
          dispatch({ type: SIGN_IN_ERROR, payload: memberData });
        }
      } else {
        const memberData = {
          loading: false,
          data: memberDataAtomic,
          error: 'Connectivity error'
        };
        dispatch({ type: SIGN_IN_ERROR, payload: memberData });
        alert('Não foi possí­vel conectar à internet');
      }
    });
  }
};

export const signOut = () => ({ type: SIGN_OUT_SUCCESS });

export const checkUpdate = (build) => {
  return dispatch => {
    database.ref(`appInfo/miBuild`)
      .on('value', function(snapshot) {
        const originVersion = parseInt(snapshot.val());
        const localVersion = parseInt(build);

        if (Number.isNaN(localVersion) || localVersion < originVersion) {
          dispatch({ type: UPDATE_APP, version: originVersion });
        }
      });
  }
}

export const checkTerms = (userId) => {
  return dispatch => {
    const RTDBUrl = development ? `devMiTerms/${btoa(userId)}` : `miTerms/${btoa(userId)}`;

    database.ref(RTDBUrl)
      .once('value', function(snapshot) {
        if (!snapshot.val()) {
          dispatch({ type: ACCEPT_TERMS, payload: false });
        } else {
          dispatch({ type: ACCEPT_TERMS, payload: true });
        }
      });
  }
}

export const acceptTerms = (userId) => {
  return dispatch => {
    const RTDBUrl = development ? `devMiTerms/${btoa(userId)}` : `miTerms/${btoa(userId)}`;

    database.ref(RTDBUrl)
    .push({ agree: true })
    .then((snap) => {
      dispatch({ type: ACCEPT_TERMS, payload: true });
    });
  }
}

export const bumpVersion = () => ({ type: BUMP_VERSION });
