import styled from 'styled-components';

import { ButtonBase, Drawer, IconButton } from '@material-ui/core';

export const BtnUploadPhoto = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  padding: 15px !important;
  background: ${props => props.theme.colors.primary} !important;
  border-radius: 5px !important;
  margin-bottom: 15px !important;
  width: 100% !important;

  p {
    color: #fff !important;
  }

  > svg > path {
    fill: #fff !important;
  }
`;

export const BtnText = styled.div`
  text-align: left;
`;

export const BtnTextTitle = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 5px;
`;

export const BtnTextSubtitle = styled.p`
  font-size: 0.825rem;
  color: ${props => props.theme.text.primary};
`;

export const BtnSelectFromGallery = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  padding: 15px !important;
  background: ${props => props.theme.colors.background} !important;
  border-radius: 5px !important;
  margin-bottom: 15px !important;
  width: 100% !important;

  > svg > path {
    fill: ${props => props.theme.iconTintColor.active} !important;
  }
`;

export const WebcamContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: #000;
`;

export const WebcamHeader = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 10px;
  z-index: 6;
`;

export const BtnExitWebcam = styled(IconButton)`
  width: 40px;
  height: 40px;
`;

export const WebcamFooter = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 15px;
`;

export const WebcamGhostBtn = styled.div`
  width: 52px;
  height: 52px;
`;

export const WebcamCenterBtn = styled(ButtonBase)`
  width: 60px;
  height: 60px;
  z-index: 11;
  background: rgba(255,255,255,.2) !important;
  border-radius: 50% !important;
  border: 2px solid #fff !important;
  transition: all .15s ease;
  transform: translateX(-30px);

  &.send-mode {
    background: ${props => props.theme.colors.primary} !important;
    border-color: transparent !important;
  }
`;

export const WebcamToggleModeBtn = styled(IconButton)`
  z-index: 11;
  opacity: 0;
`;

export const WebcamScreenshot = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  z-index: 10;
`;

export const WebcamCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WebcamRetakePhoto = styled(IconButton)`
  width: 60px;
  height: 60px;
  z-index: 11;
  background: ${props => props.theme.colors.error} !important;
  transition: all .15s ease !important;
  transform: ${props => props.show ? 'translateX(-45px)' : 'translateX(0)'} !important;
  opacity: ${props => props.show ? 1 : 0} !important;
`;

export const BtnTakePhoto = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  padding: 15px !important;
  background: transparent !important;
  border-radius: 5px !important;
  margin-bottom: 15px !important;
  border: 1px solid ${props => props.theme.separator} !important;
  width: 100% !important;

  > svg > path {
    fill: ${props => props.theme.iconTintColor.active} !important;
  }
`;

export const CustomDrawer = styled(Drawer)`
  /* .MuiPaper-root,
  .MuiDrawer-paper,
  .MuiDrawer-paperAnchorBottom,
  .MuiPaper-elevation16 {
  } */

  @media screen and (min-width: 1024px) {
    .MuiDrawer-paper {
      margin: 0 320px !important;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      max-height: 90% !important;
      overflow-y: auto !important;
    }
  }
`;

export const DrawerContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 20px 80px 20px;
`;

export const GalleryTitle = styled.p`
  font-weight: 700;
  color: ${props => props.theme.text.primary};
`;

export const GalleryImages = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  grid-gap: 15px;
  margin: 15px 0 30px 0;
`;

export const GalleryImage = styled(ButtonBase)`
  border-radius: 5px !important;
  background: url(${props => props.bg}) !important;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: none !important;
  height: ${props => props.height}px !important;
  transition: all .15s ease !important;
  position: relative !important; 
  border: 1px solid transparent !important;
  border-color: ${props => props.selected ? props.theme.colors.primary : 'rgba(0,0,0,0)'} !important; 
  overflow: hidden !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.6);
    transition: all .15s ease;
    opacity: ${props => props.selected ? 1 : 0};
    z-index: 1;
  }

  > svg {
    z-index: 5;
    transition: all .15s ease;
    opacity: ${props => props.selected ? 1 : 0};
  }
`;

export const GalleryFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed !important;
  bottom: 0 !important;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 20px;
  height: 80px;
  background: #424242;
  z-index: 5;

  @media screen and (min-width: 1024px) {
    width: calc(100% - 640px);
  }
`;

export const BtnRemove = styled(ButtonBase)`
  flex: 1;
  background: ${props => props.theme.colors.secondary} !important;
  color: ${props => props.theme.text.primary} !important;
  font-weight: 700 !important;
  border-radius: 5px !important;
  height: 40px !important;
  margin-right: 15px !important;
`;

export const BtnApply = styled(ButtonBase)`
  flex: 1;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  font-weight: 700 !important;
  border-radius: 5px !important;
  height: 40px !important;
`;