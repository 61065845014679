import React from 'react';

const Terms = () => (
    <>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>CAPÍTULO I</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>DA CONTRATAÇÃO DOS PAINÉIS PELO ANUNCIANTE</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>A FOCO INVESTIMENTOS E PARTICIPAÇÕES SOCIETÁRIAS EIRELI, empresa individual de responsabilidade limitada, inscrita no CNPJ no. 16.801.930/0001-84, com sede na Rua Abud Farah, 148, cj. 01-D, Vila Santo Antônio, na cidade de Guaxupé/MG, CEP 37800-000, doravante denominada “CONTRATADA”, é detentora do portal de anúncios e comunicação TELESAPP.NET e sistema de PAINÉIS TELESAPP MÍDIA INDOOR, bem como qualquer outro site, plataforma, aplicativo operado pelo TELESAPP ou TELESAPP MÍDIA INDOOR, mi.telesapp.net, doravante denominados simplesmente “PLATAFORMA” ou “TELESAPP”; destinada para uso de seus clientes, ANUNCIANTES, parceiros, visitantes, fornecedores e usuários, doravante denominados “USUÁRIO”.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>O USUÁRIO concorda que, ao clicar em “Cadastre-se agora”, “Cadastre-se no Telesapp”, “Cadastre-se” ou em um botão semelhante, registrar-se, acessar ou utilizar nossos serviços (descritos abaixo), o USUÁRIO está concordando em celebrar um Termo de Uso legalmente vinculativo com a CONTRATADA e sua PLATAFORMA (mesmo se estiver utilizando os Serviços em nome de uma empresa). Caso não concorde com este Termo de Uso (“Termo de Uso” ou “Termo de Uso do Usuário”), não clique em “Cadastre-se agora” (ou semelhante) e não acesse ou, de outra forma, utilize nossos Serviços. Este documento informa as responsabilidades, deveres e obrigações que todo USUÁRIO assume ao acessar e/ou utilizar o site.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>1. Definições</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>ANUNCIANTE: É considerado um ANUNCIANTE todo e qualquer USUÁRIO da PLATAFORMA que de alguma forma divulgue seu anúncio nos pontos de exibição dos painéis TELESAPP MÍDIA INDOOR, seja ele mero anúncio seja anúncio promocional seja comunicado seja outras formas de dar publicidade a conteúdos de seu interesse via PLATAFORMA.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>ANÚNCIOS: São considerados anúncios todas as formas de divulgação na PLATAFORMA, incluindo meros anúncios, avatar, descrição, anúncios promocionais, anúncios patrocinados, feed de notícias, vídeos, imagens animadas, ou seja, todo e qualquer conteúdo ou dados levado a público, seja em forma de imagem, vídeo ou dados.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>ANÚNCIO PROMOCIONAL: Forma de criar um anúncio dentro do portal <u><a href="http://www.telesapp.net/">www.telesapp.net</a></u> trazendo dados específicos para uma promoção de vendas de serviços ou produtos, com o consequente direcionamento do usuário diretamente para o chat do ANUNCIANTE.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>CONTRATADA: A empresa contratada para fazer a gestão do funcionamento da PLATAFORMA é Foco Investimentos e Participações Ltda. EIRELI.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>CONTRATO: significa todas as cláusulas e disposições previstas neste Termo de Uso e Adesão, que dão suporte à relação jurídica entre a CONTRATADA e o CLIENTE.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>NUVEM: É a forma de armazenamento dos arquivos, que ficam na nuvem e podem ser acessados pelos painéis remotamente. O ANUNCIANTE configura em quais painéis o anúncio deverá ser exibido, durante quanto tempo será veiculado, de acordo com o que foi contratado. Assim, os painéis se conectam a internet atualiza a sua programação e passam a exibir o conteúdo do ANUNCIANTE de forma automática.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>PLATAFORMA: É todo o conteúdo do portal TELESAPP e do sistema de painéis TELESAPP MÍDIA INDOOR.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>PLAYER TELESAPP MÍDIA INDOOR: É a aplicação tecnológica para que o sistema TELESAPP MÍDIA INDOOR possa ser exibida nos painéis.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>PONTO DE EXIBIÇÃO: É o local, dentro do estabelecimento comercial do DONO DO PONTO, onde ficará instalado o PAINEL TELESAPP MÍDIA INDOOR, para ser visualizado pelo PÚBLICO em geral.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>PORTAL TELESAPP ou TELESAPP: É todo o conteúdo e funcionalidades disponíveis para uso pelo CLIENTE ou USUÁRIO, que constam do portal www.telesapp.net.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>PÚBLICO: toda e qualquer pessoa que visualize os painéis TELESAPP MÍDIA INDOOR ou seu portal telesapp.net, mesmo que não tenha cadastro.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>SISTEMA DE PONTUAÇÃO: A melhoria na pontuação é proporcional ao uso da PLATAFORMA pelo usuário.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>TELESAPP MIDIA INDOOR: O Telesapp Mídia Indoor é o sistema do telesapp.net que gerencia todo o ambiente de uma operação mídia indoor nos padrões Telesapp. Pelo Telesapp Mídia Indoor USUÁRIO poderá cadastrar um novo painel, pesquisar os painéis disponíveis e em destaque, enviar anúncios para painéis de sua preferência e realizar os pagamentos pelas contratações. E mais, os seus anúncios também estarão presentes no portal de negociações de serviços e produtos telesapp.net, com grande visibilidade na WEB e Google.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>TV BOX: Com Telesapp Mídia Indoor basta ter uma TV e adquirir um TV box, caso ainda não o possua, e já pode-se começar a operar o sistema de Painéis Telesapp Mídia Indoor.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>2. Objeto do serviço de mídia indoor</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>2.1 A PLATAFORMA é um sistema de MÍDIA INDOOR, complementado por um portal de anúncios e comunicação, para divulgações de anúncios em painéis de exibição em pontos comerciais cadastrados, onde o ANUNCIANTE terá que optar por uma das modalidades de planos oferecidas e disponíveis no ato da contratação.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>2.2 Somente aos ANUNCIANTES que firmaram contrato junto ao portal <u><a href="http://www.telesapp.net/">www.telesapp.net</a></u> será permitida a criação de anúncios promocionais.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>2.2.1 Cada Anúncio Promocional terá suas próprias características, como local de uso, código de confirmação, prazo de entrega, meios de entrega ou utilização, prazo de validade, limitações de ofertas no estabelecimento comercial, dia e hora para utilização, a serem definidos pelo ANUNCIANTE.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>2.2.2 É de responsabilidade do USUÁRIO verificar tais condições na página do anúncio promocional, sendo que o ANUNCIANTE responderá pela veracidade e correção de tais informações acima citadas.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>3. Limitação da Responsabilidade</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>3.1 De acordo com a legislação aplicável, a CONTRATADA não assume qualquer responsabilidade contratual ou extracontratual em razão de danos e ou prejuízos diretos e indiretos, que possa vir a ocasionar: (I) impossibilidade de utilização do serviço. (II) eventuais custos incorridos para obtenção de serviços substitutivos, ou de qualquer bem comprado ou adquirido ou informação ou mensagem recebidos ou operações concretizadas através destes serviços. (III) do acesso não autorizado ou a alteração de suas transmissões de dados. (IV) declarações de conduta de terceiros através do serviço.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>3.2 O ANUNCIANTE concorda que, além disso, a CONTRATADA não será responsável por nenhum dano ou prejuízo derivados da interrupção, suspensão ou término do serviço, incluindo, mais não se limitando, a danos, lucros, uso, dados ou outros bens intangíveis, prejuízos diretos, indiretos, acidentais, especiais, consequentes, ou exemplares, mesmo que a interrupção, suspensão ou terminação seja justificada ou não, negligente ou intencional, advertida ou inadvertida. A CONTRATADA não se responsabilizará por quaisquer danos e ou indenizações geradas em função das avaliações dos usuários do site em relação aos estabelecimentos ANUNCIANTES.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>4. Anúncio</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>4.1 O anúncio no painel TELESAPP MÍDIA INDOOR será contratado por prazo fixados automaticamente no próprio sistema TELESAPP MÍDIA INDOOR. Os anúncios se repetirão a cada novo bloco de anúncios, a princípio são blocos com 30 anúncios de 10 segundos cada, formando blocos de 5 minutos. Sendo assim, em uma hora esse bloco de anúncio é exibido 12 vezes. Em um estabelecimento comercial funcionando 8h por dia cada anúncio será exibido 96 vezes / dia.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5. Negociações com anunciantes</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5.1 Os anúncios na PLATAFORMA são de responsabilidade exclusiva dos ANUNCIANTES. Cabe aos ANUNCIANTES assegurarem idoneidade em seus negócios e transações. Assim como a criação e divulgação de anúncios na PLATAFORMA.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5.2 A CONTRATADA, por meio da PLATAFORMA, não realiza qualquer intermediação de venda, compra e/ou descontos, trocas ou qualquer tipo de transação feita pelos usuários de seu site, tratando-se de serviço exclusivamente de disponibilização de mídia para divulgação. A transação deverá ser feita diretamente entre as partes interessadas.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>6. Indenizações</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>6.1 O USUÁRIO se obriga a indenizar a CONTRATADA, seus representantes, agentes, sócios e funcionários, de eventuais condenações relativas à reclamações administrativas ou demandas judiciais incluídos eventuais reembolsos de honorários de advogados de seus procuradores e ou de terceiros, em razão de quaisquer danos sofridos pela PLATAFORMA, provenientes do uso ou transmissão de informações indevidas, realizadas através dos diversos login(s) e senha(s) cadastrados na PLATAFORMA que viole os TERMOS DE USO, lei ou regulamento local, nacional ou internacional, que venham a prejudicar os direitos de terceiros, desde que devidamente comprovados.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7. Links</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7.1 A CONTRATADA não se responsabiliza pelo destino sugerido em links vinculados nos anúncios e não mantém vínculos com outros sites sugeridos por eles, bem como não será responsável, direta ou indiretamente, por qualquer dano ou prejuízo causado, ou que presume tenham sido ocasionados por tais conteúdos, produtos ou serviços disponíveis em ditos sites ou recursos externos, ou pela utilização ou confiança depositada pelo USUÁRIO, ou terceiros, em tais conteúdos, produtos ou serviços.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>8. Garantia e responsabilidades</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>8.1 O conteúdo dos anúncios veiculados na PLATAFORMA é de responsabilidade exclusiva do ANUNCIANTE.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>8.2 A PLATAFORMA não garante que o serviço cumprirá com todos os requisitos e/ou necessidades do ANUNCIANTE, USUÁRIO e ou terceiro, ou que o serviço se prestará de maneira ininterrupta, segura ou isenta de erro.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>8.3 A CONTRATADA permite que os usuários avaliem e comentem os serviços e produtos dos ANUNCIANTES na PLATAFORMA, inserindo conteúdo no portal TELESAPP. A CONTRATADA não revisa o conteúdo inserido e não se responsabiliza pelo seu teor. No entanto, a CONTRATADA reserva-se o direito de retirar do ar e/ou suprimir o conteúdo inserido se não estiver de acordo com os dispostos nos Termos de Uso da Plataforma.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>8.4 A CONTRATADA não se responsabiliza por eventuais danos ou prejuízos ocasionados por esses comentários e avaliações ou pela transmissão desse conteúdo através do serviço.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>9. Obrigações dos ANUNCIANTES</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>9.1 O ANUNCIANTE se compromete a não divulgar e inserir conteúdos impróprios e a não enviar material para produção dos anúncios que: (a) Violem a lei, a moral, os bons costumes, a propriedade intelectual, os direitos à honra, à vida privada, o sigilo das comunicações, à imagem, à intimidade pessoal e familiar; (b) Infrinjam patentes, marcas, segredos comerciais, direitos autorais; (c) Estimulem a prática de condutas ilícitas; (d) Incitem a prática de atos de discriminação, seja em razão de sexo, raça, religião, crenças, idade ou qualquer outra condição; (e) Coloquem à disposição ou possibilitem o acesso a mensagens, produtos ou serviços ilícitos, violentos, pornográficos, degradantes; (f) Induzam ou incitem práticas perigosas, de risco ou nocivas para a saúde e para o equilíbrio psíquico; (g) Sejam falsos, ambíguos, inexatos, exagerados, de forma que possam induzir a erro sobre seu objeto ou sobre as intenções ou propósitos do comunicador; (h) Constituam publicidade ilícita, enganosa ou desleal, e que configurem concorrência desleal; (i) Veiculem, incitem ou estimulem a pedofilia; (j) Incorporem vírus ou outros elementos físicos ou eletrônicos que possam danificar ou impedir o normal funcionamento da rede, do sistema ou dos equipamentos informáticos (hardware e software) de terceiros ou que possam danificar os documentos eletrônicos e arquivos armazenados nestes equipamentos informáticos; (k) Hostilizem terceiros; (l) Transmitam conteúdos ilegais, daninhos, incômodos, ameaçadores, abusivos, tortuosos, difamatórios, vulgares, obscenos, invasores da intimidade de terceiros, odiosos, xenófobos, ou, de algum modo, inaceitáveis. (m) Utilizem de falsificação de rubricas ou, de outro modo de manipular identificadores com o fim de disfarçar a natureza do conteúdo transmitido.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>9.2 O ANUNCIANTE se responsabiliza por toda e qualquer divulgação e promoção de produtos e/ou serviços, sendo ele o único responsável pela publicação na PLATAFORMA e se compromete a enviar e manter conteúdos dos anúncios de acordo com as especificações desse Termo de Uso.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>9.3 A CONTRATADA e o DONO DO PONTO reservam-se o direito de recusar o conteúdo do material enviado pelo ANUNCIANTE e o direito de suprimir e retirar do ar conteúdos impróprios ou de algum modo inaceitáveis e que não estejam de acordo com esses Termos de Uso.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>9.4 A CONTRATADA não assumirá responsabilidade alguma, por nenhuma circunstância, pelo conteúdo, incluindo, sem limitação, erros ou omissões, danos ou prejuízos derivados do uso do conteúdo exibido, enviado por e-mail ou, de qualquer modo, transmitido através do serviço.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>9.5 O ANUNCIANTE reconhece que a CONTRATADA pode manter ou revelar o conteúdo se for requerido para ele em virtude das disposições legais aplicáveis ou, de boa fé, e o considera necessário para: (a) Dar cumprimento a lei e a procedimentos legais, tais como ordens judiciais ou de órgãos administrativos competentes; (b) Fazer cumprir as presentes Condições; (c) Contestar reclamações relativas a violações de direitos de terceiros; ou (d) Proteger os legítimos interesses da PLATAFORMA, seus USUÁRIOS e o público.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>9.6 O ANUNCIANTE entende e aceita que o processo técnico e a transmissão do serviço, incluindo seu conteúdo, podem implicar em: (a) Transmissões através de diversas redes; e (b) Modificações ou mudanças realizadas no objeto para tornar compatível o conteúdo com as necessidades técnicas de conexão de redes ou dispositivos.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>CAPÍTULO II</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{marginBottom: '0cm' , textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px'}}>DO DONO DO PONTO COMERCIAL DE EXIBIÇÃO DOS PAINÉIS (“DONO DO PONTO”)</p>
        <p style={{marginBottom: '0cm' , textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px'}}><br /></p>
        <p style={{marginBottom: '0cm' , textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px'}}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>1. Partes</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>1.1 Este Termo de Uso aplica-se entre, de um lado, a CONTRATADA, e de outro lado, toda e qualquer pessoa física ou jurídica, usuária dos serviços contidos na PLATAFORMA, prestados por meio do cadastro ao site www.telesapp.net, especificamente no que se referente a este CAPÍTULO, ao DONO DO PONTO COMERCIAL DE EXIBIÇÃO DOS PAINÉIS, doravante designado DONO DO PONTO.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>2. Objeto</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>2.1 Este Termo de Uso se aplica ao Telesapp.net e quaisquer outros sistemas web, comunicações, aplicativos ou serviços relacionados à PLATAFORMA que declaram ser oferecidos de acordo com este Termo de Uso (“Serviços”), incluindo a coleta externa de dados para esses Serviços, como nossos anúncios e os plug-ins, compartilhamentos.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>3. Obrigações do DONO DO PONTO</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>3.1 São obrigações do DONO DO PONTO:</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(a) fazer corretamente o cadastro (adição) do Painel Mídia Indoor, informando os horários de funcionamento do Painel e demais requisitos, exigidos pelo sistema, para a decisão do ANUNCIANTE para contratar o seu Painel;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(b) zelar pelo cumprimento dos horários de funcionamento informados durante o cadastro e atualização do painel de anúncios;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(c) manter o painel sempre conectado à internet para o recebimento de novos anúncios em tempo real;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(d) manter-se atento às notificações para atender aos pedidos de inserção de anúncios feitos pelos ANUNCIANTES, aceitando ou rejeitando a adição do novo anúncio no menor prazo possível (recomenda-se ter o aplicativo TELESAPP instalado no celular);</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(e) caso o painel de mídia indoor não estabeleça a conexão por mais de 24 horas, o painel se tornará inelegível para receber novos ANUNCIANTES sendo necessária reativação do mesmo. Se por força maior o painel não puder ser re-conectado no prazo de 72horas, contendo anúncios ativos, ele será banido do sistema e o proprietário deverá arcar com os prejuízos de estornar o débito aos ANUNCIANTES que já realizaram o pagamento, mas não lhe foi proporcionado o número de aparições contratado;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(f) reportar anúncios que não seguem os termos de uso, contendo violência, pornografia, propaganda enganosa ou outros subgrupos de anúncios proibidos contidos nos termos de uso;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(g) certificar-se que a conta provida para o recebimento dos pagamentos de ANUNCIANTES esteja ativa e pronta para receber novas transferências e depósitos. Caso não seja possível ser realizado o pagamento, o DONO DO PONTO deverá entrar em contato diretamente com a CONTRATADA para requisitar a obtenção de forma alternativa dos pagamentos retidos;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(h) o estabelecimento privado que possui o painel deve estar devidamente cadastrado no endereço correto, uma vez que DONO DO PONTO tenha reivindicado o local, bloqueará outros interessados em cadastrar painéis no endereço em questão;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(i) promover a notificação de desativação do painel com antecedência se por ventura o proprietário do painel desejar por si o encerramento da utilização e disponibilização do painel no país, cumprindo assim os prazos dos últimos anúncios recebidos para então promover a desativação de fato do painel de anúncios;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(j) o DONO DO PONTO é responsável pelo custo de instalação do painel, bem como pela aquisição da TV BOX para a gestão dos anúncios. Além das telas e dos players, é preciso ter também todos os cabos, conectores, extensores, e adaptadores necessários, acessórios para fixação e estruturas de suporte, segundo as exigências de cada local;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(k) baixar o player com o seu aplicativo (Telesapp Mídia Indoor) para que o dispositivo (TV BOX) possa manter os anúncios atualizados no painel;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(l) escolher locais de fácil visualização para que os clientes do PONTO DE EXIBIÇÃO possam visualizar o Painel Telesapp Mídia Indoor;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(m) informar no cadastro do PONTO DE EXIBIÇÃO dados sobre o público que costuma frequentá-lo, quais atividades eles fazem e quanto tempo passam ali. Dessa forma os ANUNCIANTES escolherão quais os pontos que irão atingir o seu público alvo com mais precisão;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>4. Direitos do DONO DO PONTO</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>4.1 São direitos do DONO DO PONTO:</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(a) receber parte da receita auferida, 30% (trinta por cento), oriunda de contratações para o seu (s) painel (eis) com a venda de espaços de publicidade pela PLATAFORMA; caso a grade de anúncios tenha atingido 15 anúncios pagos ou mais, o DONO DO PONTO receberá 50% da receita auferida pela venda de espaços de publicidade pela PLATAFORMA em relação ao 16º até o 30º anúncio pago contratado em vigência, concomitantemente.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(b) poder inserir até 60% dos anúncios não comercializados como anúncios próprios, enquanto estes espaços ainda não tiverem sido comercializados.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5. Do uso dos espaços de publicidade pelo próprio DONO DO PONTO</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5.1 O dono do ponto comercial terá direito a divulgar seus próprios anúncios nos espaços vagos da grade de anúncios (“anúncios facultativos”). Ambas as PARTES concordam que a qualquer momento os anúncios facultativos poderão ser substituídos por anúncios pagos, na seguinte ordem: 2 primeiros pagos substituem 2 anúncios facultativos do dono do ponto comercial e o 3° anúncio pago que ingressar na grade de anúncios substitui 1 anúncio do Telesapp, preservando, assim, a proporção até que não haja mais anúncios facultativos.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5.2 O dono do ponto comercial / painel é terminantemente proibido de vender os espaços dos anúncios facultativos, que possui na grade anúncios, seja de forma direta seja de forma indireta, ou seja, fazendo upload dos anúncios pelo seu próprio perfil profissional, como se fossem seus.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5.3 Dos anúncios facultativos, 2/3 dos espaços na grade serão destinados a anúncios do ponto comercial e 1/3 a anúncios do Telesapp.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5.4 Com o início das divulgações no painel, a grade será composta de 100% de anúncios facultativos Telesapp, até que o dono do ponto comercial vá enviando seus anúncios facultativos para preencher os 2/3 da grade de anúncios facultativos a que tem direito.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5.5 O player Telesapp Mídia Indoor irá verificar quantos espaços para anúncios vagos existem, partindo do princípio de que no início não existiram anúncios pagos e que o bloco de anúncios contém 30 espaços para anúncios, serão exibidos até 30 anúncios da própria PLATAFORMA, que serão substituídos pelos anúncios do PONTO COMERCIAL (PONTO DE EXIBIÇÃO) e pelos próprios anúncios pagos, quando ocorrer a contratação pelos ANUNCIANTES. A proporção de anúncios entre PLATAFORMA E PONTO COMERCIAL será de 1 para 2, ou seja, o PONTO COMERCIAL poderá exibir até 20, sendo o número de anúncios da PLATAFORMA reduzido para 10. Com o ingresso de anúncios pagos, haverá a redução dos anúncios gratuitos nesta proporção. Os anúncios gratuitos serão substituídos a qualquer momento por anúncios pagos, respeitando a proporção de suspensão dos anúncios do Ponto Comercial / Telesapp. Somente haverá a remoção quando o ANUNCIANTE paga o seu anúncio. Os ciclos são de 5 minutos de aparições para cada bloco de anúncios. Quando começar a surgir três anúncios pagos será descontado 2 do ponto comercial e 1 do Telesapp Mídia Indoor. Os anúncios pagos terão preferência em ser os primeiros a serem exibidos nos monitores.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>6. Relacionamento Dono do Ponto com Dono do Equipamento</p>
        <p style={{marginBottom: '0cm' , textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px'}}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>6.1 O Dono do Ponto poderá, a seu critério, ceder o seu espaço para a instalação de um Painel Mídia Indoor de terceiro, dono do equipamento ou investidor. Este proprietário do equipamento terá direito a um percentual de remuneração a ser negociado entre as partes interessadas, a saber: Dono do Ponto e Dono do Equipamento.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>6.2 O Telesapp Mídia Indoor não tem qualquer responsabilidade ou ingerência sobre esta negociação.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7. Disposições gerais</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7.1 Se o ponto comercial possuir uma TV, já instalada em local adequado, o custo será somente a aquisição do tv-box (ao redor de R$250,00) e o custo de instalação.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7.2 Os pagamentos dos anúncios serão feito por meio de PIX.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7.3. Caso algum ponto fique desligado continuamente durante 1 mês será cancelado automaticamente o Painel Telesapp Mídia Indoor.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>CAPÍTULO III</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>Política de Privacidade</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>Esta Política de Privacidade (“Política de Privacidade”) destina-se a informá-lo sobre o modo como a CONTRATADA utiliza e divulga informações coletadas em suas visitas à nossa PLATAFORMA e em mensagens que trocamos com o USUÁRIO (“Comunicações”). Esta Política de Privacidade aplica-se somente a informações coletadas por meio da PLATAFORMA.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>Ao acessar a plataforma, enviar comunicações ou fornecer qualquer tipo de dado pessoal, o USUÁRIO entende os termos aqui previstos e aceita esta política de privacidade, consentindo com o tratamento de suas informações, conforme aqui descrito ou nos termos da legislação em vigor.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>Esta Política de Privacidade fornece uma visão geral de nossas práticas de privacidade e das escolhas que o USUÁRIO pode fazer, bem como direitos que o USUÁRIO pode exercer em relação aos Dados Pessoais tratados pela CONTRATADA. Se o USUÁRIO tiver alguma dúvida sobre o uso de Dados Pessoais, entre em contato pelo e-mail contato@telesapp.net.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>Além disso, a Política de Privacidade não se aplica a quaisquer aplicativos, produtos, serviços, site ou recursos de mídia social de terceiros que possam ser oferecidos ou acessados por meio da PLATAFORMA. O acesso a esses links fará com que USUÁRIO deixe a PLATAFORMA e possa resultar na coleta ou compartilhamento de informações sobre o USUÁRIO por terceiros. A CONTRATADA não controla, endossa ou faz quaisquer representações sobre esses sites de terceiros ou suas práticas de privacidade, que podem ser diferentes das nossas. Recomendamos que USUÁRIO revise a política de privacidade de qualquer site com o qual o USUÁRIO interaja antes de permitir a coleta e o uso de seus Dados Pessoais.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>Caso o USUÁRIO nos envie Dados Pessoais referentes a outras pessoas físicas, o USUÁRIO declara ter a competência para fazê-lo e declara ter obtido o consentimento necessário para autorizar o uso de tais informações nos termos desta Política de Privacidade.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>1. Definições</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>1.1 Para os fins desta Política de Privacidade:</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(i) “Dados Pessoais” significa qualquer informação que, direta ou indiretamente, identifique ou possa identificar uma pessoa natural, como por exemplo, nome, CPF, data de nascimento, endereço IP, dentre outros;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(ii) “Dados Pessoais Sensíveis” significa qualquer informação que revele, em relação a uma pessoa natural, origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(iii) “Tratamento de Dados Pessoais” significa qualquer operação efetuada no âmbito dos Dados Pessoais, por meio de meios automáticos ou não, tal como a recolha, gravação, organização, estruturação, armazenamento, adaptação ou alteração, recuperação, consulta, utilização, divulgação por transmissão, disseminação ou, alternativamente, disponibilização, harmonização ou associação, restrição, eliminação ou destruição. Também é considerado Tratamento de Dados Pessoais qualquer outra operação prevista nos termos da legislação aplicável;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(iv) “Leis de Proteção de Dados” significa todas as disposições legais que regulem o Tratamento de Dados Pessoais, incluindo, porém sem se limitar, a Lei nº 13.709/18, Lei Geral de Proteção de Dados Pessoais (“LGPD”).</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>2. Uso de Dados Pessoais</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>2.1 Coletamos e usamos Dados Pessoais para gerenciar o seu relacionamento com a CONTRATADA e a PLATAFORMA e melhor atendê-lo quando o USUÁRIO estiver usando a PLATAFORMA, personalizando e melhorando sua experiência. Exemplos de como usamos os dados incluem:</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(i) Para cobrar e faturar os valores previstos nos serviços contratados pelo USUÁRIO;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(ii) Para confirmar ou corrigir as informações que temos sobre o USUÁRIO;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(iii) Para enviar informações que acreditamos ser do interesse do USUÁRIO;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(iv) Para personalizar sua experiência de uso da PLATAFORMA; e</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(v) Para entrarmos em contato por um número de telefone e/ou endereço de e-mail fornecido, suporte, solução de problemas e, quando necessário, para fins comerciais razoáveis, tais como envio de informações promocionais de produtos ou serviços da PLATAFORMA ou de nossos parceiros. Neste caso, se o USUÁRIO assim o desejar, poderá solicitar a remoção ou alteração de seus dados pessoais enviando um e-mail para contato@telesapp.net</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>2.2 Além disso, os Dados Pessoais fornecidos também podem ser utilizados na forma que julgarmos necessária ou adequada:</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(a) nos termos das Leis de Proteção de Dados;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(b) para atender exigências de processo judicial;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(c) para cumprir decisão judicial, decisão regulatória ou decisão de autoridades competentes, incluindo autoridades fora do país de residência;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(d) para aplicar nossos Termos e Condições de Uso;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(e) para proteger nossas operações;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(f) para proteger direitos, privacidade, segurança nossos, seus ou de terceiros;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(g) para detectar e prevenir fraude;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(h) permitir-nos usar as ações disponíveis ou limitar danos que venhamos a sofrer; e</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(i) de outros modos permitidos por lei.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>3. Não fornecimento de Dados Pessoais</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>3.1 USUÁRIO não é obrigado a compartilhar os Dados Pessoais que solicitamos, no entanto, se o USUÁRIO optar por não os compartilhar, em alguns casos, não poderemos fornecer ao USUÁRIO acesso completo à PLATAFORMA, alguns recursos especializados ou ser capaz de responder efetivamente a quaisquer consultas que o USUÁRIO possa ter.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>4. Dados coletados</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>4.1 O público em geral poderá navegar na PLATAFORMA ou visualizá-la sem necessidade de qualquer cadastro e envio de Dados Pessoais. No entanto, algumas das funcionalidades da PLATAFORMA poderão depender de cadastro e envio de Dados Pessoais.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>4.2 No contato conosco, a PLATAFORMA poderá coletar:</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(i) Dados de contato: nome, sobrenome, número de telefone, cidade, Estado e endereço de e-mail; e</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(ii) Informações que USUÁRIO envia. Informações que USUÁRIO envia via formulário (dúvidas, reclamações, sugestões, críticas, elogios etc.).</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>4.3 Na navegação geral na PLATAFORMA, a PLATAFORMA poderá coletar:</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(i) Dados de localização. Dados de geolocalização quando o USUÁRIO acessa a PLATAFORMA;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(ii) Preferências. Informações sobre suas preferências e interesses em relação à PLATAFORMA (quando o USUÁRIO nos diz o que eles são ou quando os deduzimos do que sabemos sobre o USUÁRIO) e como o USUÁRIO prefere receber nossas Comunicações;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(iii) Dados de navegação na PLATAFORMA. Informações sobre suas visitas e atividades na PLATAFORMA, incluindo o conteúdo (e quaisquer anúncios) com os quais o USUÁRIO visualiza e interage, informações sobre o navegador e o dispositivo que o USUÁRIO está usando, seu endereço IP, sua localização, o endereço do site a partir do qual o USUÁRIO chegou. Algumas dessas informações são coletadas usando nossas Ferramentas de Coleta Automática de Dados, que incluem cookies, web beacons e links da web incorporados. Para saber mais, leia como a CONTRATADA usa Ferramentas de Coleta Automática de Dados no item 7 abaixo;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(iv) Dados anônimos ou agregados. Respostas anônimas para pesquisas ou informações anônimas e agregadas sobre como a PLATAFORMA é usufruída. Durante nossas operações, em certos casos, aplicamos um processo de desidentificação ou pseudonimização aos seus dados para que seja razoavelmente improvável que algum USUÁRIO identifique o USUÁRIO através do uso desses dados com a tecnologia disponível; e</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(v) Outras informações que podemos coletar. Outras informações que não revelem especificamente a sua identidade ou que não são diretamente relacionadas a um indivíduo, tais como informações sobre navegador e dispositivo; dados de uso da PLATAFORMA; e informações coletadas por meio de cookies, pixel tags e outras tecnologias.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>4.4 CONTRATADA não coleta Dados Pessoais Sensíveis.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5. Compartilhamento de Dados Pessoais com terceiros</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>5.1 O Telesapp poderá compartilhar seus Dados Pessoais:</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(i) Com a(s) empresa(s) parceira(s) que o USUÁRIO selecionar ou optar em enviar os seus dados, dúvidas, perguntas etc., bem como com provedores de serviços ou parceiros para gerenciar ou suportar certos aspectos de nossas operações comerciais em nosso nome. Esses provedores de serviços ou parceiros podem estar localizados nos Estados Unidos, no Brasil ou em outros locais globais, incluindo servidores para homologação e produção, e prestadores de serviços de hospedagem e armazenamento de dados, gerenciamento de fraudes, suporte ao cliente, vendas em nosso nome, atendimento de pedidos, personalização de conteúdo, atividades de publicidade e marketing (incluindo publicidade digital e personalizada) e serviços de TI, por exemplo;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(ii) Com terceiros, com o objetivo de nos ajudar a gerenciar a PLATAFORMA; e</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(iii) Com terceiros, caso ocorra qualquer reorganização, fusão, venda, joint venture, cessão, transmissão ou transferência de toda ou parte da CONTRATADA, ativo ou capital (incluindo os relativos à falência ou processos semelhantes)</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>6. Transferências internacionais de Dados</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>6.1 Dados Pessoais e informações de outras naturezas coletadas pela PLATAFORMA podem ser transferidos ou acessados por entidades pertencentes ao grupo corporativo das empresas parceiras em todo o mundo de acordo com esta Política de Privacidade.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7. Forma de coleta automática de Dados Pessoais</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7.1 Quando o USUÁRIO visita a PLATAFORMA, ela pode armazenar ou recuperar informações em seu navegador, principalmente na forma de cookies, que são arquivos de texto contendo pequenas quantidades de informação. Visite nossa Política de Cookies do TELESAPP para saber mais.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7.2 Essas informações podem ser sobre o USUÁRIO, suas preferências ou seu dispositivo e são usadas principalmente para que a PLATAFORMA funcione como o USUÁRIO espera. As informações geralmente não o identificam diretamente, mas podem oferecer uma experiência na internet mais personalizada.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7.3 De acordo com esta Política de Privacidade e a Política de Cookies do TELESAPP, a CONTRATADA e nossos prestadores de serviços terceirizados, mediante seu consentimento, podemos coletar seus Dados Pessoais de diversas formas, incluindo, entre outros:</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7.3.1 Por meio do navegador ou do dispositivo: Algumas informações são coletadas pela maior parte dos navegadores ou automaticamente por meio de dispositivos de acesso à internet, como o tipo de computador, resolução da tela, nome e versão do sistema operacional, modelo e fabricante do dispositivo, idioma, tipo e versão do navegador de Internet que está utilizando. Podemos utilizar essas informações para assegurar que a PLATAFORMA funcione adequadamente.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>7.3.2 Uso de cookies: Informações sobre o seu uso da PLATAFORMA podem ser coletadas por terceiros, por meio de cookies. Cookies são informações armazenadas diretamente no computador que USUÁRIO está utilizando. Os cookies permitem a coleta de informações tais como o tipo de navegador, o tempo dispendido na PLATAFORMA, as páginas visitadas, as preferências de idioma, e outros dados de tráfego anônimos. CONTRATADA e nossos prestadores de serviços utilizamos informações para proteção de segurança, para facilitar a navegação, exibir informações de modo mais eficiente, e personalizar sua experiência ao utilizar a PLATAFORMA, assim como para rastreamento online. Também coletamos informações estatísticas sobre o uso da PLATAFORMA para aprimoramento contínuo do nosso design e funcionalidade, para entender como a PLATAFORMA é utilizada e para auxiliá-lo a solucionar questões relativas à PLATAFORMA.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>8. Direitos do Usuário</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>8.1 O USUÁRIO pode, a qualquer momento, requerer à PLATAFORMA:</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(i) confirmação de que seus Dados Pessoais estão sendo tratados;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(ii) acesso aos seus Dados Pessoais;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(iii) correções a dados incompletos, inexatos ou desatualizados;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(iv) anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto em lei;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(v) portabilidade de Dados Pessoais a outro prestador de serviços, contanto que isso não afete nossos segredos industriais e comerciais;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(vi) eliminação de Dados Pessoais tratados com seu consentimento, na medida do permitido em lei;</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(vii) informações sobre as entidades às quais seus Dados Pessoais tenham sido compartilhados</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(viii) informações sobre a possibilidade de não fornecer o consentimento e sobre as consequências da negativa; e</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>(ix) revogação do consentimento. Os seus pedidos serão tratados com especial cuidado de forma a que possamos assegurar a eficácia dos seus direitos. Poderá lhe ser pedido que faça prova da sua identidade de modo a assegurar que a partilha dos Dados Pessoais é apenas feita com o seu titular.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>8.2 O USUÁRIO deverá ter em mente que, em certos casos (por exemplo, devido a requisitos legais), o seu pedido poderá não ser imediatamente satisfeito, além de que a PLATAFORMA poderá não conseguir atendê-lo por conta de cumprimento de obrigações legais.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>9. Segurança dos Dados Pessoais</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>9.1 Buscamos adotar as medidas técnicas e organizacionais previstas pelas Leis de Proteção de Dados adequadas para proteção dos Dados Pessoais na nossa organização. Infelizmente, nenhuma transmissão ou sistema de armazenamento de dados tem a garantia de serem 100% seguros. Caso tenha motivos para acreditar que sua interação conosco tenha deixado de ser segura (por exemplo, caso acredite que a segurança de qualquer uma de suas contas foi comprometida), favor nos notificar imediatamente.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>10. Links de hipertexto para outros sites e redes sociais</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>10.1 A PLATAFORMA poderá, de tempos a tempos, conter links de hipertexto que redirecionará o USUÁRIO para sites das redes dos nossos parceiros, ANUNCIANTES, fornecedores etc. Se o USUÁRIO clicar em um desses links para qualquer um desses sites, lembramos que cada sites possui as suas próprias práticas de privacidade e que não somos responsáveis por essas políticas. Consulte as referidas políticas antes de enviar quaisquer Dados Pessoais para esses sites.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>10.2 Não nos responsabilizamos pelas políticas e práticas de coleta, uso e divulgação (incluindo práticas de proteção de dados) de outras organizações, tais como Facebook, Apple, Google, Microsoft, ou de qualquer outro desenvolvedor de software ou provedor de aplicativo, plataforma de mídia social, sistema operacional, prestador de serviços de internet sem fio ou fabricante de dispositivos, incluindo todos os Dados Pessoais que divulgar para outras organizações por meio dos aplicativos, relacionadas a tais aplicativos, ou publicadas em nossas páginas em mídias sociais. A CONTRATADA recomenda que o USUÁRIO se informe sobre a política de privacidade de cada site visitado ou de cada prestador de serviço utilizado.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>11. Uso da PLATAFORMA por menores de idade</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>11.1 A plataforma não se destina a pessoas com menos de 18 (dezoito) anos e pedimos que tais pessoas não nos forneçam qualquer dado pessoal.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>12. Atualizações desta Política de Privacidade</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>12.1 Se modificarmos nossa Política de Privacidade, publicaremos o novo texto na PLATAFORMA, com a data de revisão atualizada. Podemos alterar esta Política de Privacidade a qualquer momento. Caso haja alteração significativa nos termos dessa Política de Privacidade, podemos informá-lo por meio das informações de contato que tivermos em nosso banco de dados ou por meio de notificação em nossa PLATAFORMA.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>12.2 Recordamos que a CONTRATADA tem como compromisso não tratar os seus Dados Pessoais de forma incompatível com os objetivos descritos acima, exceto se de outra forma requerido por lei ou ordem judicial.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>12.3 Sua utilização da PLATAFORMA após as alterações significa que aceitou as Políticas de Privacidade revisadas. Caso, após a leitura da versão revisada, o USUÁRIO não esteja de acordo com seus termos, favor encerrar o acesso à PLATAFORMA.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>13. Encarregado do tratamento dos Dados Pessoais</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>13.1 Caso pretenda exercer qualquer um dos direitos previstos nesta Política de Privacidade e/ou nas Leis de Proteção de Dados, ou resolver quaisquer dúvidas relacionadas ao Tratamento de seus Dados Pessoais, favor contatar-nos através do e-mail contato@telesapp.net.</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>Telesapp.net</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>Telesapp Mídia Indoor</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}>Foco Investimentos e Participações Ltda. EIRELI</p>
        <p style={{textAlign: 'left', fontFamily: '"Quicksand", serif', fontSize: '13px', marginBottom: '0cm' }}><br /></p>
</>
);

export default Terms;