import React, { useState } from 'react';
import GlobalStyle from './styles/global';
import styled, { ThemeProvider } from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createMuiTheme, ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import useWindowSize from './utils/hooks/useWindowSize';
import { useTheme } from './context/Theme';
import NavBar from './components/NavBar';
import Routes from './routes/index';
import MainFab from './components/MainFab';
import BottomBar from './components/BottomBar';
import ModalInnerApplication from './components/ModalInnerApplication';
import DrawerInnerApplication from './components/DrawerInnerApplication';
import RefreshApp from './components/RefreshApp';
import AcceptTerms from './components/AcceptTerms';
import AcceptCookies from './components/AcceptCookies';
import NotificationsPopup from './components/NotificationsPopup';

// Main container of the app. In the desktop, it's the rectangle with rounded borders, and in the mobile/tablet is the whole viewport
const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  transition: all .1s ease;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  @media screen and (min-width: 1024px) {
    width: calc(100% - 90px);
    height: calc(100% - 60px);
    border-radius: 10px;
    background: ${props => props.theme.colors.background};
    box-shadow: ${props => props.theme.title === 'light' ? '0 4px 20px #cccccc80': 'none'};
  }
`;

const MainApplication = ({ userStations, addStation }) => {
  const { theme } = useTheme();
  const size = useWindowSize();
  const [ notificationsOpen, setNotificationsOpen ] = useState(false);
  const [ modalShown, setModalShown ] = useState(false);
  const [ drawerShown, setDrawerShown ] = useState(true);

  const MUITheme = createMuiTheme({
    overrides: {
      MuiSnackbar: {
        root: {
          margin: '0 10px 80px 10px',
          height: '50px',
        }
      }
    },
    palette: {
      type: theme.title,
      primary: {
        main: theme.colors.primary
      }
    }
  });

  const toggleNotifications = (status) => {
    setNotificationsOpen(status);
  }

  return (
    <ThemeProvider theme={theme}>
      <MUIThemeProvider theme={MUITheme}>
        <GlobalStyle />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AcceptCookies />
          <AcceptTerms />
          <RefreshApp />
          <Container>
            <NavBar
              notificationsOpen={notificationsOpen}
              toggleNotifications={toggleNotifications}
            />
            <NotificationsPopup
              notificationsOpen={notificationsOpen}
              toggleNotifications={toggleNotifications}
              userStations={userStations}
              addStation={addStation}
            />
            <Routes />
            <MainFab />
            { size.width < 1024 && <BottomBar /> }
            { size.width >= 1024 &&
              <ModalInnerApplication
                modalShown={modalShown}
                setModalShown={setModalShown}
              />
            }
            { size.width >= 1024 &&
              <DrawerInnerApplication
                drawerShown={drawerShown}
                setDrawerShown={setDrawerShown}
              />
            }
          </Container>
        </MuiPickersUtilsProvider>
      </MUIThemeProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = state => ({
  userStations: state.StationsReducer.userStations,
  addStation: state.StationsReducer.addStation,
});

export default connect(mapStateToProps, { })(MainApplication);