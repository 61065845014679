import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';
import { motion } from "framer-motion";

const letterAnimation = keyframes`
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Container = styled(motion.div)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${props => `${rgba(props.theme.colors.background, .9)}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;

  @media screen and (min-width: 1024px) {
    position: absolute;
  }
`;

export const Text = styled.p`
  font-weight: 700;
  color: ${props => props.theme.text.primary};
  transform: translateY(-90px);
  display: block;
  text-align: center;
`;

export const Char = styled.span`
  opacity: 0;
  display: inline-block;
  min-width: 5px;
  transform: translateY(10px);
  animation: ${letterAnimation} .3s cubic-bezier(.785,.135,.15,.86) forwards 1;
  animation-delay: ${props => (props.index + 1) * .05}s;
`;
