import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IconButton, ButtonBase } from '@material-ui/core';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.secondary};
  z-index: 200;
  padding: 20px;

  @media screen and (min-width: 1024px) {
    position: relative;
    width: calc(${props => props.width}px - 640px);
    height: calc(${props => props.height}px - 140px);
    min-width: 600px;
    border-radius: 15px;
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 0 20px;

  @media screen and (min-width: 1024px) {
    position: unset;
    padding: 0px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  color: ${props => props.theme.text.secondary};
  font-weight: 700;
`;

export const BtnClose = styled(IconButton)`
  width: 40px;
  height: 40px;
  transform: translateX(10px);
  margin-left: auto !important;

  > span > svg > path {
    fill: ${props => props.theme.iconTintColor.inactive} !important;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  padding: 60px 0 20px 0;

  @media screen and (min-width: 1024px) {
    padding-top: 20px;
  }
`;

export const Paragraph = styled.p`
  color: ${props => props.theme.text.secondary};
  padding: 10px;
`;

export const EmptyState = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
  position: relative;

  @media screen and (min-width: 1024px) {
    text-align: left;
    box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
  }
`;

export const EmptyStateHeadline = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 600;
  margin-bottom: 10px;
  z-index: 2;
  position: relative;
`;

export const EmptyStateSub = styled.p`
  color: ${props => props.theme.text.secondary};
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
`;

export const EmptyStateBtn = styled(ButtonBase)`
  border-radius: 5px !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  height: 40px;
  padding: 0 15px !important;
`;

export const Indicator = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  margin-top: 3px;
  background: ${props => props.theme.separator};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: all .15s ease-out;
    height: 100%;
    background: ${props => props.theme.iconTintColor.inactive};
  }
`;