import React, { useContext } from 'react';

import {
    Container,
    Header,
    Row,
    Title,
    BtnClose,
    Paragraph,
    TextContainer,
    EmptyState,
    EmptyStateHeadline,
    EmptyStateSub,
    EmptyStateBtn,
    Indicator
} from './styles';
import { mdiClose, mdiInformation } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import Lottie from 'react-lottie';

import animationData from '../../assets/lotties/go_telesapp.json';
import useWindowSize from '../../utils/hooks/useWindowSize';

import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { connect } from 'react-redux';

function About ({ setBottomSheetShow }) {
    const { t } = useTranslation();
    const history = useHistory();
    const theme = useContext(ThemeContext);
    const size = useWindowSize();

    const handleExit = () => {
        if (size.width < 1024) {
            setBottomSheetShow(false);
        } else {
            history.replace('/');
        }
    }

    const openTelesapp = () => {
        const win = window.open("https://telesapp.net", "_blank");
        win.focus();
    }

    const variants = {
        initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    }
    
    return (
        <Container
          className='noSelect'
          variants={variants}
          width={size.width}
          height={size.height}
          initial='initial'
          animate='visible'
          exit='hidden'
        >
            <Header>
                <Row>
                    <Icon
                        path={mdiInformation}
                        size='24px'
                        color={theme.iconTintColor.inactive}
                        style={{ marginRight: '10px' }}
                    />
                    <Title>
                        Telesapp Mídia Indoor
                    </Title>
                    <BtnClose size='small' onClick={handleExit}>
                        <Icon
                        path={mdiClose}
                        size='24px'
                        />
                    </BtnClose>
                </Row>
                <Indicator />
            </Header>
            <TextContainer>
                <Paragraph>
                    O Telesapp Mídia Indoor é o sistema do telesapp.net que gerencia todo o ambiente de uma operação mídia indoor nos padrões Telesapp.
                </Paragraph>
                <Paragraph>
                    Pelo Telesapp Mídia Indoor você poderá cadastrar um novo painel, pesquisar os painéis disponíveis e em destaque, enviar anúncios para painéis de sua preferência e realizar os pagamentos pelas contratações.
                </Paragraph>
                <Paragraph>
                    E mais, os seus anúncios também estarão presentes no portal de negociações de serviços e produtos telesapp.net, com grande visibilidade na WEB e Google.
                </Paragraph>
            </TextContainer>
            <EmptyState>
                <Lottie
                    options={{
                    loop: true,
                    autoplay: true,
                    animationData: animationData,
                    rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                    }
                    }}
                    width={(size.width < 1024) ? '33%' : '33%'}
                    height={(size.width < 1024) ? '53%' : '100%'}
                    isClickToPauseDisabled={true}
                    style={{ position: 'absolute', right: 8, bottom: 0, opacity: .7 }}
                />
                <EmptyStateHeadline> Telesapp </EmptyStateHeadline>
                <EmptyStateSub> Que tal anunciar seus produtos e serviços no Telesapp? </EmptyStateSub>
                <EmptyStateBtn onClick={ openTelesapp }> Anunciar </EmptyStateBtn>
            </EmptyState>
        </Container>
    );
}

export default connect(null, { })(About);