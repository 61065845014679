import React, { useEffect } from 'react';

import refreshIcon from '@iconify-icons/mdi/refresh';
import { Icon } from '@iconify/react';
import { useClearCache } from 'react-clear-cache';

import { connect } from 'react-redux';

import { bumpVersion, checkUpdate } from '../../store/actions/AuthenticationActions';

import {
  Container,
  RefreshButton,
  Title
} from './styles';


const RefreshApp = ({ build, currentBuild, bumpVersion, checkUpdate }) => {

  const { emptyCacheStorage } = useClearCache();

  useEffect(() => {
    checkUpdate(build);
  }, []);

  const handleRefreshButton = () => {
    bumpVersion();
    emptyCacheStorage();
    console.log('version bump');
  }

  if (currentBuild !== build) {
    return (
      <Container>
        <Title> O app foi atualizado. Clique no botão pra recarregar </Title>
        <RefreshButton
          onClick={e => { handleRefreshButton(e) }}
          startIcon={<Icon icon={refreshIcon} width={52} height={52} color={'#fff'} />}
        >
        </RefreshButton>
      </Container>
    );
  }

  return false;
}

const mapStateToProps = state => ({
  build: state.UserDataReducer.build,
  currentBuild: state.UserDataReducer.currentBuild
});

export default connect(mapStateToProps, { bumpVersion, checkUpdate })(RefreshApp);
