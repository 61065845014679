import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 999;

  .context-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: 999;

    &.show {
      pointer-events: auto;
    }
  }

  .context-menu {
    position: absolute;
    right: 20px;
    top: 60px;
    padding: 10px;
    overflow: hidden;
    width: 220px;
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
    box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
    border-radius: 10px;
    transform: translate(5%, -5%) scale(.8);
    opacity: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    pointer-events: none;
    transform-origin: right top;
    z-index: 1000;
    transition: transform .1s cubic-bezier(.785,.135,.15,.86) .15s, opacity .1s cubic-bezier(.785,.135,.15,.86) 0s, height .15s cubic-bezier(.785,.135,.15,.86);

    &.show {
      transform: translate(0) scale(1);
      opacity: 1;
      pointer-events: auto;
      transition: transform .15s cubic-bezier(.785,.135,.15,.86) 0s, opacity .15s cubic-bezier(.785,.135,.15,.86) 0s, height .15s cubic-bezier(.785,.135,.15,.86) .05s;
    }

    .btn-error {
      &:hover {
        background: ${props => props.theme.colors.error};
        
        > * {
          color: #fff !important;
        }
      }
    }
  }

  select {
    border: none;
    background: transparent;
    font-size: 16px;
    font-family: 'Quicksand', Arial, Helvetica, sans-serif;
    color: ${props => props.theme.text.secondary};
    cursor: pointer;
    -webkit-appearance: none;  /* Remove estilo padrão do Chrome */
    -moz-appearance: none; /* Remove estilo padrão do FireFox */
    appearance: none; /* Remove estilo padrão do FireFox*/
    text-align-last: right;

    &:focus {
      outline: none;
    }
  }

  option {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
    color: ${props => props.theme.text.primary};
    direction: ltr;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const Overlay = styled.div``;

export const Menu = styled.div`
  height: ${props => props.height + 20}px;
`;

export const Root = styled.div`
  width: calc(100% - 20px);
  flex-direction: column;
  transform: ${props => props.showSubMenu ? 'translateX(-100%)' : 'translateX(0)'};
  opacity: ${props => props.showSubMenu ? 0 : 1};
  transition: all .15s cubic-bezier(.785,.135,.15,.86);
  position: absolute;
  top: 10px;
  left: 10px;
`;

export const SubMenu = styled.div`
  width: calc(100% - 20px);
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  transition: all .15s cubic-bezier(.785,.135,.15,.86);
  transform: translateX(100%);
  opacity: 0;
  position: absolute;
  top: 10px;
  left: 10px;

  &.show {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const Option = styled.div`
  font-size: 16px;
  color: ${props => props.theme.text.primary};
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  transition: all .1s ease;
  justify-content: space-between;

  &:active {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
  }

  @media screen and (min-width: 1024px) {
    cursor: pointer;

    &:hover {
      background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
    }
  }
`;

export const OptionLabel = styled.label`
  font-size: 16px;
  color: ${props => props.theme.text.primary};
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:active {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
  }

  @media screen and (min-width: 1024px) {
    cursor: pointer;

    &:hover {
      background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
    }
  }
`;
