import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  touch-action: none;
  z-index: ${props => props.active ? 20 : 15};
`;

export const Image = styled(animated.img)`
  width: ${props => props.mode === 'landscape' ? '100% !important' : 'auto !important'};
  height: ${props => props.mode === 'portrait' ? '100% !important' : 'auto !important'};
  object-fit: contain;
  transition: all .15s ease;
  touch-action: none;
  will-change: transform;
`;