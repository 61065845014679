import React, { useContext, useEffect, useRef, useState } from 'react';

import { Icon, InlineIcon } from '@iconify/react';
import roundClose from '@iconify/icons-ic/round-close';
import roundHelpOutline from '@iconify/icons-ic/round-help-outline';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { listenStationActivation, stopListeningActivation } from '../../store/actions/StationsActions';
import { getUserPix, clearManagePix } from '../../store/actions/PaymentsActions';
import useWindowSize from '../../utils/hooks/useWindowSize';
import Lottie from 'react-lottie';
import { connect } from 'react-redux';

import tutorial from '../../assets/images/logo_md.svg';
import successAnimation from '../../assets/lotties/success.json';
import errorAnimation from '../../assets/lotties/error-face.json';
import ManagePixKeys from '../../components/ManagePixKeys';

import { Container, Header, HeaderTitle, ButtonClose, Pages, Page, PageTitle, MIPlayerThumb, Footer, LeftButton, RightButton, HelpButton, HelpButtonText, Indicator, Row, PINContainer, PINInput, Overlay, Spinner, CircleLoader, FeedbackButton, Text, Char } from './styles';

const StationActivation = ({ stationActivation, listenStationActivation, stopListeningActivation, userPix, addPix, email, token, getUserPix, clearManagePix, deletePix }) => {
  const history = useHistory();
  const location = useLocation();
  const theme = useContext(ThemeContext);
  const size = useWindowSize();
  const { t } = useTranslation();
  const helpContentRef = useRef();
  const [ step, setStep ] = useState(0);
  const [ helpOpen, setHelpOpen ] = useState(false);
  const [ pin, setPin ] = useState(['0', '0', '0', '0']);
  const [ loading, setLoading ] = useState(false);
  const [ status, setStatus ] = useState('');
  const [ pixDrawerOpen, setPixDrawerOpen ] = useState(false);

  useEffect(() => {
    let { codStation, station } = location;
    if (codStation) {
      codStation = codStation.toString();
      const decodedCodStation = atob(atob(codStation));
      setPin([`${decodedCodStation[0]}`, `${decodedCodStation[1]}`, `${decodedCodStation[2]}`, `${decodedCodStation[3]}`]);
      listenStationActivation(codStation, station);
    } else {
      handleClose();
    }

    return () => { stopListeningActivation(); }
  }, []);

  useEffect(() => {
    if ((userPix === undefined || !userPix.data || userPix.data?.data?.length === 0) && !userPix.loading)  {
      togglePixDrawer(true);
      setPixDrawerOpen(true);
    }
  }, [userPix]);

  useEffect(() => {
    if ((addPix !== undefined && addPix.data.length > 0) || (deletePix !== undefined && deletePix.data.length > 0)) {
      if (!addPix.loading && !deletePix.loading) {
        getUserPix({ email, token }, userPix);
      }
      return () => clearManagePix();
    }
  }, [addPix, deletePix]);

  useEffect(() => {
    if (typeof array && stationActivation.length) {
      if (stationActivation[0].state === 'activating') {
        setLoading(true);
      }
      if (stationActivation[0].state === 'activated') {
        setLoading(false);
        setStep(2);
        setStatus('success');
        stopListeningActivation();
      }
      if (stationActivation[0].state === 'failed') {
        setLoading(false);
        setStep(2);
        setStatus('error');
      }
    }
  }, [stationActivation]);

  const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const handleCloseDrawer = () => {
    togglePixDrawer(false)();
  }

  const togglePixDrawer = state => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

    setPixDrawerOpen(state);
  }

  const handleClose = () => {
    history.goBack();
  }

  const handleLeft = () => {
    if (step === 2) {
      setStatus('');
    }

    if (step > 0) {
      setStep(step - 1);
    }
  }

  const handleContinue = () => {
    if (step < 2) {
      setStep(step + 1);
    }
    else {
      handleClose();
    }
  }

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  const pageVariants = {
    visible: { opacity: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  return (
    <Container
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
      height={size.height}
    >
      <Header hide={status}>
        <Row>
          <HeaderTitle>{ t('stationActivation.headerTitle') }</HeaderTitle>
          <ButtonClose onClick={handleClose}>
            <Icon
              icon={roundClose}
              width={24}
              height={24}
              color={theme.text.secondary}
            />
          </ButtonClose>
        </Row>
        <Indicator step={step} />
      </Header>

      <Overlay
        className={loading ? 'show' : ''}
      >
        <Spinner>
          <CircleLoader cx="40" cy="40" r="20" stroke={theme.colors.primary} fill="none" />
        </Spinner>
        <Text>
          {
            Array.from(t('stationActivation.onActivation')).map((char, index) => (
              <Char
                key={index}
                index={index}
              >
                {char}
              </Char>
            ))
          }
        </Text>
      </Overlay>

      <Pages>
        <AnimatePresence exitBeforeEnter={true}>
          {
            step === 0 && (
              <Page
                variants={pageVariants}
                initial='hidden'
                animate='visible'
                exit='hidden'
              >
                <PageTitle>{ t('stationActivation.pageTitle') }</PageTitle>
                <MIPlayerThumb bg={tutorial} />

                <HelpButton>
                  <InlineIcon
                    icon={roundHelpOutline}
                    width={18}
                    height={18}
                    color={theme.text.secondary}
                  />
                  <HelpButtonText onClick={() => setHelpOpen(true)}>{ t('stationActivation.helpButton') }</HelpButtonText>
                </HelpButton>

                <Dialog
                  open={helpOpen}
                  onClose={() => setHelpOpen(false)}
                  scroll='paper'
                  aria-labelledby='scroll-dialog-title'
                  aria-describedby='scroll-dialog-description'
                >
                  <DialogTitle id='scroll-dialog-title'>{ t('stationActivation.dialogTitle') }</DialogTitle>
                  <DialogContent dividers>
                    <DialogContentText
                      id='scroll-dialog-description'
                      ref={helpContentRef}
                      tabIndex={-1}
                    >

                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setHelpOpen(false)} color="primary">
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </Page>
            )
          }

          {
            step === 1 && (
              <Page
                variants={pageVariants}
                initial='hidden'
                animate='visible'
                exit='hidden'
              >
                <PageTitle>{ t('stationActivation.pin') }</PageTitle>
                <PINContainer className='noSelect'>
                  {
                    pin.map((char, index) => (
                      <PINInput
                        key={index}
                        readOnly
                        value={char}
                      />
                    ))
                  }
                </PINContainer>
              </Page>
            )
          }

          {
            step === 2 && (
              <Page
                variants={pageVariants}
                initial='hidden'
                animate='visible'
                exit='hidden'
                className='center'
              >
                <PageTitle>{status === 'success' ? t('stationActivation.success') : t('stationActivation.failure')}</PageTitle>
                <Lottie
                  options={status === 'success' ? successOptions : errorOptions}
                  isClickToPauseDisabled={true}
                  width={status === 'error' ? 200 : 280}
                  height={status === 'error' ? 200 : 280}
                />
                <FeedbackButton
                  onClick={status === 'success' ? handleClose : handleLeft}
                >
                  { status === 'success' ? t('form.loadStation.retry') : t('form.addStation.retry') }
                </FeedbackButton>
              </Page>
            )
          }
        </AnimatePresence>
      </Pages>

      <Footer>
        <LeftButton
          disabled={step !== 1}
          onClick={handleLeft}
        >
          { t('form.addStation.button.back') }
        </LeftButton>
        <RightButton
          onClick={handleContinue}
          disabled={step !== 0 || userPix === undefined || !userPix.data || userPix.data?.data?.length === 0 || userPix.loading }
        >
          { t('form.addStation.button.continue') }
        </RightButton>
      </Footer>
      <ManagePixKeys pixDrawerOpen={pixDrawerOpen} handleCloseDrawer={handleCloseDrawer} setPixDrawerOpen={setPixDrawerOpen} activation={true} />
    </Container>
  );
}

const mapStateToProps = state => ({
  stationActivation: state.StationsReducer.stationActivation,
  userPix: state.PaymentsReducer.userPix,
  addPix: state.PaymentsReducer.addPix,
  deletePix: state.PaymentsReducer.deletePix,
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token,
});

export default connect(mapStateToProps, { listenStationActivation, stopListeningActivation, getUserPix, clearManagePix })(StationActivation);
