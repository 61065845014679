import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { ButtonBase } from '@material-ui/core';
import { rgba } from 'polished';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  z-index: 200;
  padding: 20px;

  @media screen and (min-width: 1024px) {
    position: relative;
    width: 640px;
    height: calc(${props => props.height}px - 140px);
    min-height: 600px;
    border-radius: 15px;
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 0 20px;
  display: ${props => props.hide ? 'none' : 'block'};

  @media screen and (min-width: 1024px) {
    position: unset;
    padding: 0px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.p`
  color: ${props => props.theme.text.secondary};
  font-weight: 700;
`;

export const ButtonClose = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  pointer-events: auto;
  transform: translateX(10px) !important;
`;

export const Indicator = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  margin-top: 3px;
  background: ${props => props.theme.separator};
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => `calc(50% * ${props.step})`};
    transition: all .15s ease-out;
    height: 100%;
    background: ${props => props.theme.iconTintColor.inactive};
  }
`;

export const Pages = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px 0;
  display: flex;
  justify-content: center;
`;

export const Page = styled(motion.div)`
  width: 100%;
  height: 100%;

  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const PageTitle = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 30px;
  white-space: pre-line;
`;

export const MIPlayerThumb = styled.div`
  width: 250px;
  height: 250px;
  border-radius: 5px;
  background: ${props => `url(${props.bg})`};
  background-position: center;
  background-size: cover;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px 20px;

  @media screen and (min-width: 1024px) {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

export const LeftButton = styled(ButtonBase)`
  flex: 1;
  font-weight: 700 !important;
  color: ${props => props.theme.text.primary} !important;
  border: 1px solid ${props => props.theme.separator} !important;
  margin-right: 15px !important;
  border-radius: 5px !important;
  height: 40px;
  background: ${props => props.theme.colors.secondary} !important;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const RightButton = styled(ButtonBase)`
  flex: 1;
  font-weight: 700 !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const HelpButton = styled(ButtonBase)`
  background: transparent !important;
  padding: 0 !important;
  border-radius: 5px !important;
  height: 30px;
  margin-top: 10px !important;
`;

export const HelpButtonText = styled.p`
  color: ${props => props.theme.text.secondary};
  margin-left: 5px;
`;

export const PINContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PINInput = styled.input`
  text-align: center;
  font-size: 2.0rem;
  padding: 0;
  flex: 1;
  height: 60px;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
  border: none;
  border-radius: 5px;
  color: ${props => props.theme.text.primary};
  transition: all .15s ease;
  font-weight: 700 !important;
  max-width: 23%;

  &:first-of-type {
    margin-right: 5px;
  }

  &:nth-of-type(2),
  &:nth-of-type(3) {
    margin: 0 5px;
  }

  &:last-of-type {
    margin-left: 5px;
  }
`;

export const FeedbackButton = styled(ButtonBase)`
  font-weight: 700 !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  padding: 0 30px !important;
  margin-top: 30px !important;
`;

// Spinner keyframes and elements
const svgRotate = keyframes`
  0% {
		transform: rotateZ(0deg);
  }
  100% {
		transform: rotateZ(360deg);
  }
`;

const spinCircle = keyframes`
  0%, 25% {
		stroke-dashoffset: 55;
		transform: rotate(0deg);
  }
	50%, 75% {
		stroke-dashoffset: 25;
		transform: rotate(45deg);
  }
	100% {
		stroke-dashoffset: 55;
		transform: rotate(360deg);
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => rgba(props.theme.colors.secondary, .8)};
  z-index: 205;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(.95);
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  pointer-events: none;

  &.show {
    opacity: 1;
    transform: scale(1);
    pointer-events: auto;
  }
`;

export const Spinner = styled.svg`
  width: 80px;
  height: 80px;
  animation: ${svgRotate} 2s linear infinite;
`;

export const CircleLoader = styled.circle`
  stroke-width: 5;
  stroke-linecap: round;
  stroke-dashoffset: 55;
  stroke-dasharray: 63;
  transform-origin: 50% 50%;
  animation: ${spinCircle} 1.4s cubic-bezier(.785,.135,.15,.86) infinite both;
`;

// Text loader

const letterAnimation = keyframes`
  0% {
    transform: translateY(10px);
  }
  80%, 100% {
    transform: translateY(0);
  }
`;

export const Text = styled.p`
  font-weight: 700;
  color: ${props => props.theme.text.primary};
  display: block;
  text-align: center;
`;

export const Char = styled.span`
  display: inline-block;
  min-width: 5px;
  transform: translateY(10px);
  animation: ${letterAnimation} 1s cubic-bezier(.785,.135,.15,.86) alternate infinite;
  animation-delay: ${props => (props.index + 1) * .05}s;
`;
