import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ButtonBase, Drawer } from '@material-ui/core';

export const Container = styled(motion.div)`
  position: ${props => props.fromIndex ? 'unset' : 'absolute'};
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.fromIndex ? 'calc(100% - 60px)' : '100%'};
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  z-index: 200;
  padding-top: ${props => props.searchVisible ? '120px' : '60px'};
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  overflow-y: scroll;

  @media screen and (min-width: 1024px) {
    padding-top: 0;
    position: relative;
    border-radius: 15px;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
    }

    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.colors.primary ? props.theme.colors.background : props.theme.text.secondary};
      border-radius: 2px;
    }
  }
`;

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 205;
  pointer-events: none;
  display: flex;
  flex-direction: column-reverse;
  transform: ${props => props.searchVisible ? 'translateY(0)' : 'translateY(-50%)'};
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
  background: ${props => props.theme.colors.background};

  @media screen and (min-width: 1024px) {
    transform: none;
    position: unset;
    padding: 0px;
  }
`;

export const Row = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.colors.background};
  pointer-events: auto;
  z-index: 210;
  padding: 0 10px;
`;

export const ButtonBack = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
`;

export const SearchForm = styled.form`
  flex: 1;
`;

export const SearchInput = styled.input`
  border: none;
  background: transparent;
  color: ${props => props.theme.text.primary};
  height: 100%;
  margin: 0 10px 0 5px;
`;

export const ButtonSearch = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
`;

export const Tabs = styled.div`
  width: 100%;
  height: 60px;
  background: ${props => props.theme.colors.background};
  display: flex;
  align-items: center;
  z-index: 205;
`;

export const Tab = styled(ButtonBase)`
  color: ${props => props.active ? props.theme.text.primary : props.theme.text.secondary} !important;
  height: 100% !important;
  border-bottom: 1px solid transparent !important;
  border-color: ${props => props.active ? props.theme.text.primary : 'transparent'} !important;
  transition: all .15s ease !important;
  pointer-events: auto;
  padding: 0 20px !important;
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;

export const VoiceSearchDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background} !important;

    @media screen and (min-width: 1024px) {
      height: 250px !important;
    }
  }
`;

export const ButtonCloseDrawer = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  margin: 15px 0 30px 10px !important;
`;

export const ButtonDrawerAction = styled(ButtonBase)`
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  background: ${props => props.listening ? (props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary) : props.theme.colors.error} !important;
  transition: all .15s ease;
  transform: ${props => props.listening ? 'scale(.95)' : 'scale(1)'} !important;
  margin-top: auto !important;
  margin-bottom: 30px !important;
  align-self: center !important;
`;

export const DrawerSubtitle = styled.p`
  font-size: 1.125rem;
  color: ${props => props.theme.text.secondary};
  text-align: left;
  margin: 0 20px;
  margin-bottom: 10px;
`;

export const Transcript = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.text.primary};
  margin: 0 20px;
  text-align: left;
`;

export const StationsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  @media screen and (min-width: 1024px) {
    padding: 20px 0px 40px 0px;
  }
`;

export const StationsListHeader = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin: 0 0 10px 20px;
`;

export const ButtonEnableMultiselect = styled(ButtonBase)`
  position: fixed !important;
  bottom: 30px !important;
  right: 20px !important;
  width: 50px !important;
  height: 50px;
  border-radius: 50% !important;
  background: ${props => props.theme.colors.primary} !important;
  transform: ${props => props.hide ? 'scale(.8)' : 'scale(1)'} !important;
  opacity: ${props => props.hide ? 0 : 1} !important;
  pointer-events: ${props => props.hide ? 'none' : 'auto'} !important;
  transition: all .3s cubic-bezier(.785,.135,.15,.86) !important;

  @media screen and (min-width: 1024px) {
    position: fixed !important;
    bottom: 12% !important;
    right: 30% !important;
  }
`;

export const ButtonDeskDisableMultiselect = styled(ButtonBase)`
  @media screen and (min-width: 1024px) {
    position: fixed !important;
    bottom: 18% !important;
    right: 30% !important;
    width: 30px !important;
    height: 30px;
    margin-right: 10px !important;
    border-radius: 50% !important;
    background: ${props => props.theme.colors.error} !important;
    transform: ${props => props.hide ? 'scale(.8)' : 'scale(1)'} !important;
    opacity: ${props => props.hide ? 0 : 1} !important;
    pointer-events: ${props => props.hide ? 'none' : 'auto'} !important;
    transition: all .3s cubic-bezier(.785,.135,.15,.86) !important;
  }
`;

export const SelectedStationsBottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${props => props.theme.colors.background};
  padding: 0 10px;
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  transform: ${props => props.show ? 'translateY(0)' : 'translateY(100%)'};
`;

export const SelectedStationsCount = styled.p`
  color: ${props => props.inactive ? props.theme.text.secondary : props.theme.text.primary};
  margin-left: 10px;
`;

export const ButtonCloseBottomBar = styled(ButtonCloseDrawer)`
  margin: 0 !important;
`;

export const SelectedStationsAction = styled(ButtonBase)`
  margin-left: auto !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: transparent;
`;

export const LoadingProgress = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 8%;
  height: 50px;
`;

export const EndOfResults = styled.p`
  text-align: center;
  color: ${props => props.theme.text.primary};
  font-weight: bold;
`;
