import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    scrollbar-width: thin !important;
    scrollbar-color: ${props => props.theme.colors.background} ${props => props.theme.colors.primary};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
    font-family: 'Quicksand', Arial, Helvetica, sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  body::-webkit-scrollbar {
    width: 3px;
  }

  body::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.background};
  }

  body::-webkit-scrollbar-thumb {
    background: ${props => props.theme.text.secondary};
  }

  #root {
    width: 100%;
    height: 100%;
    background: ${props => props.theme.colors.secondary};
    transition: all .15s ease;
    position: relative;
    overflow: hidden !important;

    @media screen and (min-width: 1024px) {
      background: ${props => props.theme.title === 'light' ?  props.theme.colors.background : props.theme.colors.secondary};

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 85px;
        background: ${props => props.theme.colors.primary};
        transition: all .15s ease;
      }
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.text.primary};
  }

  img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    font-family: 'Quicksand', Arial, Helvetica, sans-serif !important;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  input, button, textarea {
    font-family: 'Quicksand', Arial, Helvetica, sans-serif !important;
    font-weight: 400 !important;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  }

  button {
    background: transparent;
    border: none;
    font-size: 1rem;

    &:focus {
      outline: none;
    }
  }

  .noSelect {
    user-drag: none !important;
    -webkit-user-drag: none !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    -webkit-tap-highlight-color: transparent !important;
  }

  .search {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 400px;
    z-index: 10;
  }

  .gmnoprint a, .gmnoprint span {
    display:none;
  }
  .gmnoprint div {
      background:none !important;
  }
  img[src="http://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"], a[href^="http://maps.google.com/maps"]{
    display:none !important;
  }

  .doka-image-editor {
    --color-background: ${props => props.theme.doka.background};
    --color-foreground: ${props => props.theme.doka.foreground};
  }
`;
