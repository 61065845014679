import React, { useState } from 'react';

import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import AdItem from '../../components/AdItem';
import useWindowSize from '../../utils/hooks/useWindowSize';

import { Container, List, Inputs } from './styles';

const ListAds = ({ activeAds }) => {

  const { t } = useTranslation();
  const size = useWindowSize();
  const [ sort, setSort ] = useState('+id');
  const [ items, setItems ] = useState(activeAds);

  const handleChangeSort = (evt) => {
    setSort(evt.target.value);

    switch (evt.target.value) {
      case '+id':
        setItems(_.orderBy(items, [function(o) { return o.attributes['expiration-date'] }], ['desc']));
        break;
      case '-id':
        setItems(_.orderBy(items, [function(o) { return o.attributes['expiration-date'] }], ['asc']));
        break;
      default:
        setItems(_.orderBy(items, [function(o) { return o.attributes['expiration-date'] }], ['desc']));
        break;
    }
  }


  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  return (
    <Container
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
      width={size.width}
      height={size.height}
    >
      <List>
        <Inputs>
          <FormControl variant="filled" style={{ marginRight: 15 }}>
            <InputLabel id='sort-label'>{ t('listStations.orderBy') }</InputLabel>
            <Select
              labelId='sort-label'
              id='sort-select'
              value={sort}
              onChange={handleChangeSort}
              style={{
                minWidth: '180px'
              }}
            >
              <MenuItem value='+id'>{ t('listStations.orderBy.recent') }</MenuItem>
              <MenuItem value='-id'>{ t('listStations.orderBy.older') }</MenuItem>
            </Select>
          </FormControl>
        </Inputs>
        {
          items.map(ad => (
            <AdItem
              key={ad.id}
              ad={ad}
            />
          ))
        }
      </List>
    </Container>
  )
}

export default ListAds;
