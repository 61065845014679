import React, { useState, useEffect, useRef, useContext } from 'react';

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { ThemeContext } from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Backdrop,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useParams, useRouteMatch, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';

import useWindowSize from '../../utils/hooks/useWindowSize';
import { manageStationImages, sendThumbToStation, updateThumbStation, getStation } from '../../store/actions/StationsActions';
import { asyncGetUserCompanies } from '../../store/actions/CompaniesActions';

import UploadFeedback from '../../components/UploadFeedback';
import ManageStationImgsFeedback from '../../components/ManageStationImgsFeedback';
import FilePicker from '../../components/FilePicker';
import ImageCrop from '../../components/ImageCrop';
import successAnimation from '../../assets/lotties/success.json';
import errorAnimation from '../../assets/lotties/error-face.json';

import {
  Container,
  Header,
  Row,
  Title,
  BtnClose,
  Indicator,
  Footer,
  LeftButton,
  RightButton,
  Pages,
  Page,
  PageTitle,
  BtnFeedback,
  AlertBtnExit,
  AlertBtnConfirm,
  ThumbsSlider,
  ThumbsInner,
  Thumb} from './styles';

const ManageStationImgs = ({ asyncGetUserCompanies, email, token, userCompaniesData, manageStationImages, manageStationImagesProcess, operationToken, sendThumbToStation, thumbStation, updateThumbStation, getStation, viewStation }) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const termsContentRef = useRef();
  const thumbsSliderRef = useRef();
  const size = useWindowSize();

  const [ cropState, setCropState ] = useState('select');
  const [ thumbsSliderWidth, setThumbsSliderWidth ] = useState(360);
  const [ alertOpen, setAlertOpen ] = useState(false);

  const [ stationThumb, setStationThumb ] = useState(''); // avatar da estação
  const [ stationImgs, setStationImgs ] = useState([]); // imagens da estação
  const [ imgsToDelete, setImgsToDelete ] = useState([]); // imagens da estação
  const [ usedImages, setUsedImages ] = useState([]); // already used images
  const [ stationId, setStationId ] = useState('');
  const [ existingAvatar, setExistingAvatar ] = useState('');

  let match = useRouteMatch('/manage-station-images/:step');
  if ((match !== null) && (!params.hasOwnProperty('step'))) {
    params.step = match.params.step;
  }

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  }


  useEffect(() => {

    if (location.hasOwnProperty('stImages')) {
      const stImgsFormat = location.stImages.map(function(image) {   
        return {id: image.id, mime_type: image.mime_type, url: image.storage_ref, exists: true}
      });
      setStationImgs([...stImgsFormat]);
      setStationId(location.stId);
      setExistingAvatar(location.avatar);
    } else {
      history.push('/');
    }

    document.addEventListener('keydown', handleKeyDown);
    const credentials = { email, token }
    asyncGetUserCompanies(credentials, userCompaniesData);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  useEffect(() => {
    const imgsToSend = _.filter(stationImgs, function(o){ return o.exists !== true; });
    
    if ((imgsToDelete.length || imgsToSend.length) && !stationThumb) {
      if (manageStationImagesProcess.status === 'success') {
        history.push('/manage-station-images/success');
        getStation({ email, token }, stationId, viewStation);
      } else if (manageStationImagesProcess.status === 'sending_error' || manageStationImagesProcess.status === 'deleting_error') {
        getStation({ email, token }, stationId, viewStation);
        history.push('/manage-station-images/error');
      }
    }
    else if ((!imgsToDelete.length && !imgsToSend.length) && stationThumb) {
      if (thumbStation.sent) {
        history.push('/manage-station-images/success');
        getStation({ email, token }, stationId, viewStation);
      }
    }
    else if ((imgsToDelete.length || imgsToSend.length) && stationThumb) {
      if (manageStationImagesProcess.status === 'success' && thumbStation.sent) {
        history.push('/manage-station-images/success');
        getStation({ email, token }, stationId, viewStation);
      } else if ((manageStationImagesProcess.status === 'sending_error' || manageStationImagesProcess.status === 'deleting_error') && !thumbStation.sent) {
        getStation({ email, token }, stationId, viewStation);
        history.push('/manage-station-images/error');
      } else if ((manageStationImagesProcess.status === 'sending_error' || manageStationImagesProcess.status === 'deleting_error') && thumbStation.sent) {
        history.push('/manage-station-images/success');
        getStation({ email, token }, stationId, viewStation);
      }
    }
  }, [operationToken, thumbStation]);

  useEffect(() => {
    let usedImagesArray = [];
    userCompaniesData.map((item) => {
      item.attributes.stations.map((item) => {
        item['image-station'].map((item) => {
          item['storage-ref'] = item['storage-ref'].split('?')[0];
          usedImagesArray = [ item, ...usedImagesArray ];
        });
      });
    });

    setUsedImages(usedImagesArray);

  }, [userCompaniesData]);

  const handleKeyDown = evt => {
    if (evt.key === 'Escape') {
      history.goBack();
    }
  }

  const handleClose = () => {
    history.push(`/station/${stationId}`);
  }

  useEffect(() => {
    if (size.width < 1024 && thumbsSliderRef.current) {
      setThumbsSliderWidth(thumbsSliderRef.current.offsetWidth);
    }
  }, [size.width, stationImgs.length]);

  const handleBack = () => {
    if (params.step === '1') {
      if (cropState === 'crop') {
        setCropState('select');
        setStationThumb(null);
      }
      else {
        history.push(`/manage-station-images/${parseInt(params.step) - 1}`);
        setStationThumb(null);
      }
    } else {
      history.push(`/manage-station-images/${parseInt(params.step) - 1}`);
    }
  }

  const handleContinue = () => {
    if (params.step < 1) {
      history.push(`/manage-station-images/${parseInt(params.step) + 1}`);
    }
    else if (params.step === '1') {
      const imgsToSend = _.filter(stationImgs, function(o){ return o.exists !== true; });
      if (stationThumb || imgsToDelete.length || imgsToSend.length) {
        if (stationThumb) {
          const thumbStationData = {
            ...stationThumb,
            stationId
          };

          sendThumbToStation({ email, token }, thumbStationData);
        }
        if (imgsToDelete.length || imgsToSend.length) {
          const manageStationImagesData = {
            imgsToDelete,
            imgsToSend,
            stationId
          };
          manageStationImages({ email, token }, manageStationImagesData);
        }
      } else {
        handleClose();
      }
    }
  }

  // Função passada ao file picker, que é adicionado um item (metadata) array de imagens da estação
  // Quando o checkbox da imagem em questão é checked no interior do file picker é chamada esta função
  // para então adicionar o novo item no vetor
  const handleStationImages = (operation, metaData) => {
      if (operation === 'add') {
        // novo array com spread do antigo mais o novo item (apenas 1) a ser adicionado
        let newStationImgs = [ ...stationImgs, metaData];
        // filtragem para não repetir o mesmo arquivo, usando como identificador único a url da imagem
        // porém é necessário realizar o split pois a url do arquivo não é única após o "?" como a seguir
        // "localizacao_unica_do_arquivo?alt=media&token=e6a39046-ca5d-4057-8681-533c1c9ffe47"
        // "localizacao_unica_do_arquivo?alt=media&token=36ae32ff-cd72-45ec-a570-37b4623ca9d2"
        // veja que após o "?" a informação pode mudar, portanto o uso do split
    
        setStationImgs([ ...newStationImgs]);
      } else if (operation === 'add-multiple') {
        // novo array com spread do antigo mais o spread do array das múltiplas imagens a serem adicionadas
        let newStationImgs = [ ...stationImgs, ...metaData];
  
        setStationImgs([ ...newStationImgs]);
      } else {
        if (metaData.hasOwnProperty('exists')) {
          setImgsToDelete([ ...imgsToDelete, metaData ]);
          _.remove(stationImgs, function(item) { return (item.id === metaData.id && item.hasOwnProperty('exists') === metaData.hasOwnProperty('exists')); });
        } else {
          // remoção das imagens selecionadas, usando o método remove do lodash, encontrando um item que possui a url
          // igual à url dos arquivos da galeria (usada para selecionar ou remover seleção ou excluir)
          _.remove(stationImgs, function(item) { return (item.url.split('?')[0] === metaData.url.split('?')[0] && item.hasOwnProperty('exists') === metaData.hasOwnProperty('exists')); });
        }

        setStationImgs([ ...stationImgs ]);
      }
  }


  const handleAlertClose = () => {
    setAlertOpen(false);
  }


  const handleCancelUpload = () => {
    handleAlertClose();
    history.push()
  }

  const handleRemoveFromArray = (img) => {
    setTimeout(() => {
      let status = window.confirm(`${t('form.addStation.images.remove')}`);

      if (status) {
        handleStationImages('remove', img);
      }
    }, 150);
  }

  const mobileVariants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  const desktopVariants = {
    initial: { opacity: 0, scale: .95, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }



  return (
    <Container
      variants={size.width < 1024 ? mobileVariants : desktopVariants}
      initial='initial'
      animate='visible'
      exit='hidden'
      width={size.width}
      height={size.height}
    >
      <Header>
        <Row>
          <Title>{ (cropState === 'crop') ? 'Atualizar imagens e capa do painel' : 'Atualizar imagens do painel' }</Title>
          <BtnClose size='small' onClick={handleClose}>
            <Icon
              path={mdiClose}
              size='24px'
            />
          </BtnClose>
        </Row>
        <Indicator step={params.step} sentThumb={thumbStation.sent} />
      </Header>

      <Pages>
        {
          params.step === '0' && (
            <Page>
              <PageTitle>{(stationImgs.length > 0) ? `${stationImgs.length} ${(stationImgs.length > 1) ? `${t('form.addStation.images.selected.multiple')}` : `${t('form.addStation.images.selected.single')}`}` : 'Nenhuma imagem adicional'}</PageTitle>
              {
                (stationImgs.length > 0) && (
                  <ThumbsSlider>
                    <ThumbsInner
                      ref={thumbsSliderRef}
                      drag='x'
                      dragElastic={0.2}
                      dragDirectionLock={true}
                      dragMomentum={true}
                      dragConstraints={{ left: thumbsSliderWidth > size.width ? -thumbsSliderWidth + size.width : 0, right: 0 }}
                      numberOfChildren={stationImgs.length}
                    >
                      {
                        stationImgs.map((img, index) => (
                          <Thumb
                            key={index}
                            bg={img.url}
                            onClick={() => handleRemoveFromArray(img)}
                          />
                        ))
                      }
                    </ThumbsInner>
                  </ThumbsSlider>
                )
              }

              <FilePicker
                folder={'station'}
                parentImagesHandling={handleStationImages}
                parentUsedImages={usedImages}
                showGallery={true}
                showPicker={true}
                showTakePhoto={true}
              />
              {/* Componente para visualização de andamento de uploads */}
              <UploadFeedback
                folder={'station'}
              />
            </Page>
          )
        }

        {
          params.step === '1' && (
            <Page>
              <PageTitle>{ cropState === 'crop' ? 'Salve as alterações com o botão amarelo e finalize' : 'Deseja alterar a imagem principal do painel? Se sim, selecione uma imagem, caso contrário vá em finalizar.'}</PageTitle>
              <ImageCrop
                imgs={stationImgs}
                cropState={cropState}
                setCropState={setCropState}
                setStationThumb={setStationThumb}
                avatar={existingAvatar}
              />
              <ManageStationImgsFeedback />
            </Page>
          )
        }
        {
          params.step === 'success' && (
            <Page>
              <PageTitle style={{ textAlign: 'center' }}> Painel atualizado com sucesso. </PageTitle>
              <Lottie
                options={successOptions}
                isClickToPauseDisabled={true}
                width={280}
                height={251}
                style={{ marginTop: -20 }}
              />
              <BtnFeedback onClick={handleClose}>{t('form.addStation.access')}</BtnFeedback>
              <ManageStationImgsFeedback />
            </Page>
          )
        }
        {
          params.step === 'error' && (
            <Page>
              <PageTitle style={{ textAlign: 'center' }}>
                Houve algum erro ao enviar uma imagem específica. Por favor, verifique o que faltou e tente novamente.
              </PageTitle>
              <Lottie
                options={errorOptions}
                isClickToPauseDisabled={true}
                width={180}
                height={180}
                style={{ margin: '15px auto' }}
              />
              <BtnFeedback onClick={handleClose}>{t('form.addStation.access')}</BtnFeedback>
              <ManageStationImgsFeedback />
            </Page>
          )
        }
      </Pages>
      {
        params.step !== 'success' && params.step !== 'error' && (
          <Footer>
            <LeftButton disabled={params.step === '0'} onClick={handleBack}>{(cropState === 'crop' && params.step === '5') ? t('imagecrop.chooseAnother') : t('form.addStation.button.back')}</LeftButton>
            <RightButton disabled={manageStationImagesProcess.status !== 'stopped' || (cropState === 'crop' && !stationThumb)} onClick={handleContinue}>{(params.step < 1) ? t('form.addStation.button.continue') : t('form.addStation.button.finish')}</RightButton>
          </Footer>
        )
      }

      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText>{ (stationImgs.length > 1) ? t('form.addStation.images.cancel.multiple') : t('form.addStation.images.cancel.single') }</DialogContentText>
        </DialogContent>
        <DialogActions>
          <AlertBtnExit onClick={handleAlertClose}>
            Cancelar
          </AlertBtnExit>
          <AlertBtnConfirm onClick={handleCancelUpload}>
            Sim
          </AlertBtnConfirm>
        </DialogActions>
      </Dialog>
      <Snackbar
        key={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        color='primary'
        open={thumbStation.sent}
        autoHideDuration={4000}
        onClose={() => updateThumbStation({ loading: false, error: false, sent: false })}
        onExit={() => updateThumbStation({ loading: false, error: false, sent: false })}
      >
        <SnackbarContent
          style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
          message='A capa do painel foi alterado'
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => updateThumbStation({ loading: false, error: false, sent: false })}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
      { /* BACKDROP PARA SOBREPOR A TELA ENQUANTO ESTIVER ENVIANDO O ANUNCIO PARA VÁRIOS PAINEIS */}
      <Backdrop style={{ zIndex: 999, color: theme.colors.primary }} open={thumbStation.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

const mapStateToProps = state => ({
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token,
  userCompaniesData: state.CompaniesReducer.userCompanies.data,
  manageStationImagesProcess: state.StationsReducer.manageStationImages,
  operationToken: state.StationsReducer.operationToken,
  thumbStation: state.StationsReducer.thumbStation,
  viewStation: state.StationsReducer.viewStation
});

export default connect(mapStateToProps, { asyncGetUserCompanies, manageStationImages, sendThumbToStation, updateThumbStation, getStation })(ManageStationImgs);
