import './styles/fonts.css';

import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import MainApplication from './MainApplication';
import Initial from './pages/Initial';

const RootView = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLoggedIn = value => setLoggedIn(value);

  if (loggedIn) {
    return (
      <Router>
        <MainApplication />
      </Router>
    );
  }
  return (
    <Router>
      <Route
        path="/:auth?/:email?/:token?" exact
        render={props =>
          <Initial
            {...props}
            handleLoggedIn={handleLoggedIn}
          />
        }
      />
    </Router>
  );
}

export default function App() {
  return (
    <Suspense fallback="loading">
      <RootView />
    </Suspense>
  );
}
