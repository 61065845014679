import React, { useState, useEffect, useRef } from 'react';

import { Container, Overlay, Menu, SubMenu, Root } from './styles';

function ContextMenu ({ children, show = false, showSubMenu = false, onClose, hasSubMenu }) {
  const [ _show, set_show ] = useState(show);
  const [ _showSubMenu, set_showSubMenu ] = useState(showSubMenu);
  const [ currHeight, setCurrHeight ] = useState(200);

  const rootRef = useRef();
  const subMenuRef = useRef();

  useEffect(() => {
    document.addEventListener('keydown', evt => {
      if ((evt.key === 'Escape' || evt.key === 'Esc') && !_show) {
        closeMenu();
      }
    });

    setCurrHeight(rootRef.current.offsetHeight);
  }, [hasSubMenu, _show]);

  useEffect(() => {
    if (show) {
      set_show(true);
    }
    else {
      set_show(false);
    }

    if (showSubMenu) {
      set_showSubMenu(true);

      setCurrHeight(subMenuRef.current.offsetHeight);
    }
    else {
      set_showSubMenu(false);

      setCurrHeight(rootRef.current.offsetHeight);
    }
  }, [show, showSubMenu]);

  function closeMenu () {
    set_show(false);
    set_showSubMenu(false);

    if (onClose) onClose();
  }

  function getComponent (key) {
    return children.filter(comp => {
      return comp.key === key;
    });
  }

  return (
    <Container>
      <Overlay
        className={_show ? 'context-menu-overlay show' : 'context-menu'}
        onClick={closeMenu}
      />
      <Menu className={_show ? 'context-menu show' : 'context-menu'} height={currHeight}>
        <Root ref={rootRef} showSubMenu={_showSubMenu}>
          { getComponent('root') }
        </Root>
        {
          hasSubMenu && (
            <SubMenu className={_showSubMenu ? 'show' : ''} ref={subMenuRef}>
              { getComponent('submenu') }
            </SubMenu>
          )
        }
      </Menu>
    </Container>
  );
}

export default ContextMenu;

export { SubMenu, Root, Option, OptionLabel } from './styles';