import React, { useState, useContext, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { Icon } from '@iconify/react';
import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import roundPhotoCamera from '@iconify/icons-ic/round-photo-camera';
import roundCreditCard from '@iconify/icons-ic/round-credit-card';

import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { TextField, Snackbar, SnackbarContent, Menu, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import Card from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,

} from '../../utils/helpers/creditCardHelpers';
import useStateWithCallback from '../../utils/hooks/useStateWithCallback';
import { SERVER_ADDRESS } from '../../constraints/types';

import { Container, Header, BackButton, HeaderTitle, GhostButton, Content, ProfileInfo, ProfilePictureContainer, ProfileName, ButtonChangePicture, Button, ButtonText, PixIcon, Heading, CustomDrawer, DrawerHeading, DrawerSubheading, EmptyContainer, EmptyIllustration, EmptyMessage, DrawerFooter, DrawerActionButton, DrawerMain, DrawerFormAdd, DrawerList, SnackbarUndoButton, CardForm, CardFormRow, CardContainer } from './styles';
import ManagePixKeys from '../../components/ManagePixKeys';

const Profile = ({ user }) => {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const formRef = useRef();
  const [ pixDrawerOpen, setPixDrawerOpen ] = useState(false);
  const [ cardDrawerOpen, setCardDrawerOpen ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ addingData, setAddingData ] = useState(false);
  const [ editingCardIndex, setEditingCardIndex ] = useState(-1);
  const [ selectedCard, setSelectedCard ] = useState(null);
  const [ editing, setEditing ] = useState(false);
  const [ cardData, setCardData ] = useState({
    cvc: '',
    expiry: '',
    focused: '',
    name: '',
    number: '',
    issuer: '',
  })
  const [ allCards, setAllCards ] = useState([
    {
      cvc: 777,
      expiry: '10/27',
      name: user.attributes.name,
      number: '•••• •••• •••• 5214',
      focused: '',
      issuer: 'mastercard',
      preview: true
    },
    {
      cvc: 777,
      expiry: '10/27',
      name: user.attributes.name,
      number: '•••• •••• •••• 4545',
      focused: '',
      issuer: 'visa',
      preview: true
    },
    {
      cvc: 777,
      expiry: '10/27',
      name: user.attributes.name,
      number: '•••• •••• •••• 5214',
      focused: '',
      issuer: 'mastercard',
      preview: true
    },
  ]);

  const [ itemToDelete, setItemToDelete ] = useState(null);
  const [ snackPack, setSnackPack ] = useState([]);
  const [ snackOpen, setSnackOpen ] = useStateWithCallback(false);
  const [ snackMessage, setSnackMessage ] = useState(undefined);
  const [ allPix, setAllPix ] = useState([
    {
      name: 'email',
      type: 'text',
      label: 'E-mail',
      value: 'lemrall3@gmail.com'
    },
    {
      name: 'cel',
      type: 'text',
      label: 'Celular',
      value: '+5535987045044'
    },
    {
      name: 'random',
      type: 'text',
      label: 'Chave aleatória',
      value: 'asdadh1i90+5a4sadhasd'
    },
  ]);
  const [ pixData, setPixData ] = useState([
    {
      name: 'email',
      type: 'text',
      label: 'E-mail',
      value: ''
    },
    {
      name: 'cel',
      type: 'number',
      label: 'Celular',
      value: ''
    },
    {
      name: 'cpf',
      type: 'text',
      label: 'CPF',
      value: ''
    },
    {
      name: 'cnpj',
      type: 'text',
      label: 'CNPJ',
      value: ''
    },
    {
      name: 'random',
      type: 'text',
      label: 'Chave aleatória',
      value: ''
    }
  ]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: false,
    onDrop: () => {
    },
    onDropRejected: () => {
      alert('Apenas arquivos de imagem com extensão .png ou .jpg');
    }
  });

  const { name, 'avatar-medium': avatar } = user.attributes;

  useEffect(() => {
    if (snackPack.length && !snackMessage) {
      setSnackMessage({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setSnackOpen(true);
    }
    else if (snackPack.length && snackMessage && snackOpen) {
      setSnackOpen(false);
    }
  }, [snackPack, snackMessage, snackOpen, setSnackOpen]);

  const handleBackButton = () => {
    history.goBack();
  }

  const togglePixDrawer = state => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

    setPixDrawerOpen(state);
  }

  const toggleCardDrawer = state => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

    setCardDrawerOpen(state);
  }


  const handleDrawerLeftButton = () => {
    if (addingData) {
      setAddingData(false);
    }
    else {
      toggleCardDrawer(false)();
      togglePixDrawer(false)();
    }
  }

  const handleDrawerRightButton = () => {
    if (addingData) {
      if (pixData.some(item => item.value)) {
        const result = allPix.map(item => {
          return pixData.map(sub => {
            return item.value === sub.value;
          })
        });

        if (result.map(arr => arr.some(value => value)).some(value => value)) {
          alert('Pix já adicionado');

          return;
        }

        let toAdd = [];

        pixData.forEach((item) => {
          if (item.value.trim()) {
            toAdd.push(item);
          }
        });

        addPixItem(toAdd);

        setPixData(pixData.map(item => item.value ? { ...item, value: '' } : item));

        setAddingData(false);
      }
      else {
        const { focused, issuer, ...newObj } = cardData;

        if (Object.values(newObj).every(item => item.toString().trim().length > 0)) {
          ReactDOM.findDOMNode(formRef.current).dispatchEvent(new Event('submit'));
        }
      }
    }
    else {
      setAddingData(true);
    }
  }

  const openSnack = message => {
    setSnackPack((prev) => [ ...prev, { message, key: new Date().getTime() } ]);
  }

  const handleCloseDrawer = () => {
    setAddingData(false);
    toggleCardDrawer(false)();
    togglePixDrawer(false)();
  }

  const addPixItem = (pix) => {
    if (Array.isArray(pix) && Number.isInteger(pix[1])) {
      const curr = [ ...allPix ];

      curr.splice(pix[1], 0, pix[0]);

      setAllPix(curr);
    }
    else if (Array.isArray(pix) && typeof pix[1] === 'object') {
      setAllPix([...allPix, ...pix]);
      openSnack('Pix adicionado');
    }
    else {
      setAllPix([...allPix, pix[0]]);
      openSnack('Pix adicionado');
    }
  }


  const handleCloseSnack = (evt, reason) => {
    if (reason === 'clickaway') return;

    setSnackOpen(false);
    setItemToDelete(null);
  }

  const handleExited = () => {
    setSnackMessage(undefined);
  }

  const undoItemDelete = () => {
    if (itemToDelete[0].number) {
      if (Array.isArray(itemToDelete) && Number.isInteger(itemToDelete[1])) {
      const curr = [ ...allCards ];

      curr.splice(itemToDelete[1], 0, itemToDelete[0]);

      setAllCards(curr);
    }
    }
    else {
      addPixItem(itemToDelete);
    }

    handleCloseSnack();
  }

  const handleCardCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setCardData({
        ...cardData,
        issuer
      })
    }
  }

  const handleCardFormInputChange = ({ target }) => {
    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    }
    else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    }
    else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    setCardData({
      ...cardData,
      [target.name]: target.value
    });
  }

  const handleCardFormInputFocus = ({ target }) => {
    setCardData({
      ...cardData,
      focused: target.name
    })
  }

  const handleCardFormSubmit = evt => {
    if (evt) evt.preventDefault();

    if (editing && editingCardIndex >= 0) {
      setAllCards(allCards.map((item, index) => index === editingCardIndex ? cardData : item));

      setEditing(false);
      openSnack('Cartão editado');
    }
    else {
      setAllCards([ ...allCards, cardData ]);

      openSnack('Cartão adicionado');
    }

    formRef.current.reset();

    setAddingData(false);
    setCardData({
      cvc: '',
      expiry: '',
      focused: '',
      name: '',
      number: '',
      issuer: '',
    });
  }

  const handleOpenMenu = (evt, card) => {
    evt.preventDefault();
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    setSelectedCard(card);

    setAnchorEl(evt.currentTarget);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  }

  const handleEditCard = () => {
    const card = {...selectedCard};
    setAddingData(true);

    setEditing(true);
    setEditingCardIndex(allCards.findIndex(item => item.number === card.number));
    setCardData(card);

    handleCloseMenu();
  }

  const handleRemoveCard = () => {
    const curr = [ ...allCards ];
    const card = {...selectedCard};

    const foundIndex = curr.findIndex(item => item.number === card.number);

    setItemToDelete([ card, foundIndex ]);

    openSnack('Cartão removido');

    setAllCards(allCards.filter((item) => item.number !== card.number));

    handleCloseMenu();
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  return (
    <Container
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
    >
      <Header>
        <BackButton onClick={handleBackButton}>
          <Icon
            icon={roundArrowBack}
            width={24}
            height={24}
            color={theme.text.primary}
          />
        </BackButton>
        <HeaderTitle>Perfil</HeaderTitle>
        <GhostButton />
      </Header>

      <ProfileInfo>
        <ProfilePictureContainer avatar={`${SERVER_ADDRESS}${avatar}`}>
          <ButtonChangePicture {...getRootProps()}>
            <input {...getInputProps()} />
            <Icon
              icon={roundPhotoCamera}
              width={24}
              height={24}
              color='#fff'
            />
          </ButtonChangePicture>
        </ProfilePictureContainer>
        <ProfileName>{name}</ProfileName>
      </ProfileInfo>

      <Content>
        <Heading>Ações</Heading>
        <Button onClick={toggleCardDrawer(true)}>
          <Icon
            icon={roundCreditCard}
            width={24}
            height={24}
            color={theme.text.primary}
          />
          <ButtonText>Gerenciar cartões</ButtonText>
        </Button>
        <Button onClick={togglePixDrawer(true)}>
          <PixIcon src={require('../../assets/images/pix_icon.png')} />
          <ButtonText>Gerenciar Chaves Pix</ButtonText>
        </Button>
      </Content>

      <ManagePixKeys pixDrawerOpen={pixDrawerOpen} handleCloseDrawer={handleCloseDrawer} setPixDrawerOpen={setPixDrawerOpen} />

      <CustomDrawer
        anchor='right'
        open={cardDrawerOpen}
        onClose={handleCloseDrawer}
        className='noSelect'
      >
        <Snackbar
          key={snackMessage ? snackMessage.key : undefined}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          color='primary'
          open={snackOpen}
          autoHideDuration={itemToDelete ? 4000 : 1000}
          onClose={handleCloseSnack}
          onExit={handleExited}
        >
          <SnackbarContent
            style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
            message={snackMessage ? snackMessage.message : undefined}
            action={
              itemToDelete &&
              <SnackbarUndoButton onClick={undoItemDelete}>
                DESFAZER
              </SnackbarUndoButton>
            }
          />
        </Snackbar>

        <DrawerHeading>Seus cartões</DrawerHeading>
        <DrawerSubheading>{addingData ? 'Adicionando cartão' : 'Gerencie seus cartões de crédito que usa para os pagamentos no sistema'}</DrawerSubheading>

        <DrawerMain hide={addingData}>
          {
            allCards.length > 0 ? (
              <DrawerList>
                {
                  allCards.map((card, index) => (
                    <CardContainer
                      onClick={(evt) => handleOpenMenu(evt, card)}
                      onContextMenu={evt => evt.preventDefault()}
                      key={index}
                    >
                      <Card
                        cvc={card.cvc}
                        expiry={card.expiry}
                        name={card.name}
                        number={card.number}
                      />
                    </CardContainer>
                  ))
                }
              </DrawerList>
            ) : (
              <EmptyContainer>
                <EmptyIllustration src={require('../../assets/images/empty.svg')} draggable={false} />
                <EmptyMessage>Nenhum cartão de crédito por aqui...</EmptyMessage>
              </EmptyContainer>
            )
          }

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            variant='selectedMenu'
            elevation={2}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            <MenuItem onClick={handleEditCard}>Editar</MenuItem>
            <MenuItem onClick={handleRemoveCard}>Remover</MenuItem>
          </Menu>
        </DrawerMain>

        <DrawerFormAdd show={addingData} style={{ paddingTop: '30px' }}>
          <Card
            cvc={cardData.cvc}
            expiry={cardData.expiry}
            focused={cardData.focused}
            name={cardData.name}
            number={cardData.number}
            callback={handleCardCallback}
          />

          <CardForm ref={formRef} onSubmit={handleCardFormSubmit}>
            <CardFormRow>
              <TextField
                variant='filled'
                type='tel'
                name='number'
                fullWidth
                placeholder='0123 4567 8900 0000'
                label='Número do cartão'
                pattern='[\d| ]{16,22}'
                value={cardData.number}
                onChange={handleCardFormInputChange}
                onFocus={handleCardFormInputFocus}
                autoComplete={'' + Math.random()}
              />
            </CardFormRow>
            <CardFormRow>
              <TextField
                variant='filled'
                type='text'
                name='name'
                fullWidth
                placeholder='José Marcos'
                label='Nome no cartão'
                value={cardData.name}
                onChange={handleCardFormInputChange}
                onFocus={handleCardFormInputFocus}
                autoComplete={'' + Math.random()}
              />
            </CardFormRow>
            <CardFormRow>
              <TextField
                variant='filled'
                type='tel'
                name='expiry'
                fullWidth
                placeholder='MM/AA'
                label='Data de validade'
                pattern='\d\d/\d\d'
                value={cardData.expiry}
                onChange={handleCardFormInputChange}
                onFocus={handleCardFormInputFocus}
                autoComplete={'' + Math.random()}
              />
              <TextField
                variant='filled'
                type='tel'
                name='cvc'
                label='CVC'
                pattern='\d{3,4}'
                fullWidth
                value={cardData.cvc}
                onChange={handleCardFormInputChange}
                onFocus={handleCardFormInputFocus}
                autoComplete={'' + Math.random()}
                style={{ marginLeft: '15px' }}
              />
            </CardFormRow>
          </CardForm>
        </DrawerFormAdd>

        <DrawerFooter>
          <DrawerActionButton onClick={handleDrawerLeftButton}>{addingData ? 'Cancelar' : 'Voltar'}</DrawerActionButton>
          <DrawerActionButton onClick={handleDrawerRightButton}>{editing ? 'Salvar' : 'Adicionar'}</DrawerActionButton>
        </DrawerFooter>
      </CustomDrawer>
    </Container>
  );
}

const mapStateToProps = state => ({
  user: state.AuthenticationReducer.memberData.data
});


export default connect(mapStateToProps, null)(Profile);
