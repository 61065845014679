import React from 'react';

import { AnimatePresence } from 'framer-motion';
import { Switch, useLocation } from 'react-router-dom';

const AnimatedSwitch = ({ children }) => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter={true} initial={false}>
      <Switch location={location} key={location.pathname}>
        { children }
      </Switch>
    </AnimatePresence>
  )
}

export default AnimatedSwitch;