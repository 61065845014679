import React, { useEffect, useState, useRef, useContext } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory, useParams, useLocation, useRouteMatch } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CloseIcon from '@material-ui/icons/Close';
import { ThemeContext } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { mdiClose, mdiInformation } from '@mdi/js';
import Lottie from 'react-lottie';
import Icon from '@mdi/react';
import _ from 'lodash';
import {
  Snackbar,
  SnackbarContent,
  IconButton,
  Backdrop,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem
} from '@material-ui/core';

import {
  Container,
  Header,
  Row,
  Title,
  BtnClose,
  Indicator,
  Pages,
  Page,
  PageTitle,
  SliderContainer,
  SliderHeader,
  SliderHeaderText,
  BtnSeeAll,
  Slide,
  CreateAd,
  CreateAdBtn,
  CreateAdSub,
  CreateAdHeadline,
  Thumb,
  CustomDrawer,
  DrawerContainer,
  DrawerFooter,
  DrawerBtnActionLeft,
  DrawerBtnActionRight,
  Footer,
  LeftButton,
  RightButton,
  StationInner,
  StationContent,
  StationTitle,
  StationAddress,
  StationContainer,
  StationImg,
  ThumbsSlider,
  ThumbsInner,
  BtnFeedback,
  SuccessText,
  FinalPriceContainer,
  FinalPriceText,
  FinalPriceValue,
  PopLine
} from './styles';

import FilePicker from '../../components/FilePicker';
import SaveButton from '../../components/SaveButton';
import ListStations from '../ListStations';
import SearchStations from '../SearchStations';
import animationData from '../../assets/lotties/new-ad.json';
import successAnimation from '../../assets/lotties/success-send-ad.json';
import errorAnimation from '../../assets/lotties/error-face.json';
import useWindowSize from '../../utils/hooks/useWindowSize';
import Slider from '../../components/Slider';
import UploadFeedback from '../../components/UploadFeedback';
import { asyncGetUserCompanies } from '../../store/actions/CompaniesActions';
import { SERVER_ADDRESS } from '../../constraints/types';
import { getCompanyAds, setUserAds, sendAdToStation, updateSendAdToStation, createAd, updateCreateAd, updateCreatedAdAlert, updateStServiceData } from '../../store/actions/AdsActions';
import { getCategories, calculatePrice, selectStations } from '../../store/actions/StationsActions';

// react-doka
import { DokaImageEditor } from 'react-doka';

// doka
import 'doka/doka.css';
import {
    // editor
    locale_en_gb,
    createDefaultImageReader,
    createDefaultImageWriter,

    // plugins
    setPlugins,
    plugin_crop,
    plugin_crop_locale_en_gb,
    plugin_crop_defaults,
    plugin_finetune,
    plugin_finetune_locale_en_gb,
    plugin_finetune_defaults,
    plugin_filter,
    plugin_filter_locale_en_gb,
    plugin_filter_defaults,
    plugin_decorate,
    plugin_decorate_defaults,
    plugin_decorate_locale_en_gb,
    component_shape_editor_locale_en_gb,
} from 'doka';
import {Heading} from "../Dashboard/styles";

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_decorate);

const editorSize = {
    height: '584px',
};

const editorDefaults = {
    imageReader: createDefaultImageReader(),
    imageWriter: createDefaultImageWriter(),
    imageCropAspectRatio: 16/9,
    ...plugin_crop_defaults,
    ...plugin_finetune_defaults,
    ...plugin_filter_defaults,
    ...plugin_decorate_defaults,
    locale: {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_finetune_locale_en_gb,
        ...plugin_filter_locale_en_gb,
        ...plugin_decorate_locale_en_gb,
        ...component_shape_editor_locale_en_gb,
    },
};

const AddService = ({ email, token, userId, categories, selectedStations, userCompaniesData, companyAdsData, getCategories, asyncGetUserCompanies, sendAdToStation, updateSendAdToStation, createAd, createAdFinish, updateCreateAd, updateCreatedAdAlert, createdAdAlert, createAdData, sentAdToStation, getCompanyAds, setUserAds, stServiceData, updateStServiceData, calculatePrice, campaignPrice, addOrigin, selectStations }) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const history = useHistory();
  const size = useWindowSize();
  const params = useParams();
  const location = useLocation();
  const [ads, setAds] = useState([]);
  const [ errorAlert, setErrorAlert ] = useState(false);
  const [ resultImg, setResultImg ] = useState('');
  const [ imageRemoved, setImageRemoved ] = useState(null);
  const [ newAdMode, setNewAdMode ] = useState(false);
  const [ creatingANewOne, setCreatingANewOne ] = useState(false);
  const [ existingAdMode, setExistingAdMode ] = useState(false);
  const [ selectedAd, setSelectedAd ] = useState(false);
  const [ stepIncomplete, setStepIncomplete ] = useState(true);
  const [ newAdTitle, setNewAdTitle ] = useState('');
  const [ newAdCategory, setNewAdCategory ] = useState('');
  const [ newAdDescription, setNewAdDescription ] = useState('');
  const thumbsSliderRef = useRef();
  const [ thumbsSliderWidth, setThumbsSliderWidth ] = useState(360);
  const [ adPeriod, setAdPeriod ] = useState(stServiceData.weeks);
  const [ adDuration, setAdDuration ] = useState(10);
  const [ usedImages, setUsedImages ] = useState([]); // already used images
  const [ selectPanelsAlert, setSelectPanelsAlert ] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverText, setPopoverText] = useState('price');
  const [ qrcode, setQrcode] = useState(`${SERVER_ADDRESS}`);
  const [ freeAnnouncement, setFreeAnnouncement] = useState(false);

  let match = useRouteMatch('/announce/:step');
  if ((match !== null) && (!params.hasOwnProperty('step'))) {
    params.step = match.params.step;
  }

  const successOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const mobileVariants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  const desktopVariants = {
    initial: { opacity: 0, scale: .95, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  useEffect(() => {
    if (params.step === 'success' || params.step === 'error') history.push('/announce/0');

    document.addEventListener('keydown', handleKeyDown);
    updateSendAdToStation({
      data: [],
      loading: false,
      finish: false,
      error: null
    });

    updateCreateAd({
      data: [],
      loading: false,
      finish: false,
      error: null
    });
    updateCreatedAdAlert(false);

    if (addOrigin) {
      selectStations([]);
    }

    const credentials = { email, token }
    asyncGetUserCompanies(credentials, userCompaniesData);
    getCategories(credentials, categories);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  useEffect(() => {
    if (createdAdAlert) setQrcode(`${SERVER_ADDRESS}${createAdData.links.web}`);
  }, [createdAdAlert]);

  useEffect(() => {
    userCompaniesData.map((company, index) => {
      const credentials = { email, token }
      getCompanyAds(credentials, company.id, companyAdsData);
    });
  }, [userCompaniesData]);

  useEffect(() => {
    updateStServiceData({
      img: stServiceData.img,
      serviceId: stServiceData.serviceId,
      selectedStations: stServiceData.selectedStations,
      weeks: parseInt(adPeriod)
    });

    calcPrice();

  }, [adPeriod, adDuration, selectedStations.length]);

  useEffect(() => {
    if (params.step === '2' && stServiceData.img.hasOwnProperty('contentType') && stServiceData.img.contentType.split('/')[0] === 'video') {
      if (addOrigin) {
        handleContinue();
      } else {
        setStepIncomplete(false);
        handleContinue();
      }
    }
  }, [params.step])

  useEffect(() => {
    const newAdsSorted = _.orderBy([...ads, ...companyAdsData], [function(o) { return o.attributes['updated-at']; }], ['desc']);
    const newAdsSortedAndUnique = _.uniqBy(newAdsSorted, function (e) { return e.id; });
    setAds(newAdsSortedAndUnique);
    setUserAds(newAdsSortedAndUnique);
  }, [companyAdsData]);

  useEffect(() => {
    if (params.step === '1') {
      if (stServiceData.img) setStepIncomplete(false)
      else setStepIncomplete(true);
    }
  }, [stServiceData]);

  useEffect(() => {
    if (resultImg && params.step === '2') setStepIncomplete(false);
  }, [resultImg]);

  useEffect(() => {
    updateStServiceData({
      img: stServiceData.img,
      serviceId: stServiceData.serviceId,
      selectedStations: selectedStations,
      weeks: stServiceData.weeks
    })

    const freeAnnouncement = _.filter(selectedStations, function(o) { return o.attributes['owner-id'] == userId }).length === selectedStations.length ? true : false;
    setFreeAnnouncement(freeAnnouncement);

  }, [selectedStations])

  useEffect(() => {
    if (!sentAdToStation.loading && sentAdToStation.finish && !sentAdToStation.error) {
      history.push(`/announce/success`);
    }
    if (sentAdToStation.error) {
      history.push(`/announce/error`);
    }
  }, [sentAdToStation])

  useEffect(() => {
    if (((params.step === '4' && addOrigin) || (params.step === '3' && !addOrigin)) && (campaignPrice.loading || campaignPrice.error)) setStepIncomplete(true);
    if (((params.step === '4' && addOrigin) || (params.step === '3' && !addOrigin)) && (!campaignPrice.loading && !campaignPrice.error)) setStepIncomplete(false);
  }, [campaignPrice])

  const handlePopoverClick = (event, mode) => {
    setPopoverText(mode);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const calcPrice = () => {
    const credentials = { email, token }

    let pSt = [];
    selectedStations.forEach((station, index) => {
      if (!station.attributes['owner?']) pSt.push(parseInt(station.id));
    });
    calculatePrice(credentials, pSt, adPeriod, adDuration);
  }

  const selectAd = (ad) => {
    ad ? setExistingAdMode(true) : setExistingAdMode(false);
    ad ? setQrcode(`${SERVER_ADDRESS}${ad.links.web}`) : setQrcode(`${SERVER_ADDRESS}`);
    setSelectedAd(ad);
  }

  const processEditedImg = (blob) => {
     var reader = new FileReader();
     reader.readAsDataURL(blob);
     reader.onloadend = function() {
         var base64data = reader.result;
         setResultImg({ localUrl: URL.createObjectURL(blob), b64: base64data, obj: blob });
     }
  }

  const handleBack = () => {
    if (params.step === '1') {
      selectAd(false);

      updateStServiceData({
        img: null,
        serviceId: null,
        selectedStations: [],
        weeks: stServiceData.weeks
      });

      setStepIncomplete(true);
      setCreatingANewOne(false);
      history.push(`/announce/${parseInt(params.step) - 1}`);
    }
    else if (params.step === '2') {
      setResultImg('');
      if (stServiceData.img) setStepIncomplete(false);
      history.push(`/announce/${parseInt(params.step) - 1}`);
    }
    else if (params.step === '3') {
      if (stServiceData.img.hasOwnProperty('contentType') && stServiceData.img.contentType.split('/')[0] === 'video') {
        history.push(`/announce/1`);
      } else {
        history.push(`/announce/${parseInt(params.step) - 1}`);
      }
    }
    else if (params.step === '4' && !addOrigin) {
      history.push(`/announce/2`);
    }
    else if (params.step === 'error') {
      history.push(`/announce/4`);
    }
    else if (params.step === 'success') {
      history.push(`/announce/0`);
    }
    else {
      history.push(`/announce/${parseInt(params.step) - 1}`);
    }
  }

  const handleContinue = () => {
    if (params.step === '0') {
      if (selectedAd) {
        updateStServiceData({
          img: { url: `${SERVER_ADDRESS}${selectedAd.attributes['avatar-original']}` },
          serviceId: selectedAd.id,
          selectedStations: selectedStations,
          weeks: stServiceData.weeks
        });
      }
      if (newAdMode) setCreatingANewOne(true);

      setNewAdMode(false);
      setExistingAdMode(false);
      history.push(`/announce/${parseInt(params.step) + 1}`);
    }
    else if (params.step === '1') {
      setStepIncomplete(true);
      history.push(`/announce/${parseInt(params.step) + 1}`);
    }
    else if (params.step === '2') {
      if (!stServiceData.img.hasOwnProperty('contentType') || stServiceData.img.contentType.split('/')[0] === 'image') {
        if (addOrigin) setStepIncomplete(true);
        updateStServiceData({
          img: { url: resultImg.localUrl, b64: resultImg.b64, name: resultImg.obj.name, size: resultImg.obj.size, type: resultImg.obj.type },
          serviceId: stServiceData.serviceId,
          selectedStations: selectedStations,
          weeks: (stServiceData.weeks === undefined) ? 1 : stServiceData.weeks
        });
      }
      if (creatingANewOne && !createAdFinish) {
        if (!stServiceData.img.hasOwnProperty('contentType') || stServiceData.img.contentType.split('/')[0] === 'image') {

          updateStServiceData({
            img: { url: resultImg.localUrl, b64: resultImg.b64, name: resultImg.obj.name, size: resultImg.obj.size, type: resultImg.obj.type },
            serviceId: stServiceData.serviceId,
            selectedStations: selectedStations,
            weeks: (stServiceData.weeks === undefined) ? 1 : stServiceData.weeks
          });

          let extension = resultImg.obj.type.split("/")[1];
          if (extension === undefined) extension = 'jpeg';

          const credentials = { email, token };
          const serviceData = {
            name: newAdTitle,
            description: newAdDescription,
            categoryId: newAdCategory,
            originalFileName: `${uuidv4()}.${extension}`,
            avatar: resultImg.b64,
            isPromoAd: false,
            promoAdCampaign: ''
          }
          createAd(credentials, userCompaniesData[0].id, serviceData, stServiceData);
        }
        if (stServiceData.img.hasOwnProperty('contentType') && stServiceData.img.contentType.split('/')[0] === 'video') {
          let extension = stServiceData.img.contentType.split("/")[1];
          if (extension === undefined) extension = 'mp4';

          const credentials = { email, token };
          const serviceData = {
            name: newAdTitle,
            description: newAdDescription,
            categoryId: newAdCategory,
            isPromoAd: false,
            promoAdCampaign: ''
          }
          createAd(credentials, userCompaniesData[0].id, serviceData, stServiceData);
        }
      }
      history.push(`/announce/${parseInt(params.step) + 1}`);
    }
    else if (params.step === '2' && !addOrigin) {
      setStepIncomplete(false);
      history.push(`/announce/${parseInt(params.step) + 1}`);
    }
    else if (params.step === '3' && addOrigin) {
      setStepIncomplete(false);
      history.push(`/announce/${parseInt(params.step) + 1}`);
    }
    else if (params.step === '3' && !addOrigin) {
      if (stServiceData.img && selectedStations.length) {
        const credentials = { email, token };
        let serviceData = {
          qrcode: qrcode,
          seconds: adDuration,
          ...stServiceData
        }

        if (!stServiceData.serviceId) {
          serviceData.serviceId = ads[0].id;
        }

        sendAdToStation(credentials, serviceData, userId);
      } else {
        setErrorAlert(true);
        history.push(`/announce/1`);
      }
    }
    else if (params.step === '4' && addOrigin) {
      if (stServiceData.img && selectedStations.length) {
        const credentials = { email, token };
        let serviceData = {
          qrcode: qrcode,
          ...stServiceData
        }
        if (!stServiceData.serviceId && ads.length > 0) {
          serviceData.serviceId = ads[0].id;
        }

        sendAdToStation(credentials, serviceData, userId);
      } else {
        setErrorAlert(true);
        history.push(`/announce/1`);
      }
    }
    else {
      history.push(`/announce/${parseInt(params.step) + 1}`);
    }
  }

  const handleAdPeriodChange = evt => {
    setAdPeriod(parseInt(evt.target.value));
  }

  const handleAdDurationChange = evt => {
    setAdDuration(parseInt(evt.target.value));
  }

  const handleRemoveFromArray = (img) => {
    setTimeout(() => {
      let status = window.confirm(`${t('form.addStation.images.remove')}`);

      if (status) {
        handleServiceImages('remove', img);
        setImageRemoved(img);
      }
    }, 150);
  }

  const handleServiceImages = (operation, metaData) => {
    if (operation === 'add') {
      updateStServiceData({
        img: metaData,
        serviceId: stServiceData.serviceId,
        selectedStations: selectedStations,
        weeks: stServiceData.weeks
      });
    } else if (operation === 'add-multiple') {
      if (metaData.length) {
        updateStServiceData({
          img: metaData[0],
          serviceId: stServiceData.serviceId,
          selectedStations: selectedStations,
          weeks: stServiceData.weeks
        });
      }
    } else {
      updateStServiceData({
        img: null,
        serviceId: stServiceData.serviceId,
        selectedStations: selectedStations,
        weeks: stServiceData.weeks
      });
    }
  }

  const renderCreateNewAd = () => {
    return (
      <>
        <TextField
          label={ t('newAd.title') }
          variant='filled'
          value={newAdTitle}
          onChange={(evt) => setNewAdTitle(evt.target.value)}
          fullWidth
        />

        <FormControl variant="filled" fullWidth style={{ marginTop: 15 }}>
          <InputLabel id='category-label'>{ t('form.addStation.category.label') }</InputLabel>
          <Select
            labelId='category-label'
            id='category'
            value={newAdCategory}
            onChange={(evt) => setNewAdCategory(evt.target.value)}
          >
            {
              categories.data.map((item, i) => (
                <MenuItem
                  value={item.id}
                  key={i}
                >
                  {item.attributes.name}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <TextField
          label={ t('newAd.description') }
          variant='filled'
          value={newAdDescription}
          onChange={(evt) => setNewAdDescription(evt.target.value)}
          fullWidth
          style={{ marginTop: 15 }}
        />
      </>
    );
  }

  const renderUseExistingAd = () => {

    const { type, id } = selectedAd;
    const { thumb, 'avatar-medium': avatarMedium, name, description, address, status } = selectedAd.attributes;

    return (
      <>
        <StationInner>
          <StationContainer>
            <StationImg
              bg={`${SERVER_ADDRESS}${avatarMedium}`}
            />
            <StationContent>
              <StationTitle>
                {(name.length > 30) ? `${name.substring(0,30)}...` : name }
              </StationTitle>
              <StationAddress>
                {(description.length > 30) ? `${description.substring(0,30)}...` : description }
              </StationAddress>
            </StationContent>
          </StationContainer>
        </StationInner>
      </>
    );
  }

  const renderDisplayAmount = () => {
    let displayAmount = 0;
    let adPeriodCurrent = adPeriod;
    if (freeAnnouncement) adPeriodCurrent = 4;

    selectedStations.forEach((station, index) => {
      const totalMinutes = diffPeriodMinutes(station.attributes['open-start'], station.attributes['open-end']);
      displayAmount += ((totalMinutes/5)*(adPeriodCurrent*7));
    });

    if (freeAnnouncement) return `${displayAmount} aparições mensais`
    return `${displayAmount} aparições`
  }

  const diffPeriodMinutes = (start, end) => {
    start = start.split(":");
    end = end.split(":");
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0)
       hours = hours + 24;

    return (hours*60)+minutes;
  }

  const handleKeyDown = evt => {
    if (evt.key === 'Escape' && true) {
      const status = window.confirm('Tem certeza que deseja cancelar?');

      if (status) {
        handleExit();
      }
    }
  }

  const handleExit = () => {
    updateSendAdToStation({
      data: [],
      loading: false,
      finish: false,
      error: null
    });
    updateCreateAd({
      data: [],
      loading: false,
      finish: false,
      error: null
    });
    updateStServiceData({
      img: null,
      serviceId: null,
      selectedStations: [],
      weeks: stServiceData.weeks
    });
    updateCreatedAdAlert(false);
    history.replace('/');
  }

  if (params.step === '0all') {
    return (
      <ListStations
        type={'select-ad'}
        parentClickHandling={selectAd}
      />
    );
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Container
      variants={size.width < 1024 ? mobileVariants : desktopVariants}
      initial='initial'
      animate='visible'
      exit='hidden'
      width={size.width}
      height={size.height}
      step={parseInt(params.step)}
      atSearch={params.step==='3' && addOrigin}
    >
      <Header>
        <Row>
          <Title hidden={params.step === 'success' || params.step === 'error'}> Enviar anúncio para paineis MI</Title>
          <BtnClose size='small' onClick={handleExit}>
            <Icon
              path={mdiClose}
              size='24px'
            />
          </BtnClose>
        </Row>
        <Indicator step={(params.step === 'success' || params.step === 'error') ? 100 : parseInt(params.step)} hidden={params.step === 'success' || params.step === 'error'} />
      </Header>

      <Pages>
        {
          params.step === '0' && (
            <Page step={parseInt(params.step)} mobile={size.width < 1024 ? true : false}>
              <PageTitle step={params.step}>Use um anúncio que já existe ou crie um novo</PageTitle>
              <Heading>Você também poderá alterar vídeo e imagem de um anúncio já existente no próximo passo</Heading>
                <>
                  <SliderContainer>
                  {
                    ads.length > 0 && (
                      <>
                        <SliderHeader>
                          <SliderHeaderText>Selecionar anúncio</SliderHeaderText>
                          <BtnSeeAll onClick={() => history.push('/announce/0all')}>{ t('dashboard.browseAll') }</BtnSeeAll>
                        </SliderHeader>
                        <Slider>
                          {
                            ads.map((ad, index) => (
                              <Slide
                                key={index}
                                bg={`${SERVER_ADDRESS}${ad.attributes['avatar-medium']}`}
                                onClick={() => selectAd(ad)}
                                selected={selectedAd.id === ad.id}
                              />
                            ))
                          }
                        </Slider>
                      </>
                      )
                  }
                  </SliderContainer>
                      <CreateAd>
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                              preserveAspectRatio: "xMidYMid slice",
                            }
                          }}
                          width={(size.width < 1024) ? '33%' : 'auto'}
                          height={(size.width < 1024) ? '53%' : 'auto'}
                          isClickToPauseDisabled={true}
                          style={{ position: 'absolute', right: 10, bottom: 10, opacity: 1 }}
                        />
                        <CreateAdHeadline>Criar novo anúncio</CreateAdHeadline>
                        <CreateAdSub style={{ marginBottom: 0 }}>Ao criar um novo anúncio você tem todas as vantagens do Telesapp, além de poder</CreateAdSub>
                        <CreateAdSub style={{ marginBottom: 20 }}>enviá-lo para paineis</CreateAdSub>
                        <CreateAdBtn onClick={() => setNewAdMode(true) }> Novo anúncio </CreateAdBtn>
                      </CreateAd>
                </>
            </Page>
          )
        }
        {
          params.step === '1' && (
            <Page step={parseInt(params.step)} mobile={size.width < 1024 ? true : false}>
              <PageTitle>{(selectedAd) ? selectedAd.attributes.name : newAdTitle }</PageTitle>
              {
                (stServiceData.img !== null) && (
                  <ThumbsSlider>
                    <ThumbsInner
                      ref={thumbsSliderRef}
                      drag={size.width > 768 ? false : 'x'}
                      dragElastic={0.2}
                      dragDirectionLock={true}
                      dragMomentum={true}
                      dragConstraints={{ left: thumbsSliderWidth > size.width ? -thumbsSliderWidth + size.width : 0, right: 0 }}
                      numberOfChildren={2}
                    >
                      {
                        (stServiceData.img && (!stServiceData.img.hasOwnProperty('contentType') || stServiceData.img.contentType.split('/')[0] === 'image')) &&(
                          <Thumb
                            bg={stServiceData.img.url}
                            onClick={() => handleRemoveFromArray(stServiceData.img)}
                          />
                        )
                      }
                      {
                        (stServiceData.img && (stServiceData.img.hasOwnProperty('contentType') && stServiceData.img.contentType.split('/')[0] === 'video')) && (
                          <Thumb onClick={() => handleRemoveFromArray(stServiceData.img)}>
                            <video width="180" height="120" autoPlay muted loop>
                              <source src={stServiceData.img.url} type={stServiceData.img.contentType} />
                              Your browser does not support the video tag.
                            </video>
                          </Thumb>
                        )
                      }
                    </ThumbsInner>
                  </ThumbsSlider>
                )
              }

              <FilePicker
                folder={'ads'}
                parentImagesHandling={handleServiceImages}
                parentRemovedImage={imageRemoved}
                parentUsedImages={usedImages}
                showGallery={true}
                showPicker={true}
                showTakePhoto={true}
              />
              {/* Componente para visualização de andamento de uploads */}
              <UploadFeedback
                folder={'station'}
              />
            </Page>
          )
        }
        {
          (params.step === '2' && stServiceData.img && (!stServiceData.img.hasOwnProperty('contentType') || stServiceData.img.contentType.split('/')[0] === 'image')) && (
            <Page step={parseInt(params.step)} mobile={size.width < 1024 ? true : false}>
              <PageTitle>Alterações na imagem</PageTitle>
              <div style={editorSize}>
                  <DokaImageEditor
                      {...editorDefaults}
                      src={stServiceData.img.url}
                      onProcess={({ dest }) => processEditedImg(dest)}
                  />
              </div>
            </Page>
          )
        }
        {
          params.step === '3' && addOrigin && (
            <Page step={parseInt(params.step)} mobile={size.width < 1024 ? true : false}>
                <SearchStations
                  handleContinueParent={handleContinue}
                  handleBackParent={handleBack}
                  fromIndex={false}
                />
            </Page>
          )
        }
        {
          ((params.step === '4' && addOrigin) || (params.step === '3' && !addOrigin)) && (
            <Page step={parseInt(params.step)} mobile={size.width < 1024 ? true : false}>
              <PageTitle>Quase lá! Confira os detalhes finais</PageTitle>
              <SliderContainer>
                <SliderHeader>
                  <SliderHeaderText>
                    { selectedStations.length > 1 && 'Paineis selecionados'}
                    { selectedStations.length === 1 && 'Painel selecionado'}
                  </SliderHeaderText>
                </SliderHeader>
                <Slider>
                  {
                    selectedStations.map((station, index) => {
                      return (
                        <Slide
                          key={index}
                          bg={`${SERVER_ADDRESS}${station.attributes['avatar-original']}`}
                          onClick={() => history.push(`/station/${station.id}`)}
                        />
                      )
                    })
                  }
                </Slider>
              </SliderContainer>

              <FinalPriceContainer>
                <Row>
                  <Icon
                    path={mdiInformation}
                    size='24px'
                    color={theme.iconTintColor.inactive}
                    onClick={(event) => handlePopoverClick(event, 'price')}
                  />
                  <FinalPriceText>
                    { _.filter(selectedStations, function(o) { return o.attributes['owner?'] === false }).length > 1 && 'Valor mínimo e máximo total'}
                    { _.filter(selectedStations, function(o) { return o.attributes['owner?'] === false }).length === 1 && 'Valor estimado total'}
                    { _.filter(selectedStations, function(o) { return o.attributes['owner?'] === false }).length === 0 && 'Valor estimado total'}
                  </FinalPriceText>
                </Row>
                { freeAnnouncement &&
                  <Row style={{ marginLeft: '25px'}}>
                    <FinalPriceValue>
                      Gratuito
                    </FinalPriceValue>
                  </Row>
                }
                { !freeAnnouncement &&
                  <Row style={{ marginLeft: '25px'}}>
                  { (campaignPrice.loading) &&
                    <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}>
                      <FinalPriceValue><Skeleton width={180} /></FinalPriceValue>
                    </SkeletonTheme>
                  }
                  { (!campaignPrice.loading && !campaignPrice.error) &&
                    <FinalPriceValue>
                      { _.filter(selectedStations, function(o) { return o.attributes['owner?'] === false }).length > 1 && `R$ ${campaignPrice.min.toFixed(2).replace('.', ',')} — R$ ${campaignPrice.max.toFixed(2).replace('.', ',')}` }
                      { _.filter(selectedStations, function(o) { return o.attributes['owner?'] === false }).length === 1 && `R$ ${campaignPrice.min.toFixed(2).replace('.', ',')}` }
                    </FinalPriceValue>
                  }
                  </Row>
                }
              </FinalPriceContainer>

              <SliderHeaderText>Período de exibição</SliderHeaderText>

              { freeAnnouncement &&
                <Row style={{ marginLeft: '25px'}}>
                  <FinalPriceValue style={{ fontSize: '14px' }}>
                    Até ser substituído por um anúncio pago
                  </FinalPriceValue>
                </Row>
              }
              { !freeAnnouncement &&
                <FormControl variant="filled" fullWidth style={{ marginTop: 15 }}>
                  <InputLabel id='ad-period-label'>Período de exibição</InputLabel>
                  <Select
                    labelId='ad-period-label'
                    id='ad-period'
                    value={adPeriod}
                    onChange={handleAdPeriodChange}
                  >
                    <MenuItem value='1'>1 semana</MenuItem>
                    <MenuItem value='2'>2 semanas</MenuItem>
                    <MenuItem value='3'>3 semanas</MenuItem>
                    <MenuItem value='4'>1 mês</MenuItem>
                    <MenuItem value='8'>2 meses</MenuItem>
                    <MenuItem value='12'>3 meses</MenuItem>
                    <MenuItem value='16'>4 meses</MenuItem>
                  </Select>
                </FormControl>
              }
              <SliderHeaderText>Duração do anúncio</SliderHeaderText>
                  <FormControl variant="filled" fullWidth style={{ marginTop: 15 }}>
                    <InputLabel id='ad-period-label'>Duração do anúncio</InputLabel>
                    <Select
                        labelId='ad-duration-label'
                        id='ad-duration'
                        value={adDuration}
                        onChange={handleAdDurationChange}
                    >
                      <MenuItem value='10'>10 segundos</MenuItem>
                      <MenuItem value='15'>15 segundos</MenuItem>
                      <MenuItem value='20'>20 segundos</MenuItem>
                      <MenuItem value='25'>25 segundos</MenuItem>
                      <MenuItem value='30'>30 segundos</MenuItem>
                      <MenuItem value='35'>35 segundos</MenuItem>
                      <MenuItem value='40'>40 segundos</MenuItem>
                      <MenuItem value='45'>45 segundos</MenuItem>
                      <MenuItem value='50'>50 segundos</MenuItem>
                      <MenuItem value='55'>55 segundos</MenuItem>
                      <MenuItem value='60'>60 segundos</MenuItem>
                    </Select>
                  </FormControl>
              <FinalPriceContainer>
                <Row>
                  <Icon
                    path={mdiInformation}
                    size='24px'
                    color={theme.iconTintColor.inactive}
                    onClick={(event) => handlePopoverClick(event, 'display')}
                  />
                  <FinalPriceText>Número de aparições</FinalPriceText>
                </Row>
                <Row style={{ marginLeft: '25px'}}>
                    <FinalPriceValue>
                      { renderDisplayAmount() }
                    </FinalPriceValue>
                </Row>
              </FinalPriceContainer>
              <SliderHeaderText>Qr code</SliderHeaderText>
                <FormControl variant="filled" fullWidth style={{ marginTop: 15 }}>
                  <TextField
                    label={'Caso não queira exibí-lo deixe vazio'}
                    variant='filled'
                    value={qrcode}
                    onChange={(evt) => setQrcode(evt.target.value)}
                    fullWidth
                  />
                </FormControl>
            </Page>
          )
        }
        {
          params.step === 'success' && (
            <Page step={parseInt(params.step)} mobile={size.width < 1024 ? true : false}>
              <PageTitle style={{ textAlign: 'center' }}>
                { selectedStations.length > 1 && 'Anúncio enviado aos paineis!' }
                { selectedStations.length === 0 && 'Anúncio enviado ao painel!' }
              </PageTitle>
              <Lottie
                options={successOptions}
                isClickToPauseDisabled={true}
                width={280}
                height={251}
                style={{ marginBottom: '20px', borderRadius: '150px' }}
              />
              <SuccessText style={{ textAlign: 'center' }}>
                { freeAnnouncement && 'O anúncio já está pronto para ser exibido no seu painel!' }
                { !freeAnnouncement && 'Fique atento às notificações, seu anúncio aparecerá nos paineis assim que for aprovado!' }
              </SuccessText>
              <SaveButton />
            </Page>
          )
        }
        {
          params.step === 'error' && (
            <Page step={parseInt(params.step)} mobile={size.width < 1024 ? true : false}>
              <PageTitle style={{ textAlign: 'center' }}>{t('form.addStation.error')}</PageTitle>
              <Lottie
                options={errorOptions}
                isClickToPauseDisabled={true}
                width={180}
                height={180}
                style={{ margin: '15px auto' }}
              />
              <BtnFeedback onClick={handleBack}>{t('form.addStation.retry')}</BtnFeedback>
            </Page>
          )
        }
      </Pages>
      {
        (params.step === '1' || params.step === '2' || (params.step === '3' && !addOrigin) || (params.step === '4' && addOrigin)) && (
          <Footer>
            <LeftButton disabled={false} onClick={handleBack}>{ t('form.addStation.button.back') }</LeftButton>
            { ((params.step < 3 && !addOrigin) || (params.step < 4 && addOrigin)) && <RightButton disabled={stepIncomplete} onClick={handleContinue}>{ t('form.addStation.button.continue') }</RightButton> }
            { ((params.step === '3' && !addOrigin) || (params.step === '4' && addOrigin)) &&
              <DrawerBtnActionRight
                onClick={handleContinue}
                disabled={stepIncomplete || (selectedStations.length === 0) || stServiceData === undefined || stServiceData.img === undefined || !stServiceData.img }
              >
                { t('form.addStation.button.finish') }
              </DrawerBtnActionRight>
            }
          </Footer>
        )
      }
      { /* Drawer para prosseguir com a criação de um novo anúncio */ }
      <CustomDrawer
        anchor='bottom'
        open={newAdMode}
        onClose={() => setNewAdMode(false)}
      >
        <DrawerContainer>
          { renderCreateNewAd() }
        </DrawerContainer>
        <DrawerFooter>
          <DrawerBtnActionLeft
            onClick={() => setNewAdMode(false)}
          >
            Cancelar
          </DrawerBtnActionLeft>
          <DrawerBtnActionRight
            onClick={handleContinue}
            disabled={!newAdTitle || !newAdCategory || !newAdDescription}
          >
            Continuar
          </DrawerBtnActionRight>
        </DrawerFooter>
      </CustomDrawer>

      { /* Drawer para prosseguir com a seleção de um anúncio existente */ }
      <CustomDrawer
        anchor='bottom'
        open={existingAdMode !== false}
        onClose={() => {}}
      >
        <DrawerContainer>
          { selectedAd && renderUseExistingAd() }
        </DrawerContainer>
        <DrawerFooter>
          <DrawerBtnActionLeft
            onClick={() => selectAd(false)}
          >
            Cancelar
          </DrawerBtnActionLeft>
          <DrawerBtnActionRight
            onClick={handleContinue}
            disabled={existingAdMode === false}
          >
            Continuar
          </DrawerBtnActionRight>
        </DrawerFooter>
      </CustomDrawer>
      { /* SNACKBAR PARA AVISAR SOBRE O ANUNCIO CRIADO GRATUITAMENTE NO TELESAPP */}
      <Snackbar
        key={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        color='primary'
        open={createdAdAlert}
        autoHideDuration={4000}
        onClose={() => updateCreatedAdAlert(false)}
        onExit={() => updateCreatedAdAlert(false)}
      >
        <SnackbarContent
          style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
          message='Anúncio publicado no telesapp!'
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => updateCreatedAdAlert(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
      { /* SNACKBAR PARA AVISAR SOBRE A SELEÇÃO DE PAINEL */}
      <Snackbar
        key={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{ bottom: '-50px' }}
        color='primary'
        open={selectPanelsAlert}
        autoHideDuration={4000}
        onClose={() => setSelectPanelsAlert(false)}
        onExit={() => setSelectPanelsAlert(false)}
      >
        <SnackbarContent
          style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
          message='Escolha os paineis que deseja enviar'
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSelectPanelsAlert(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
      { /* SNACKBAR PARA AVISAR SOBRE INFORMAÇÃO FALTANDO */}
      <Snackbar
        key={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{ bottom: '-50px' }}
        color='primary'
        open={errorAlert}
        autoHideDuration={4000}
        onClose={() => setErrorAlert(false)}
        onExit={() => setErrorAlert(false)}
      >
        <SnackbarContent
          style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
          message='Perdoe-nos, houve um erro. Por gentileza repita o processo.'
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setErrorAlert(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
      { /* POPOVER PARA INFORMAR O USUARIO SOBRE A QUESTÃO DE MENOR E MÁXIMO VALOR DA CAMPANHA */ }
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        { (popoverText === 'price') && (selectedStations.length > 1) &&
          <>
            <PopLine>
              O valor mínimo representa o caso de apenas 1 painel aceitar seu anúncio.
            </PopLine>
            <PopLine>
              O valor máximo é a condição de todos os paineis aceitarem seu anúncio.
            </PopLine>
            <PopLine>
              Você só paga quando receber as notificações de aprovação.
            </PopLine>
          </>
        }
        { (popoverText === 'price') && (selectedStations.length === 1) &&
          <>
            <PopLine>
              Valor estimado da campanha caso o anúncio seja aprovado
            </PopLine>
          </>
        }
        { popoverText === 'display' && (selectedStations.length > 1) &&
          <>
            <PopLine>
              Número máximo de vezes que seu anúncio será exibido no período, em todos os paineis selecionados.
            </PopLine>
          </>
        }
        { (popoverText === 'display') && (selectedStations.length === 1) &&
          <>
            <PopLine>
              Número máximo de vezes que seu anúncio será exibido no período, no painel selecionado.
            </PopLine>
          </>
        }
      </Popover>
      { /* BACKDROP PARA SOBREPOR A TELA ENQUANTO ESTIVER ENVIANDO O ANUNCIO PARA VÁRIOS PAINEIS */}
      <Backdrop style={{ zIndex: 999, color: theme.colors.primary }} open={sentAdToStation.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

const mapStateToProps = state => ({
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token,
  userCompaniesData: state.CompaniesReducer.userCompanies.data,
  selectedStations: state.StationsReducer.selectedStations,
  categories: state.StationsReducer.categories,
  campaignPrice: state.StationsReducer.campaignPrice,
  companyAdsData: state.AdsReducer.companyAds.data,
  userAds: state.AdsReducer.userAds,
  sentAdToStation: state.AdsReducer.sendAdToStation,
  createAdData: state.AdsReducer.createAd.data,
  createAdFinish: state.AdsReducer.createAd.finish,
  createdAdAlert: state.AdsReducer.createdAdAlert,
  stServiceData: state.AdsReducer.stServiceData,
  addOrigin: state.AdsReducer.addOrigin,
  userId: state.AuthenticationReducer.memberData.data.id
});

export default connect(mapStateToProps, { asyncGetUserCompanies, getCategories, getCompanyAds, setUserAds, sendAdToStation, updateSendAdToStation, createAd, updateCreateAd, updateCreatedAdAlert, updateStServiceData, calculatePrice, selectStations })(AddService);
