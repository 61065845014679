import React, { useEffect, useState } from 'react';

import Terms from './terms';

import { connect } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import { checkTerms, acceptTerms } from '../../store/actions/AuthenticationActions';

import arrowRight from '@iconify-icons/mdi/arrow-right';
import { Icon } from '@iconify/react';

import {
  Container,
  TermsContainer,
  Header,
  Row,
  Title,
  Indicator,
  TextWrapper,
  TermsActions,
  TermsControlLabel,
  TermsControlLabelText,
  RefreshButton,
  ContinueWrapper
} from './styles';

const AcceptTerms = ({ userId, termsAccepted, checkTerms, acceptTerms }) => {

  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    if (!termsAccepted) {
      checkTerms(userId);
    }
  }, []);

  const handleAcceptedTerms = () => {
    setAgreed(!agreed);
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .30, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .30, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .30, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  if (!termsAccepted) {
    return (
      <Container>
        <TermsContainer
          variants={variants}
          initial='initial'
          animate='visible'
          exit='hidden'
        >
          <Header>
              <Row>
                  <Title>
                    Termos de uso e contratação dos painéis telesapp mídia Indoor e política de privacidade
                  </Title>
              </Row>
              <Indicator />
          </Header>
          <TextWrapper>
            <Terms />
          </TextWrapper>
          <TermsActions>
            <TermsControlLabel
              className='noSelect'
              control={<Checkbox color='primary' onChange={handleAcceptedTerms} />}
              label={
                <TermsControlLabelText>
                  Aceito os termos de uso do Telesapp Mídia Indoor
                </TermsControlLabelText>
               }
            />
            { agreed &&
              <ContinueWrapper 
                variants={variants}
                initial='initial'
                animate='visible'
                exit='hidden'
              >
                <RefreshButton
                  onClick={e => { acceptTerms(userId) }}
                  startIcon={<Icon icon={arrowRight} width={52} height={52} color={'#fff'} />}
                >
                </RefreshButton>
              </ContinueWrapper>
            }
          </TermsActions>
        </TermsContainer>
      </Container>
    );
  }

  return false;
}

const mapStateToProps = state => ({
  userId: state.AuthenticationReducer.memberData.data.id,
  termsAccepted: state.AuthenticationReducer.termsAccepted,
});

export default connect(mapStateToProps, { checkTerms, acceptTerms })(AcceptTerms);
