import styled from 'styled-components';
import { ButtonBase, Drawer } from '@material-ui/core';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};

  .MuiPaper-root,.MuiMenu-paper,.MuiPopover-paper,.MuiPaper-elevation2,.MuiPaper-rounded {
    left: 50% !important;
  }

  @media screen and (min-width: 1024px) {
    position: relative;
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 55;
  background: ${props => props.theme.colors.background};
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
`;

export const BackButton = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: transparent !important;
`;

export const HeaderTitle = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  text-align: center;
  margin: auto;
  flex: 1;
`;

export const GhostButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  pointer-events: none;
`;

export const MediaInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 15px 0;
`;

export const MediaContainer = styled.div`
  width: 100%;
  height: 200px;
  background: url(${props => props.avatar});
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 15px;
`;

export const VideoContainer = styled.video`
  width: 100%;
  height: auto;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (min-width: 1024px) {
    height: auto !important;
  }
`;

export const Heading = styled.p`
  font-size: 1.4rem;
  font-weight: 300;
  color: ${props => props.theme.text.primary};
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

export const SubHeading = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  color: ${props => props.theme.text.secondary};
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
`;

export const LeftButton = styled(ButtonBase)`
  flex: 1;
  font-weight: 700 !important;
  color: #fff !important;
  margin-right: 15px !important;
  border-radius: 5px !important;
  height: 40px;
  background: #ff3b3b !important;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const RightButton = styled(ButtonBase)`
  flex: 1;
  font-weight: 700 !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const CustomDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background: ${props => props.theme.colors.secondary} !important;
    border-radius: 10px 10px 0 0 !important;
  }

  @media screen and (min-width: 1024px) {
    .MuiDrawer-paper {
      margin: 0 320px !important;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      max-height: 90% !important;
      overflow-y: auto !important;
    }
  }
`;

export const DrawerContainer = styled.div`
  width: 100%;
  padding: 30px 20px 80px 20px;
`;

export const PageTitle = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 30px;
  white-space: pre-line;
`;

export const BtnFeedback = styled(RightButton)`
  padding: 0 30px !important;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const LeftButtonUndo = styled(ButtonBase)`
  flex: 1;
  font-weight: 700 !important;
  color: ${props => props.theme.text.primary} !important;
  border: 1px solid ${props => props.theme.separator} !important;
  margin-right: 15px !important;
  border-radius: 5px !important;
  height: 40px;
  background: ${props => props.theme.colors.secondary} !important;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const LoadingApprovalContainer = styled.div`
  margin-top: 40px;
  text-align: center;
`;

export const LoadingApprovalText = styled.p`
  margin-bottom: 15px;
  color: ${props => props.theme.text.primary};
  font-weight: 700;
`;