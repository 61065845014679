import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ButtonBase } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  will-change: transform;
  position: relative;

  @media screen and (min-width: 1024px) {
    height: calc(100% - 70px);
    margin-top: 70px;
    justify-content: space-between;
    will-change: transform;
  }
`;

export const StationsList = styled.div`
  flex: ${props => props.mapCollapsed ? '0 0 100%' : '0 0 500px'};
  height: 100%;
  overflow: auto;
  z-index: 9;
  background: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${props => props.mapCollapsed ? '0 40px' : '0 15px'};
`;

export const StationsListHeader = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
`;

export const StationsListHeaderTitle = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.text.primary};
  font-weight: 700;
`;

export const BtnCollapseMap = styled(ButtonBase)`
  height: 40px;
  border-radius: 5px !important;
  width: 140px !important;
  border: 1px solid ${props => props.theme.separator} !important;
  color: ${props => props.theme.text.primary} !important;
  transition: all .15s ease;

  &:hover {
    border-color: ${props => props.theme.colors.primary} !important;
  }
`;

export const StationsGrid = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  scrollbar-width: thin !important;
  scrollbar-color: ${props => props.theme.colors.background} ${props => props.theme.colors.secondary};
  position: relative;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.background};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: ${props => props.theme.colors.primary};
  }
`;

export const StationContainer = styled.div`
  flex: 1;
  cursor: pointer;
  display: flex;
  height: 120px;
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid ${props => props.selected ? props.theme.colors.primary : props.theme.separator};
  transition: all .15s ease;
  background: ${props => props.theme.colors.background};
  box-shadow: ${props => props.theme.title === 'light' && props.selected ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};

  &:first-of-type {
    margin-top: 0;
  }
`;

export const StationImages = styled.div`
  height: 100%;
  width: 150px;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 15px;
  border: 1px solid ${props => props.theme.separator};
`;

export const StationImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const StationDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2px 0;
`;

export const StationRating = styled.div`
  display: flex;
  align-items: center;
  transform: translateX(-2px);
  margin-top: auto;
`;

export const StationStars = styled.div`
  display: flex;
  align-items: center;

  > svg > path {
    fill: #e3cd52 !important;
  }
`;

export const StationTitle = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 5px;
`;

export const StationSubtitle = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-left: -2px;

    > path {
      fill: ${props => props.theme.text.secondary} !important;
    }
  }
`;

export const StationAddress = styled.span`
  color: ${props => props.theme.text.secondary};
  margin-left: 2px;
`;

export const StationRatingText = styled.div`
  font-size: 1rem;
  margin-left: 5px;
  color: ${props => props.theme.text.primary};
`;