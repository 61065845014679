import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  z-index: 50;
  background: #0f1112;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transform: scale(.99);
  transition: all .3s cubic-bezier(.785,.135,.15,.86);

  &.show {
    pointer-events: auto;
    opacity: 1;
    transform: scale(1);
  }
`;

export const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: ${props => props.width}px;
`;