import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ButtonBase } from '@material-ui/core';
import { rgba } from 'polished';

export const DarkMap = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#242f3e"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#746855"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#242f3e"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#263c3f"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#6b9a76"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#38414e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#212a37"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9ca5b3"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#746855"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#1f2835"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#f3d19c"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#2f3948"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#d59563"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#17263c"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#515c6d"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#17263c"
      }
    ]
  }
]

export const LightMap = [
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
]

export const Container = styled(motion.div)`
  height: 100%;
  flex: 1;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const MapMarker = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50% 50% 50% 0;
  background: ${props => props.isPremium ? '#ffc014' : '#FF6161'};
  border: 2px solid ${props => props.theme.title === 'dark' ? '#242F3E' : '#EDEDEE'};
  position: absolute;
  transform: translate3D(-50%, -100%, 0) scale(1) rotate(-45deg);
  transform-origin: 50% 50%;
  left: 50%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${props => props.isPremium ? 500 : 490};
  transition: all .2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &::after {
    content: "";
    width: 25px;
    height: 25px;
    background: ${props => props.theme.title === 'dark' ? '#242F3E' : '#EDEDEE'} ${props => `url(${props.bg})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    border-radius: 50%;
    transform: rotate(45deg);
  }

  &:hover {
    transform: translate3D(-50%, -120%, 0) scale(1.3) rotate(-45deg);
    background: ${props => props.theme.title === 'light' ? '#242F3E' : '#EDEDEE'};
    z-index: 505 !important;
  }

  &.selected {
    transform: translate3D(-50%, -120%, 0) scale(1.3) rotate(-45deg);
    background: ${props => props.theme.title === 'light' ? '#242F3E' : '#EDEDEE'};
    z-index: 505 !important;
  }
  
  @media screen and (min-width: 1024px) {
    cursor: pointer;
  }
`;

export const CustomInfoWindow = styled(motion.div)`
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  width: 300px;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  z-index: 105;
  background: ${props => props.theme.colors.secondary};
  transform: translate(-52%, -10%);
  cursor: pointer;
  box-shadow: ${props => props.theme.title === 'light' ? 'rgba(0, 0, 0, 0.28) 0px 8px 28px' : 'none'};
`;

export const StationImages = styled.div`
  width: 100%;
  height: 200px;
  position: relative;

  &:hover {
    > div {
      opacity: 1;
    }
  }
`;

export const StationImagesSlider = styled(motion.div)`
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  transition: all .3s ease;
`;

export const Arrows = styled.div`
  position: absolute;
  z-index: 20;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all .15s ease;
`;

export const LeftArrow = styled(ButtonBase)`
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  background: ${props => props.theme.title === 'dark' ? rgba(props.theme.colors.secondary, .8) : rgba(props.theme.colors.background, .8)} !important;
  pointer-events: auto;
  
  &:hover {
    background: ${props => props.theme.title === 'dark' ? rgba(props.theme.colors.secondary, 1) : rgba(props.theme.colors.background, 1)} !important;
  }
`;

export const RightArrow = styled(ButtonBase)`
  width: 35px !important;
  height: 35px !important;
  border-radius: 50% !important;
  background: ${props => props.theme.title === 'dark' ? rgba(props.theme.colors.secondary, .8) : rgba(props.theme.colors.background, .8)} !important;
  pointer-events: auto;
  
  &:hover {
    background: ${props => props.theme.title === 'dark' ? rgba(props.theme.colors.secondary, 1) : rgba(props.theme.colors.background, 1)} !important;
  }
`;

export const StationImage = styled.img`
  width: 300px;
  height: 100%;
  object-fit: cover;
`;

export const StationDetails = styled.div`
  padding: 15px;
`;

export const StationDetailsRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const StationTitle = styled.p`
  color: ${props => props.theme.text.primary};
  margin-right: 5px;
  font-weight: 600;
`;

export const StationRating = styled.div`
  display: flex;
  align-items: center;
`;

export const StationAddress = styled.div`
  display: flex;
  align-items: center;
  transform: translateX(-2px);

  > svg > path {
    fill: ${props => props.theme.text.secondary} !important;
  }
`;

export const StationAddressText = styled.p`
  color: ${props => props.theme.text.secondary};
`;

export const BtnOpenStation = styled(ButtonBase)`
  text-align: center;
  width: 100% !important;
  margin-top: 15px !important;
  border-radius: 5px !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  padding: 10px 0 !important;
  cursor: pointer !important;
  transition: all .15s ease;

  &:hover {
    background: #006973 !important;
  }
`;

export const StationStars = styled.div`
  display: flex;
  align-items: center;

  > svg > path {
    fill: #e3cd52 !important;
  }
`;

export const StationRatingText = styled.div`
  font-size: 1rem;
  margin-left: 5px;
  color: ${props => props.theme.text.primary};
`;