import styled from 'styled-components';
import { FormControlLabel, ButtonBase, IconButton, Drawer } from '@material-ui/core';
import { motion } from 'framer-motion';
import { rgba, darken } from 'polished';
import { PageInfo, PageInfoText } from '../AddStation/styles';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.secondary};
  z-index: 200;
  padding: 20px;

  @media screen and (min-width: 1024px) {
    position: relative;
    width: calc(${props => props.width}px - 640px);
    height: calc(${props => props.height}px - 50px);
    ${props => !props.atSearch && 'min-width: 600px'};
    ${props => props.atSearch && 'width: 600px !important'};

    border-radius: 15px;
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 0 20px;

  @media screen and (min-width: 1024px) {
    position: unset;
    padding: 0px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  color: ${props => props.theme.text.secondary};
  font-weight: 700;
`;

export const BtnClose = styled(IconButton)`
  width: 40px;
  height: 40px;
  transform: translateX(10px);
  margin-left: auto !important;

  > span > svg > path {
    fill: ${props => props.theme.iconTintColor.inactive} !important;
  }
`;

export const Indicator = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  margin-top: 3px;
  background: ${props => props.theme.separator};
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => `calc(25% * ${props.step})`};
    transition: all .15s ease-out;
    height: 100%;
    background: ${props => props.theme.iconTintColor.inactive};
  }
`;

export const Pages = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px 0;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    padding-top: 20px;
  }
`;

export const Page = styled(motion.div)`
  width: 100%;
  overflow-y: ${props => props.step !== 0 ? 'auto' : 'none'};
  padding-bottom: 60px;

  ${props => !props.mobile && `
    &::-webkit-scrollbar {
      width: 3px;
    }
  `}

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primary ? props.theme.colors.background : props.theme.text.secondary};
    border-radius: 2px;
  }

  @media screen and (min-width: 768px) {
    height: 100%;
    padding-right: 10px;
  }
`;

export const PageTitle = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: ${props => props.step === '0' ? '10px' : '30px'};
  white-space: pre-line;
`;

export const SuccessText = styled.p`
  font-size: 1.4rem;
  color: ${props => props.theme.text.secondary};
  font-weight: 700;
  margin-bottom: 30px;
  white-space: pre-line;
`;

export const ServiceBtns = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SliderContainer = styled.div`
  width: 100%;
  margin: 30px 0 15px 0;

  @media screen and (min-width: 768px) {
    overflow-y: auto;

    ::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.background};
    }

    ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.colors.primary ? props.theme.colors.primary : props.theme.text.secondary};
      border-radius: 10px;
    }
  }

`;

export const SliderHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const SliderHeaderText = styled.p`
  margin-top: 10px;
  color: ${props => props.theme.text.primary};
`;

export const BtnSeeAll = styled(ButtonBase)`
  color: ${props => props.theme.colors.primary} !important;
  font-weight: 600 !important;
  padding: 5px !important;
  border-radius: 5px !important;
`;

export const Slide = styled.div`
  width: 180px;
  height: 120px;
  border-radius: 5px;
  background: ${props => `url(${props.bg})`};
  background-position: center;
  background-size: cover;
  transition: all .15s ease;
  margin-right: 15px;
  border: ${props => props.selected ? '2px solid transparent' : 'none'} !important;
  border-color: ${props => props.selected ? props.theme.colors.primary : 'rgba(0,0,0,0)'} !important;

  @media screen and (min-width: 1024px) {
    &:hover {
      filter: brightness(50%);
    }
  }
`;

export const CreateAd = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  margin-top: 40px;
  overflow: hidden;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
  position: relative;

  @media screen and (min-width: 1024px) {
    text-align: left;
    box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
  }
`;

export const CreateAdIllustration = styled.img`
  width: 50%;
  object-fit: contain;
  margin: 20px 0;
`;

export const CreateAdHeadline = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 600;
  margin-bottom: 10px;
  z-index: 2;
  position: relative;
`;

export const CreateAdSub = styled.p`
  color: ${props => props.theme.text.secondary};
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
  padding-right: 15px;
`;

export const CreateAdBtn = styled(ButtonBase)`
  border-radius: 5px !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  height: 40px;
  padding: 0 15px !important;
`;

export const Thumb = styled.div`
  width: 180px;
  height: 120px;
  border-radius: 5px;
  background: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  margin-right: 15px;
  position: relative;
`;

export const CustomDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background: ${props => props.theme.colors.secondary} !important;
    border-radius: 10px 10px 0 0 !important;
  }

  @media screen and (min-width: 1024px) {
    .MuiDrawer-paper {
      margin: 0 320px !important;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      max-height: 90% !important;
      overflow-y: auto !important;
    }
  }
`;

export const DrawerContainer = styled.div`
  width: 100%;
  padding: 30px 20px 80px 20px;
`;

export const DrawerFooter = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  display: flex;
  align-items: center;
  padding: 0 20px;
  transform: ${props => props.hide ? 'translateY(100%)' : 'translateY(0)'};
  z-index: 35;
  transition-delay: ${props => props.hide ? '0s' : '.15s'};

  @media screen and (min-width: 1024px) {
    position: unset;
  }
`;

export const DrawerBtnActionLeft = styled(ButtonBase)`
  border: 1px solid ${props => props.theme.separator} !important;
  margin-right: 15px !important;
  border-radius: 5px !important;
  height: 40px;
  flex: 1 !important;
`;

export const RightButtonDrawer = styled(ButtonBase)`
  flex: 1 !important;
  font-weight: 700 !important;
  background: ${props => props.disabled ? props.theme.text.secondary : props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
`;

export const DrawerBtnActionRight = styled(RightButtonDrawer)``;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: ${props => props.theme.colors.secondary};

  @media screen and (min-width: 1024px) {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

export const LeftButton = styled(ButtonBase)`
  flex: 1;
  font-weight: 700 !important;
  color: ${props => props.theme.text.primary} !important;
  border: 1px solid ${props => props.theme.separator} !important;
  margin-right: 15px !important;
  border-radius: 5px !important;
  height: 40px;
  background: ${props => props.theme.colors.secondary} !important;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const RightButton = styled(ButtonBase)`
  flex: 1;
  font-weight: 700 !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const StationInner = styled(motion.div)`
  position: relative;
  z-index: 5;
`;

export const StationContainer = styled(ButtonBase)`
  display: flex;
  align-items: center !important;
  width: 100% !important;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background} !important;
  position: relative !important;
`;

export const StationContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-right: 10px;
`;

export const StationTitle = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 600;
  margin-bottom: 5px;
`;

export const StationAddress = styled.p`
  color: ${props => props.theme.text.secondary};
`;

export const StationImg = styled.div`
  width: 80px;
  height: 60px;
  border-radius: 5px;
  background: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  margin-right: 15px;
  position: relative;
`;

export const ThumbsSlider = styled(motion.div)`
  width: 100%;
  height: 80px;
  margin-bottom: 40px;
  touch-action: none;
  -ms-touch-action: none;
  pointer-events: none;
`;

export const ThumbsInner = styled(motion.div)`
  display: inline-flex;
  align-items: center;
  height: 100%;
  pointer-events: auto;
  width: ${props => (15 * props.numberOfChildren) + (80 * props.numberOfChildren) + 15}px;
`;

export const ThumbSingle = styled.button`
  width: 80px;
  height: 80px;
  border-radius: 5px;
  margin-right: 15px;
  background: url(${props => props.bg});
  background-position: center;
  background-size: cover;
  position: relative;
  transition: all .15s ease;

  &:active {
    filter: brightness(25%);
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      filter: brightness(25%);
    }
  }
`;

export const BtnFeedback = styled(RightButton)`
  padding: 0 30px !important;
  margin: 30px auto 0 auto !important;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const FinalPriceContainer = styled(PageInfo)`
  margin-top: 20px;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  svg {
    flex: 0 0 24px !important;
  }
`;

export const FinalPriceText = styled(PageInfoText)`
  color: ${props => props.theme.text.secondary};
`;

export const FinalPriceValue = styled(PageInfoText)`
  font-size: 1.125rem;
  margin-top: 8px;
`;

export const PopLine = styled.p`
  padding: 10px;
`;
