import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  overflow: hidden;
  pointer-events: none;
`;

export const Overlay = styled.div`
  z-index: 200;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  &.show {
    pointer-events: auto;
  }
`;

export const Popup = styled.div`
  position: absolute;
  top: 60px;
  right: 60px;
  width: 360px;
  height: 500px;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
  border-radius: 10px;
  opacity: 0;
  transition: transform .1s cubic-bezier(.785,.135,.15,.86) .15s, opacity .1s cubic-bezier(.785,.135,.15,.86) 0s;
  transform: translate(5%, -5%) scale(.8);
  pointer-events: none;
  transform-origin: right top;
  z-index: 201;
  overflow: hidden;
  padding-right: 2px;

  &.show {
    transform: translate(0) scale(1);
    opacity: 1;
    pointer-events: auto;
    transition: transform .15s cubic-bezier(.785,.135,.15,.86) 0s, opacity .15s cubic-bezier(.785,.135,.15,.86) 0s;
  }
`;

export const List = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100% - 55px);
  overflow-y: auto;
  padding: 10px 8px 10px 10px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.text.secondary};
    border-radius: 2px;
  }
`;

export const Header = styled.div`
  width: 100%;
  padding: 20px 15px 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  font-size: 16px;
`;

export const NotificationsCount = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.error};
  font-size: 14px;
  color: #fff;
  text-align: center;
  padding-bottom: 1px;
  font-weight: 600;
  transition: all .15s ease;
  transform: ${props => props.hide ? 'scale(.8)' : 'scale(1)'};
  opacity: ${props => props.hide ? 0 : 1};
`;
