import React, { useEffect, useState } from 'react';

import { ptBR, enUS, pl, ru } from 'date-fns/locale'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import NotificationItem from '../NotificationItem';
import { getNotifications, setNotificationRead } from '../../store/actions/NotificationsActions';

import { Container, Popup, Overlay, List, Header, Title, NotificationsCount } from './styles';

const NotificationsPopup = ({ notificationsOpen, toggleNotifications, getNotifications, setNotificationRead, userId, userNotifications, userStations, addStation }) => {
  const history = useHistory();
  const [ notificationData, setNotificationData ] = useState([]);
  /*
  const [ fakeData, setFakeData ] = useState([
    {
      id: 1,
      content: 'Dê uma resposta à solicitação em Gabe Studio',
      date: new Date(2020, 10, 6),
      img: 'https://www.telesapp.net/system/professional/companies/avatars/000/000/095/medium/BHzvlTCs.jpg?1597238045',
      type: 'alert',
      stationName: 'Gabe Studio',
      important: true,
      read: false
    }
  ]);
  */
  const { t, i18n } = useTranslation();
  const [ locale, setLocale ] = useState(ptBR);

  useEffect(() => {
    switch (i18n.language.toLowerCase()) {
      case 'pt-br':
        setLocale(ptBR);
        break;
      case 'en' || 'enUS':
        setLocale(enUS);
        break;
      case 'pl':
        setLocale(pl);
        break;
      case 'ru':
        setLocale(ru);
        break;
      default:
        setLocale(ptBR);
        break;
    }
  }, [i18n.language]);

  useEffect(() => {
    getNotifications(userId, userStations);
  }, [addStation, userStations]);

  useEffect(() => {
    setNotificationData(userNotifications);
  }, [userNotifications]);

  const handleReadNotification = (notification, index) => {

    if (notification.type === 'activation' && notification.hasOwnProperty('cdStation') && notification.uniquename) {
      const codStation = atob(atob(notification.cdStation));
      history.push({
        pathname: `/station/${notification.uniquename}/activation`,
        codStation
      });
    }
    if (notification.type === 'new-request') {
      history.push({
        pathname: '/ad-approval',
        screenData: notification
      });
    }
    if (notification.type === 'success') {
      history.push({
        pathname: '/ad-approved',
        screenData: notification
      });
    }

    if (!notification.read) setNotificationRead(notification.uid, userId);
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const handleKeyDown = evt => {
    if (evt.key === 'Escape' && !notificationsOpen) {
      toggleNotifications(false);
    }
  }

  return (
    <Container>
      <Overlay
        className={notificationsOpen ? 'show' : ''}
        onClick={() => toggleNotifications(false)}
      />
      <Popup
        className={notificationsOpen ? 'show' : ''}
      >
        <Header>
          <Title>{ t('menu.notifications.label') }</Title>
          <NotificationsCount hide={(notificationData.length) ? (notificationData.filter(item => !item.read).length > 0 ? 0 : 1) : 1}>
            {(notificationData.length) ? (notificationData.filter(item => !item.read).length || 1) : (0)}
          </NotificationsCount>
        </Header>
        <List>
          {
            notificationData.length && notificationData.map((item, index) => (
              <NotificationItem
                key={index}
                notification={item}
                index={index}
                handleReadNotification={handleReadNotification}
                locale={locale}
              />
            ))
          }
        </List>
      </Popup>
    </Container>
  );
}

const mapStateToProps = state => ({
  userId: btoa(state.AuthenticationReducer.memberData.data.id),
  userNotifications: state.NotificationsReducer.userNotifications,
  userStations: state.StationsReducer.userStations,
});

export default connect(mapStateToProps, { getNotifications, setNotificationRead })(NotificationsPopup);
