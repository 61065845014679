import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  z-index: 200;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    position: relative;
    width: calc(${props => props.width}px - 640px);
    height: calc(${props => props.height}px - 50px);
    min-width: 600px;
    border-radius: 15px;
  }

`;

export const Header = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
`;

export const BtnBack = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
`;

export const Title = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  margin: auto;
`;

export const BtnSearch = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  opacity: 0 !important;
  pointer-events: none !important;
  border-radius: 50% !important;
`;

export const List = styled(motion.div)`
  width: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.background};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primary};
    border-radius: 2px;
  }
`;

export const Inputs = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  padding: 0 20px;
`;
