import { combineReducers } from 'redux';
import AuthenticationReducer from './AuthenticationReducer';
import StationsReducer from './StationsReducer';
import UploadsReducer from './UploadsReducer';
import CompaniesReducer from './CompaniesReducer';
import NotificationsReducer from './NotificationsReducer';
import ChatReducer from './ChatReducer';
import AdsReducer from './AdsReducer';
import UserDataReducer from './UserDataReducer';
import PaymentsReducer from './PaymentsReducer';

export default combineReducers({
  AuthenticationReducer,
  StationsReducer,
  UploadsReducer,
  CompaniesReducer,
  NotificationsReducer,
  ChatReducer,
  AdsReducer,
  UserDataReducer,
  PaymentsReducer
});
