import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.colors.secondary};
  transition: all .4s cubic-bezier(.785,.135,.15,.86);
  position: absolute;
  top: 100%;
  right: 0;
  border: none;
  width: 100%;
  max-height: calc(100vh - 60px);
  overflow: auto;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  transform: ${props => props.show ? 'rotateX(0)' : 'rotateX(15deg)'};
	opacity: ${props => props.show ? 1 : 0};
	pointer-events: ${props => props.show ? 'auto' : 'none'};
  transform-style: preserve-3d;
	transform-origin: top center;

  @media screen and (min-width: 1024px) {
    right: 20px;
    border-radius: 0 0 10px 10px;
    padding: 10px;
  }
`;

export const Item = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px 10px 10px;
  cursor: pointer;
  transition: all .15s ease;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;

  > svg > path {
    fill: ${props => props.theme.iconTintColor.inactive} !important;
  }

  &:active {
    background: ${props => props.theme.colors.background};
  }

  @media screen and (min-width: 1024px) {
    border-radius: 5px;

    &:hover {
      background: ${props => props.theme.colors.background};
    }
  }
`;

export const ItemText = styled.p`
  color: ${props => props.theme.text.primary};
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
  text-align: left;
  font-size: 1rem;
`;

export const SetSearchValueBtn = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  position: relative;
  transition: all .15s ease;

  &:active {
    background: ${props => props.theme.colors.secondary};
  }

  @media screen and (min-width: 1024px) {
    cursor: pointer;

    &:hover {
      background: ${props => props.theme.colors.secondary};
    }
  }

  > svg > path {
    fill: ${props => props.theme.iconTintColor.inactive} !important;
  }
`;
