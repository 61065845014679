import React, { useEffect, useState, useCallback, useRef } from 'react';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiChevronLeft, mdiCameraRetake, mdiCameraRear, mdiCheckboxMarked } from '@mdi/js';
import VideoThumbnail from 'react-video-thumbnail';
import { useDropzone } from 'react-dropzone';
import Webcam from 'react-webcam';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import useDeviceDetect from '../../utils/hooks/useDeviceDetect';
import useWindowSize from '../../utils/hooks/useWindowSize';

import { fileUpload, listFolder, removeFromStack, deleteFromBucket, updateDeleteFromBucket } from '../../store/actions/UploadsActions';

import {
  BtnUploadPhoto,
  BtnText,
  BtnTextTitle,
  BtnTextSubtitle,
  BtnSelectFromGallery,
  BtnTakePhoto,
  WebcamContainer,
  WebcamHeader,
  WebcamFooter,
  BtnExitWebcam,
  WebcamToggleModeBtn,
  WebcamCenterBtn,
  WebcamGhostBtn,
  WebcamScreenshot,
  WebcamCenterContainer,
  WebcamRetakePhoto,
  CustomDrawer,
  DrawerContainer,
  GalleryFooter,
  GalleryImages,
  GalleryImage,
  GalleryTitle,
  BtnRemove,
  BtnApply
} from './styles';

const FilePicker = ({ showPicker = true, showGallery = true, showTakePhoto = true, parentImagesHandling, parentUsedImages, fileUpload, removeFromStack, deleteFromBucket, updateDeleteFromBucket, itemDelFromBucket, listFolder, uploadStack, uploadToken, stationFiles, adsFiles, userId, folder, parentRemovedImage }) => {
  const [ drawerOpen, setDrawerOpen ] = useState(false);
  const [ camera, setCamera ] = useState(false);
  const [ hasUploadedFile, setHasUploadedFile ] = useState(false);
  const [ checkedItems, setCheckedItems ] = useState({});
  const [ files, setFiles ] = useState([]);
  const [ cameraMode, setCameraMode ] = useState('environment');
  const [ photoSrc, setPhotoSrc ] = useState(null);
  const [ imgHeight, setImgHeight ] = useState(80);
  const [ currentFiles, setCurrentFiles ] = useState({ data: [], loading: false, error: false });

  const imgRef = useRef();
  const ref = useRef();
  const filePickerRef = useRef();
  const cameraRef = useRef(null);
  const size = useWindowSize();
  const { isMobile } = useDeviceDetect();

  const { t, i18n } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: (folder === 'ads') ? 'image/jpeg, image/png, video/*' : 'image/jpeg, image/png',
    maxFiles: 10,
    onDrop: acceptedFiles => {
      handleUpload(acceptedFiles);
      setFiles(acceptedFiles);
    }
  });

  // Use Effect responsável por simular um componentDidMount, para chamar a priori o listfolder caso ainda nao tenha buscado
  useEffect(() => {
    updateDeleteFromBucket({ item: null, loading: false, error: false });

    if (folder === 'ads') {
      setCurrentFiles(adsFiles);
      if (!adsFiles.data.length) {
        listFolder(adsFiles, folder, btoa(userId));
      }
    } else {
      setCurrentFiles(stationFiles);
      if (!stationFiles.data.length) {
        listFolder(stationFiles, folder, btoa(userId));
      }
    }
  }, []);

  // Use Effect responsável por manter as imagens na galeria sempre quadradas, independente da largura da tela
  useEffect(() => {
    if (imgRef.current) {
      setImgHeight(imgRef.current.offsetWidth);
    }
  }, [imgRef.current, size.width]);

  // Use Effect responsável por detectar uma imagem removida no componente pai
  // fazendo que o checkbox que representa a imagem removida seja setado para false
  useEffect(() => {
    if (parentRemovedImage) {
      setCheckedItems({
        ...checkedItems,
        [parentRemovedImage.name]: false
      });
    }
  }, [parentRemovedImage]);

  // Use Effect responsável por rastrear as imagens que são enviadas pelo usuário no momento
  // fazendo que as imagens assim que são recentemente enviadas para o storage venham já pré-selecionadas
  useEffect(() => {
    // atualizar os arquivos em questao (de anuncios ou de paineis)
    (folder === 'ads') ? setCurrentFiles(adsFiles) : setCurrentFiles(stationFiles);
    // criando o array local para recuperar os itens já selecionados, e ser usado para selecionar o restante que foi enviado pelo usuário
    let sentCheckedFiles = { ...checkedItems };
    // array para guardar todas as imagens que serão adicionadas todas de uma vez para o array contendo
    // as imagens selecionadas pelo usuário no componente pai
    let imgsToAdd = [];
    currentFiles.data.map((item, index) => {
      // variável responsável por extrair uma imagem que já está no stationFiles (valor do store que contém as imagens do storage do usuario)
      // o método find do lodash está nesse caso verificando se há um item do storage que possui no nome o mesmo uid que foi salvo em files (arquivos selecionados pelo usuário no momento)
      let automaticallySlct = _.find(files, function(f) { return f.uid === item.name.split('.')[0]; });
      // se há um item, isso significa que um dos itens que o usuário selecionou para enviar já está presente no stationFiles (e portanto storage)
      if (automaticallySlct) {

        const { url } = item;
        // adição do checkbox correspondente a imagem para true (seleção automatica)
        if (folder === 'station') {
          sentCheckedFiles = {
            ...sentCheckedFiles,
            [item.name]: true
          }
        }
        // uma vez feita devidamente o procedimento, é necessário remover este item dos arquivos selecionados pelo usuário
        // para que não seja feita novamente esta verificação
        _.remove(files, function(f) {
          return f.uid === item.name.split('.')[0];
        });

        // concatenação do arquivo no array descrito previamente, que será usado para que
        // os arquivos sejam inseridos de uma vez ao final de percorrer todos os stationFiles
        imgsToAdd = [...imgsToAdd, item];

        // definição de fato os itens do storage que estão selecionados para ser enviados no request do componente pai
        if (folder === 'station') {
          setCheckedItems({
            ...checkedItems,
            ...sentCheckedFiles
          });
        }
      }
      // ao final de todo o procedimento, são adicionadas todas os arquivos recém enviados para o array do
      // componente pai que gerencia os arquivos selecionados pelo usuário para ser utilizado no request
      if (index+1 === currentFiles.data.length) {
        if (folder === 'ads' && imgsToAdd.length) {
          setCheckedItems({
            [imgsToAdd[0].name]: true
          });
        }
        parentImagesHandling('add-multiple', imgsToAdd);
      }
    });
  }, [stationFiles, adsFiles, currentFiles]);

  useEffect(() => {
    // Se foi feito a atualização de novo arquivo enviado, e todos os arquivos já foram enviados
    // é chamada novamente a listFolder para listagem da pasta do usuário
    if (hasUploadedFile && !uploadStack.length) {
      listFolder(currentFiles, folder, btoa(userId));
      setHasUploadedFile(false);
    }

    if (uploadStack[0]!==undefined && uploadStack[0].hasOwnProperty('progress')) {
      // se o primeiro elemento da pilha de uploads chegar ao total de 100% a flag hasUploadedFile é setada para true
      if (uploadStack[0].progress === 100) setHasUploadedFile(true);
    }

    // chegagem no update do componente, para verificar se houve um arquivo excluído
    // para promover também a sua remoção no componente pai (que gerencia o array de imagens selecionadas)
    if (itemDelFromBucket.item && !itemDelFromBucket.error) {
      parentImagesHandling('remove', itemDelFromBucket.item);
    }

  }, [uploadToken, itemDelFromBucket]);

  // chamada de upload de arquivos para cada um dos arquivos selecionados na função anterior
  const handleUpload = (files) => {
    Array.from(files).forEach((file, index) => {
      const uploadData = { key: btoa(userId), folder, isThumb: false }

      const { name, size, type } = file;

      if ((folder === 'station') && (type.split('/')[0] !== 'image')) {
        alert(`${name} ${t('filepicker.send.error')}`);
      } else {
        const uniqueName = uuidv4();
        files[index].uid = uniqueName;
        fileUpload(file, name, uniqueName, size, type, uploadStack, uploadData);
      }
    });
  }

  const handleResetWebcam = () => {
    setPhotoSrc(null);
  }

  const handleSetPhotoOnArray = () => {
    // imagem está em 64, extraindo o contentType do cabeçalho
    const contentType = photoSrc.split(':').pop().split(';')[0];

    // decodificando a imagem, apenas na segunda parte do b64 pois se não buga
    const byteCharacters = atob(photoSrc.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    };
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], {type: contentType});

    // criação de nome único para o upload
    const uniqueName = uuidv4();
    blob.uid = uniqueName;

    // inserção do novo arquivo em files para que seja feita a seleção automática após o envio
    const newFiles = [ ...files, blob ];
    setFiles(newFiles);

    const uploadData = { key: btoa(userId), folder, isThumb: false }
    fileUpload(blob, uniqueName, uniqueName, blob.size, contentType, uploadStack, uploadData);

    setCamera(false);
    setPhotoSrc(null);
  }

  const capture = useCallback(() => {
    const imageSrc = cameraRef.current.getScreenshot();
    setPhotoSrc(imageSrc);
  }, [cameraRef, setPhotoSrc]);

  const handleTakePhoto = () => {
    setCamera(true);
  }

  // chamada de exclusão de arquivo individual
  const deleteFromGallery = (item, folder, userId) => {
    const isTheImageUsed = _.some(parentUsedImages, { 'storage-ref': item.url.split('?')[0] });
    if (!isTheImageUsed && (folder !== 'ads')) {
      deleteFromBucket(item, folder, userId);
      parentImagesHandling('remove', item);
    } else {
      alert(t('filepicker.alert.deleteError', { image: item.name }));
    }
  }

  const toggleDrawer = state => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

    setDrawerOpen(state);
  }

  // função que itera pelos arquivos selecionados, encontra eles no array de arquivos recebidos do firebase, e faz a chamada para a função de exclusão individual
  const handleDeleteFromGallery = () => {
    if (_.isEmpty(checkedItems) || Object.values(checkedItems).every(v => !v)) {
      return;
    }
    else {
      const status = window.confirm(`${t('filepicker.alert.delete')}`);

      if (status) {
        for (let key in checkedItems) {
          if (checkedItems[key]) {
            const result = currentFiles.data.find(item => item.name === key);

            if (result) {
              deleteFromGallery(result, folder, btoa(userId));
            }
          }
        }
      }
    }
  }

  // função para adicionar/remover itens da galeria do array de elementos selecionados
  // e enviar ao componente pai a ação (add, remover)
  const handleToggleSelectImg = (item) => {
    if (checkedItems.hasOwnProperty(item.name) && checkedItems[item.name]) {
      parentImagesHandling('remove', item);

      if (folder === 'station') {
        setCheckedItems({
          ...checkedItems,
          [item.name]: false
        });
      }
      if (folder === 'ads') {
        setCheckedItems({
          [item.name]: false
        });
      }
    }
    else {
      parentImagesHandling('add', item);

      if (folder === 'station') {
        setCheckedItems({
          ...checkedItems,
          [item.name]: true
        });
      }

      if (folder === 'ads') {
        setCheckedItems({
          [item.name]: true
        });
      }
    }
  };

  // renderização dos itens da galeria, populados pela função listFolder
  function renderGallery() {
    // checagem se os dados estão sendo carregados
    if (!currentFiles.data.length && currentFiles.loading) return;

    // percorrendo todos os arquivos da pasta, exibindo um checkbox para cada item para possibilitar a seleção pelo usuário
    return (
      <CustomDrawer
        anchor='bottom'
        open={drawerOpen}
        onClose={toggleDrawer(false)}
      >
        <DrawerContainer>
          <GalleryTitle>{currentFiles.data.length > 0 ? `${t('filepicker.title.contain')}` : `${t('filepicker.title.empty')}`}</GalleryTitle>
          {
            currentFiles.data.length > 0 && (
              <>
                <GalleryImages>
                  {
                    currentFiles.data.map((item, index) => {
                      if (item.contentType.split('/')[0] === 'image') {
                        return (
                          <GalleryImage
                            key={index}
                            ref={imgRef}
                            bg={item.url}
                            height={imgHeight}
                            selected={checkedItems.hasOwnProperty(item.name) && checkedItems[item.name]}
                            onClick={() => handleToggleSelectImg(item)}
                          >
                            <Icon
                              path={mdiCheckboxMarked}
                              size='24px'
                              color='#01a3b2'
                            />
                          </GalleryImage>
                        )
                      }
                      if (item.contentType.split('/')[0] === 'video') {
                        return (
                          <GalleryImage
                            key={index}
                            ref={imgRef}
                            height={imgHeight}
                            selected={checkedItems.hasOwnProperty(item.name) && checkedItems[item.name]}
                            onClick={() => handleToggleSelectImg(item)}
                          >
                            <VideoThumbnail videoUrl={item.url} />
                          </GalleryImage>
                        )
                      }
                    })
                  }
                </GalleryImages>
                <GalleryFooter>
                  <BtnRemove
                    onClick={(!_.isEmpty(checkedItems) && Object.values(checkedItems).every(v => v)) ? handleDeleteFromGallery : toggleDrawer(false)}
                  >
                    { (!_.isEmpty(checkedItems) && Object.values(checkedItems).every(v => v)) ? t('filepicker.button.delete') : 'Cancelar' }
                  </BtnRemove>
                  <BtnApply onClick={() => setDrawerOpen(false)}>{ t('filepicker.button.apply') }</BtnApply>
                </GalleryFooter>
              </>
            )
          }
        </DrawerContainer>
      </CustomDrawer>
    )
  }
  // implementar o clique abrindo o drawer contendo a informação do renderGallery
  return (
    <div>
      {
        isMobile && showTakePhoto && (
          <BtnTakePhoto onClick={handleTakePhoto}>
            <BtnText>
              <BtnTextTitle>{ t('filepicker.camera.title') }</BtnTextTitle>
              <BtnTextSubtitle>{ t('filepicker.camera.subtitle') }</BtnTextSubtitle>
            </BtnText>
            <Icon
              path={mdiChevronRight}
              size='24px'
            />
          </BtnTakePhoto>
        )
      }
      {
        camera && (
          <WebcamContainer>
            <WebcamHeader>
              <BtnExitWebcam size='small' onClick={() => setCamera(false)}>
                <Icon
                  path={mdiChevronLeft}
                  size='24px'
                  color='#fff'
                />
              </BtnExitWebcam>
            </WebcamHeader>
            <Webcam
              ref={cameraRef}
              audio={false}
              mirrored={true}
              width={size.width}
              height={size.height}
              screenshotFormat='image/jpeg'
              videoConstraints={{
                facingMode: cameraMode
              }}
            />
            { photoSrc && <WebcamScreenshot src={photoSrc} /> }
            <WebcamFooter>
              <WebcamGhostBtn />
              <WebcamCenterContainer>
                <WebcamRetakePhoto show={photoSrc} onClick={handleResetWebcam}>
                  <Icon
                    path={mdiCameraRetake}
                    size='24px'
                    fill='#fff'
                  />
                </WebcamRetakePhoto>
                <WebcamCenterBtn onClick={photoSrc ? handleSetPhotoOnArray : capture} className={photoSrc ? 'send-mode' : ''}>
                  {
                    photoSrc && (
                      <Icon
                        path={mdiChevronRight}
                        size='24px'
                        color='#fff'
                      />
                    )
                  }
                </WebcamCenterBtn>
              </WebcamCenterContainer>
              <WebcamToggleModeBtn size='medium' disabled={true}>
                <Icon
                  path={mdiCameraRear}
                  size='28px'
                  color='#fff'
                />
              </WebcamToggleModeBtn>
            </WebcamFooter>
          </WebcamContainer>
        )
      }
      { showGallery &&
        (
          <div>
            <BtnSelectFromGallery onClick={toggleDrawer(true)}>
              <BtnText>
                <BtnTextTitle>{t('filepicker.gallery.title')}</BtnTextTitle>
                <BtnTextSubtitle>{t('filepicker.gallery.subtitle')}</BtnTextSubtitle>
              </BtnText>
              <Icon
                path={mdiChevronRight}
                size='24px'
              />
            </BtnSelectFromGallery>
            { renderGallery() }
          </div>
        )
      }
      { showPicker &&
        (
          <div>
            {/* input do tipo file para múltiplos arquivos */}
            <BtnUploadPhoto ref={filePickerRef} {...getRootProps()}>
              <input {...getInputProps()} />
              <BtnText>
                <BtnTextTitle>{t('filepicker.picker.title')}</BtnTextTitle>
                <BtnTextSubtitle>{t('filepicker.picker.subtitle')}</BtnTextSubtitle>
              </BtnText>
              <Icon
                path={mdiChevronRight}
                size='24px'
              />
            </BtnUploadPhoto>
          </div>
        )
      }
    </div>
  )
}

const mapStateToProps = state => ({
  uploadStack: state.UploadsReducer.uploadStack,
  userId: state.AuthenticationReducer.memberData.data.id,
  stationFiles: state.UploadsReducer.stationFiles,
  adsFiles: state.UploadsReducer.adsFiles,
  uploadToken: state.UploadsReducer.uploadToken,
  itemDelFromBucket: state.UploadsReducer.deleteFromBucket
});

export default connect(mapStateToProps, { fileUpload, listFolder, removeFromStack, deleteFromBucket, updateDeleteFromBucket })(FilePicker);
