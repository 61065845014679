import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
`;

export const SearchContainer = styled.div`
  position: fixed;
  z-index: 105;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: ${props => props.theme.colors.background};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  height: 80px;
  transition: all .15s ease;
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
  
  &.active {
    opacity: 1;
    pointer-events: auto !important;
    transition-delay: ${props => props.withoutMapMode ? '.3s' : '0s'};
  }

  &.map-mode {
    width: calc(100% - 40px);
    border-radius: 5px;
    top: 20px;
    opacity: 1;
    pointer-events: auto;
    height: 50px;
    padding: 0 10px 0 5px;
    box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.1)' : 'none'};
  }

  svg > path {
    fill: ${props => props.theme.iconTintColor.active} !important;
  }
`;

export const BtnClear = styled.button`
  width: 40px;
  height: 40px;
  display: none;
  align-items: center;
  justify-content: center;
  transition: all .15s ease;

  &.show {
    display: flex;
  }
`;

export const SearchInput = styled.input`
  border: none;
  background: transparent;
  padding: 15px 0 15px 5px;
  color: ${props => props.theme.text.primary};
  flex: 1;
`;

export const SearchOverlay = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 99;
  background: ${props => props.theme.colors.background};
  transition: clip-path .6s cubic-bezier(.785,.135,.15,.86), opacity .15s ease-out;
  clip-path: ${props => props.withoutClipPath ? 'circle(2% at calc(100% - 45px) calc(100% - 105px))' : 'none'};
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: auto;
    clip-path: ${props => props.withoutClipPath ? 'circle(135% at calc(100% - 45px) calc(100% - 105px))' : 'none'}; 

    @media screen and (max-width: 415px) {
      clip-path: circle(125% at calc(100% - 45px) calc(100% - 105px));
    }
  }
`;

export const LeftButton = styled.button`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  transition: all .15s ease;
  border-radius: 50%;
  padding-top: 4px;

  &:active {
    background: ${props => darken(.05, props.theme.colors.background)};
  }
`;