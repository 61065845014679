import React, { useState, useEffect, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { ThemeContext } from 'styled-components';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import useWindowSize from '../../utils/hooks/useWindowSize';
import { deleteStation } from '../../store/actions/StationsActions';
import ListAds from '../ListAds';

import { motion, AnimatePresence } from "framer-motion";

import MDIcon from '@mdi/react';
import { mdiClose } from '@mdi/js';

import {
    Container,
    HeaderOp,
    RowOp,
    TitleOp,
    BtnClose,
    Indicator,
    Pages,
    Page,
    PageTitle,
    CustomRadio,
    CustomFormControlLabel,
    ReportOptions,
    RadioContainer,
    Footer,
    SendButton,
    Heading
} from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteStation = ({ setDeleteIsOpen, station, deleteStation, deleteStationData, credentials, blockStation, blockStationData, blocked }) => {

    const history = useHistory();
    const theme = useContext(ThemeContext);
    const size = useWindowSize();
    const [value, setValue] = React.useState('Vou excluir para criar outro melhor 😁 e ativá-lo em seguida.');

    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (deleteStationData.success) {
            setDeleteIsOpen(false);
            history.push('/');
        }
        else if (blockStationData.success || blockStationData.error) {
            setDeleteIsOpen(false);
        }
    }, [deleteStationData, blockStationData]);

    const handleChange = (event) => {
      if (event.target.value === 'Especificar outro motivo:') setIsOpen(true);
      else setIsOpen(false);

      setValue(event.target.value);
    };

    const handleDeleteStation = () => {
        const { id } = station.data;
        const { name, address, "owner-id": userId } = station.data.attributes;

        const reason = (value === 'Especificar outro motivo:') ? description : value;

        const reasonData = { id, name, address, userId, reason }

        deleteStation(credentials, station, reasonData);
    }

    const confirmBlockStation = () => {
        setOpen(true);
    }

    const cancelBlockStation = () => {
        setOpen(false);
    }

    const handleBlockStation = () => {
        setOpen(false);
        blockStation(credentials, station);
    }

    const handleExit = () => {
        setDeleteIsOpen(false);
    }

    const mobileVariants = {
        initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
      }
    
    const desktopVariants = {
        initial: { opacity: 0, scale: .95, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        visible: { opacity: 1, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        hidden: { opacity: 0, scale: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    }

    return (
        <Container
            variants={size.width < 1024 ? mobileVariants : desktopVariants}
            initial='initial'
            animate='visible'
            exit='hidden'
        >
            <HeaderOp>
                <RowOp>
                    <TitleOp> Desativar painel MI </TitleOp>
                    <BtnClose size='small' onClick={()=> setDeleteIsOpen(false)}>
                        <MDIcon
                            path={mdiClose}
                            size='24px'
                        />
                    </BtnClose>
                </RowOp>
                <Indicator step={100} />
            </HeaderOp>
            <Pages>
                { !deleteStationData.error &&
                    <Page mobile={size.width < 1024 ? true : false}>
                        <ReportOptions>
                            <PageTitle>Excluir painel 😔</PageTitle>
                            <Heading>
                                Para excluir o painel você não pode ter anúncios pagos ativos (precisa exibí-los até a expiração). Por gentileza,
                                especifique o motivo da exclusão para melhorarmos.
                            </Heading>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                    <RadioContainer onClick={() => { let evt = { target: { value: 'Vou excluir para criar outro melhor 😁 e ativá-lo em seguida.' }}; handleChange(evt); }}>
                                        <CustomFormControlLabel
                                            value="Vou excluir para criar outro melhor 😁 e ativá-lo em seguida."
                                            control={<CustomRadio />}
                                            label="Vou excluir para criar outro melhor 😁 e ativá-lo em seguida."
                                        />
                                    </RadioContainer>
                                    <RadioContainer onClick={() => { let evt = { target: { value: 'Não é interessante oferecer anúncios no estabelecimento.' }}; handleChange(evt); }}>
                                        <CustomFormControlLabel
                                            value="Não é interessante oferecer anúncios no estabelecimento."
                                            control={<CustomRadio />}
                                            label="Não é interessante oferecer anúncios no estabelecimento."
                                        />
                                    </RadioContainer>
                                    <RadioContainer
                                        onClick={() => { let evt = { target: { value: 'Especificar outro motivo:' }}; handleChange(evt); }}
                                        isOpen={isOpen}
                                    >
                                        <CustomFormControlLabel
                                            value="Especificar outro motivo:"
                                            control={<CustomRadio />}
                                            label="Especificar outro motivo:"
                                        />
                                        <AnimatePresence>
                                            {isOpen &&
                                                <motion.div
                                                    layout
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    exit={{ opacity: 0 }}
                                                >
                                                    <div style={{ width: '100%', height: '100px' }}>
                                                        <FormControl variant="filled" fullWidth style={{ marginTop: 15 }}>
                                                            <TextField
                                                                label={'Descreva a situação'}
                                                                variant='filled'
                                                                value={description}
                                                                onChange={(evt) => setDescription(evt.target.value)}
                                                                fullWidth
                                                                multiline
                                                                rowsMax={3}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                </motion.div>
                                            }
                                        </AnimatePresence>
                                    </RadioContainer>
                                </RadioGroup>
                            </FormControl>
                        </ReportOptions>
                    </Page>
                }
                { deleteStationData.error && !blocked &&
                    <Page mobile={size.width < 1024 ? true : false}>
                        <ReportOptions>
                            <PageTitle>Não foi possível excluir o painel</PageTitle>
                            <Heading>
                                Existem anúncios pagos ativos no momento, e você deve exibí-los até a data de expiração para excluir o painel. Deseja bloquear o painel para novos anúncios?
                            </Heading>
                            <ListAds activeAds={deleteStationData.error.data.data} />
                        </ReportOptions>
                    </Page>
                }
                { deleteStationData.error && blocked &&
                    <Page mobile={size.width < 1024 ? true : false}>
                        <ReportOptions>
                            <PageTitle>Não é possível excluir o painel</PageTitle>
                            <Heading>
                                O painel está bloqueado, porém ainda existem anúncios pagos ativos, e você deve exibí-los até a data de expiração para excluir o painel.
                            </Heading>
                            <ListAds activeAds={deleteStationData.error.data.data} />
                        </ReportOptions>
                    </Page>
                }
            </Pages>
            { !deleteStationData.error && 
                <Footer>
                    <SendButton
                        disabled={isOpen && !description}
                        onClick={handleDeleteStation}
                    >
                        Excluir painel
                    </SendButton>
                </Footer>
            }
            { deleteStationData.error && !blocked &&
                <Footer>
                    <SendButton
                        disabled={isOpen && !description}
                        onClick={confirmBlockStation}
                    >
                        Bloquear Painel
                    </SendButton>
                </Footer>
            }
            { deleteStationData.error && blocked &&
                <Footer>
                    <SendButton
                        disabled={isOpen && !description}
                        onClick={handleExit}
                    >
                        Sair
                    </SendButton>
                </Footer>
            }
            { /* DIÁLOGO PARA CONFIMAÇÃO DE BLOQUEIO */}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={cancelBlockStation}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    Confirmar bloqueio
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    Após bloquear o painel você terá que reativá-lo novamente caso queira que o painel volte a receber anúncios.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={cancelBlockStation} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleBlockStation} color="primary">
                    Confirmar
                </Button>
                </DialogActions>
            </Dialog>
            { /* BACKDROP PARA SOBREPOR A TELA ENQUANTO ESTIVER BLOQUEANDO OU EXCLUINDO O PAINEL */}
            <Backdrop style={{ zIndex: 999, color: theme.colors.primary, position: 'absolute' }} open={deleteStationData.loading || blockStationData.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}

const mapStateToProps = state => ({
    deleteStationData: state.StationsReducer.deleteStation,
    blockStationData: state.StationsReducer.blockStation
});

export default connect(mapStateToProps, { deleteStation })(DeleteStation);