import React, { useContext, useEffect, useState } from 'react';

import { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';
import { AnimatePresence, useMotionValue } from 'framer-motion';

import GoogleMapReact from 'google-map-react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import Lottie from 'react-lottie';
import { useTranslation} from 'react-i18next';
import Icon from '@mdi/react';
import { mdiStar, mdiMapMarkerOutline, mdiChevronLeft, mdiChevronRight } from '@mdi/js';

import useWindowSize from '../../utils/hooks/useWindowSize';
import animationData from '../../assets/images/404.json';
import { SERVER_ADDRESS } from '../../constraints/types';

import { DarkMap, LightMap, Container, MapMarker, Wrapper, CustomInfoWindow, StationImages, StationImagesSlider, StationImage, StationTitle, StationAddress, StationAddressText, BtnOpenStation, StationDetails, StationDetailsRow, StationRating, StationRatingText, StationStars, Arrows, LeftArrow, RightArrow } from './styles';

const libraries = ["places"];

const Map = ({ stations, addressValue, updateAddressSugestions, center, openStationScreen, zoom, selectedStationId, handlePress, selectedStation }) => {
  const history = useHistory();
  const size = useWindowSize();
  const [ infoWindowPos, setInfoWindowPos ] = useState([]);
  const x = useMotionValue(0);

  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  });

  // Return map bounds based on list of stations
  const getMapBounds = (map, maps, stations) => {
    const bounds = new maps.LatLngBounds();

    stations.forEach((station) => {
      bounds.extend(new maps.LatLng(
        station.attributes.latitude,
        station.attributes.longitude,
      ));
    });

    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, stations) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, stations);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  const handleMarkerClick = (evt, station) => {
    x.set(0);

    if (handlePress) handlePress(station);

    const pos = evt.target.getBoundingClientRect();
    const doc = document.documentElement;

    let top = pos.top + (window.pageYOffset || doc.offsetTop || 0);
    let left = pos.left + (window.pageXOffset || doc.offsetLeft || 0);
    let distanceFromRight = (window.innerWidth || doc.offsetWidth || 0) - pos.right - 30;

    if (pos.x < 650) {
      left = left + 125;
    }
    else if (distanceFromRight < 120) {
      left = left - 125;
    }

    if (top > ((window.innerHeight || doc.offsetHeight) / 2 - 40)) {
      top = top - 300;
    }

    setInfoWindowPos([
      top,
      left
    ]);
  }

  const handleContainerKeyDown = evt => {
    if ((evt.key === 'Escape' || evt.key === 'Esc') && selectedStation) {
      callParentHandlePress();
    }
  }

  const handleRightArrowClick = () => {
    const rounded = Math.round(x.get());

    if (selectedStation && rounded <= 0 && rounded > 3 * -300) {
      x.set(rounded - 300);
    }
  }

  const handleLeftArrowClick = () => {
    const rounded = Math.round(x.get());

    if (selectedStation && rounded < 0 && rounded > 4 * -300) {
      x.set(rounded + 300);
    }
  }

  const handleOpenStation = () => {
    openStationScreen(selectedStation);
  }

  const callParentHandlePress = () => {
    if (handlePress) handlePress(null);

    x.set(0);
  }

  if (!isLoaded) {
    return (
      <div style={{ width: '100%', height: '100%', zIndex: 10, display: 'flex', alignItems: 'center', justifyContent: 'center', background: theme.colors.background }}>
        <p style={{ color: theme.text.primary }}>{t('map.init.loading')}</p>
      </div>
    )
  };

  if (loadError) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: theme.colors.background }}>
        <Lottie
          options={defaultOptions}
          width={350}
          height={350}
          isClickToPauseDisabled={true}
          style={{ marginTop: -50 }}
        />
        <p style={{ color: theme.text.primary }}>{t('map.init.error')}</p>
        <button onClick={() => history.go(0)} style={{ marginTop: '10px', color: theme.text.secondary, cursor: 'pointer' }}>Clique aqui para tentar novamente</button>
      </div>
    )
  };

  return (
    <Container
      initial={{ opacity: 0, transition: { duration: 1 }}}
      animate={{ opacity: 1, transition: { duration: 1 }}}
      exit={{ opacity: 0, transition: { duration: 1 }}}
      onKeyDown={handleContainerKeyDown}
    >
      <Search
        addressValue={addressValue}
        updateAddressSugestions={updateAddressSugestions}
      />
      <Wrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
          center={center}
          zoom={zoom}
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, stations)}
          yesIWantToUseGoogleMapApiInternals
          hoverDistance={30}
          fullscreenControl={false}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: false,
            disableDefaultUI: true,
            styles: theme.title === 'dark' ? DarkMap : LightMap
          }}
          onClick={e => {
            if (!e.event.target.closest('.infowindow') && size.width > 1024) {
              handlePress(null);
              x.set(0);
            }
          }}
          onZoomAnimationStart={size.width > 1024 ? callParentHandlePress : null}
          onDrag={size.width > 1024 ? callParentHandlePress : null}
        >
          {
            stations.map((station, index) => {
              const { latitude, longitude, 'is-premium': isPremium } = station.attributes;

              return (
                <MapMarker
                  key={index}
                  className={station.id === selectedStationId ? 'map-marker selected' : 'map-marker'}
                  lat={latitude}
                  lng={longitude}
                  isPremium={isPremium}
                  bg={`${SERVER_ADDRESS}${station.attributes['avatar-thumb']}`}
                  onClick={(evt) => size.width > 1024 ? handleMarkerClick(evt.nativeEvent, station) : handlePress(station)}
                >
                </MapMarker>
              )
            })
          }

        </GoogleMapReact>

        <AnimatePresence exitBeforeEnter>
          {
            selectedStation && size.width > 1024 && (
              <CustomInfoWindow
                className='infowindow'
                top={infoWindowPos[0]}
                left={infoWindowPos[1]}
                initial={{ opacity: 0, transition: { duration: .15 }}}
                animate={{ opacity: 1, transition: { duration: .15 }}}
                exit={{ opacity: 0, transition: { duration: .15 }}}
              >
                <StationImages>
                  { false && (
                    <Arrows>
                      <LeftArrow onClick={handleLeftArrowClick}>
                        <Icon
                          path={mdiChevronLeft}
                          size='24px'
                          color={theme.text.primary}
                        />
                      </LeftArrow>
                      <RightArrow onClick={handleRightArrowClick}>
                        <Icon
                          path={mdiChevronRight}
                          size='24px'
                          color={theme.text.primary}
                        />
                      </RightArrow>
                    </Arrows>
                  ) }
                  <StationImagesSlider
                    style={{ x }}
                  >
                    <StationImage src={`${SERVER_ADDRESS}${selectedStation.attributes['avatar-thumb']}`} />
                  </StationImagesSlider>
                </StationImages>
                <StationDetails>
                  <StationDetailsRow>
                    <StationTitle>{selectedStation.attributes.name}</StationTitle>
                    <StationRating>
                      <StationStars>
                        <Icon
                          path={mdiStar}
                          size='18px'
                        />
                      </StationStars>
                      <StationRatingText>{selectedStation.attributes['answered-proposals']}</StationRatingText>
                    </StationRating>
                  </StationDetailsRow>
                  <StationAddress>
                    <Icon
                      path={mdiMapMarkerOutline}
                      size='18px'
                    />
                    <StationAddressText>{selectedStation.attributes.city}</StationAddressText>
                  </StationAddress>
                  <BtnOpenStation onClick={handleOpenStation}>Ver detalhes</BtnOpenStation>
                </StationDetails>
              </CustomInfoWindow>
            )
          }
        </AnimatePresence>
      </Wrapper>

    </Container>
  )
}

const Search = ({ addressValue, updateAddressSugestions }) => {
  const {
    ready,
    suggestions: {status, data},
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "br" }
    },
    debounce: 80,
  });

  useEffect(() => {
    if (ready) {
      setValue(addressValue);

      if (status === "OK") {
        updateAddressSugestions(data);
      }
    }
  }, [addressValue])

  return false
}

export default Map;
