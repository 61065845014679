import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { SwipeableDrawer } from './styles';

import AdApproval from '../../pages/AdApproval';
import AdApproved from '../../pages/AdApprovedPayment';
import Profile from '../../pages/Profile';

export default function DrawerInnerApplication({ drawerShown, setDrawerShown }) {

  const location = useLocation();
  const history = useHistory();
  const [componentToRender, setComponentToRender] = useState(AdApproval);

  useEffect(() => {
    handleLocationChange();
  }, []);

  history.listen((location, action) => {
    handleLocationChange();
  });

  const handleExit = () => {
    history.goBack();
  }

  const handleLocationChange = () => {
    const { pathname } = history.location;

    if (pathname.match(/^\/ad-approval\/?$/)) {
      setComponentToRender(AdApproval);
      setDrawerShown(true);
    }
    else if (pathname.match(/^\/ad-approved\/?$/)) {
      setComponentToRender(AdApproved);
      setDrawerShown(true);
    }
    else if (pathname.match(/^\/profile\/?\S*?$/)) {
      setComponentToRender(Profile);
      setDrawerShown(true);
    }
    else {
      setDrawerShown(false);
    }
  }

  const ComponentToRender = componentToRender;

  return (
    <SwipeableDrawer
      anchor={'right'}
      open={drawerShown}
      variant="temporary"
      onEscapeKeyDown={handleExit}
      onBackdropClick={handleExit}
    >
      <div style={{ width: '500px' }}>
        <ComponentToRender />
      </div>
    </SwipeableDrawer>
  );
}
