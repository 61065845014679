import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ButtonBase } from '@material-ui/core';

export const Container = styled.div`
  position: relative;
`;

export const StationInner = styled(motion.div)`
  position: relative;
  z-index: 5;
`;

export const StationContainer = styled(ButtonBase)`
  display: flex;
  align-items: center !important;
  width: 100% !important;
  padding: 16px 20px !important;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background} !important;
  position: relative !important;
`;

export const StationImg = styled.div`
  width: ${props => props.type === 'stations' ? '60px' : '80px' };
  height: 60px;
  border-radius: 5px;
  background: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  margin-right: 15px;
  position: relative;
`;

export const StationContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-right: 10px;
`;

export const StationTitle = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 14px;
`;

export const StationAddress = styled.p`
  color: ${props => props.theme.text.secondary};
  font-size: 14px;
`;

export const InactiveAlert = styled.div`
  width: 24px;
  height: 24px;
  background: ${props => props.theme.colors.error};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StationActions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
  width: 150px;
`;

export const BtnEditStation = styled(motion.div)``;

export const BtnEditStationInner = styled(ButtonBase)`
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  margin: 0 10px !important;
  background: ${props => props.theme.colors.primary} !important;
`;

export const BtnDeleteStation = styled(motion.div)``;

export const BtnDeleteStationInner = styled(ButtonBase)`
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  margin: 0 10px !important;
  background: ${props => props.theme.colors.error} !important;
`;

export const StationAdVideo = styled.video`
  width: 80px;
  height: 60px;
  border-radius: 5px;
  margin-right: 15px;
`;

export const PopLine = styled.p`
  padding: 10px;
`;

export const ButtonDelete = styled(ButtonBase)``;

export const Badge = styled.p`
  color: ${props => props.theme.text.primary};
  background-color: ${props => props.type === 'system' ? 'orangered' : props.type === 'owner' ? 'dodgerblue' : 'green'};
    border-radius: 40px;
  padding: 1px 5px;
  font-size: 10px;
  font-weight: bold;
`;