import React, { useState, useContext } from 'react';
import MDIcon from '@mdi/react';
import Moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
  mdiDeleteOffOutline,
} from '@mdi/js';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

import { ThemeContext } from 'styled-components';
import { SERVER_ADDRESS } from '../../constraints/types';
import Popover from '@material-ui/core/Popover';

import {
  Container,
  StationInner,
  StationContainer,
  StationImg,
  StationContent,
  StationTitle,
  StationAddress,
  StationAdVideo,
  PopLine,
  ButtonDelete,
  Badge,
} from './styles';
import { deleteOwnerAd } from '../../store/actions/AdsActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdItemOwner = ({ ad, key, deleteOwnerAd, email, token, maxOwner, stationAds }) => {
  const theme = useContext(ThemeContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverType, setPopoverType] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteAdId, setDeleteAdId] = useState(null);

  const handlePopoverClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setPopoverType(type);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const confirmDeleteAd = (serviceStationId) => {
    setConfirmOpen(true);
    setDeleteAdId(serviceStationId);
  }

  const cancelDeleteAd = () => {
    setConfirmOpen(false);
  }

  const handleDeleteOwnerAd = () => {
    const credentials = { email, token };
    setConfirmOpen(false);
    deleteOwnerAd(credentials, deleteAdId);
  }

  const open = Boolean(anchorEl);
  const checkExists = _.find(stationAds.data, function(o) { return o.service_station_id === ad.service_station_id; });

  if (!checkExists) return false;

  return (
    <Container
      key={key}
      onContextMenu={(e) => e.preventDefault()}
    >
      <StationInner>
        <StationContainer onClick={(e) => handlePopoverClick(e, ad.type)}>
          { !ad.video &&
            <StationImg
              bg={`${SERVER_ADDRESS}${ad.image}`}
            />
          }
          { ad.video &&
            <StationAdVideo
              autoPlay={true}
              muted={true}
              controls={false}
              loop={true}
            >
              <source
                src={ad.video}
                type={ad.video_content_type}
              />
              Your browser does not support the video tag.
            </StationAdVideo>
          }
          <StationContent>
            <Badge type={ad.type}>
              { ad.type === 'system' && "telesapp" }
              { ad.type === 'owner' && "dono" }
              { ad.type === 'paid' && "pago" }
            </Badge>
            <StationTitle>
              Data de expiração:
            </StationTitle>
            <StationAddress>
              { ad.type === 'system' && maxOwner && "Até ser substítuído por pago" }
              { ad.type === 'system' && !maxOwner && "Até ser substítuído por pago ou por dono" }
              { ad.type === 'owner' && "Até ser substítuído por pago" }
              { ad.type === 'paid' && Moment(ad.expiration).format('DD/MM/YYYY') }
            </StationAddress>
          </StationContent>
          { ad.type === 'owner' &&
            <ButtonDelete onClick={() => confirmDeleteAd(ad.service_station_id)}>
              <MDIcon
                path={mdiDeleteOffOutline}
                size='24px'
                color={theme.text.primary}
              />
            </ButtonDelete>
          }
        </StationContainer>
      </StationInner>
      <Dialog
          open={confirmOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={cancelDeleteAd}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">
            Confirmar exclusão de anúncio
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Você realmente deseja excluir este anúncio? Será atualizado no player a partir do próximo ciclo.
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={cancelDeleteAd} color="primary">
              Cancelar
          </Button>
          <Button onClick={handleDeleteOwnerAd} color="primary">
              Confirmar
          </Button>
          </DialogActions>
      </Dialog>
      { !confirmOpen &&
        <Popover
          id={key}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <PopLine>
            { popoverType === 'paid' && "Execute o MI player no seu painel para exibir o anúncio até sua data de expiração."}
            { popoverType === 'system' && "Anúncios do sistema correspondem a 30% dos anúncios não pagos."}
            { popoverType === 'owner' && "Anúncios do dono do painel correspondem a 70% dos anúncios não pagos."}
          </PopLine>
        </Popover>
      }
    </Container>
  );
}

const mapStateToProps = state => ({
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token,
  stationAds: state.AdsReducer.stationAds,
});

export default connect(mapStateToProps, { deleteOwnerAd })(AdItemOwner);