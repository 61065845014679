import { ButtonBase } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ yAxis }) => css`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    z-index: 1;
    bottom: 0px;
    padding: 30px;
    background: rgb(0 68 76 / 95%);
    color: #ffffff;
    -webkit-box-align: center;
    align-items: center;
    flex: 1 1 0%;
    width: 100vw;

    transform-origin: bottom;
    transition: transform 300ms;
    transform: scaleY(${yAxis});
    -webkit-transform: scaleY(${yAxis});
    -moz-transform: scaleY(${yAxis});
    -o-transform: scaleY(${yAxis});
    -ms-transform: scaleY(${yAxis});

    @media screen and (max-width: 960px) {
      flex-direction: column;
    }
  `}
`;

export const Description = styled.div`
    margin: 0px;
    padding: 0px;
    outline: none;
    list-style: none;
    border: 0px;
    background: none;
    text-decoration: none;
    line-height: 1.5;

    @media screen and (max-width: 960px) {
      text-align: center;
      padding-bottom: 20px;
    }
`;

export const Title = styled.p`
    font-family: 'Quicksand',Arial,Helvetica,sans-serif !important;
    font-size: 20px;
    letter-spacing: 0px;
    color: #ffffff;
    padding-bottom: 10px;
`;

export const Paragraph = styled.p`
    font-family: 'Quicksand',Arial,Helvetica,sans-serif !important;
    font-size: 12px;
    font-size: 14px;
    line-height: 1.5;
`;

export const Link = styled.a`
    color: #ffffff;
    &:hover {
      color: #308c67;
    }
    &:active {
      color: #ffffff;
    }
`;

export const Agree = styled(ButtonBase)`
    background-color: #166749 !important;
    text-align: center;
    font-weight: 600 !important;
    font-size: 15px;
    letter-spacing: 0px !important;
    padding: 0px 10px !important;
    color: #ffffff;
    text-transform: uppercase;
    height: 50px;
    margin-right: 40px !important;
    border-radius: 3px !important;

    @media screen and (max-width: 960px) {
      margin-right: 0px !important;
    }
`;
