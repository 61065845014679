import styled from 'styled-components';
import { Button, ButtonBase, Drawer } from '@material-ui/core';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};

  .MuiPaper-root,.MuiMenu-paper,.MuiPopover-paper,.MuiPaper-elevation2,.MuiPaper-rounded {
    left: 50% !important;
  }

  @media screen and (min-width: 1024px) {
    position: relative;
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 55;
  background: ${props => props.theme.colors.background};
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
`;

export const BackButton = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: transparent !important;
`;

export const HeaderTitle = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  text-align: center;
  margin: auto;
  flex: 1;
`;

export const GhostButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  pointer-events: none;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (min-width: 1024px) {
    height: auto !important;
  }
`;

export const Heading = styled.p`
  font-size: 1.4rem;
  font-weight: 300;
  color: ${props => props.theme.text.primary};
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

export const SubHeading = styled.p`
  font-size: 1.2rem;
  font-weight: 300;
  color: ${props => props.theme.text.secondary};
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

export const MediaInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 15px 0;
`;

export const MediaContainer = styled.div`
  width: 100%;
  height: 200px;
  background: url(${props => props.avatar});
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 15px;
`;

export const VideoContainer = styled.video`
  width: 100%;
  height: auto;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
`;

export const PayButtonCard = styled(Button)`
  flex: 1;
  font-weight: 700 !important;
  color: #fff !important;
  margin-right: 15px !important;
  border-radius: 5px !important;
  height: 40px;
  background: #27b27a !important;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const PayButtonPix = styled(Button)`
  flex: 1;
  font-weight: 700 !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const PixIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const PaymentSuccessButton = styled(Button)`
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  user-select: none;
  min-height: 56px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-appearance: none !important;
  outline: none;
  width: 100%;
  font-weight: 700 !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const CustomDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    display: flex !important;
    flex-direction: column !important;
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background} !important;
    padding: 20px 20px 80px 20px;
    height: 100% !important;
    position: relative !important;
    overflow: hidden auto !important;
  }

  @media screen and (min-width: 1024px) {

    .MuiDrawer-paper {
      top: 0 !important;
      flex: 1 0 auto !important;
      height: 100% !important;
      display: flex !important;
      width: 500px !important;
      position: fixed !important;
    }
  }
`;

export const DrawerHeading = styled.p`
  font-weight: 700;
  color: ${props => props.theme.text.primary};
`;

export const DrawerSubheading = styled.p`
  color: ${props => props.theme.text.secondary};
  margin: 5px 0 15px 0;
  margin-bottom: ${props => props.addingData ? '35px' : '15px'};
`;

export const DrawerMain = styled.div`
  display: flex;
  flex-direction: column;
  transform: ${props => props.hide ? 'scale(.95)' : 'scale(1)'};
  opacity: ${props => props.hide ? 0 : 1};
  pointer-events: ${props => props.hide ? 'none' : 'auto'};
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  flex: 1;
`;

export const DrawerList = styled(motion.div)`
  flex: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
`;

export const PaidDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background: ${props => props.theme.colors.secondary} !important;
    border-radius: 10px 10px 0 0 !important;
  }

  @media screen and (min-width: 1024px) {
    .MuiDrawer-paper {
      margin: 0 320px !important;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      max-height: 90% !important;
      overflow-y: auto !important;
    }
  }
`;

export const PaidDrawerContainer = styled.div`
  width: 100%;
  padding: 30px 20px 80px 20px;
`;

export const CardContainer = styled(ButtonBase)`
  margin-bottom: 30px !important;
  border-radius: 5px !important;
`;

export const EmptyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyIllustration = styled.img`
  width: 40%;
  height: 150px;
  object-fit: contain;
  align-self: center;
  margin-top: -80px;
`;

export const EmptyMessage = styled.p`
  color: ${props => props.theme.text.primary};
  text-align: center;
`;

export const DrawerFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};

  @media screen and (min-width: 1024px) {
    position: absolute;
  }
`;

export const DrawerActionButton = styled(ButtonBase)`
  font-weight: 700 !important;
  background: ${props => props.disabled ? props.theme.text.secondary : props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  flex: 1;

  &:first-of-type {
    margin-right: 15px !important;
    background: ${props => props.theme.colors.secondary} !important;
    color: ${props => props.theme.text.primary} !important;
    border: 1px solid ${props => props.theme.separator} !important;
  }
`;

export const CardSelectedWrapper = styled.div`
  border-style: ${props => props.selected ? 'solid' : 'none'};
  border-color: ${props => props.selected ? 'rgb(39, 164, 178)' : 'none'};
  border-width: ${props => props.selected ? '3px' : 'none'};
  border-radius: ${props => props.selected ? '18px' : 'none'};
`;

export const QrCodeContainer = styled.section`
  margin-right: auto;
  margin-left: auto;
  font-size: .875rem;
  text-align: center;
  margin-bottom: 4rem;
  align-items: center;
  flex-direction: column;
  background-
  display: flex;
`;

export const QrCodeBox = styled.div`
  line-height: 1.15;
  text-align: center;
  font-size: .875rem;
  display: block;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  color: ${props => props.theme.text.primary};
  background-color: ${props => props.theme.colors.secondary};
`;

export const QrCodeTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 0;
  font-weight: 800;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

export const QrCodeSubtitle = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-size: .875rem;
`

export const QrCode = styled.img`
  width: 12em;
  height: 12em;
  mix-blend-mode: normal;
  border-style: solid;
  border-color: ${props => props.theme.colors.primary};
  border-width: .125rem;
  border-radius: 10px;
  padding: .5rem;
  margin: 1rem;
`;

export const QrCodeFooterTitle = styled.p`
  font-size: 1.5rem;
`;

export const QrCodeCopyToClipboard = styled(Button)`
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  user-select: none;
  min-height: 56px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-appearance: none !important;
  outline: none;
  width: 100%;
  font-weight: 700 !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const WatingPaymentText = styled.p`
  font-color: ${props => props.theme.colors.primary};
  margin-bottom: 12px;
`;

export const PageTitle = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 30px;
  white-space: pre-line;
`;
