import React, { useContext, useState } from 'react';

import { ThemeContext } from 'styled-components';
import { Icon } from '@iconify/react';
import reactStringReplace from 'react-string-replace';
import roundCheckCircle from '@iconify/icons-ic/round-check-circle';
import roundError from '@iconify/icons-ic/round-error';
import roundAddCircle from '@iconify/icons-ic/round-add-circle';
import roundPowerSettingsNew from '@iconify/icons-ic/round-power-settings-new';
import roundNotifications from '@iconify/icons-ic/round-notifications';
import VideoThumbnail from 'react-video-thumbnail';
import { formatDistanceToNow } from 'date-fns';

import { Badge, NotificationContent, NotificationImg, NotificationTimestamp, Notification, NotificationWrapper, NotificationTitle, NotificationDot } from './styles';

const loadingImgPathDark = require('../../assets/images/loading_image_animated_dark.gif');

const loadingImgPathLight = require('../../assets/images/loading_image_animated_light.gif');

const NotificationItem = ({ notification, index, handleReadNotification, locale }) => {
  const theme = useContext(ThemeContext);
  const [thumb, setThumb] = useState('');

  const renderTitle = (notification) => {
    const { read, important, content, stationName } = notification;

    if (important) {
      return (
        <NotificationTitle bold={(!read || important) ? 1 : 0}>{content}</NotificationTitle>
      )
    }

    return (
      <NotificationTitle bold={(!read || important) ? 1 : 0}>
        {
          reactStringReplace(content, stationName, (match, i) => (
            <span key={i} style={{ color: theme.colors.primary }}>{match}</span>
          ))
        }
      </NotificationTitle>
    )
  }

  const formatNotificationDate = (date) => {
    let interval;

    try {
      interval = formatDistanceToNow(date, {includeSeconds: true, addSuffix: true, locale });
    } catch(err) {
      interval = err.message;
    }
    return interval
  }

  const renderBadge = (notification) => {
    const { type, important, read } = notification;

    let badge;
    let bg;

    switch (type) {
      case 'alert':
        badge = roundError;
        bg = '#0F1112';
        break;
      case 'new-request':
        badge = roundAddCircle;
        bg = theme.colors.warning;
        break;
      case 'activation':
        badge = roundPowerSettingsNew;
        bg = theme.colors.error;
        break;
      case 'success':
        badge = roundCheckCircle;
        bg = theme.colors.primary;
        break;
      default:
        badge = roundNotifications;
        bg = theme.colors.primary;
        break;
    }

    return (
      <Badge {...{ bg, important, read }}>
        <Icon
          icon={badge}
          width={12}
          height={12}
          color='#fff'
        />
      </Badge>
    )
  }
  
  return (
    <NotificationWrapper
      key={notification.id}
      initial={{ opacity: 0, y: 20, transition: { duration: .3, easing: 'cubic-bezier(.785,.135,.15,.86)' }}}
      animate={{ opacity: 1, y: 0, transition: { delay: .15 * index, duration: .3, easing: 'cubic-bezier(.785,.135,.15,.86)' }}}
    >
      <Notification
        read={notification.read ? 1 : 0}
        important={notification.important ? 1 : 0}
        onClick={() => handleReadNotification(notification, index)}
      >

        { /* PERFORMANCE ISSUES WHEN RENDERING VIDEO THUMBS :(
          (notification.contentType.split('/')[0] === 'video') &&
          <>
            <NotificationImg bg={thumb ? thumb : (theme.title === 'dark' ? loadingImgPathDark : loadingImgPathLight)}>
              { renderBadge(notification) }
            </NotificationImg>
            <div style={{ display: 'none' }}>
              <VideoThumbnail
                videoUrl={notification.img}
                thumbnailHandler={(thumbnail) => setThumb(thumbnail)}
              />
            </div>
          </>
        */ }
        {
          (notification.type !== 'activation' && notification.contentType.split('/')[0] === 'video') &&
          <NotificationImg bg={(theme.title === 'dark' ? loadingImgPathDark : loadingImgPathLight)}>
            { renderBadge(notification) }
          </NotificationImg>
        }
        { (notification.type !== 'activation' && notification.contentType.split('/')[0] !== 'video') &&
          <NotificationImg bg={notification.img}>
            { renderBadge(notification) }
          </NotificationImg>
        }
        { (notification.type === 'activation') &&
          <NotificationImg bg={notification.img}>
            { renderBadge(notification) }
          </NotificationImg>
        }
        <NotificationContent>
          { renderTitle(notification) }
          <NotificationTimestamp important={notification.important ? 1 : 0}>{ formatNotificationDate(notification.date) }</NotificationTimestamp>
        </NotificationContent>
        { !notification.read && !notification.important && <NotificationDot /> }
      </Notification>
    </NotificationWrapper>
  );
}

export default NotificationItem;
