import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  display: ${props => props.size.width < 1024 ? 'block' : 'none'};
  pointer-events: ${props => props.open ? 'auto' : 'none'};
  width: 100%;
  height: 100%;
  z-index: 1005;
`;

export const Sheet = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 30px);
  overflow-y: auto;
  z-index: 1000;
  background: ${props => props.theme.colors.secondary};
  border-radius: 15px 15px 0 0;
  pointer-events: auto;
  padding: 16px;
  -webkit-user-drag: auto !important;
  user-select: none !important;
  touch-action: auto !important;
  -ms-touch-action: auto !important;
`;
