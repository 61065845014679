import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';

export const Container = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  z-index: 35;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  transform: translateY(100%);
  transform: ${props => props.searching ? 'translateY(100%)' : 'translateY(0)'};
  border-top: 1px solid ${props => props.theme.separator};

  &.hide {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export const NavButtons = styled.nav`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Button = styled(ButtonBase)`
  flex: 1 !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;

  > svg {
    transition: all .3s cubic-bezier(.785,.135,.15,.86) !important;
    transform: ${props => props.active ? 'translateY(0)' : 'translateY(10px)'} !important;

    > path {
      transition: all .15s ease !important;
      fill: ${props => props.active ? props.theme.iconTintColor.active : props.theme.iconTintColor.inactive} !important;
    }
  }
`;

export const ButtonText = styled.p`
  color: ${props => props.active ? props.theme.iconTintColor.active : props.theme.iconTintColor.inactive};
  font-size: 12px;
  text-align: center;
  display: block;
  height: 16px;
  margin-top: 4px;
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  transform: ${props => props.active ? 'translateY(0)' : 'translateY(10px)'};
  opacity: ${props => props.active ? 1 : 0};
`;