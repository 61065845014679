import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Function used to change the tab, in both the top and bottom bar
export default function useCurrentPage () {
  const location = useLocation();
  function getCurrentPage () {
    const { pathname } = location;
    if (pathname.match(/^\/station\/?\d*?$/)) {
      return 1;
    }
    if (pathname.match(/^\/announce\/?\S*?$/)) {
      return currentPage;
    }

    if (pathname.match(/^\/station\/?\S*?\/activation$/)) {
      return currentPage;
    }
    if ('/') {
      return 0;
    }
  }

  const [ currentPage, setCurrentPage ] = useState(getCurrentPage);

  useEffect(() => {
    function handleRefresh () {
      setCurrentPage(getCurrentPage());
    }

    window.addEventListener('beforeunload', handleRefresh);

    return function cleanup () {
      window.removeEventListener('beforeunload', handleRefresh);
    }
  }, []);

  useEffect(() => {
    setCurrentPage(getCurrentPage());
  }, [location.pathname]);

  return { currentPage, setCurrentPage };
}
