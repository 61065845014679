import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ThumbsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 90px);
  grid-gap: 15px;
  width: 100%;
  pointer-events: auto;
  opacity: 1;
  transition: all .02s ease-in-out;

  &.hide {
    pointer-events: none;
    opacity: 0;
  }
`;

export const Thumb = styled.img`
  border-radius: 5px;
  height: 90px;
  transition: all .15s ease-in-out;
  overflow: hidden;
  object-fit: cover;

  @media screen and (min-width: 1024px) {
    cursor: pointer;

    &:hover {
      filter: contrast(80%);
    }
  }
`;

export const CropContainer = styled.div`
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transition: all .02s ease-in-out;
  position: absolute;
  left: 0;

  &.show {
    pointer-events: auto;
    opacity: 1;
  }
`;

export const Footer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 15;
  opacity: 0;
  pointer-events: none;
`;

export const LeftButton = styled.button`
  flex: 1;
  border-radius: 5px;
  font-weight: 700;
  color: ${props => props.theme.text.primary};
  border: 1px solid ${props => props.theme.separator};
  margin-right: 15px;
  border-radius: 5px;
  height: 40px;
  background: ${props => props.theme.colors.secondary};
  cursor: pointer;
  pointer-events: ${props => props.action !== 'select' ? 'auto' : 'none'};
`;

export const RightButton = styled.button`
  flex: 1;
  border-radius: 5px;
  font-weight: 700;
  background: ${props => props.theme.colors.primary};
  color: #fff ;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
  pointer-events: ${props => (props.action !== 'select' && props.action !== 'sending') ? 'auto' : 'none'};
`;

export const CanvasContainer = styled.div`
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transition: all .02s ease-in-out;
  position: absolute;
  left: 0;
  transition-delay: .3s;

  &.show {
    pointer-events: auto;
    opacity: 1;
  }
`;

export const AvatarWrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
`

export const Avatar = styled.img`
  width: 80%;
  border-radius: 5px;

  @media screen and (min-width: 1024px) {
    cursor: pointer;

    &:hover {
      filter: contrast(80%);
    }
  }
`;

export const AvatarTitle = styled.span`
  position: absolute;
  left: 5px;
  top: 5px;
  color: ${props => props.theme.text.primary};
  font-size: 1.1rem;
  font-weight: 700;
  background-color:rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 5px;
`;