import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ButtonBase } from '@material-ui/core';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 16;
  pointer-events: none;

  @media screen and (min-width: 1024px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 16;
    pointer-events: none;
    padding: 15px;
  }
`;

export const Bubble = styled(motion.div)`
  position: absolute;
  right: 5px;
  top: 100px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  transition: opacity .15s ease-out;
  transform: ${props => props.screenShow ? 'scale(.4)' : 'scale(1)'};
  opacity: ${props => props.screenShow ? 0 : 1};
  pointer-events: ${props => props.screenShow ? 'none' : 'auto'};
  border: 1px solid #006973;
`;

export const Screen = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.secondary};
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  transition: all .15s cubic-bezier(.785,.135,.15,.86);
  opacity: ${props => props.screenShow ? 1 : 0};
  transform: ${props => props.screenShow ? 'scale(1)' : 'scale(.97)'};
  pointer-events: ${props => props.screenShow ? 'auto' : 'none'};
`;

export const Title = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 30px;
`;

export const UploadList = styled.ul``;

export const UploadItem = styled.li`
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
`;

export const UploadItemImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
`;

export const UploadItemTitle = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.text.primary};
  margin-left: 15px;
`;

export const UploadItemProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: auto;
`;

export const BtnMinimize = styled(ButtonBase)`
  flex: 0 0 40px;
  margin-top: auto !important;
  background: transparent !important;
  border: 1px solid ${props => props.theme.separator} !important;
  border-radius: 5px !important;
  color: ${props => props.theme.text.primary} !important;
`;

export const VideoContainer = styled.div`
 background-color: ${props => props.type == 'mp4' ? 'purple' : 'green'};
 width: 40px;
 height: 40px;
 border-radius: 5px;
`;

export const ExtText = styled.p`
 text-align: center;
 verticalAlign: middle;
 line-height: 40px;
 color: #ffffff;
`;
