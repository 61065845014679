import React from 'react';

import { Container, Heading, GoBack } from './styles';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';

import useWindowSize from '../../utils/hooks/useWindowSize';

import animationData from '../../assets/images/404.json';

const NotFound = () => {
  const history = useHistory();
  const size = useWindowSize();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  };

  return (
    <Container>
      <Lottie
        options={defaultOptions}
        width={350}
        height={350}
        isClickToPauseDisabled={true}
        style={{ marginTop: size.width < 1024 ? -50 : 0 }}
      />
      <Heading>Eita, parece que você se perdeu!</Heading>
      <GoBack tabIndex='0' onClick={() => history.goBack()}>Clique aqui para voltar para a página anterior</GoBack>
    </Container>
  )
}

export default NotFound;
