import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IconButton } from '@material-ui/core';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.secondary};
  z-index: 200;
  padding: 20px;

  @media screen and (min-width: 1024px) {
    position: relative;
    width: calc(${props => props.width}px - 640px);
    height: calc(${props => props.height}px - 140px);
    min-width: 600px;
    border-radius: 15px;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
      border-radius: 2px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: ${props => props.theme.colors.primary ? props.theme.colors.background : props.theme.text.secondary};
      border-radius: 2px;
    }
  }
`;


export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 0 20px;

  @media screen and (min-width: 1024px) {
    position: unset;
    padding: 0px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Title = styled.p`
  color: ${props => props.theme.text.secondary};
  font-weight: 700;
`;

export const BtnClose = styled(IconButton)`
  width: 40px;
  height: 40px;
  transform: translateX(10px);
  margin-left: auto !important;

  > span > svg > path {
    fill: ${props => props.theme.iconTintColor.inactive} !important;
  }
`;

export const Indicator = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  margin-top: 3px;
  background: ${props => props.theme.iconTintColor.inactive};
`;

export const TextContainer = styled.div`
  width: 100%;
  padding: 60px 0 20px 0;
  white-space: pre-line;

  @media screen and (min-width: 1024px) {
    padding-top: 20px;
  }
`;