import React, { useContext } from 'react';

import { mdiArrowTopLeft, mdiOrderBoolAscendingVariant } from '@mdi/js';
import outlineWatchLater from '@iconify/icons-ic/outline-watch-later';
import magnifyingGlass from '@iconify-icons/ph/magnifying-glass';
import { ThemeContext } from 'styled-components';
import { Icon, InlineIcon } from '@iconify/react';
import MDIcon from '@mdi/react';
import Fuse from 'fuse.js';

import {
  SearchTermsList,
  ButtonSearchTerm,
  SearchTerm,
  ButtonEditSearchTerm
} from './styles';


const AutoCompleteList = ({ searchTerms, historyData, handleSearch, editSearchTerm, searchValue, activeTab }) => {
  
  var searchOptions = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 120,
    maxPatternLength: 120,
    minMatchCharLength: 1,
    keys: activeTab === 0 ? [ 'name' ] : activeTab === 1 ? [ 'uniquename' ] : [ 'name' ]
  };

  const theme = useContext(ThemeContext);

  var fuse = new Fuse(searchTerms, searchOptions);
  var autocompleteResults = null;

  // Function that returns matched text with searchValue
  const results = () => {

    autocompleteResults = fuse.search(searchValue);

    return (
      <>
        {
          autocompleteResults.map((item, index) => {
            let title;
            if (activeTab === 0) title = item.item.name;
            if (activeTab === 1) title = item.item.uniquename;
            if (activeTab === 2) title = item.item.name;

            return (
              <ButtonSearchTerm key={index} onClick={(evt) => handleSearch(evt, title)}>
                <Icon
                  icon={magnifyingGlass}
                  width={24}
                  height={24}
                  color={theme.iconTintColor.inactive}
                />
                <SearchTerm>{activeTab === 1 ? `@${title}` : title}</SearchTerm>
                <ButtonEditSearchTerm onClick={() => editSearchTerm(title)}>
                  <MDIcon
                    path={mdiArrowTopLeft}
                    size='24px'
                    color={theme.iconTintColor.inactive}
                  />
                </ButtonEditSearchTerm>
              </ButtonSearchTerm>
            )
          })
        }
      </>
    );
  }

  // Function that returns previous history of searched data by user, shows only when searchValue is empty
  const history = () => {
    return (
      <>
        {
          historyData.map((item, index) => {
            return (
              <ButtonSearchTerm key={index} onClick={(evt) => handleSearch(evt, item)}>
                <Icon
                  icon={outlineWatchLater}
                  width={24}
                  height={24}
                  color={theme.iconTintColor.inactive}
                />
                <SearchTerm>{item}</SearchTerm>
                <ButtonEditSearchTerm onClick={() => editSearchTerm(item)}>
                  <MDIcon
                    path={mdiArrowTopLeft}
                    size='24px'
                    color={theme.iconTintColor.inactive}
                  />
                </ButtonEditSearchTerm>
              </ButtonSearchTerm>
            )
          })
        }
      </>
    );
  }

  return (
    <SearchTermsList>
      { (searchValue === '') && history() }
      { (searchValue !== '') && results() }
    </SearchTermsList>
  );
}

export default AutoCompleteList

/*
import React, { useContext } from 'react';

import { Container, List, Item, Link } from './styles';
import { ThemeContext } from 'styled-components';
import Fuse from 'fuse.js';

const AutoCompleteList = ({ style, searching, data, historyData, searchValue, setValueAndSearch, handleInputChange, localRemoveFromHistory }) => {

  const theme = useContext(ThemeContext);

  var searchOptions = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 120,
    maxPatternLength: 120,
    minMatchCharLength: 1,
    keys: [ 'name' ]
  };

  var fuse = new Fuse(data, searchOptions);
  var autocompleteResults = null;

  // Function that returns matched text with searchValue
  const results = () => {

    autocompleteResults = fuse.search(searchValue);

    return (
      <List>
        {
          autocompleteResults.map((item, index) => {
            const { name } = item.item;
            return (
              <Item key={index}>
                <Link href="#" onClick={() => setValueAndSearch(name)}>
                  { name }
                </Link>
                <Link href="#" onClick={() => { const event = { target: { value: name } }; handleInputChange(event); }}>
                  ↑
                </Link>
              </Item>
            )
          })
        }
      </List>
    );
  }
  // Function that returns previous history of searched data by user, shows only when searchValue is empty
  const history = () => {
    return (
      <List>
        {
          historyData.map((item, index) => {
            return (
              <Item key={index}>
                <Link href="#" onClick={() => setValueAndSearch(item)}>
                  { item }
                </Link>
                <Link href="#" onClick={() => localRemoveFromHistory(index, 'stations') }>
                  &times;
                </Link>
              </Item>
            )
          })
        }
      </List>
    );
  }

  return (
    <Container
      theme={theme}
      searching={searching}
      style={style}
    >
      { (searchValue !== '') && results() }

      { (searchValue === '') && history() }
    </Container>
  );
}

export default AutoCompleteList;

*/
