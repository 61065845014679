import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import AddStation from '../../pages/AddStation';
import AddService from '../../pages/AddService';
import StationActivation from '../../pages/StationActivation';
import ManageStationImgs from '../../pages/ManageStationImgs';
import About from '../About';
import Help from '../Help';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(37 234 255 / 10%)'
  },
  paper: {
    width: '1000px',
    height: '80%'
  },
}));

export default function ModalInnerApplication({ modalShown, setModalShown }) {
  const classes = useStyles();
  const history = useHistory();
  const [componentToRender, setComponentToRender] = useState(AddStation);

  useEffect(() => {
    handleLocationChange();
  }, []);

  history.listen((location, action) => {
    handleLocationChange();
  });

  const handleLocationChange = () => {
    const { pathname } = history.location;

    if (pathname.match(/^\/add-media-panel\/?\S*?$/)) {
      setComponentToRender(AddStation);
      setModalShown(true);
    }
    else if (pathname.match(/^\/announce\/?\S*?$/)) {
      setComponentToRender(AddService);
      setModalShown(true);
    }
    else if (pathname.match(/^\/station\/?\S*?\/activation$/)) {
      setComponentToRender(StationActivation);
      setModalShown(true);
    }
    else if (pathname.match(/^\/about\/?$/)) {
      setComponentToRender(About);
      setModalShown(true);
    }
    else if (pathname.match(/^\/help\/?$/)) {
      setComponentToRender(Help);
      setModalShown(true);
    }
    else if (pathname.match(/^\/manage-station-images\/?\S*?$/)) {
      setComponentToRender(ManageStationImgs);
      setModalShown(true);
    }
    else {
      setModalShown(false);
    }
  }

  const ComponentToRender = componentToRender;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={modalShown}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div>
        <ComponentToRender />
      </div>
    </Modal>
  );
}
