import _ from 'lodash';
import axios from 'axios';
import { database } from '../../firebase';
import Moment from 'moment';

import {
  SERVER_ADDRESS,
  ASYNC_GET_ALL_STATIONS,
  GET_ALL_STATIONS,
  GET_ALL_STATIONS_ERROR,
  UPDATE_TOKEN,
  ASYNC_SEARCH_STATIONS,
  ASYNC_SEARCH_STATIONS_WITH_TAG,
  ASYNC_SEARCH_STATIONS_WITH_CATEGORY,
  SEARCH_STATIONS,
  SEARCH_STATIONS_ERROR,
  SEARCH_STATIONS_WITH_TAG,
  SEARCH_STATIONS_WITH_TAG_ERROR,
  SEARCH_STATIONS_WITH_CATEGORY,
  SEARCH_STATIONS_WITH_CATEGORY_ERROR,
  REMOVE_STATION_HISTORY,
  UPDATE_ADDRESS_VALUE,
  UPDATE_ADDRESS_SUGESTIONS,
  UPDATE_ADD_STATION_DATA,
  ADD_STATION,
  ADD_STATION_SUCCESS,
  ADD_STATION_ERROR,
  EDIT_STATION,
  EDIT_STATION_SUCCESS,
  EDIT_STATION_ERROR,
  SEND_THUMB_TO_STATION,
  SEND_THUMB_TO_STATION_SUCCESS,
  SEND_THUMB_TO_STATION_ERROR,
  SENT_THUMB,
  GET_STATION,
  GET_STATION_SUCCESS,
  GET_STATION_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  CHECK_UNIQUENAME,
  CHECK_UNIQUENAME_SUCCESS,
  CHECK_UNIQUENAME_ERROR,
  GET_LAST_STATIONS,
  GET_LAST_STATIONS_SUCCESS,
  GET_LAST_STATIONS_ERROR,
  GET_USER_STATIONS,
  GET_USER_STATIONS_SUCCESS,
  GET_USER_STATIONS_ERROR,
  CALCULATE_PRICE,
  CALCULATE_PRICE_SUCCESS,
  CALCULATE_PRICE_ERROR,
  SELECT_STATIONS,
  UPDATE_VIEW_ORIGIN,
  UPDATE_SELECTING,
  UPDATE_THUMB_STATION,
  UPDATE_EDIT_STATION,
  UPDATE_SEND_REPORT,
  UPDATE_DELETE_STATION,
  LISTEN_STATION_ACTIVATION,
  STOP_LISTENING_ACTIVATION,
  MANAGE_STATION_IMAGES,
  MANAGE_STATION_IMAGES_SUCCESS,
  MANAGE_STATION_IMAGES_ERROR,
  SEND_REPORT,
  SEND_REPORT_SUCCESS,
  SEND_REPORT_ERROR,
  SYNC_PLAYER,
  SYNC_PLAYER_SUCCESS,
  SYNC_PLAYER_ERROR,
  UPDATE_SYNC_PLAYER,
  DELETE_STATION,
  DELETE_STATION_SUCCESS,
  DELETE_STATION_ERROR,
  BLOCK_STATION,
  BLOCK_STATION_SUCCESS,
  BLOCK_STATION_ERROR,
  UPDATE_BLOCK_STATION,
  UNBLOCK_STATION,
  UNBLOCK_STATION_SUCCESS,
  UNBLOCK_STATION_ERROR,
  UPDATE_UNBLOCK_STATION
} from '../../constraints/types';

import {
  development,
  production
} from '../../constraints/utils';

///////////////////// RETRIEVE ALL STATIONS INDEXES (AUTOCOMPLETE) BEGIN /////////////////////*
/*
  This function is called when user enter in application or reload the
  page, fetching all stations that able autocomplete functionallity
  when user is typing in the search input
*/

export const asyncGetAllStations = (credentials, previousData) => {
  return dispatch => {

    const stations = {
      data: previousData,
      error: false,
      loading: true
    }

    dispatch({ type: ASYNC_GET_ALL_STATIONS, payload: stations });
    getAllStations(dispatch, credentials, previousData);
  }
}

const getAllStations = (dispatch, credentials, previousData) => {
  const url = `${SERVER_ADDRESS}/api/v1/all-stations`
  axios.get(url, {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      'X-Access-Token': credentials.token,
      'X-User-Email': credentials.email,
    }
  })
  .then(res => {
    // Remover títulos duplicados
    let allNamesFiltered = _.uniqBy(res.data.names, function (e) { return e.name; });
    let allUniqueNamesFiltered = _.uniqBy(res.data.uniquenames, function (e) { return e.uniquename; });
    let allCategoriesFiltered = _.map(res.data.categories, function(c) { return _.omit(c, ['id'])});
    // Remover o campo id
    allNamesFiltered = _.map(allNamesFiltered, function(c) { return _.omit(c, ['id'])});
    allUniqueNamesFiltered = _.map(allUniqueNamesFiltered, function(c) { return _.omit(c, ['id'])});

    const result = { names: allNamesFiltered, uniquenames: allUniqueNamesFiltered, categories: allCategoriesFiltered }

    const stations = {
      data: result,
      error: false,
      loading: false
    }

    let newToken = credentials.token;

    if (res.headers['x-access-token']) {
      newToken = res.headers['x-access-token'];
      dispatch({ type: UPDATE_TOKEN, newToken });
    }
    dispatch({ type: GET_ALL_STATIONS, payload: stations });
  })
  .catch(error => {
    let stations = {
      data: previousData,
      error: true,
      loading: false
    }
    if (error.response !== undefined) {
      stations.error = error.response;
      dispatch({ type: GET_ALL_STATIONS_ERROR, payload: stations });
    } else {
      stations.error = 'Connectivity error';
      dispatch({ type: GET_ALL_STATIONS_ERROR, payload: stations });
    }
  });
}

///////////////////// RETRIEVE ALL STATIONS END /////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
/////////////////////// STATIONS SEARCH BEGIN ///////////////////////
/*
  This function is called when user submit the search form with him
  values, changing the loader and the search results
*/

export const asyncSearchStations = (credentials, endpoint, previousData, previousEndpoint, searchInput, filterPlace, filterRange, searchOrderBy) => {
  return dispatch => {
    const stationsSearch = {
      data: previousData,
      error: false,
      loading: true,
      endpoint: (previousEndpoint!==null) ? previousEndpoint : endpoint
    }
    if (searchOrderBy === 'keywords' || searchOrderBy === 'distance' || searchOrderBy === 'distance') dispatch({ type: ASYNC_SEARCH_STATIONS, payload: stationsSearch });
    if (searchOrderBy === 'tags') dispatch({ type: ASYNC_SEARCH_STATIONS_WITH_TAG, payload: stationsSearch });
    if (searchOrderBy === 'categories') dispatch({ type: ASYNC_SEARCH_STATIONS_WITH_CATEGORY, payload: stationsSearch });

    searchStations(dispatch, endpoint, credentials, previousData, previousEndpoint, searchInput, filterPlace, filterRange, searchOrderBy);
  }
}

const searchStations = (dispatch, endpoint, credentials, previousData, previousEndpoint, searchInput, filterPlace, filterRange, searchOrderBy) => {
  if ((!searchInput) && (!filterRange) && (!filterPlace)) {
    searchInput = filterRange = filterPlace = null;
  } else if ((searchInput) && (!filterRange) && (!filterPlace)) {
    filterRange = filterPlace = null;
  } else if ((!searchInput) && (!filterRange) && (filterPlace)) {
    searchInput = filterRange = null;
  } else if ((!searchInput) && (filterRange) && (filterPlace)) {
    searchInput = null;
  } else if ((searchInput) && (!filterRange) && (filterPlace)) {
    filterRange = null;
  }

  axios.get(endpoint, {
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
      'X-Access-Token': credentials.token,
      'X-User-Email': credentials.email,
    },
    params: {
      keywords: searchInput,
      place: filterPlace,
      range: filterRange,
      order_by: searchOrderBy
    }
  })
    .then(res => {
      const dC = JSON.stringify(previousData); // convert previous data to compare with the new data (dC = duplicity check)
      const rdC = JSON.stringify(res.data.data); // convert new data to compare with the previous data (rdC = res duplicity check)
      let newData = [];

      if (dC != rdC) {
        newData = [ ...previousData, ...res.data.data ];
      } else {
        newData = [ ...res.data.data ];
      }

      const stationsSearch = {
        data: newData,
        error: false,
        loading: false,
        endpoint: decodeURI(res.data.links.next)
      }

      let newToken = credentials.token;

      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      if (searchOrderBy === 'keywords' || searchOrderBy === 'distance') dispatch({ type: SEARCH_STATIONS, payload: stationsSearch, searchInput });
      if (searchOrderBy === 'tags') dispatch({ type: SEARCH_STATIONS_WITH_TAG, payload: stationsSearch, searchInput });
      if (searchOrderBy === 'categories') dispatch({ type: SEARCH_STATIONS_WITH_CATEGORY, payload: stationsSearch, searchInput });
    })
    .catch(error => {
      const stationsSearch = {
        data: previousData,
        error: true,
        loading: false,
        endpoint: endpoint
      }
      if (error.response !== undefined) {
        stationsSearch.error = error.response;

        if (searchOrderBy === 'keywords' || searchOrderBy === 'distance') dispatch({ type: SEARCH_STATIONS_ERROR, payload: stationsSearch });
        if (searchOrderBy === 'tags') dispatch({ type: SEARCH_STATIONS_WITH_TAG_ERROR, payload: stationsSearch });
        if (searchOrderBy === 'categories') dispatch({ type: SEARCH_STATIONS_WITH_CATEGORY_ERROR, payload: stationsSearch });
      } else {
        stationsSearch.error = 'Connectivity error';
        if (searchOrderBy === 'keywords' || searchOrderBy === 'distance') dispatch({ type: SEARCH_STATIONS_ERROR, payload: stationsSearch });
        if (searchOrderBy === 'tags') dispatch({ type: SEARCH_STATIONS_WITH_TAG_ERROR, payload: stationsSearch });
        if (searchOrderBy === 'categories') dispatch({ type: SEARCH_STATIONS_WITH_CATEGORY_ERROR, payload: stationsSearch });
      }
    });
};

//////////////////////// STATIONS SEARCH END ////////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
/////////////////////// REMOVE SEARCH HISTORY BEGIN ///////////////////////
/*
  This function is called when user clicks on the "close" button in
  an autocomplete list, doing the job for removing this element from history
*/

export const removeFromHistory = (index) => ({
  type: REMOVE_STATION_HISTORY,
  payload: index
});
/////////////////////// REMOVE SEARCH HISTORY END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
//////////////////////// UPDATE ADDRESS VALUE BEGIN ////////////////////////
/*
  This function is called when user is typing the address that he wants to
  search stations next to address
*/

export const updateAddressValue = (value) => ({
  type: UPDATE_ADDRESS_VALUE,
  payload: value
});

export const updateAddressSugestions = (array) => ({
  type: UPDATE_ADDRESS_SUGESTIONS,
  payload: array
});

/////////////////////// UPDATE ADDRESS VALUE END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
//////////////////////// UPDATE ADD STATION DATA BEGIN ////////////////////////
/*
  This function simply replace the stored addStation data by the new data
  passed by argument
*/

export const updateAddStationData = (addStation) => ({
  type: UPDATE_ADD_STATION_DATA,
  payload: addStation
});

export const updateViewOrigin = (bool) => ({
  type: UPDATE_VIEW_ORIGIN,
  payload: bool
});

/////////////////////// UPDATE ADD STATION DATA END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// ADD STATION BEGIN /////
/*
  This function is called when user wants to create a stations and the receiving
  ads into her station, depending on allow/deny new incoming ads
*/

export const addStation = (credentials, stationData) => {

  const {
    name,
    periodBegin,
    periodEnd,
    weekDaysBegin,
    weekDaysEnd,
    description,
    inches,
    resolution,
    companyId,
    categoryId,
    stationImgs,
    stationThumb,
    tag
  } = stationData;

  return dispatch => {
    dispatch({ type: ADD_STATION });
    // set incoming data
    var data = JSON.stringify(
      {"data":
        {
          "type":"stations",
          "attributes":{
            "name":name,
            "open_start":periodBegin,
            "open_end":periodEnd,
            "inches":inches,
            "dimensions":resolution,
            "professional_company_id":companyId,
            "admin_category_id": categoryId,
            "promotional_text":description,
            "week_day_begin":weekDaysBegin,
            "week_day_end":weekDaysEnd,
            "original_file_name": stationThumb.fileName,
            "avatar": stationThumb.b64,
            "uniquename": tag
          }
        }
      }
    );
    // set endpoint to create station
    var config = {
      method: 'post',
      url: `${SERVER_ADDRESS}/api/v1/stations/`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': credentials.token
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      var addStation = {
        data: response.data,
        finish: false,
        loading: true,
        error: false,
        stImgsData: [],
        stImgsError: false
      }
      // return the success of an station created
      dispatch({ type: ADD_STATION_SUCCESS, payload: addStation });
      // getting the new token if is present
      var newToken = credentials.token;
      if (response.headers['x-access-token']) {
        newToken = response.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }
      ////////////////////////////////////
      // mapping all station images to sent into the new created one
      stationImgs.map((img, index) => {
        var data = JSON.stringify({"data":{"type":"image-station","attributes":{"name":img.name,"storage-ref":img.url,"size":img.size,"mime-type":img.contentType,"station-id":response.data.data.id}}});

        var config = {
          method: 'post',
          url: `${SERVER_ADDRESS}/api/v1/image_stations/`,
          headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/json',
            'X-User-Email': credentials.email,
            'X-Access-Token': newToken
          },
          data : data
        };

        axios(config)
        .then(function (response) {

          // Capturando novo token caso exista
          if (response.headers['x-access-token']) {
            newToken = response.headers['x-access-token'];
            dispatch({ type: UPDATE_TOKEN, newToken });
          }
          ////////////////////////////////////
          // foreach successful sent of image, an array showing data of the image has been sent on payload
          addStation.stImgsData.push({ ...img, sent: true });
          if (index === stationImgs.length-1) {
            // if is the last image, loading is turned to off again anyway
            addStation.loading = false;
            addStation.finish = true;
            dispatch({ type: ADD_STATION_SUCCESS, payload: addStation });
          } else {
            dispatch({ type: ADD_STATION_SUCCESS, payload: addStation });
          }
        })
        .catch(function (error) {
          // foreach unsuccessful sent of image, an array showing data of the image and your error is sent on payload
          addStation.stImgsData.push({ ...img, sent: false });
          addStation.stImgsError = error;
          if (index === stationImgs.length-1) {
            // if is the last image, loading is turned to off again anyway
            addStation.loading = false;
            dispatch({ type: ADD_STATION_ERROR, payload: addStation });
          } else {
            dispatch({ type: ADD_STATION_ERROR, payload: addStation });
          }
        });
      });
    })
    .catch(function (error) {
      // if there's an error when adding station (first level, not images) the error is throwed
      const addStation = {
        data: [],
        finish: false,
        loading: false,
        error: error,
        stImgsData: [],
        stImgsError: false
      }
      dispatch({ type: ADD_STATION_ERROR, payload: addStation });
    });
  }
}

/////////////////////// ADD STATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// EDIT STATION BEGIN /////
/*
  This function is called when user wants
  to edit a existing station at station show
*/

export const editStation = (credentials, stationData, editingDataType) => {

  const {
    id,
    name,
    category,
    tag,
    description,
    periodBegin,
    periodEnd,
    weekDaysBegin,
    weekDaysEnd,
    inches
  } = stationData;

  return dispatch => {
    const payload = {
      finish: false,
      loading: true,
      error: false,
      editingDataType: editingDataType
    }

    dispatch({ type: EDIT_STATION, payload });
    // set incoming data
    var data = '';
    if (editingDataType === 'title') {
      data = JSON.stringify(
        {"data":
          {
            "type":"stations",
            "attributes":{
              "name":name,
              "admin_category_id": category,
              "uniquename": tag,
              "promotional_text": description,
            }
          }
        }
      );
    }
    else if (editingDataType === 'content') {
      data = JSON.stringify(
        {"data":
          {
            "type":"stations",
            "attributes":{
              "open_start":periodBegin,
              "open_end":periodEnd,
              "inches":inches,
              "week_day_begin":weekDaysBegin,
              "week_day_end":weekDaysEnd,
            }
          }
        }
      );
    }
    else if (editingDataType === 'description') {
      data = JSON.stringify(
        {"data":
          {
            "type":"stations",
            "attributes":{
              "promotional_text": description
            }
          }
        }
      );
    }

    var config = {
      method: 'patch',
      url: `${SERVER_ADDRESS}/api/v1/stations/${id}`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': credentials.token
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      const payload = {
        finish: true,
        loading: false,
        error: false,
        editingDataType: editingDataType
      }

      dispatch({ type: EDIT_STATION_SUCCESS, payload });
      // getting the new token if is present
      var newToken = credentials.token;
      if (response.headers['x-access-token']) {
        newToken = response.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }
    })
    .catch(function (error) {
      const payload = {
        finish: true,
        loading: false,
        error: error,
        editingDataType: editingDataType
      }

      dispatch({ type: EDIT_STATION_ERROR, payload });
    });
  }
}

/////////////////////// EDIT STATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// MANAGE STATION IMAGES BEGIN /////
/*
  This function is called when user wants to create a stations and the receiving
  ads into her station, depending on allow/deny new incoming ads
*/

export const manageStationImages = (credentials, manageStationImagesData) => {

  const {
    imgsToDelete,
    imgsToSend,
    stationId
  } = manageStationImagesData;

  return dispatch => {
    // set incoming data
    var manageStationImages = {
      status: 'sending',
      loading: true,
      imgsToSendFinish: imgsToSend.length ? false : true,
      imgsToDeleteFinish: imgsToDelete.length ? false : true,
      stImgsStatuses: []
    }

    dispatch({ type: MANAGE_STATION_IMAGES, payload: manageStationImages });

    var newToken = credentials.token;

    ////////////////////////////////////
    // mapping all station images to sent into existing one
    imgsToSend.map((img, index) => {
      var data = JSON.stringify({"data":{"type":"image-station","attributes":{"name":img.name,"storage-ref":img.url,"size":img.size,"mime-type":img.contentType,"station-id":stationId}}});

      var config = {
        method: 'post',
        url: `${SERVER_ADDRESS}/api/v1/image_stations/`,
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/json',
          'X-User-Email': credentials.email,
          'X-Access-Token': newToken
        },
        data : data
      };

      axios(config)
      .then(function (response) {

        // Capturando novo token caso exista
        if (response.headers['x-access-token']) {
          newToken = response.headers['x-access-token'];
          dispatch({ type: UPDATE_TOKEN, newToken });
        }
        ////////////////////////////////////
        // foreach successful sent of image, an array showing data of the image has been sent on payload
        manageStationImages.stImgsStatuses.push({ ...img, sent: true });
        dispatch({ type: MANAGE_STATION_IMAGES, payload: manageStationImages });

        if (index === imgsToSend.length-1) {
          // if is the last image, loading is turned to off again anyway
          manageStationImages.imgsToSendFinish = true;
          dispatch({ type: MANAGE_STATION_IMAGES, payload: manageStationImages });
          if (manageStationImages.imgsToSendFinish && manageStationImages.imgsToDeleteFinish) {
            manageStationImages.loading = false;
            manageStationImages.status = 'success';
            dispatch({ type: MANAGE_STATION_IMAGES_SUCCESS, payload: manageStationImages });
          }
        }
      })
      .catch(function (error) {
        // foreach unsuccessful sent of image, an array showing data of the image and your error is sent on payload
        manageStationImages.stImgsStatuses.push({ ...img, sent: false });
        dispatch({ type: MANAGE_STATION_IMAGES, payload: manageStationImages });

        if (index === imgsToSend.length-1) {
          // if is the last image, loading is turned to off again anyway
          manageStationImages.imgsToSendFinish = true;
          dispatch({ type: MANAGE_STATION_IMAGES, payload: manageStationImages });
          if (manageStationImages.imgsToSendFinish && manageStationImages.imgsToDeleteFinish) {
            manageStationImages.loading = false;
            manageStationImages.status = 'sending_error';
            dispatch({ type: MANAGE_STATION_IMAGES_ERROR, payload: manageStationImages });
          }
        }
      });
    });

        ////////////////////////////////////
    // mapping all station images to sent into existing one
    imgsToDelete.map((img, index) => {
      var data = '';

      var config = {
        method: 'delete',
        url: `${SERVER_ADDRESS}/api/v1/image_stations/${img.id}`,
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/json',
          'X-User-Email': credentials.email,
          'X-Access-Token': newToken
        },
        data : data
      };

      axios(config)
      .then(function (response) {

        // Capturando novo token caso exista
        if (response.headers['x-access-token']) {
          newToken = response.headers['x-access-token'];
          dispatch({ type: UPDATE_TOKEN, newToken });
        }
        ////////////////////////////////////
        // foreach successful sent of image, an array showing data of the image has been sent on payload
        manageStationImages.stImgsStatuses.push({ ...img, deleted: true });
        dispatch({ type: MANAGE_STATION_IMAGES, payload: manageStationImages });

        if (index === imgsToDelete.length-1) {
          // if is the last image, loading is turned to off again anyway
          manageStationImages.imgsToDeleteFinish = true;
          dispatch({ type: MANAGE_STATION_IMAGES, payload: manageStationImages });
          if (manageStationImages.imgsToSendFinish && manageStationImages.imgsToDeleteFinish) {
            manageStationImages.loading = false;
            manageStationImages.status = 'success';
            dispatch({ type: MANAGE_STATION_IMAGES_SUCCESS, payload: manageStationImages });
          }
        }
      })
      .catch(function (error) {
        // foreach unsuccessful sent of image, an array showing data of the image and your error is sent on payload
        manageStationImages.stImgsStatuses.push({ ...img, deleted: false });
        dispatch({ type: MANAGE_STATION_IMAGES, payload: manageStationImages });

        if (index === imgsToSend.length-1) {
          // if is the last image, loading is turned to off again anyway
          manageStationImages.imgsToDeleteFinish = true;
          dispatch({ type: MANAGE_STATION_IMAGES, payload: manageStationImages });
          if (manageStationImages.imgsToSendFinish && manageStationImages.imgsToDeleteFinish) {
            manageStationImages.loading = false;
            manageStationImages.status = 'deleting_error';
            dispatch({ type: MANAGE_STATION_IMAGES_ERROR, payload: manageStationImages });
          }
        }
      });
    });
  }
}

/////////////////////// MANAGE STATION IMAGES END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// SEND THUMB TO STATION BEGIN /////
/*
  This function is called after successful station adding, leading the user to doing
  the job of uploading an thumb of station, wich is mandatory to activate the panel
*/

export const sendThumbToStation = (credentials, thumbStationData) => {
  return dispatch => {
    dispatch({ type: SEND_THUMB_TO_STATION });
    var data = JSON.stringify(
      {"data":
        {
          "type":"stations",
          "attributes":{
            "original_file_name": thumbStationData.fileName,
            "avatar": thumbStationData.b64,
          }
        }
      }
    );
    // set endpoint to create station
    var config = {
      method: 'patch',
      url: `${SERVER_ADDRESS}/api/v1/stations/${thumbStationData.stationId}`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': credentials.token
      },
      data : data
    };

    axios(config)
    .then(function (response) {
      // getting the new token if is present
      var newToken = credentials.token;
      if (response.headers['x-access-token']) {
        newToken = response.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const payload = { loading: false, error: false, sent: true }
      dispatch({ type: SEND_THUMB_TO_STATION_SUCCESS, payload });
    })
    .catch(function (error) {
      const payload = { loading: false, error: error, sent: false }
      dispatch({ type: SEND_THUMB_TO_STATION_ERROR, payload });
    });
  }
}

/////////////////////// SEND THUMB TO STATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// SENT THUMB BEGIN /////
/*
  This function is called after successful upload of a station thumb,
  that allows user advance the step when adding a new station
*/

export const setSentThumb = (thumbPath) => ({
  type: SENT_THUMB,
  payload: thumbPath
});

/////////////////////// SENT THUMB END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// GET STATION BEGIN /////
/*
  Endpoint to get all data from station
*/

export const getStation = (credentials, id, previousData) => {
  return dispatch => {
    dispatch({ type: GET_STATION });
    const url = `${SERVER_ADDRESS}/api/v1/stations/${id}`
    axios.get(url, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Access-Token': credentials.token,
        'X-User-Email': credentials.email,
      }
    })
    .then(res => {

      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const payload = {
        data: res.data.data,
        error: false,
        loading: false
      }
      dispatch({ type: GET_STATION_SUCCESS, payload });
    })
    .catch(error => {
      const payload = {
        data: previousData.data,
        error: error,
        loading: false
      }
      dispatch({ type: GET_STATION_ERROR, payload });
    });
  }
}


/////////////////////// GET STATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// GET CATEGORIES BEGIN /////
/*
  Endpoint to get all categories that will be used at station register
*/

export const getCategories = (credentials, previousData) => {
  return dispatch => {
    dispatch({ type: GET_CATEGORIES });
    const url = `${SERVER_ADDRESS}/api/v1/categories`
    axios.get(url, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Access-Token': credentials.token,
        'X-User-Email': credentials.email,
      }
    })
    .then(res => {

      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const payload = {
        data: res.data.data,
        error: false,
        loading: false
      }
      dispatch({ type: GET_CATEGORIES_SUCCESS, payload });
    })
    .catch(error => {
      const payload = {
        data: previousData.data,
        error: error,
        loading: false
      }
      dispatch({ type: GET_CATEGORIES_ERROR, payload });
    });
  }
}

/////////////////////// GET CATEGORIES END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// CHECK UNIQUENAME //////////////////////////
/*
  Endpoint to check if selected uniquename
  already is in use at add station form
*/

export const checkUniquename = (credentials, uniquename) => {
  return dispatch => {
    dispatch({ type: CHECK_UNIQUENAME });
    const url = `${SERVER_ADDRESS}/api/v1/stations/${uniquename}/check_uniquename`
    axios.get(url, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Access-Token': credentials.token,
        'X-User-Email': credentials.email,
      }
    })
    .then(res => {

      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const payload = {
        //isUnique: false,
        isUnique: res.data[0].uniquename,
        error: false,
        loading: false
      }
      dispatch({ type: CHECK_UNIQUENAME_SUCCESS, payload });
    })
    .catch(error => {
      const payload = {
        isUnique: undefined,
        error: error,
        loading: false
      }
      dispatch({ type: CHECK_UNIQUENAME_ERROR, payload });
    });
  }
}


/////////////////////// CHECK UNIQUENAME END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// GET LAST STATIONS //////////////////////////
/*
  Endpoint to get the last stations registered at telesapp mi
  to be used at Dashboard screen
*/

export const getLastStations = (credentials, previousData) => {
  return dispatch => {
    dispatch({ type: GET_LAST_STATIONS });
    const url = `${SERVER_ADDRESS}/api/v1/last_stations`
    axios.get(url, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Access-Token': credentials.token,
        'X-User-Email': credentials.email,
      }
    })
    .then(res => {

      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const payload = {
        data: res.data.data,
        error: false,
        loading: false
      }
      dispatch({ type: GET_LAST_STATIONS_SUCCESS, payload });
    })
    .catch(error => {
      const payload = {
        data: previousData,
        error: error,
        loading: false
      }
      dispatch({ type: GET_LAST_STATIONS_ERROR, payload });
    });
  }
}

/////////////////////// GET LAST STATIONS END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// GET USER STATIONS //////////////////////////
/*
  Endpoint to get all stations owned by current user
*/


export const getUserStations = (credentials, previousData) => {
  return dispatch => {
    dispatch({ type: GET_USER_STATIONS });
    const url = `${SERVER_ADDRESS}/api/v1/user_stations`
    axios.get(url, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Access-Token': credentials.token,
        'X-User-Email': credentials.email,
      }
    })
    .then(res => {

      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const payload = {
        data: res.data.data.reverse(),
        error: false,
        loading: false
      }
      dispatch({ type: GET_USER_STATIONS_SUCCESS, payload });
    })
    .catch(error => {
      const payload = {
        data: previousData.reverse(),
        error: error,
        loading: false
      }
      dispatch({ type: GET_USER_STATIONS_ERROR, payload });
    });
  }
}

/////////////////////// GET USER STATIONS END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// CALCULATE PRICE BEGIN //////////////////////////
/*
  Request for calculating the total of campaign price
*/


export const calculatePrice = (credentials, selectedStations, weeks, seconds) => {
  return dispatch => {
    dispatch({ type: CALCULATE_PRICE });
    const url = `${SERVER_ADDRESS}/api/v1/station_price`
    axios.get(url, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Access-Token': credentials.token,
        'X-User-Email': credentials.email,
      },
      params: {
        stations: selectedStations,
        weeks: weeks,
        seconds: seconds
      }
    })
    .then(res => {

      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const max = _.sumBy(res.data, 'price_total');
      const min = _.minBy(res.data, 'price_total').price_total

      const payload = {
        data: res.data,
        max: max,
        min: min,
        error: false,
        loading: false
      }
      dispatch({ type: CALCULATE_PRICE_SUCCESS, payload });
    })
    .catch(error => {
      const payload = {
        data: [],
        max: null,
        min: null,
        error: error,
        loading: false
      }
      dispatch({ type: CALCULATE_PRICE_ERROR, payload });
    });
  }
}

export const listenStationActivation = (codStation, station) => {
  return dispatch => {
    const RTDBUrl = development ? `devMiActivations` : `miActivations`;

    database.ref(RTDBUrl)
      .on('value', snapshot => {
        const activationStatus = _.filter(snapshot.val(), function(o) { return atob(atob(o.cdStation)) == atob(atob(codStation)); });

        let returnStation = station;

        if (activationStatus[0] !== undefined) {
          if (activationStatus[0].hasOwnProperty('state') && activationStatus[0].state === 'activated') {
            returnStation.data.attributes.status = 'AC';
          }
        }

        dispatch({
          type: LISTEN_STATION_ACTIVATION,
          payload: activationStatus,
          returnStation: returnStation
        });
      });
  }
}

export const stopListeningActivation = () => {
  return dispatch => {
    const RTDBUrl = development ? `devMiActivations` : `miActivations`;

    database.ref(RTDBUrl).off();
    dispatch({
      type: STOP_LISTENING_ACTIVATION,
      payload: []
    });
  }
}

/////////////////////// CALCULATE PRICE END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// SEND REPORT BEGIN //////////////////////////
/*
  Station reported by user, sending message to telesapp administrator
  through firebase chat
*/


export const sendReport = (reportData) => {
  return dispatch => {
    dispatch({ type: SEND_REPORT });

    if (production) {
      const time = Moment.now();
      const message = `*Report de painel*\n\nId do painel: ${reportData.id}\nNome: ${reportData.name}\nEndereço: ${reportData.address}\nReport: ${reportData.report}`;

      database.ref(`/messages/56/43/225`)
          .push({
            message,
            type: 'r',
            time,
            messageClass: 'message'
          })
          .then((snap) => {
              database.ref(`/messages/43/56/225`)
                  .push({
                    message,
                    type: 'e',
                    time,
                    messageClass: 'message',
                    visualized: false,
                    mirrorUid: snap.key
                  })
                    .then(() => {
                      dispatch ({ type: SEND_REPORT_SUCCESS });
                    })
                    .catch(error => {
                      dispatch({ type: SEND_REPORT_ERROR });
                    })
          })
          .catch(error => {
            const payload = {
              loading: false,
              error: error,
              sent: false
            }

            dispatch({ type: SEND_REPORT_ERROR, payload });
          })
      } else {
        dispatch ({ type: SEND_REPORT_SUCCESS });
      }
  }
}

/////////////////////// SEND REPORT END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// SYNC PLAYER BEGIN //////////////////////////
/*
  Set MI panel to sync again their ads, trying fix
  errors when player download ads
*/


export const syncPlayer = (credentials, station) => {
  return dispatch => {
    dispatch({ type: SYNC_PLAYER });

    var newToken = credentials.token;

    var config = {
      method: 'get',
      url: `${SERVER_ADDRESS}/api/v1/sync_station/${station.data.id}`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': newToken
      }
    };

    axios(config)
    .then(function (res) {

      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }
      dispatch({ type: SYNC_PLAYER_SUCCESS });
    })
    .catch(function (error) {
      const payload = {
        loading: false,
        error: error,
        success: false
      };

      dispatch({ type: SYNC_PLAYER_ERROR, payload });
    });
  }
}

/////////////////////// SYNC PLAYER END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// DELETE STATION BEGIN //////////////////////////
/*
  Try delete panel, throws an error if there
  is existing paid ad returning their
*/


export const deleteStation = (credentials, station, reasonData) => {
  return dispatch => {

    dispatch({ type: DELETE_STATION });

    var data = '';
    var newToken = credentials.token;

    var config = {
      method: 'delete',
      url: `${SERVER_ADDRESS}/api/v1/disable_station/${station.data.id}`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': newToken
      },
      data : data
    };

    axios(config)
    .then(function (res) {

      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      dispatch({ type: DELETE_STATION_SUCCESS });
      // ENVIO DO MOTIVO DA EXCLUSÃO PELO CHAT
      if (production) {
        const time = Moment.now();
        const message = `*Exclusão de Painel*\n\nId do painel: ${reasonData.id}\nNome: ${reasonData.name}\nEndereço: ${reasonData.address}\nUsuario: ${reasonData.userId}\nMotivo: ${reasonData.reason}`;
  
        database.ref(`/messages/56/43/225`)
            .push({
              message,
              type: 'r',
              time,
              messageClass: 'message'
            })
            .then((snap) => {
                database.ref(`/messages/43/56/225`)
                    .push({
                      message,
                      type: 'e',
                      time,
                      messageClass: 'message',
                      visualized: false,
                      mirrorUid: snap.key
                    })
            })
      }
    })
    .catch(function (error) {
      const payload = {
        loading: false,
        success: false,
        error: error.response
      }
      dispatch({ type: DELETE_STATION_ERROR, payload });
    });
  }
}

/////////////////////// DELETE STATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// BLOCK STATION BEGIN //////////////////////////
/*
  Block station to prevent incoming paid ads
*/


export const blockStation = (credentials, station) => {
  return dispatch => {

    dispatch({ type: BLOCK_STATION });

    var newToken = credentials.token;

    var config = {
      method: 'get',
      url: `${SERVER_ADDRESS}/api/v1/block_station/${station.data.id}`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': newToken
      }
    };

    axios(config)
    .then(function (res) {

      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      let payload = station;
      payload.data.attributes.status = 'DU';

      dispatch({ type: BLOCK_STATION_SUCCESS, payload });
    })
    .catch(function (error) {
      const payload = {
        loading: false,
        success: false,
        error: error.response
      }
      dispatch({ type: BLOCK_STATION_ERROR, payload });
    });
  }
}

/////////////////////// BLOCK STATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// UNBLOCK STATION BEGIN //////////////////////////
/*
  Unblock station to permit new activation for incoming paid ads
*/


export const unblockStation = (credentials, station) => {
  return dispatch => {

    dispatch({ type: UNBLOCK_STATION });

    var newToken = credentials.token;

    var config = {
      method: 'get',
      url: `${SERVER_ADDRESS}/api/v1/unblock_station/${station.data.id}`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': newToken
      }
    };

    axios(config)
    .then(function (res) {

      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      let payload = station;
      payload.data.attributes.status = 'AA';

      dispatch({ type: UNBLOCK_STATION_SUCCESS, payload });
    })
    .catch(function (error) {
      const payload = {
        loading: false,
        success: false,
        error: error.response
      }
      dispatch({ type: UNBLOCK_STATION_ERROR, payload });
    });
  }
}

/////////////////////// BLOCK STATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// SELECT STATIONS //////////////////////////
/*
  Action to handle the selected stations array from search tool
*/

export const selectStations = (array) => ({
  type: SELECT_STATIONS,
  payload: array
});

export const updateSelecting = (bool) => ({
  type: UPDATE_SELECTING,
  payload: bool
});

export const updateThumbStation = (thumbStation) => ({
  type: UPDATE_THUMB_STATION,
  payload: thumbStation
});

export const updateEditStation = (editStation) => ({
  type: UPDATE_EDIT_STATION,
  payload: editStation
});

export const updateSendReport = (reportData) => ({
  type: UPDATE_SEND_REPORT,
  payload: reportData
});

export const updateSyncPlayer = (syncPlayerData) => ({
  type: UPDATE_SYNC_PLAYER,
  payload: syncPlayerData
});

export const updateDeleteStation = (deleteStationData) => ({
  type: UPDATE_DELETE_STATION,
  payload: deleteStationData
});

export const updateBlockStation = (blockStationData) => ({
  type: UPDATE_BLOCK_STATION,
  payload: blockStationData
});

export const updateUnblockStation = (unblockStationData) => ({
  type: UPDATE_UNBLOCK_STATION,
  payload: unblockStationData
});
