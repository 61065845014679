import React, { useState, useEffect, useRef } from 'react';

import { useSpring } from 'framer-motion';
import useWindowSize from '../../utils/hooks/useWindowSize';

import { Container } from './styles';

const Slider = ({ children }) => {
  const sliderRef = useRef(null);
  const size = useWindowSize();
  const [ width, setWidth ] = useState(size.width);

  const x = useSpring(15, { stiffness: 300, damping: 50, ease: [0.6, 0.05, -0.01, 0.99] });

  useEffect(() => {
    if (sliderRef.current) {
      setWidth(sliderRef.current.offsetWidth);
    }
  }, [size.width, children]);

  return (
    <Container
      ref={sliderRef}
      drag='x'
      dragElastic={0.01}
      dragConstraints={{ left: width > size.width ? ((-width - 15) + size.width) : 0, right: 0 }}
      dragMomentum={false}
      style={{ x }}
    >
      { children }
    </Container>
  );
}

export default Slider;
