import React, { useContext } from 'react';

import {
    Container,
    Header,
    Row,
    Title,
    BtnClose,
    Indicator,
    TextContainer
} from './styles';
import { mdiClose, mdiHelpCircle } from '@mdi/js';

import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';

import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { connect } from 'react-redux';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import useWindowSize from '../../utils/hooks/useWindowSize';

function Help ({ setBottomSheetShow }) {
    const { t } = useTranslation();
    const theme = useContext(ThemeContext);
    const size = useWindowSize();
    const history = useHistory();

    const handleExit = () => {
        if (size.width < 1024) {
            setBottomSheetShow(false);
        } else {
            history.replace('/');
        }
    }

    const variants = {
        initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
        hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
      }
    
      return (
        <Container
          className='noSelect'
          variants={variants}
          width={size.width}
          height={size.height}
          initial='initial'
          animate='visible'
          exit='hidden'
        >
            <Header>
                <Row>
                    <Icon
                        path={mdiHelpCircle}
                        size='24px'
                        color={theme.iconTintColor.inactive}
                        style={{ marginRight: '10px' }}
                    />
                    <Title>
                        Ajuda
                    </Title>
                    <BtnClose size='small' onClick={handleExit}>
                        <Icon
                        path={mdiClose}
                        size='24px'
                        />
                    </BtnClose>
                </Row>
                <Indicator />
            </Header>
            <TextContainer>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        { t(`faq.q${1}`) }
                    </AccordionSummary>
                    <AccordionDetails>
                        { t(`faq.a${1}`) }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        { t(`faq.q${2}`) }
                    </AccordionSummary>
                    <AccordionDetails>
                        { t(`faq.a${2}`) }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        { t(`faq.q${3}`) }
                    </AccordionSummary>
                    <AccordionDetails>
                        { t(`faq.a${3}`) }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        { t(`faq.q${4}`) }
                    </AccordionSummary>
                    <AccordionDetails>
                        { t(`faq.a${4}`) }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        { t(`faq.q${5}`) }
                    </AccordionSummary>
                    <AccordionDetails>
                        { t(`faq.a${5}`) }
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        { t(`faq.q${6}`) }
                    </AccordionSummary>
                    <AccordionDetails>
                        { t(`faq.a${6}`) }
                    </AccordionDetails>
                </Accordion>
            </TextContainer>
        </Container>
    );
}

export default connect(null, { })(Help);