import React, { useRef, useState, useEffect, Children } from 'react';

import { useSpring } from 'framer-motion';

import useWindowSize from '../../utils/hooks/useWindowSize';

import { Container, SwiperWrapper } from './styles';

const Swiper = ({ children, show, style, swiperPosition, swiperMoving, setSwiperMoving }) => {
  const size = useWindowSize();
  const [ swiperWidth, setSwiperWidth ] = useState(1024);
  const [ numberOfChildren, setNumberOfChildren ] = useState(1);
  const [ activeSnapPoint, setActiveSnapPoint ] = useState(0);
  const [ snapPoints, setSnapPoints ] = useState([]);
  const [ dragging, setDragging ] = useState(false);
  const swiper = useRef();
  const x = useSpring(0, { stiffness: 300, damping: 200, ease: [0.6, 0.05, -0.01, 0.99] });

  useEffect(() => {
    x.onChange(value => {
      if (Number.isInteger(value)) {
        setSwiperMoving(false);
      }
    })
  }, []);

  useEffect(() => {
    if (size.width < 1024) {
      setNumberOfChildren(Children.toArray(children).length);
      setSwiperWidth(swiper.current.offsetWidth);

      let aux = [];

      Children.toArray(children).forEach((_, index) => {
        aux.push(-(size.width - 20) * (index));
      });

      setSnapPoints(aux);
    }
  }, [children, size.width]);

  useEffect(() => {
    if (snapPoints.length > 0 && !swiperMoving && !dragging) {
      setSwiperMoving(true);

      if (swiperPosition === 0 || swiperPosition === 20) {
        x.set(0);
      }
      else {
        x.set(-swiperPosition + 10);
      }
    }
  }, [swiperPosition]);

  const handleDragEnd = (_, info) => {
    const endPoint = Math.round(info.point.x);

    if (endPoint >= 0) return;

    if (info.velocity.x < -500 && endPoint > snapPoints[snapPoints.length - 1]) {
      setActiveSnapPoint(activeSnapPoint + 1);
      x.set(snapPoints[activeSnapPoint + 1]);
    }
    else if (info.velocity.x > 500 && endPoint < 0) {
      setActiveSnapPoint(activeSnapPoint - 1);
      x.set(snapPoints[activeSnapPoint - 1]);
    }
    else {
      const closest = snapPoints.reduce((prev, curr) => {
        return (Math.abs(curr - endPoint) < Math.abs(prev - endPoint) ? curr : prev);
      });

      setActiveSnapPoint(snapPoints.indexOf(closest));
      x.set(Math.round(closest));
    }

    setDragging(false);
  }

  return (
    <Container {...{ style }} className={show ? 'show' : ''}>
      <SwiperWrapper
        ref={swiper}
        style={{ x }}
        drag='x'
        dragElastic={0.05}
        dragDirectionLock={true}
        dragConstraints={{ left: -swiperWidth + size.width, right: 0 }}
        dragMomentum={false}
        numberOfChildren={numberOfChildren}
        onDragStart={() => setDragging(true)}
        onDragEnd={handleDragEnd}
      >
        { children }
      </SwiperWrapper>
    </Container>
  );
}

export default Swiper;