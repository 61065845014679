import React, { useState, useContext, useEffect } from 'react';

import { connect } from 'react-redux';
import { getHours } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useWindowSize from '../../utils/hooks/useWindowSize';
import Icon from '@mdi/react';
import { mdiDotsVertical } from '@mdi/js';
import { ThemeContext } from 'styled-components';
import {
  Menu,
  MenuItem,
  Snackbar,
  SnackbarContent,
  IconButton
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

import { getLastStations, getUserStations, updateViewOrigin, updateSelecting, updateDeleteStation } from '../../store/actions/StationsActions';
import { updateOrigin } from '../../store/actions/AdsActions';
import { SERVER_ADDRESS } from '../../constraints/types';

import Slider from '../../components/Slider';
import WhatsappSupport from '../../components/WhatsappSupport';
import Lottie from 'react-lottie';
import { useSearching } from '../../context/Searching';

import animationData from '../../assets/lotties/empty-state-2.json';

import {
  Container,
  Greeting,
  Heading,
  SliderContainer,
  SliderHeader,
  SliderHeaderText,
  BtnSeeAll,
  Slide,
  EmptyState,
  EmptyStateHeadline,
  EmptyStateSub,
  EmptyStateBtn,
  Grid,
  DesktopContainer,
  Title,
  Address,
  Thumb,
  Content,
  DesktopList,
  BtnOptions,
  DesktopHeader,
  Wrapper
} from './styles';

function Dashboard ({ lastStations, userStations, memberData, token, getLastStations, getUserStations, updateViewOrigin, updateOrigin, updateSelecting, deleteStationData, updateDeleteStation, addStation }) {
  const { t } = useTranslation();
  const size = useWindowSize();
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const { searching, setSearching } = useSearching();
  const [ ads, ] = useState([]);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ setStation ] = useState(null);

  useEffect(() => {
    if (size.width < 1024) updateSelecting(false);
    setSearching({ ...searching, active: false, componentMode: false });

    let credentials = { email: memberData.email, token }
    getLastStations(credentials, lastStations.data);
    credentials = { email: memberData.email, token }
    getUserStations(credentials, userStations.data);
  }, [addStation]);


  const renderGreeting = () => {
    const currHours = getHours(new Date());
    let greeting = '';

    if (currHours < 6) {
      greeting = 'greeting.evening';
    }
    else if (currHours >= 6 && currHours < 12) {
      greeting = 'greeting.morning';
    }
    else if (currHours < 18) {
      greeting = 'greeting.afternoon';
    }
    else {
      greeting = 'greeting.evening';
    }

    return (
      <Greeting>{`${t(greeting)}, ${memberData.name}`}<span role='img' aria-label='hand waving'>👋</span></Greeting>
    )
  }

  const handleSlideClick = (data, type) => {
    if (type === 'station') {
      updateViewOrigin(true);
      history.push(`/station/${data.id}`);
    }
  }

  const handleOpenStationsList = (type) => {
    history.push({
      pathname: `/list-stations/${type}`,
    });
  }

  const handleOpenMenu = (evt, station) => {
    evt.preventDefault();
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    setAnchorEl(evt.currentTarget);
    setStation(station);
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setStation(null);
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  return (
    <Container
      variants={variants}
      initial={size.width < 1024 ? 'initial' : 'none'}
      animate={size.width < 1024 ? 'visible' : 'none'}
      exit={size.width < 1024 ? 'hidden' : 'none'}
    >
      <WhatsappSupport />
      { renderGreeting() }
      <Heading>{ t('dashboard.heading') }</Heading>

      {
        size.width < 1024 ? (
          <>
            {
                (lastStations.data.length > 0) &&
                <SliderContainer>
                  <SliderHeader>
                    <SliderHeaderText>{ t('dashboard.featured') }</SliderHeaderText>
                    <BtnSeeAll onClick={() => handleOpenStationsList('featured')}>{ t('dashboard.browseAll') }</BtnSeeAll>
                  </SliderHeader>
                  <Slider>
                    {
                      lastStations.data.map(station => (
                          <Slide
                              key={station.id}
                              bg={`${SERVER_ADDRESS}${station.attributes['avatar-original']}`}
                              onClick={() => handleSlideClick(station, 'station')}
                          />
                      ))
                    }
                  </Slider>
                </SliderContainer>
            }
            <SliderContainer>
              <SliderHeader>
                <SliderHeaderText>{ t('dashboard.myPanels') }</SliderHeaderText>
                <BtnSeeAll onClick={() => handleOpenStationsList('my')}>{ t('dashboard.browseAll') }</BtnSeeAll>
              </SliderHeader>

              {
                userStations.data.length > 0 ? (
                  <Slider>
                    {
                      userStations.data.map(station => (
                        <Slide
                          key={station.id}
                          bg={`${SERVER_ADDRESS}${station.attributes['avatar-original']}`}
                          onClick={() => handleSlideClick(station, 'station')}
                        />
                      ))
                    }
                  </Slider>
                ) : (
                  <EmptyState>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        }
                      }}
                      width='50%'
                      height='50%'
                      isClickToPauseDisabled={true}
                      style={{ position: 'absolute', right: 8, bottom: 0, opacity: .7 }}
                    />
                    <EmptyStateHeadline>{ t('dashboard.nonePanels') }</EmptyStateHeadline>
                    <EmptyStateSub>{ t('dashboard.registerPanels') }</EmptyStateSub>
                    <EmptyStateBtn onClick={() => history.push(`${t('routes.addMediaPanel')}/0`)}>{ t('dashboard.newPanel') }</EmptyStateBtn>
                  </EmptyState>
                )
              }
            </SliderContainer>

            <SliderContainer>
              <SliderHeader>
                <SliderHeaderText>{ t('dashboard.myAds') }</SliderHeaderText>
                { ads.length > 0 && <BtnSeeAll>{ t('dashboard.browseAll') }</BtnSeeAll> }
              </SliderHeader>

              {
                ads.length > 0 ? (
                  <Slider>
                    {
                      ads.map(ad => (
                        <Slide
                          key={ad.id}
                          bg={ad.thumb}
                          onClick={() => handleSlideClick(ad, 'ad')}
                        />
                      ))
                    }
                  </Slider>
                ) : (
                  <EmptyState>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        }
                      }}
                      width='50%'
                      height='50%'
                      isClickToPauseDisabled={true}
                      style={{ position: 'absolute', right: 8, bottom: 0, opacity: .7 }}
                    />
                    <EmptyStateHeadline>{ t('dashboard.noneAds') }</EmptyStateHeadline>
                    <EmptyStateSub>{ t('dashboard.registerAds') }</EmptyStateSub>
                    <EmptyStateBtn onClick={() => { updateOrigin(true); history.push('announce/0'); }}>{ t('dashboard.advertise') }</EmptyStateBtn>
                  </EmptyState>
                )
              }
            </SliderContainer>
          </>
        ) : (
          <Grid>
            <DesktopContainer empty={lastStations.data.length > 0 ? 0 : 1}>
              <DesktopHeader>{ t('dashboard.myPanels') }</DesktopHeader>

              {
                userStations.data.length > 0 ? (
                  <DesktopList>
                    {
                      userStations.data?.map(station => (
                        <Wrapper key={station.id} onClick={() => handleSlideClick(station, 'station')}>
                          <Thumb bg={`${SERVER_ADDRESS}${station.attributes['avatar-original']}`} />
                          <Content>
                            <Title>{station.attributes.name}</Title>
                            <Address>{station.attributes.address}</Address>
                          </Content>
                        </Wrapper>
                      ))
                    }
                  </DesktopList>
                ) : (
                  <EmptyState>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        }
                      }}
                      isClickToPauseDisabled={true}
                    />
                    <EmptyStateHeadline>{ t('dashboard.nonePanels') }</EmptyStateHeadline>
                    <EmptyStateSub>{ t('dashboard.registerPanels') }</EmptyStateSub>
                    <EmptyStateBtn onClick={() => history.push(`${t('routes.addMediaPanel')}/0`)}>{ t('dashboard.newPanel') }</EmptyStateBtn>
                  </EmptyState>
                )
              }
            </DesktopContainer>
            {
            (lastStations.data.length > 0) &&
            <DesktopContainer empty={lastStations.data.length > 0 ? 0 : 1}>
              <DesktopHeader>{ t('dashboard.featured') }</DesktopHeader>
                <DesktopList>
                  {
                    lastStations.data?.map(station => (
                      <Wrapper key={station.id} onClick={() => handleSlideClick(station, 'station')}>
                        <Thumb bg={`${SERVER_ADDRESS}${station.attributes['avatar-original']}`} />
                        <Content>
                          <Title>{station.attributes.name}</Title>
                          <Address>{station.attributes.address}</Address>
                        </Content>
                      </Wrapper>
                    ))
                  }
                </DesktopList>
            </DesktopContainer>
            }
            <DesktopContainer empty={ads.length > 0 ? 0 : 1}>
              <DesktopHeader>{ t('dashboard.myAds') }</DesktopHeader>

              {
                ads.length > 0 ? (
                  <DesktopList>
                    {
                      ads?.map(ad => (
                        <Wrapper key={ad.id}>
                          <Thumb bg={ad.thumb} />
                          <Content>
                            <Title>{ad.name}</Title>
                            <Address>{ad.address}</Address>
                          </Content>
                          <BtnOptions onClick={(evt) => handleOpenMenu(evt, ad)}>
                            <Icon
                              path={mdiDotsVertical}
                              size='24px'
                              color={theme.iconTintColor.active}
                            />
                          </BtnOptions>
                        </Wrapper>
                      ))
                    }
                  </DesktopList>
                ) : (
                  <EmptyState>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice",
                        }
                      }}
                      width='50%'
                      isClickToPauseDisabled={true}
                      style={{ marginTop: -20 }}
                    />
                    <EmptyStateHeadline>{ t('dashboard.noneAds') }</EmptyStateHeadline>
                    <EmptyStateSub>{ t('dashboard.registerAds') }</EmptyStateSub>
                    <EmptyStateBtn onClick={() => { updateOrigin(true); history.push('announce/0'); }}>{ t('dashboard.advertise') }</EmptyStateBtn>
                  </EmptyState>
                )
              }

              {
                lastStations.data.length > 0 && (
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    variant='selectedMenu'
                  >
                    <MenuItem onClick={() => {}}>{ t('viewStation.edit') }</MenuItem>
                    <MenuItem onClick={() => {}}>{ t('filepicker.button.delete') }</MenuItem>
                  </Menu>
                )
              }
            </DesktopContainer>
          </Grid>
        )
      }
      <Snackbar
        key={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        color='primary'
        open={(deleteStationData === undefined ? false : (!deleteStationData.loading && deleteStationData.success))}
        autoHideDuration={4000}
        onClose={() => updateDeleteStation({ loading: false, error: false, success: false })}
        onExit={() => updateDeleteStation({ loading: false, error: false, success: false })}
      >
        <SnackbarContent
          style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
          message={'Painel excluído'}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => updateDeleteStation({ loading: false, error: false, success: false })}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
    </Container>
  )
}

const mapStateToProps = state => ({
  token: state.AuthenticationReducer.token,
  memberData: state.AuthenticationReducer.memberData.data.attributes,
  lastStations: state.StationsReducer.lastStations,
  userStations: state.StationsReducer.userStations,
  deleteStationData: state.StationsReducer.deleteStation,
  addStation: state.StationsReducer.addStation,
});

export default connect(mapStateToProps, { getLastStations, getUserStations, updateViewOrigin, updateOrigin, updateSelecting, updateDeleteStation })(Dashboard);
