import React, { useEffect, useRef, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';

import { Container, Image, Content, Title, Subtitle } from './styles';
import { SERVER_ADDRESS } from '../../constraints/types';

async function loadPolyfills () {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}

const SwiperCard = ({ station, style, onPress, selectedStationId, setSelectedStationId, setSwiperPosition, swiperMoving }) => {
  const { name, city } = station.attributes;
  const cardRef = useRef();

  const [ inViewRef, inView ] = useInView({
    threshold: 1
  });

  const setRef = useCallback(
    node => {
      cardRef.current = node;

      inViewRef(node);
    }
  )

  useEffect(() => {
    loadPolyfills();
  }, []);

  useEffect(() => {
    if (inView && selectedStationId !== station.id && !swiperMoving) {
      setSelectedStationId(station.id);
    }
  }, [cardRef, inView, swiperMoving]);

  useEffect(() => {
    if (selectedStationId === station.id) {
      setSwiperPosition(cardRef.current.offsetLeft);
    }
  }, [selectedStationId]);

  function handleContextMenu (evt) {
    evt.preventDefault();
    evt.stopPropagation();

    return false;
  }

  function handlePress () {
    if (onPress) onPress();
  }

  return (
    <Container
      {...{ style }}
      ref={setRef}
      onContextMenu={handleContextMenu}
      onClick={handlePress}
      selected={inView && selectedStationId === station.id}
      className='noSelect'
    >
      <Image src={`${SERVER_ADDRESS}${station.attributes['avatar-original']}`} draggable={false} />
      <Content>
        <Title>{name}</Title>
        <Subtitle>{city}</Subtitle>
      </Content>
    </Container>
  );
}

export default SwiperCard;
