import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ButtonBase } from '@material-ui/core';
import { lighten, darken } from 'polished';

export const NotificationWrapper = styled(motion.div)``;

export const Notification = styled(ButtonBase)`
  display: flex;
  align-items: flex-start !important;
  width: 100% !important;
  padding: 16px 20px !important;
  background: ${props => props.important ? props.theme.colors.error : (props.read ? (props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background) : (props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary))} !important;
  transition: all .15s ease !important;

  @media screen and (min-width: 1024px) {
    border-radius: 5px !important;
    padding: 16px 10px !important;

    &:hover {
      background: ${props => props.important ? lighten(.1, props.theme.colors.error) : (props.read ? (props.theme.title === 'dark' ? lighten(.1, props.theme.colors.secondary) : darken(.1, props.theme.colors.background)) : (props.theme.title === 'dark' ? lighten(.1, props.theme.colors.background) : darken(.1, props.theme.colors.secondary)))} !important;
    }
  }
`;

export const NotificationImg = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  margin-right: 16px;  
  position: relative;
`;

export const NotificationContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-right: 10px;
`;

export const NotificationTitle = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: ${props => props.bold ? 600 : 400};
  transition: all .15s ease;
`;

export const NotificationTimestamp = styled.span`
  font-size: 14px;
  color: ${props => props.theme.text.primary};
  margin-top: 4px;
`;

export const Badge = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${props => props.bg};
  width: 20px;
  height: 20px;
`;

export const NotificationDot = styled.div`
  width: 8px;
  height: 8px;
  background: ${props => props.theme.colors.error};
  border-radius: 50%;
  align-self: center;
`;