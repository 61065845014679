import React, { useRef, useEffect, useState } from 'react';

import { useSpring } from 'framer-motion';

import GalleryImage from '../GalleryImage';
import useWindowSize from '../../utils/hooks/useWindowSize';

import { Wrapper, Container } from './styles';

const Gallery = ({ images, show }) => {
  const size = useWindowSize();
  const galleryRef = useRef();

  const [ zoomMode, setZoomMode ] = useState(false);
  const [ snapPoints, setSnapPoints ] = useState([]);
  const [ activeSnapPoint, setActiveSnapPoint ] = useState(0);

  const x = useSpring(0, { stiffness: 300, damping: 50, ease: [0.6, 0.05, -0.01, 0.99] });

  useEffect(() => {
    if (!show) {
      setTimeout(() => {
        setActiveSnapPoint(0);
        x.set(0);
      }, 200);
    }
  }, [show, x]);

  useEffect(() => {
    setSnapPoints(images.map((_, index) => -size.width * index));
  }, [size.width, images]);

  const handleGalleryDragEnd = (_, info) => {
    const endPoint = Math.round(info.point.x);

    if (endPoint >= 0) return;

    if (info.velocity.x < -500 && endPoint > snapPoints[snapPoints.length - 1]) {
      setActiveSnapPoint(activeSnapPoint + 1);
      x.set(snapPoints[activeSnapPoint + 1]);
    }
    else if (info.velocity.x > 500 && endPoint < 0) {
      setActiveSnapPoint(activeSnapPoint - 1);
      x.set(snapPoints[activeSnapPoint - 1]);
    }
    else {
      const closest = snapPoints.reduce((prev, curr) => {
        return (Math.abs(curr - endPoint) < Math.abs(prev - endPoint) ? curr : prev);
      });

      setActiveSnapPoint(snapPoints.indexOf(closest));
      x.set(Math.round(closest));
    }
  }

  return (
    <Wrapper
      className={show ? 'show' : ''}
    >
      <Container
        ref={galleryRef}
        width={size.width * images.length}
        drag={zoomMode ? false : 'x'}
        dragElastic={0.01}
        dragConstraints={{ left: -(size.width * (images.length - 1)), right: 0 }}
        dragMomentum={false}
        style={{ x }}
        onDragEnd={handleGalleryDragEnd}
      >
        {
          images.map((image, index) => (
            <GalleryImage
              url={image.storage_ref ? image.storage_ref : image}
              key={index}
              setZoomMode={setZoomMode}
              active={activeSnapPoint === index}
            />
          ))
        }
      </Container>
    </Wrapper>
  )
}

export default Gallery;
