import React, { useState } from 'react';
import { Container, WhatsappIcon, Link } from './styles';
import Tooltip from '@material-ui/core/Tooltip';

const logo = require('../../assets/images/whatsapp.png');
const variants = {
  initial: { opacity: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  visible: { opacity: 1, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  hidden: { opacity: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
}
const WhatsappSupport = () => (
  <Container
    variants={variants}
    initial={'initial'}
    animate={'visible'}
    exit={'hidden'}
  >
    <Tooltip title="Suporte via whatsapp" placement="top">
      <Link href="http://wa.me/5535988933676" target="_blank">
        <WhatsappIcon src={logo} />
      </Link>
    </Tooltip>
  </Container>
);

export default WhatsappSupport;
