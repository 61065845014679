import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  ASYNC_GET_ALL_STATIONS,
  GET_ALL_STATIONS,
  GET_ALL_STATIONS_ERROR,
  ASYNC_SEARCH_STATIONS,
  ASYNC_SEARCH_STATIONS_WITH_TAG,
  ASYNC_SEARCH_STATIONS_WITH_CATEGORY,
  SEARCH_STATIONS,
  SEARCH_STATIONS_ERROR,
  SEARCH_STATIONS_WITH_TAG,
  SEARCH_STATIONS_WITH_TAG_ERROR,
  SEARCH_STATIONS_WITH_CATEGORY,
  SEARCH_STATIONS_WITH_CATEGORY_ERROR,
  SIGN_OUT_SUCCESS,
  REMOVE_STATION_HISTORY,
  UPDATE_ADDRESS_VALUE,
  UPDATE_ADDRESS_SUGESTIONS,
  UPDATE_ADD_STATION_DATA,
  ADD_STATION,
  ADD_STATION_SUCCESS,
  ADD_STATION_ERROR,
  EDIT_STATION,
  EDIT_STATION_SUCCESS,
  EDIT_STATION_ERROR,
  SEND_THUMB_TO_STATION,
  SEND_THUMB_TO_STATION_SUCCESS,
  SEND_THUMB_TO_STATION_ERROR,
  SENT_THUMB,
  GET_STATION,
  GET_STATION_SUCCESS,
  GET_STATION_ERROR,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
  CHECK_UNIQUENAME,
  CHECK_UNIQUENAME_SUCCESS,
  CHECK_UNIQUENAME_ERROR,
  GET_LAST_STATIONS,
  GET_LAST_STATIONS_SUCCESS,
  GET_LAST_STATIONS_ERROR,
  GET_USER_STATIONS,
  GET_USER_STATIONS_SUCCESS,
  GET_USER_STATIONS_ERROR,
  CALCULATE_PRICE,
  CALCULATE_PRICE_SUCCESS,
  CALCULATE_PRICE_ERROR,
  SELECT_STATIONS,
  UPDATE_VIEW_ORIGIN,
  UPDATE_SELECTING,
  UPDATE_THUMB_STATION,
  UPDATE_EDIT_STATION,
  UPDATE_SEND_REPORT,
  UPDATE_DELETE_STATION,
  LISTEN_STATION_ACTIVATION,
  STOP_LISTENING_ACTIVATION,
  MANAGE_STATION_IMAGES,
  MANAGE_STATION_IMAGES_SUCCESS,
  MANAGE_STATION_IMAGES_ERROR,
  SEND_REPORT,
  SEND_REPORT_SUCCESS,
  SEND_REPORT_ERROR,
  SYNC_PLAYER,
  SYNC_PLAYER_SUCCESS,
  SYNC_PLAYER_ERROR,
  UPDATE_SYNC_PLAYER,
  DELETE_STATION,
  DELETE_STATION_SUCCESS,
  DELETE_STATION_ERROR,
  BLOCK_STATION,
  BLOCK_STATION_SUCCESS,
  BLOCK_STATION_ERROR,
  UPDATE_BLOCK_STATION,
  UNBLOCK_STATION,
  UNBLOCK_STATION_SUCCESS,
  UNBLOCK_STATION_ERROR,
  UPDATE_UNBLOCK_STATION,
  UPDATE_APP
} from '../../constraints/types';

const INITIAL_STATE = {
  selectedStations: [],
  searchStationsHistory: [],
  addressSugestions: [],
  addressValue: '',
  stations: {
    data: [],
    error: false,
    loading: false
  },
  searchStations: {
    data: [],
    error: false,
    loading: false,
    endpoint: '',
  },
  searchStationsWithTag: {
    data: [],
    error: false,
    loading: false,
    endpoint: '',
  },
  searchStationsWithCategory: {
    data: [],
    error: false,
    loading: false,
    endpoint: '',
  },
  addStation: {
    data: null,
    finish: false,
    loading: false,
    error: false,
    stImgsData: [],
    stImgsError: false
  },
  thumbStation: {
    loading: false,
    sent: false,
    error: false
  },
  sentThumb: '',
  viewStation: {
    data: [],
    error: false,
    loading: false
  },
  categories: {
    data: [],
    error: false,
    loading: false
  },
  uniquename: {
    isUnique: undefined,
    error: false,
    loading: false
  },
  lastStations: {
    data: [],
    error: false,
    loading: false
  },
  userStations: {
    data: [],
    error: false,
    loading: false
  },
  campaignPrice: {
    data: [],
    max: null,
    min: null,
    error: false,
    loading: false
  },
  manageStationImages: {
    status: 'stopped',
    loading: false,
    imgsToSendFinish: false,
    imgsToDeleteFinish: false,
    stImgsStatuses: []
  },
  editStation: {
    finish: false,
    loading: false,
    error: false,
    editingDataType: ''
  },
  sendReport: {
    loading: false,
    error: false,
    sent: false
  },
  syncPlayer: {
    loading: false,
    error: false,
    success: false
  },
  deleteStation: {
    loading: false,
    error: false,
    success: false
  },
  blockStation: {
    loading: false,
    error: false,
    success: false
  },
  unblockStation: {
    loading: false,
    error: false,
    success: false
  },
  operationToken: uuidv4(),
  viewOrigin: true,
  selecting: true,
  stationActivation: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ASYNC_GET_ALL_STATIONS:
        return { ...state, stations: action.payload };
    case GET_ALL_STATIONS:
        return { ...state, stations: action.payload };
    case GET_ALL_STATIONS_ERROR:
        return { ...state, stations: action.payload };
    case ASYNC_SEARCH_STATIONS:
        return { ...state, searchStations: action.payload };
    case ASYNC_SEARCH_STATIONS_WITH_TAG:
        return { ...state, searchStationsWithTag: action.payload };
    case ASYNC_SEARCH_STATIONS_WITH_CATEGORY:
        return { ...state, searchStationsWithCategory: action.payload };
    case SEARCH_STATIONS:
        if (action.searchInput) {
          state.searchStationsHistory.push(action.searchInput);
          let searchStationsHistoryFiltered = _.uniqBy(state.searchStationsHistory, function (e) { return e; });
          return { ...state, searchStations: action.payload, searchStationsHistory: searchStationsHistoryFiltered };
        }
        return { ...state, searchStations: action.payload };
    case SEARCH_STATIONS_ERROR:
        return { ...state, searchStations: action.payload };
    case SEARCH_STATIONS_WITH_TAG:
        if (action.searchInput) {
          state.searchStationsHistory.push(action.searchInput);
          let searchStationsHistoryFiltered = _.uniqBy(state.searchStationsHistory, function (e) { return e; });
          return { ...state, searchStationsWithTag: action.payload, searchStationsHistory: searchStationsHistoryFiltered };
        }
        return { ...state, searchStationsWithTag: action.payload };
    case SEARCH_STATIONS_WITH_TAG_ERROR:
        return { ...state, searchStationsWithTag: action.payload };
    case SEARCH_STATIONS_WITH_CATEGORY:
        if (action.searchInput) {
          state.searchStationsHistory.push(action.searchInput);
          let searchStationsHistoryFiltered = _.uniqBy(state.searchStationsHistory, function (e) { return e; });
          return { ...state, searchStationsWithCategory: action.payload, searchStationsHistory: searchStationsHistoryFiltered };
        }
        return { ...state, searchStationsWithCategory: action.payload };
    case SEARCH_STATIONS_WITH_CATEGORY_ERROR:
        return { ...state, searchStationsWithCategory: action.payload };
    case SELECT_STATIONS:
        return { ...state, selectedStations: action.payload };
    case REMOVE_STATION_HISTORY:
        state.searchStationsHistory.splice(action.payload, 1);
        return { ...state }
    case UPDATE_ADDRESS_VALUE:
        return { ...state, addressValue: action.payload }
    case UPDATE_ADDRESS_SUGESTIONS:
        return { ...state, addressSugestions: action.payload }
    case UPDATE_ADD_STATION_DATA:
        return { ...state, addStation: action.payload }
    case ADD_STATION:
        return { ...state, addStation: { data: [], loading: true, error: false, stImgsData: [], stImgsError: false } }
    case ADD_STATION_SUCCESS:
        return { ...state, addStation: action.payload }
    case ADD_STATION_ERROR:
        return { ...state, addStation: action.payload }
    case EDIT_STATION:
        return { ...state, editStation: action.payload }
    case EDIT_STATION_SUCCESS:
        return { ...state, editStation: action.payload }
    case EDIT_STATION_ERROR:
        return { ...state, editStation: action.payload }
    case SEND_THUMB_TO_STATION:
        return { ...state, thumbStation: { loading: true, error: false, sent: false } }
    case SEND_THUMB_TO_STATION_SUCCESS:
        return { ...state, thumbStation: action.payload }
    case SEND_THUMB_TO_STATION_ERROR:
        return { ...state, thumbStation: action.payload }
    case UPDATE_THUMB_STATION:
        return { ...state, thumbStation: action.payload }
    case UPDATE_EDIT_STATION:
        return { ...state, editStation: action.payload }
    case UPDATE_SEND_REPORT:
        return { ...state, sendReport: action.payload }
    case SENT_THUMB:
        return { ...state, sentThumb: action.payload }
    case GET_STATION:
        return { ...state, viewStation: { data: [], error: false, loading: true }, manageStationImages: INITIAL_STATE.manageStationImages, thumbStation: INITIAL_STATE.thumbStation }
    case GET_STATION_SUCCESS:
        return { ...state, viewStation: action.payload }
    case GET_STATION_ERROR:
        return { ...state, viewStation: action.payload }
    case GET_CATEGORIES:
        return { ...state, categories: { data: [], error: false, loading: true } }
    case GET_CATEGORIES_SUCCESS:
        return { ...state, categories: action.payload }
    case GET_CATEGORIES_ERROR:
        return { ...state, categories: action.payload }
    case CHECK_UNIQUENAME:
        return { ...state, uniquename: { isUnique: undefined, error: false, loading: true } }
    case CHECK_UNIQUENAME_SUCCESS:
        return { ...state, uniquename: action.payload }
    case CHECK_UNIQUENAME_ERROR:
        return { ...state, uniquename: action.payload }
    case GET_LAST_STATIONS:
        return { ...state, lastStations: { data: state.lastStations.data, error: false, loading: true } }
    case GET_LAST_STATIONS_SUCCESS:
        return { ...state, lastStations: action.payload }
    case GET_LAST_STATIONS_ERROR:
        return { ...state, lastStations: action.payload }
    case GET_USER_STATIONS:
        return { ...state, userStations: { data: state.userStations.data, error: false, loading: true } }
    case GET_USER_STATIONS_SUCCESS:
        return { ...state, userStations: action.payload }
    case GET_USER_STATIONS_ERROR:
        return { ...state, userStations: action.payload }
    case CALCULATE_PRICE:
        return { ...state, campaignPrice: { data: [], max: null, min: null, error: false, loading: true } }
    case CALCULATE_PRICE_SUCCESS:
        return { ...state, campaignPrice: action.payload }
    case CALCULATE_PRICE_ERROR:
        return { ...state, campaignPrice: action.payload }
    case UPDATE_VIEW_ORIGIN:
        return { ...state, viewOrigin: action.payload }
    case UPDATE_SELECTING:
        return { ...state, selecting: action.payload }
    case LISTEN_STATION_ACTIVATION:
        return { ...state, stationActivation: action.payload, viewStation: action.returnStation }
    case STOP_LISTENING_ACTIVATION:
        return { ...state, stationActivation: action.payload }
    case MANAGE_STATION_IMAGES:
        return { ...state, manageStationImages: action.payload, operationToken: uuidv4() }
    case MANAGE_STATION_IMAGES_SUCCESS:
        return { ...state, manageStationImages: action.payload, operationToken: uuidv4() }
    case MANAGE_STATION_IMAGES_ERROR:
        return { ...state, manageStationImages: action.payload, operationToken: uuidv4() }
    case SEND_REPORT:
        return { ...state, sendReport: { loading: true, error: false, sent: false } }
    case SEND_REPORT_SUCCESS:
        return { ...state, sendReport: { loading: false, error: false, sent: true } }
    case SEND_REPORT_ERROR:
        return { ...state, sendReport: action.payload }
    case SYNC_PLAYER:
        return { ...state, syncPlayer: { loading: true, error: false, success: false } }
    case SYNC_PLAYER_SUCCESS:
        return { ...state, syncPlayer: { loading: false, error: false, success: true } }
    case SYNC_PLAYER_ERROR:
        return { ...state, syncPlayer: action.payload }
    case DELETE_STATION:
        return { ...state, deleteStation: { loading: true, error: false, success: false } }
    case DELETE_STATION_SUCCESS:
        return { ...state, deleteStation: { loading: false, error: false, success: true } }
    case DELETE_STATION_ERROR:
        return { ...state, deleteStation: action.payload }
    case BLOCK_STATION:
        return { ...state, blockStation: { loading: true, error: false, success: false } }
    case BLOCK_STATION_SUCCESS:
        return { ...state, blockStation: { loading: false, error: false, success: true }, viewStation: action.payload }
    case BLOCK_STATION_ERROR:
        return { ...state, blockStation: action.payload }
    case UNBLOCK_STATION:
        return { ...state, unblockStation: { loading: true, error: false, success: false } }
    case UNBLOCK_STATION_SUCCESS:
        return { ...state, unblockStation: { loading: false, error: false, success: true }, viewStation: action.payload }
    case UNBLOCK_STATION_ERROR:
        return { ...state, unblockStation: action.payload }
    case UPDATE_SYNC_PLAYER:
        return { ...state, syncPlayer: action.payload }
    case UPDATE_DELETE_STATION:
        return { ...state, deleteStation: action.payload }
    case UPDATE_BLOCK_STATION:
        return { ...state, blockStation: action.payload }
    case UPDATE_UNBLOCK_STATION:
        return { ...state, unblockStation: action.payload }
    case SIGN_OUT_SUCCESS:
        return INITIAL_STATE;
    case UPDATE_APP:
        return INITIAL_STATE;
    default:
        return state;
  }
}
