import styled, { keyframes } from 'styled-components';
import { FormControlLabel, ButtonBase, IconButton } from '@material-ui/core';
import { motion } from 'framer-motion';
import { rgba } from 'polished';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.secondary};
  z-index: 200;
  padding: 20px;

  @media screen and (min-width: 1024px) {
    position: relative;
    width: calc(${props => props.width}px - 640px);
    height: calc(${props => props.height}px - 50px);
    min-width: 600px;
    border-radius: 15px;
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 0 20px;

  @media screen and (min-width: 1024px) {
    position: unset;
    padding: 0px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.p`
  color: ${props => props.theme.text.secondary};
  font-weight: 700;
`;

export const BtnClose = styled(IconButton)`
  width: 40px;
  height: 40px;
  transform: translateX(10px);
  margin-left: auto !important;

  > span > svg > path {
    fill: ${props => props.theme.iconTintColor.inactive} !important;
  }
`;

export const Indicator = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  margin-top: 3px;
  background: ${props => props.theme.separator};
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => `calc(16.8% * ${props.step})`};
    transition: all .15s ease-out;
    height: 100%;
    background: ${props => props.theme.iconTintColor.inactive};
  }

`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
`;

export const LeftButton = styled(ButtonBase)`
  flex: 1;
  font-weight: 700 !important;
  color: ${props => props.theme.text.primary} !important;
  border: 1px solid ${props => props.theme.separator} !important;
  margin-right: 15px !important;
  border-radius: 5px !important;
  height: 40px;
  background: ${props => props.theme.colors.secondary} !important;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const RightButton = styled(ButtonBase)`
  flex: 1;
  font-weight: 700 !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  transform: ${props => props.disabled ? 'translateY(20px)' : 'translateY(0)'};
  opacity: ${props => props.disabled ? 0 : 1};
  transition: all .15s ease-out;
`;

export const Pages = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px 0;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    padding-top: 20px;
  }
`;

export const Page = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

export const PageTitle = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 30px;
  white-space: pre-line;
`;

export const CompanyBtns = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BtnCompany = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  padding: 0 15px !important;
  background: ${props => props.selected ? rgba(props.theme.colors.background, 1) : rgba(props.theme.colors.background, .4)} !important;
  border-radius: 5px !important;
  height: 70px;
  margin-bottom: 15px !important;
  border: 1px solid ${props => props.theme.title === 'dark' ? (props.selected ? props.theme.colors.primary : 'transparent') : (props.selected ? props.theme.colors.primary : props.theme.separator)} !important;
  transition: all .15s ease-out;
`;

export const CompanyImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;

export const CompanyName = styled.p`
  color: ${props => props.theme.text.primary};
`;

export const PageInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  background: ${props => rgba(props.theme.colors.background, .2)};
  border-radius: 5px;
  margin-bottom: 30px;
  margin-top: -15px;

  > svg > path {
    fill: ${props => props.theme.iconTintColor.active} !important;
  }
`;

export const PageInfoText = styled.p`
  font-size: 0.875rem;
  margin-left: 15px;
  line-height: 21px;
  color: ${props => props.theme.text.primary};
`;

export const PageRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

export const TermsControlLabel = styled(FormControlLabel)`
  background: ${props => props.theme.colors.background};
  padding: 15px 15px 15px 10px;
  border-radius: 5px;
  margin: 0 auto 30px auto !important;
`;

export const TermsControlLabelText = styled.p`
  color: ${props => props.theme.text.primary};
  margin-left: 5px;
`;

export const TermsLink = styled.a`
  color: ${props => props.theme.colors.primary};
`;

export const BtnFeedback = styled(RightButton)`
  padding: 0 30px !important;
  margin: 30px auto 0 auto !important;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

export const GalleryFooter = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 1024px) {
    position: unset;
  }
`;

export const BtnCancel = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 5px !important;
  border: 1px solid ${props => props.theme.iconTintColor.inactive} !important;
`;

export const BtnApply = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 5px !important;
  background: ${props => props.theme.colors.primary} !important;
  margin-left: 15px !important;
`;

export const BtnDelete = styled(ButtonBase)`
  width: 50px;
  height: 50px;
  border-radius: 5px !important;
  background: ${props => props.theme.colors.error} !important;
`;

export const GalleryRightBtns = styled.div`
  display: flex;
  align-items: center;
`;

export const DrawerContainer = styled.div`
  padding: 30px 20px 80px 20px;
  display: flex;
  flex-direction: column;
`;

export const GalleryTitle = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 30px;
`;

export const UploadStatusContainer = styled.div`
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.colors.secondary};
`;

export const UploadStatusLeft = styled.div`
  flex: 1;
`;

export const UploadStatusText = styled.p`
  color: ${props => props.theme.text.primary};
  margin-bottom: 15px;
`;

export const UploadStatusIndicator = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background: ${props => props.theme.separator};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    transition: all .15s ease-out;
    height: 100%;
    background: ${props => props.theme.iconTintColor.active};

    @media screen and (min-width: 1024px) {
      position: unset;
    }
  }
`;

export const BtnCancelUpload = styled(ButtonBase)`
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
  margin-left: 30px !important;
  background: #ffd1d1 !important;
`;

export const AlertBtnExit = styled(ButtonBase)`
  color: ${props => props.theme.colors.primary} !important;
  font-weight: 700 !important;
  padding: 8px !important;
  text-transform: uppercase !important;
  border-radius: 5px !important;
`;

export const AlertBtnConfirm = styled(ButtonBase)`
  color: ${props => props.theme.text.secondary} !important;
  font-weight: 700 !important;
  padding: 8px !important;
  text-transform: uppercase !important;
  border-radius: 5px !important;
`;

export const ThumbsSlider = styled(motion.div)`
  width: 100%;
  height: 80px;
  margin-top: -15px;
  margin-bottom: 30px;
  touch-action: none;
  -ms-touch-action: none;
  pointer-events: none;
`;

export const ThumbsInner = styled(motion.div)`
  display: inline-flex;
  align-items: center;
  height: 100%;
  pointer-events: auto;
  width: ${props => (15 * props.numberOfChildren) + (80 * props.numberOfChildren) + 15}px;
`;

export const Thumb = styled.button`
  width: 80px;
  height: 80px;
  border-radius: 5px;
  margin-right: 15px;
  background: url(${props => props.bg});
  background-position: center;
  background-size: cover;
  position: relative;
  transition: all .15s ease;

  &:active {
    filter: brightness(25%);
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      filter: brightness(25%);
    }
  }
`;

export const BtnRemoveImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: ${props => props.theme.text.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .15s ease;

  &:active {
    background: ${props => props.theme.text.secondary};
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      background: ${props => props.theme.text.secondary};
    }
  }

  > svg > path {
    fill: ${props => props.theme.colors.secondary};
  }

  @media screen and (min-width: 1024px) {
    position: unset;
  }
`;

export const TagContainer = styled.div`
  position: relative;

  .MuiInputBase-input,
  .MuiFilledInput-input,
  .MuiInputBase-inputAdornedStart,
  .MuiFilledInput-inputAdornedStart {
    padding-right: 60px;
  }
`;

// Spinner keyframes and elements
const svgRotate = keyframes`
  0% {
		transform: translateY(-55%) rotateZ(0deg);
  }
  100% {
		transform: translateY(-55%) rotateZ(360deg);
  }
`;

const spinCircle = keyframes`
  0%, 25% {
		stroke-dashoffset: 55;
		transform: rotate(0deg);
  }
	50%, 75% {
		stroke-dashoffset: 25;
		transform: rotate(45deg);
  }
	100% {
		stroke-dashoffset: 55;
		transform: rotate(360deg);
  }
`;

export const Spinner = styled(motion.svg)`
  position: absolute;
  top: 50%;
  right: 8px;
  z-index: 5;
  transform: translateY(-55%) rotateZ(0deg);
  width: 30px;
  height: 30px;
  animation: ${svgRotate} 2s linear infinite;
`;

export const CircleLoader = styled.circle`
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dashoffset: 55;
  stroke-dasharray: 63;
  transform-origin: 50% 50%;
  animation: ${spinCircle} 1.4s cubic-bezier(.785,.135,.15,.86) infinite both;
`;

export const TagStatusBadge = styled(motion.div)`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-55%);
  opacity: 1;
`;
