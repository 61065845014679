import styled from 'styled-components';
import { ButtonBase } from '@material-ui/core';
import { motion } from 'framer-motion';
import { darken } from 'polished';

export const Container = styled(motion.div)`
  width: 100%;
  height: calc(100% - 60px);
  padding: 80px 16px 0 16px;
  align-items: center;
  justify-content: center;
  overflow: hidden auto;

  @media screen and (min-width: 768px) {
    height: 100%;
  }

  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.secondary};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.text.secondary};
    border-radius: 5px;
  }
`;

export const Greeting = styled.p`
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 8px;
  color: ${props => props.theme.text.primary};
`;

export const Heading = styled.p`
  color: ${props => props.theme.text.secondary};
`;

export const SliderContainer = styled.div`
  width: 100%;
  margin: 30px 0 15px 0;
`;

export const SliderHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const SliderHeaderText = styled.p`
  color: ${props => props.theme.text.primary};
`;

export const BtnSeeAll = styled(ButtonBase)`
  color: ${props => props.theme.colors.primary} !important;
  font-weight: 600 !important;
  padding: 5px !important;
  border-radius: 5px !important;
`;

export const Slide = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 5px;
  background: ${props => `url(${props.bg})`};
  background-position: center;
  background-size: cover;
  transition: all .15s ease;
  margin-right: 15px;

  &:active {
    filter: brightness(50%);
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      filter: brightness(50%);
    }
  }
`;

export const EmptyState = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.secondary};
  position: relative;

  @media screen and (min-width: 1024px) {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
    text-align: center;
    padding: 0 16px 20px 16px;
    box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
  }
`;

export const EmptyStateIllustration = styled.img`
  width: 50%;
  object-fit: contain;
  margin: 20px 0;
`;

export const EmptyStateHeadline = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 600;
  margin-bottom: 10px;
  z-index: 2;
  position: relative;
`;

export const EmptyStateSub = styled.p`
  color: ${props => props.theme.text.secondary};
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
`;

export const EmptyStateBtn = styled(ButtonBase)`
  border-radius: 5px !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  height: 40px;
  padding: 0 15px !important;
`;

export const Grid = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
`;

export const DesktopContainer = styled.div`
  border-radius: 10px;
  background: ${props => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  width: 350px;
  margin-right: 30px;

  @media screen and (min-width: 1024px) {
    box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  }
`;

export const DesktopHeader = styled.p`
  color: ${props => props.theme.text.primary};
  padding: 24px 16px;
  font-weight: 600;
`;

export const DesktopList = styled.div`
  width: 100%;
  max-height: ${window.innerHeight - 320}px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden auto;

  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.secondary};
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.text.secondary};
    border-radius: 5px;
  }
`;

export const Wrapper = styled(ButtonBase)`
  display: flex;
  align-items: center !important;
  width: 100% !important;
  padding: 16px !important;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background} !important;
  cursor: pointer !important;

  @media screen and (min-width: 1024px) {
    &:hover {
      background: ${props => darken(.03, props.theme.colors.secondary)} !important;
    }
  }
`;

export const Thumb = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  background: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  margin-right: 15px;  
  position: relative;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

export const Title = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 600;
  margin-bottom: 5px;
`;

export const Address = styled.p`
  color: ${props => props.theme.text.secondary};
`;

export const BtnOptions = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .15s ease;

  &:active {
    background: ${props => props.theme.colors.secondary};
  }

  @media screen and (min-width: 1024px) {
    &:hover {
      background: ${props => props.theme.colors.secondary};
    }
  }
`;