import React, { useEffect } from 'react';

import { connect } from 'react-redux';
import { Base64 } from 'js-base64';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SERVER_ADDRESS } from '../../constraints/types';
import { signIn } from '../../store/actions/AuthenticationActions';

const Initial = ({ signIn, handleLoggedIn, loggedIn, memberDataAtomic, stationsDataAtomic, token }) => {
  const { t } = useTranslation();
  let params = useParams();

  useEffect(() => {
    if (!loggedIn) {
      if (!params.email || !params.token) {
        // Using hybrid development (local+production) change the SERVER_ADDRESS
        // in constraints/types to https://www.telesapp.net instead of localhost
        window.location.replace(`${SERVER_ADDRESS}/membro/mi`);
        // and set the port of your local server in the end of string i.e: /membro/mi/3000
      } else {
        const credentials = {
          email: Base64.decode(params.email),
          token: Base64.decode(params.token)
        }
        signIn(credentials, memberDataAtomic, '/api/v1/user');
      }
    } else {
      const credentials = { email: memberDataAtomic.attributes.email, token }

      handleLoggedIn(true);

    }

  }, [signIn, handleLoggedIn, params, loggedIn, memberDataAtomic, stationsDataAtomic, token ]);
  // Temporary loading screen
  return (
    <div>{t('init.message.label')}</div>
  );
}

const mapStateToProps = state => (
  {
    token: state.AuthenticationReducer.token,
    memberDataAtomic: state.AuthenticationReducer.memberData.data,
    stationsDataAtomic: state.StationsReducer.stations.data,
    loggedIn: state.AuthenticationReducer.loggedIn
  }
);

export default connect(mapStateToProps, { signIn })(Initial);
