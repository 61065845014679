import styled from 'styled-components';
import { motion } from 'framer-motion';

import { IconButton, Radio, FormControlLabel, ButtonBase } from '@material-ui/core';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.secondary};
  z-index: 200;
  padding: 20px 0 0 0;

  @media screen and (min-width: 1024px) {
    position: relative;
    padding: 20px;
    width: 400px;
    height: 650px;
    min-width: 600px;
    border-radius: 15px;
  }
`;

export const HeaderOp = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 20px 0 20px;

  @media screen and (min-width: 1024px) {
    position: unset;
    padding: 0px;
  }
`;

export const RowOp = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const TitleOp = styled.p`
  color: ${props => props.theme.text.secondary};
  font-weight: 700;
`;

export const BtnClose = styled(IconButton)`
  width: 40px;
  height: 40px;
  transform: translateX(10px);
  margin-left: auto !important;

  > span > svg > path {
    fill: ${props => props.theme.iconTintColor.inactive} !important;
  }
`;

export const Indicator = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  margin-top: 3px;
  background: ${props => props.theme.separator};
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${props => `calc(25% * ${props.step})`};
    transition: all .15s ease-out;
    height: 100%;
    background: ${props => props.theme.iconTintColor.inactive};
  }
`;

export const Pages = styled.div`
  width: 100%;
  height: 100%;
  padding: 60px 0;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    padding-top: 20px;
  }
`;

export const Page = styled(motion.div)`
  width: 100%;
  overflow-y: auto;
  padding-bottom: 60px;

  ${props => !props.mobile && `
    &::-webkit-scrollbar {
      width: 3px;
    }
  `}

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primary ? props.theme.colors.background : props.theme.text.secondary};
    border-radius: 2px;
  }

  @media screen and (min-width: 768px) {
    height: 100%;
    padding-right: 10px;
  }
`;

export const PageTitle = styled.p`
  font-size: 1.5rem;
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  margin-bottom: 30px;
  white-space: pre-line;
  margin: 0 0 30px 5px;

  @media screen and (min-width: 1024px) {
    margin-bottom: 30px;
  }
`;

export const CustomRadio = styled(Radio)`
  color: ${props => props.theme.text.primary} !important;

  .MuiSvgIcon-root {
    width: 1.2em;
    height: 1.2em; 
  }

  &.Mui-checked {
    color: ${props => props.theme.colors.primary} !important;
  }
  
  &.MuiIconButton-colorSecondary:hover {
    background-color: ${props => props.theme.colors.primary}20 !important;
  }
`;

export const ReportOptions = styled.div`
  margin: 0px 15px 0px 15px;
`;

export const CustomFormControlLabel = styled(FormControlLabel)`
  color: ${props => props.theme.text.primary};

  .MuiTypography-root {
    line-height: 1.2;
    letter-spacing: 0.0238em;
  }
`;

export const RadioContainer = styled.div`
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 5px;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  background-color: ${props => props.theme.colors.background};
  border-color: ${props => props.theme.colors.secondary};
  max-height: ${props => props.isOpen ? '500px' : '150px'};
  transition: ${props => props.isOpen ? 'max-height 0.25s ease-in' : 'max-height 0.25s ease-out'};

  overflow: hidden;

  &:hover {
    border-color: ${props => props.theme.colors.primary};
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: ${props => props.theme.colors.secondary};

  @media screen and (min-width: 1024px) {
    padding: 20px 40px 20px 35px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
`;

export const SendButton = styled(ButtonBase)`
  flex: 1;
  font-weight: 700 !important;
  background: ${props => props.disabled ? '#aaaaaa' : props => props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  pointer-events: ${props => props.disabled ? 'none' : 'all'};
  transition: all .15s ease-out;
`;
