import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withRouter } from "react-router";
import _ from 'lodash';

import {
  ActivityIndicator,
  View,
  TextInput,
  TouchableWithoutFeedback,
  TouchableOpacity,
  FlatList,
  ImageBackground,
  StyleSheet,
  StatusBar,
  Animated,
  Easing,
  BackHandler,
  Text,
  Dimensions,
  Alert,
  Keyboard,
  Clipboard,
  Platform,
  SafeAreaView
} from 'react-native';

import chatBg from '../../assets/images/chat_bg.png';
import { conversationFetch, checkContactStatus } from '../../store/actions/ChatActions';

const { width: wWidth, height: wHeight } = Dimensions.get('window');

var lastMessageUid = null;

class ChatMessages extends Component {

  constructor() {
    super();
    this.state = {
      uploadMenuAnimation: new Animated.Value(0),
      isUploadMenuShow: false,
      activeImage: null,
      activeIndex: null,
      _expandAnimValue: new Animated.Value(0),
      imgsToUpload: [],
      isInputEditable: true,
      soundEnabled: false,
      messageInput: '',
      statusBarColor: '#004047',
      messagesAmount: 20,
      previousAmount: 0,
      repeatCount: 0,
      isKeyboardShown: false,
      isHeaderActionsShown: false,
      selectedMessages: 0,
      currentMessages: [],
      isNotificationShown: false,
      notificationContent: 'Mensagem copiada',
      _scrollY: 0,
      isAudioMessageContainerShown: false,
      audioButtonPress: false,
      isAudioNotificationShown: false
    }
  }

  componentDidMount() {

    const { companyId, recipientId, serviceOrderId } = this.props.location;

    if (!companyId || !recipientId) this.props.history.push('/panels');
    else {
      lastMessageUid = null;
      this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => this.setKeyboardVisible(true));
      this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => this.setKeyboardVisible(false));

      this.props.conversationFetch(
        this.props.userId,
        recipientId,
        serviceOrderId,
        companyId,
        this.state.messagesAmount
      );

      this.props.checkContactStatus(recipientId);

      //fbase.notifications().removeAllDeliveredNotifications();

      if (this.state.isUploadMenuShow) {
        Animated.timing(this.state.uploadMenuAnimation, {
          toValue: 0,
          duration: 400,
          easing: Easing.bezier(.785,.135,.15,.86),
        }).start();

        this.setState({ isUploadMenuShow: false, isInputEditable: true });
      }

      BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    }
  }

  setKeyboardVisible = status => {
    this.setState({ isKeyboardShown: status });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.contactUploads.length) {
      const contactUploadsFinished = _.filter(this.props.contactUploads, (val) => {
        if (val.state == 'success') {
          return val
        }
      });
      if (contactUploadsFinished.length === this.props.contactUploads.length) {
        Animated.spring(this._bubbleShownHelper, {
          toValue: 0,
          stiffness: 100,
          damping: 10,
          mass: 1,
          useNativeDriver: true
        }).start();

        this.isUploadBubbleShown = false;
      } else {
        Animated.spring(this._bubbleShownHelper, {
          toValue: 1,
          delay: 1000,
          stiffness: 100,
          damping: 10,
          mass: 1,
          useNativeDriver: true
        }).start();

        if (!this.isUploadBubbleShown) {
          this.isUploadBubbleShown = true;
        }
      }
    }

    if (this.state.soundEnabled) {
      if (Platform.OS === 'android') {
        if (this.props.conversation.length > 1) {
          if ((this.props.conversation[0].uid !== lastMessageUid) && lastMessageUid !== null && !this.props.isDeleteProccess) {
            if (this.props.conversation[0].type === 'r') {
              /*
              messageReceived.play((success) => {
                if (!success) { }
              });
              */
            } else if (this.props.conversation[0].type === 'e') {
              /*
              messageSent.play((success) => {
                if (!success) { }
              });
              */
            }
          }
          if (this.props.isDeleteProccess) {
            lastMessageUid = null;
          } else {
            lastMessageUid = this.props.conversation[0].uid;
          }
        }
      }
    }
  }

  render() {
    return (
      <ImageBackground
        source={chatBg}
        style={{ width: '100%', height: '100%', flex: 1 }}
      >
      </ImageBackground>
    )
  }
}


const styles = StyleSheet.create({
  chatFooter: {
    width: '100%',
    minHeight: 60,
    maxHeight: 120,
    backgroundColor: '#fff',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  chatInput: {
    fontSize: 16,
    color: '#222',
    backgroundColor: '#fff',
    paddingHorizontal: 5,
    flex: 1,
    marginHorizontal: 8,
    paddingVertical: 8,
    marginVertical: 8,
  },
  triggerUploadMenu: {
    width: 30,
    height: 30,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 30
  },
  sendNCameraBtn: {
    width: 40,
    height: 40,
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 15,
    backgroundColor: '#01a3b2',
    borderRadius: 20,
    position: 'relative'
  },
  btnExpandInput: {
    position: 'absolute',
    left: '50%',
    top: -29,
    width: 40,
    height: 30,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5
  },
  selectUploadContainerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginRight: 5
  },
  uploadOptionContainer: {
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: 80
  },
  uploadOptionText: {
    color: '#707070',
    marginTop: 5,
    fontSize: 16
  },
  uploadOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    elevation: 2
  },
  imageOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#000',
    elevation: 2,
    zIndex: 17
  },
  imageOverlayCloseBtn: {
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 10,
  },
  uploadProgressBar: {
    position: 'absolute',
    top: 60,
    left: 0,
    width: wWidth - 140,
    height: 5,
    backgroundColor: '#FF686B',
    zIndex: 15,
    elevation: 2
  }
});

const ChatMessagesWithRouter = withRouter(ChatMessages);

const mapStateToProps = state => ({
  userId: state.AuthenticationReducer.memberData.data.id,
});

export default connect(mapStateToProps, { conversationFetch, checkContactStatus })(ChatMessagesWithRouter);
