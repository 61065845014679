import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { connect } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import Icon from '@mdi/react';
import { mdiMapMarkerOutline, mdiStar, mdiStarOutline } from '@mdi/js';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { updateAddressSugestions, asyncSearchStations, updateViewOrigin } from '../../store/actions/StationsActions';
import { SERVER_ADDRESS } from '../../constraints/types';
import Map from '../../components/Map';
import StationsLoader from '../../components/StationsLoader';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { useSearching } from '../../context/Searching';
import { useHistory } from 'react-router-dom';

import MobileSearch from '../../components/MobileSearch';
import Swiper from '../../components/Swiper';
import SwiperCard from '../../components/SwiperCard';

import { Container, StationsList, StationsListHeader, StationsListHeaderTitle, BtnCollapseMap, StationsGrid, StationContainer, StationImages, StationImage, StationTitle, StationSubtitle, StationAddress, StationDetails, StationRating, StationStars, StationRatingText } from './styles';

const swiperConfig = {
  bottom: 40,
  left: 0
}

const Stations = ({ searchStations = [], loadingStations, addressValue, updateAddressSugestions, asyncSearchStations, email, token, updateViewOrigin }) => {
  const size = useWindowSize();
  const history = useHistory();
  const { searching, setSearching } = useSearching();
  const [ center, setCenter ] = useState({
    lat: 0,
    lng: -55.1935154
  });
  const [ mapCollapsed, setMapCollapsed ] = useState(false);
  const [ sortMethod, setSortMethod ] = useState('popular');
  const [ zoom, setZoom ] = useState(100);
  const [ selectedStation, setSelectedStation ] = useState(null);
  const [ swiperPosition, setSwiperPosition ] = useState(0);
  const [ swiperMoving, setSwiperMoving ] = useState(false);
  const [ selectedStationId, setSelectedStationId ] = useState(-1);

  useEffect(() => {
    if (!searchStations.length) {
      const credentials = { email, token };
      const endpoint = `${SERVER_ADDRESS}/api/v1/search-stations`;

      asyncSearchStations(credentials, endpoint, searchStations, null, '', '', '', 'keywords');
    }
  }, []);

  useEffect(() => {
    if (size.width < 1024) {
      if (!searchStations.length && !loadingStations) setSearching({ ...searching, active: true, componentMode: false });
      else setSearching({ ...searching, active: false, componentMode: false });
    }

    if (searchStations.length > 0 && !loadingStations) {
      if (size.width < 1024) setSelectedStationId(searchStations[0].id);

      setCenter({
        lat: searchStations[0].attributes.latitude,
        lng: searchStations[0].attributes.longitude
      });
      setZoom(200);
    }
  }, [searchStations, loadingStations, size.width])

  const openStationScreen = (station) => {
    setSelectedStation(station);
    updateViewOrigin(true);
    history.push({
      pathname: `/station/${station.id}`,
      state: {
        station: station
      }
    });
  }

  const handleStationOver = (id) => {
    setSelectedStationId(id);
  }

  const handleStationOut = () => {
    setSelectedStationId(-1);
  }

  const handlePress = (station) => {
    if (station) {
      setSelectedStationId(station.id);
      setSelectedStation(station);
    }
    else {
      handleStationOut();
      setSelectedStation(null);
    }
  }

  const handleChangeSortMethod = evt => {
    setSortMethod(evt.target.value);
  }

  const toggleMapCollapse = () => {
    setMapCollapsed(!mapCollapsed);
  }

  const renderStationsGrid = () => {
    if (searchStations.length > 0) {
      return _.uniqBy(searchStations, function (e) { return e.id; }).map((station, index) => (
        <StationContainer
          key={index}
          onMouseOver={() => handleStationOver(station.id)}
          onMouseOut={handleStationOut}
          selected={selectedStationId === station.id}
          title={`Visitar estação ${station.attributes.name}`}
          role='button'
          aria-label={`Botão para abrir a página da estação ${station.attributes.name}`}
          onClick={() => openStationScreen(station)}
        >
          <StationImages>
            <StationImage src={`${SERVER_ADDRESS}${station.attributes['avatar-original']}`} />
          </StationImages>
          <StationDetails>
            <StationTitle>{station.attributes.name}</StationTitle>
            <StationSubtitle>
              <Icon
                path={mdiMapMarkerOutline}
                size='18px'
              />
              <StationAddress>{station.attributes.city}</StationAddress>
            </StationSubtitle>
            <StationRating>
              <StationStars>
                <Icon
                  path={mdiStar}
                  size='18px'
                />
              </StationStars>
              <StationRatingText>{station.attributes['answered-proposals']}</StationRatingText>
            </StationRating>
          </StationDetails>
        </StationContainer>
      ))
    }
    else {
      return false;
    }
  }

  return (
    <Container>
      { size.width < 1024 ? (
        <Swiper style={swiperConfig} show={searching.mapMode && !searching.active && !loadingStations} swiperPosition={swiperPosition} setSwiperMoving={setSwiperMoving}>
          {
            _.uniqBy(searchStations, function (e) { return e.id; }).map((station, index) => (
              <SwiperCard
                key={index}
                station={station}
                selectedStationId={selectedStationId}
                onPress={() => openStationScreen(station)}
                setSelectedStationId={setSelectedStationId}
                setCenter={setCenter}
                swiperMoving={swiperMoving}
                setSwiperPosition={setSwiperPosition}
              />
            ))
          }
        </Swiper>
      ) :
      (
        <StationsList mapCollapsed={mapCollapsed || searchStations.length === 0 }>
          <StationsListHeader>
            <StationsListHeaderTitle>{searchStations.length > 0 ? `${_.uniqBy(searchStations, function (e) { return e.id; }).length} resultados` : 'Todos os painéis ativos'}</StationsListHeaderTitle>
            {
              searchStations.length === 0 ? (
                <FormControl>
                  <InputLabel id='stations-sort-label'>Ordenar por</InputLabel>
                  <Select
                    labelId='stations-sort-label'
                    id='stations-sort'
                    value={sortMethod}
                    onChange={handleChangeSortMethod}
                    style={{
                      minWidth: '180px'
                    }}
                  >
                    <MenuItem value='popular'>Mais populares</MenuItem>
                    <MenuItem value='recent'>Mais recentes</MenuItem>
                    <MenuItem value='closest'>Mais perto</MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <BtnCollapseMap onClick={toggleMapCollapse}>{mapCollapsed ? 'Exibir mapa' : 'Ocultar mapa'}</BtnCollapseMap>
              )
            }
          </StationsListHeader>
          <StationsGrid>
            { renderStationsGrid() }
          </StationsGrid>
        </StationsList>
      ) }

      {
        <Map
          type={'st'}
          stations={_.uniqBy(searchStations, function (e) { return e.id; })}
          center={center}
          zoom={zoom}
          addressValue={addressValue}
          updateAddressSugestions={updateAddressSugestions}
          onMouseOver={handleStationOver}
          onMouseOut={handleStationOut}
          handlePress={handlePress}
          openStationScreen={openStationScreen}
          selectedStationId={selectedStationId}
          selectedStation={selectedStation}
        />
      }

      <AnimatePresence exitBeforeEnter={true}>
        { loadingStations && <StationsLoader /> }
      </AnimatePresence>

      { size.width < 1024 && <MobileSearch /> }
    </Container>
  )
}

const mapStateToProps = state => ({
  searchStations: state.StationsReducer.searchStations.data,
  loadingStations: state.StationsReducer.searchStations.loading,
  addressValue: state.StationsReducer.addressValue,
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token
});

export default connect(mapStateToProps, { updateAddressSugestions, asyncSearchStations, updateViewOrigin })(Stations);
