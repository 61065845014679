import _ from 'lodash';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import {
  SERVER_ADDRESS,
  GET_COMPANY_ADS,
  GET_COMPANY_ADS_SUCCESS,
  GET_COMPANY_ADS_ERROR,
  SET_USER_ADS,
  SEND_AD_TO_STATION,
  SEND_AD_TO_STATION_SUCCESS,
  SEND_AD_TO_STATION_ERROR,
  UPDATE_SEND_AD_TO_STATION,
  CREATE_AD,
  CREATE_AD_SUCCESS,
  CREATE_AD_ERROR,
  UPDATE_CREATE_AD,
  UPDATE_CREATED_AD_ALERT,
  UPDATE_ST_SERVICE_DATA,
  ADD_NOTIFICATION,
  APPROVE_SERVICE_STATION,
  APPROVE_SERVICE_STATION_SUCCESS,
  APPROVE_SERVICE_STATION_ERROR,
  UPDATE_APPROVE_SERVICE_STATION,
  PAY_SERVICE_STATION,
  PAY_SERVICE_STATION_SUCCESS,
  PAY_SERVICE_STATION_ERROR,
  UPDATE_PAID_SERVICE_STATION,
  GET_STATION_ADS,
  GET_STATION_ADS_SUCCESS,
  GET_STATION_ADS_ERROR,
  DELETE_OWNER_AD,
  DELETE_OWNER_AD_SUCCESS,
  DELETE_OWNER_AD_ERROR,
  UPDATE_DELETE_OWNER_AD,
  UPDATE_ORIGIN,
  UPDATE_TOKEN
} from '../../constraints/types';

import {
  development
} from '../../constraints/utils';

import { database } from '../../firebase';

/////////////////////// GET COMPANY ADS ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// GET COMPANY ADS //////////////////////////
/*
  Endpoint to concatenate all services from current company
*/


export const getCompanyAds = (credentials, companyId, previousData) => {
  return dispatch => {
    dispatch({ type: GET_COMPANY_ADS });
    const url = `${SERVER_ADDRESS}/api/v1/professional/companies/${companyId}/services`;
    axios.get(url, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Access-Token': credentials.token,
        'X-User-Email': credentials.email,
      }
    })
    .then(res => {

      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const payload = {
        data: res.data.data,
        error: false,
        loading: false
      }
      dispatch({ type: GET_COMPANY_ADS_SUCCESS, payload });
    })
    .catch(error => {
      const payload = {
        data: previousData,
        error: error,
        loading: false
      }
      dispatch({ type: GET_COMPANY_ADS_ERROR, payload });
    });
  }
}

export const setUserAds = (data) => ({ type: SET_USER_ADS, payload: data });

/////////////////////// GET COMPANY ADS END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// SEBD AD TO STATION BEGIN //////////////////////////
/*
  Endpoint to request an insertion of ad into station
*/

export const sendAdToStation = (credentials, serviceData, userId) => {

  const {
    img,
    selectedStations,
    serviceId,
    weeks,
    qrcode,
    seconds
  } = serviceData;

  return dispatch => {
    dispatch({ type: SEND_AD_TO_STATION });
    var fileType = '';
    if (img.hasOwnProperty('b64')) {
      fileType = 'image';
      let extension = '';
      if (img.hasOwnProperty('contentType')) {
        extension = img.contentType.split("/")[1];
        if (extension === undefined) extension = 'jpeg';
      } else if (img.hasOwnProperty('type')) {
        extension = img.type.split("/")[1];
        if (extension === undefined) extension = 'jpeg';
      } else {
         extension = 'jpeg';
      }

      const fileName  = `${uuidv4()}.${extension}`;
      var data = JSON.stringify(
        {"data":
          {
            "type":"st_services",
            "attributes":{
              "storage_ref":"",
              "qr_code":qrcode,
              "original_file_name": fileName,
              "avatar": img.b64,
              "user_id": userId,
              "duration_seconds": seconds
            }
          }
        }
      );
    } else {
      fileType = 'video';
      let extension = '';
      if (img.hasOwnProperty('contentType')) {
        extension = img.contentType.split("/")[1];
        if (extension === undefined) extension = 'mp4';
      } else {
        extension = 'mp4';
      }

      const fileName  = `${uuidv4()}.${extension}`;
      var data = JSON.stringify(
        {"data":
          {
            "type":"st_services",
            "attributes":{
              "storage_ref":img.url,
              "qr_code":qrcode,
              "video_content_type":img.contentType,
              "user_id": userId,
              "duration_seconds": seconds
            }
          }
        }
      );
    }
    var config = {
      method: 'post',
      url: `${SERVER_ADDRESS}/api/v1/st_services`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': credentials.token
      },
      data : data
    };
    axios(config)
    .then(function (response) {
      // getting the new token if is present
      var newToken = credentials.token;
      if (response.headers['x-access-token']) {
        newToken = response.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      var stService = response.data;

      selectedStations.map((station, index) => {
        // set incoming data
        var data = JSON.stringify(
          {"data":
            {
              "type":"service_stations",
              "attributes":{
                "professional_service_id":serviceId,
                "station_id":station.id,
                "st_service_id":stService.data.id,
                "weeks": weeks
              }
            }
          }
        );
        // set endpoint to create station
        var config = {
          method: 'post',
          url: `${SERVER_ADDRESS}/api/v1/service_stations/`,
          headers: {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/json',
            'X-User-Email': credentials.email,
            'X-Access-Token': credentials.token
          },
          data : data
        };
        axios(config)
        .then(function (response) {
          const sendAdToStation = {
            data: response.data,
            loading: false,
            finish: true,
            error: false
          }
          dispatch({ type: SEND_AD_TO_STATION_SUCCESS, payload: sendAdToStation });
          // if receiving ad station isn't property of announcer
          if (!station.attributes['owner?']) {
            const { data } = response.data;
            const notificationData = {
              content: `Nova solicitação em seu painel ${station.attributes.name}`,
              date: Date.now(),
              img: (fileType === 'video') ? data.attributes['st-service'].storage_ref : `${SERVER_ADDRESS}${data.attributes['avatar-original']}`,
              contentType: (fileType === 'video') ? img.contentType : img.type,
              id: data.id,
              stationName: data.attributes.station.name,
              stationId: data.attributes.station.id.toString(),
              senderId: btoa(userId),
              duration: stService.data.attributes['duration-seconds'],
              weeks: data.attributes.weeks,
              type: 'new-request',
              important: false,
              read: false
            }
            // convert userid (owner of the panel) to b64
            const ownerIdB64 = btoa(station.attributes['owner-id']);
            // insert the new notification to avoid user for activate the fresh ad
            addNotification(dispatch, notificationData, ownerIdB64);
          }
          // getting the new token if is present
          var newToken = credentials.token;
          if (response.headers['x-access-token']) {
            newToken = response.headers['x-access-token'];
            dispatch({ type: UPDATE_TOKEN, newToken });
          }
        })
        .catch(function (error) {
          // if there's an error when adding station (first level, not images) the error is throwed
          const sendAdToStation = {
            data: [],
            loading: false,
            finish: false,
            error: error
          }
          dispatch({ type: SEND_AD_TO_STATION_ERROR, payload: sendAdToStation });
        });
      })
    })
    .catch(function (error) {
      // if there's an error when adding station (first level, not images) the error is throwed
      const sendAdToStation = {
        data: [],
        loading: false,
        finish: false,
        error: error
      }
      dispatch({ type: SEND_AD_TO_STATION_ERROR, payload: sendAdToStation });
    });
  }
}

/////////////////////// SEND AD TO STATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// APPROVE SERVICE STATION BEGIN //////////////////////////
/*
  Request used at AdApproval screen, when the station owner set
  the service_station to approved
*/

export const approveServiceStation = (credentials, serviceStationId, notificationData) => {
  return dispatch => {
  dispatch({ type: APPROVE_SERVICE_STATION });
    var config = {
      method: 'post',
      url: `${SERVER_ADDRESS}/api/v1/aprovar/${serviceStationId}`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': credentials.token
      }
    };
    axios(config)
    .then(function (response) {
      // getting the new token if is present
      var newToken = credentials.token;
      if (response.headers['x-access-token']) {
        newToken = response.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }
      notificationData.pix = response.data.pix;
      addNotification(dispatch, notificationData, notificationData.senderId);

      const approveServiceStation = {
        data: { data: response.data.service },
        loading: false,
        error: false
      }
      dispatch({ type: APPROVE_SERVICE_STATION_SUCCESS, payload: approveServiceStation });
    })
    .catch(function (error) {
      const approveServiceStation = {
        data: [],
        loading: false,
        error: error
      }
      dispatch({ type: APPROVE_SERVICE_STATION_ERROR, payload: approveServiceStation });
    });
  }
}

/////////////////////// APPROVE SERVICE STATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// PAY SERVICE STATION BEGIN //////////////////////////
/*
  Request used at AdApprovedPayment screen, when the announcer
  do his payment to get ad working at panel, turning paid field
  to true and calculating/setting the expiration date
*/

export const payServiceStation = (credentials, serviceStationId) => {
  return dispatch => {
  dispatch({ type: PAY_SERVICE_STATION });
    var config = {
      method: 'post',
      url: `${SERVER_ADDRESS}/api/v1/service_paid/${serviceStationId}`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': credentials.token
      }
    };
    axios(config)
    .then(function (response) {
      // getting the new token if is present
      var newToken = credentials.token;
      if (response.headers['x-access-token']) {
        newToken = response.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const paidServiceStation = {
        data: response.data,
        loading: false,
        error: false
      }
      dispatch({ type: PAY_SERVICE_STATION_SUCCESS, payload: paidServiceStation });
    })
    .catch(function (error) {
      const paidServiceStation = {
        data: [],
        loading: false,
        error: error
      }
      dispatch({ type: PAY_SERVICE_STATION_ERROR, payload: paidServiceStation });
    });
  }
}

/////////////////////// PAY SERVICE STATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// ADD NOTIFICATION BEGIN //////////////////////////

const addNotification = (dispatch, notificationData, userId) => {
  const RTDBUrl = development ? `devMiNotifications/${userId}` : `miNotifications/${userId}`;

  database.ref(RTDBUrl)
    .push(notificationData)
    .then(() => {
      dispatch({ type: ADD_NOTIFICATION });
    });
};

/////////////////////// ADD NOTIFICATION END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// CREATE AD BEGIN //////////////////////////
/*
  Request a post to global telesapp and create a professional_service in it, in the
  first professional profile that logged user has
*/


export const createAd = (credentials, id, serviceData, stServiceData) => {
  return dispatch => {
    dispatch({ type: CREATE_AD });
    const url = `${SERVER_ADDRESS}/api/v1/professional/companies/${id}/services`;

    const headers = {
      Accept: 'application/vnd.api+json;',
      'Content-Type': 'application/json',
      'X-Access-Token': credentials.token,
      'X-User-Email': credentials.email,
    };

    let body = {};

    if (serviceData.hasOwnProperty('avatar')) {
      body = {
        data: {
          type: 'professional-company',
          attributes: {
            name: serviceData.name,
            description: serviceData.description,
            "admin-category-id": serviceData.categoryId,
            "professional-company-id": id,
            "original_file_name": serviceData.originalFileName,
            "avatar": serviceData.avatar,
            "promo-ad": serviceData.isPromoAd,
            "promo-phrase": serviceData.promoAdCampaign
          }
        }
      }
    }
    else {
      body = {
        data: {
          type: 'professional-company',
          attributes: {
            name: serviceData.name,
            description: serviceData.description,
            "admin-category-id": serviceData.categoryId,
            "professional-company-id": id,
            "promo-ad": serviceData.isPromoAd,
            "promo-phrase": serviceData.promoAdCampaign
          }
        }
      }
    }

    axios.post(url, body, { headers })
    .then(res => {
      // getting the new token if is present
      var newToken = credentials.token;
      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }
      const returnAd = {
        data: res.data.data,
        loading: false,
        finish: true,
        error: false
      }
      dispatch({ type: CREATE_AD_SUCCESS, payload: returnAd });

      const newStData = {
        img: serviceData.hasOwnProperty('avatar') ? { ...stServiceData.img, b64: serviceData.avatar } : stServiceData.img,
        serviceId: res.data.data.id,
        selectedStations: stServiceData.selectedStations,
        weeks: stServiceData.weeks
      }
      dispatch({ type: UPDATE_ST_SERVICE_DATA, payload: newStData });
    })
    .catch(error => {
      const returnAd = {
        data: [],
        loading: false,
        finish: false,
        error: error
      }
      dispatch({ type: CREATE_AD_ERROR, payload: returnAd });
    });
  }
};

/////////////////////// CREATE AD END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// GET STATION ADS BEGIN //////////////////////////
/*
  Request used at ViewAdsAtStation screen, when the announcer
  or the user can see wich are the ads running at panel, used
  in both cases of viewing or after payment has made
*/
export const getStationAds = (credentials, stationId) => {
  return dispatch => {
  dispatch({ type: GET_STATION_ADS });
    const url = `${SERVER_ADDRESS}/api/v1/station_services/${stationId}/mi`;
    axios.get(url, {
      headers: {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Access-Token': credentials.token,
        'X-User-Email': credentials.email,
      }
    })
    .then(function (response) {
      // getting the new token if is present
      var newToken = credentials.token;
      if (response.headers['x-access-token']) {
        newToken = response.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      const stationAds = {
        data: response.data.services,
        loading: false,
        error: false
      }
      dispatch({ type: GET_STATION_ADS_SUCCESS, payload: stationAds });
    })
    .catch(function (error) {
      const stationAds = {
        data: [],
        loading: false,
        error: error
      }
      dispatch({ type: GET_STATION_ADS_ERROR, payload: stationAds });
    });
  }
}

/////////////////////// GET SATION ADS END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// DELETE OWNER AD BEGIN //////////////////////////
/*
  Owner can delete their ads, refreshing
  mi player on the next cicle
*/


export const deleteOwnerAd = (credentials, serviceStationId) => {
  return dispatch => {
    dispatch({ type: DELETE_OWNER_AD });

    var data = '';
    var newToken = credentials.token;

    var config = {
      method: 'delete',
      url: `${SERVER_ADDRESS}/api/v1/service_stations/${serviceStationId}`,
      headers: {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-Access-Token': newToken
      },
      data : data
    };

    axios(config)
    .then(function (res) {

      if (res.headers['x-access-token']) {
        newToken = res.headers['x-access-token'];
        dispatch({ type: UPDATE_TOKEN, newToken });
      }

      dispatch({ type: DELETE_OWNER_AD_SUCCESS, payload: serviceStationId });
    })
    .catch(function (error) {
      const payload = {
        loading: false,
        error: error,
        success: false
      };
      dispatch({ type: DELETE_OWNER_AD_ERROR, payload });
    });
  }
}

/////////////////////// SYNC PLAYER END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
///////////////////// UPDATE METHODS BEGIN //////////////////////

export const updateSendAdToStation = (sendAdToStation) => ({
  type: UPDATE_SEND_AD_TO_STATION,
  payload: sendAdToStation
});

export const updateCreateAd = (createAd) => ({
  type: UPDATE_CREATE_AD,
  payload: createAd
});

export const updateCreatedAdAlert = (createdAdAlert) => ({
  type: UPDATE_CREATED_AD_ALERT,
  payload: createdAdAlert
});

export const updateStServiceData = (stServiceData) => ({
  type: UPDATE_ST_SERVICE_DATA,
  payload: stServiceData
});

export const updateApprovedServiceStation = (approveServiceStation) => ({
  type: UPDATE_APPROVE_SERVICE_STATION,
  payload: approveServiceStation
});

export const updatePaidServiceStation = (paidServiceStation) => ({
  type: UPDATE_PAID_SERVICE_STATION,
  payload: paidServiceStation
});

export const updateOrigin = (bool) => ({
  type: UPDATE_ORIGIN,
  payload: bool
});

export const updateDeleteOwnerAd = (ownerAdData) => ({
  type: UPDATE_DELETE_OWNER_AD,
  payload: ownerAdData
});

/////////////////////// UPDATE METHODS END ///////////////////////
// ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦ ✦
