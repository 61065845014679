import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';

import MDIcon from '@mdi/react';
import { mdiOrderBoolAscendingVariant, mdiClose, mdiCheckUnderline } from '@mdi/js';
import { Icon } from '@iconify/react';
import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import closeOutlined from '@iconify-icons/ant-design/close-outlined';
import magnifyingGlass from '@iconify-icons/ph/magnifying-glass';
import CircularProgress from '@material-ui/core/CircularProgress';
import roundMic from '@iconify/icons-ic/round-mic';
import roundArrowForward from '@iconify/icons-ic/round-arrow-forward';
import Badge from '@material-ui/core/Badge';
import roundClose from '@iconify/icons-ic/round-close';
import { ThemeContext } from 'styled-components';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import InfiniteScroll from 'react-infinite-scroll-component';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import useStateWithCallback from '../../utils/hooks/useStateWithCallback';
import SearchStationsItem from '../../components/SearchStationsItem';
import { asyncSearchStations, asyncGetAllStations, selectStations, updateViewOrigin, updateSelecting } from '../../store/actions/StationsActions';
import { updateOrigin } from '../../store/actions/AdsActions';
import StationsLoader from '../../components/StationsLoader';
import { SERVER_ADDRESS } from '../../constraints/types';
import AutoCompleteList from '../../components/AutoCompleteList';
import { useSearching } from '../../context/Searching';
import useActiveTab from '../../utils/hooks/useActiveTab';
import useWindowSize from '../../utils/hooks/useWindowSize';

import {
  Container,
  Header,
  Row,
  ButtonBack,
  SearchForm,
  SearchInput,
  ButtonSearch,
  Tabs,
  Tab,
  ContentWrapper,
  VoiceSearchDrawer,
  DrawerSubtitle,
  ButtonCloseDrawer,
  ButtonDrawerAction,
  Transcript,
  StationsList,
  StationsListHeader,
  ButtonEnableMultiselect,
  ButtonDeskDisableMultiselect,
  SelectedStationsBottomBar,
  SelectedStationsCount,
  SelectedStationsAction,
  ButtonCloseBottomBar,
  LoadingProgress,
  EndOfResults
} from './styles';

const SearchStations = ({ handleContinueParent, handleBackParent, searchStationsHistory, stationsDataAtomic, selectedStations, email, token, searchStations, lastSearchEndpoint, loadingStations, searchStationsWithTag, lastSearchEndpointWithTag, loadingStationsWithTag, searchStationsWithCategory, lastSearchEndpointWithCategory, loadingStationsWithCategory, asyncSearchStations, asyncGetAllStations, selectStations, updateViewOrigin, selecting, updateSelecting, updateOrigin, fromIndex = true }) => {
  const theme = useContext(ThemeContext);
  const history = useHistory();
  const searchInputRef = useRef();
  const containerRef = useRef();
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const { i18n } = useTranslation();
  const { searching, setSearching } = useSearching();
  const [ innerActiveTab, setInnerActiveTab ] = useState(0);
  const { activeTab } = useActiveTab();

  const [ searchValue, setSearchValue ] = useStateWithCallback('');
  const [ voiceSearchDrawer, setVoiceSearchDrawer ] = useState(false);
  const [ scrollDir, setScrollDir ] = useState('up');
  const [ searchResults, setSearchResults ] = useState([]);
  const [ searchLoading, setSearchLoading ] = useState(false);
  const [ searchEndpoint, setSearchEndpoint ] = useState('');
  const [ searchFocused, setSearchFocused] = useState(false);
  const [ y, setY ] = useState(0);
  const size = useWindowSize();

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  useEffect(() => {
    if (fromIndex && size.width < 1024) updateSelecting(false);
    if (size.width >= 1024) updateSelecting(true);
    if (activeTab === 1 && size.width < 1024) {
      setSearching({ ...searching, active: true, componentMode: true });
    }

    asyncGetAllStations({ email, token }, stationsDataAtomic);

    if (!searchStations.length) {
      const credentials = { email, token };
      const endpoint = `${SERVER_ADDRESS}/api/v1/search-stations`;

      asyncSearchStations(credentials, endpoint, searchStations, null, '', '', '', 'keywords');
      asyncSearchStations(credentials, endpoint, searchStationsWithTag, null, '', '', '', 'tags');
      asyncSearchStations(credentials, endpoint, searchStationsWithCategory, null, '', '', '', 'categories');
    }
  }, []);
  useEffect(() => {
    if (transcript.trim()) {
      setSearchValue(transcript);
      const credentials = { email, token };
      const endpoint = `${SERVER_ADDRESS}/api/v1/search-stations`;
      searchInputRef.current.blur();
      setSearchFocused(false);

      asyncSearchStations(credentials, endpoint, [], null, transcript, '', '', 'keywords');
      asyncSearchStations(credentials, endpoint, [], null, transcript, '', '', 'tags');
      asyncSearchStations(credentials, endpoint, [], null, transcript, '', '', 'categories');
    }
  }, [transcript, setSearchValue]);

  useEffect(() => {
    if (innerActiveTab === 0) {
      setSearchResults(_.uniqBy(searchStations, function (e) { return e.id; }));
      setSearchLoading(loadingStations);
      setSearchEndpoint(lastSearchEndpoint);
    } else if (innerActiveTab === 1) {
      setSearchResults(_.uniqBy(searchStationsWithTag, function (e) { return e.id; }));
      setSearchLoading(loadingStationsWithTag);
      setSearchEndpoint(lastSearchEndpointWithTag);
    } else if (innerActiveTab === 2) {
      setSearchResults(_.uniqBy(searchStationsWithCategory, function (e) { return e.id; }));
      setSearchLoading(loadingStationsWithCategory);
      setSearchEndpoint(lastSearchEndpointWithCategory);
    }
  }, [searchStations, searchStationsWithTag, searchStationsWithCategory, innerActiveTab]);

  useEffect(() => {
    if (!listening && transcript && voiceSearchDrawer) {
      toggleDrawer(false)();
      setVoiceSearchDrawer(false);
      resetTranscript();
    }
  }, [listening, transcript, voiceSearchDrawer, resetTranscript]);

  const handleBack = () => {
    if (searchValue) {
      resetTranscript();
      setSearchValue('');
    }
    else {
      if (searchFocused) {
        setSearchFocused(false);
      } else {
        if (!fromIndex) handleBackParent();
        else refresh();
      }
    }
  }

  const editSearchTerm = (term) => {
    setSearchValue(term);
    searchInputRef.current.focus();
  }

  const openVoiceSearch = () => {
    toggleDrawer(true)();
  }

  const toggleVoiceSearch = () => {
    if (listening) {
      resetTranscript();
      SpeechRecognition.abortListening();
    }
    else {
      SpeechRecognition.startListening({ language: i18n.language });
    }
  }

  const toggleDrawer = state => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;

    setVoiceSearchDrawer(state);
  }

  const handleScroll = useCallback(evt => {
    const el = evt.currentTarget;
    const { height } = el.getBoundingClientRect();

    if (el.scrollHeight - el.scrollTop === height) return;

    if (y > el.scrollTop) {
      setScrollDir('up');
    }
    else {
      setScrollDir('down');
    }

    setY(el.scrollTop);
  }, [y]);

  const handleSearch = (evt, term) => {
    evt.preventDefault();
    setSearchValue(term);

    const credentials = { email, token };
    const endpoint = `${SERVER_ADDRESS}/api/v1/search-stations`;
    searchInputRef.current.blur();
    setSearchFocused(false);

    asyncSearchStations(credentials, endpoint, [], null, term, '', '', 'keywords');
    asyncSearchStations(credentials, endpoint, [], null, term, '', '', 'tags');
    asyncSearchStations(credentials, endpoint, [], null, term, '', '', 'categories');
  }

  const toggleSelectStation = (station, action) => {
    if (action === 'check') {
      selectStations([ ...selectedStations, station ]);
    }
    else {
      selectStations(selectedStations.filter(item => item.id !== station.id));
    }
  }

  const cancelSelecting = () => {
    setSearching({ ...searching, active: true, componentMode: true });
    selectStations([]);
    updateSelecting(false);
  }

  const goToStation = station => {
    updateViewOrigin(false);
    history.push({
      pathname: `/station/${station.id}`,
      state: {
        station: station,
      }
    });
  }

  const handleOpenStationsPrice = () => {
    updateSelecting(false);
    if (!fromIndex) handleContinueParent();
    else {
      updateOrigin(false);
      history.push('/announce/0');
    }
  }

  const refresh = () => {
    const credentials = { email, token };
    const endpoint = `${SERVER_ADDRESS}/api/v1/search-stations`;
    setSearchFocused(false);

    asyncSearchStations(credentials, endpoint, [], null, '', '', '', 'keywords');
    asyncSearchStations(credentials, endpoint, [], null, '', '', '', 'tags');
    asyncSearchStations(credentials, endpoint, [], null, '', '', '', 'categories');
  }

  const getStationData = () => {
    if (innerActiveTab === 0) {
      if (lastSearchEndpoint !== null && lastSearchEndpoint !== "null" && lastSearchEndpoint !== undefined) {
        const page = lastSearchEndpoint.split('page[number]=')[1].split('&page[size]')[0];
        const endpoint = `${SERVER_ADDRESS}/api/v1/search-stations?page=${page}`;
        const credentials = { email, token };

        asyncSearchStations(credentials, endpoint, searchStations, lastSearchEndpoint, searchValue, '', '', 'keywords');
      }
    } else if (innerActiveTab === 1) {
      if (lastSearchEndpointWithTag !== null && lastSearchEndpointWithTag !== "null" && lastSearchEndpoint !== undefined) {
        const page = lastSearchEndpointWithTag.split('page[number]=')[1].split('&page[size]')[0];
        const endpoint = `${SERVER_ADDRESS}/api/v1/search-stations?page=${page}`;
        const credentials = { email, token };

        asyncSearchStations(credentials, endpoint, searchStationsWithTag, lastSearchEndpointWithTag, searchValue, '', '', 'tags');
      }
    } else {
      if (lastSearchEndpointWithCategory !== null && lastSearchEndpointWithCategory !== "null" && lastSearchEndpoint !== undefined) {
        const page = lastSearchEndpointWithCategory.split('page[number]=')[1].split('&page[size]')[0];
        const endpoint = `${SERVER_ADDRESS}/api/v1/search-stations?page=${page}`;
        const credentials = { email, token };

        asyncSearchStations(credentials, endpoint, searchStationsWithCategory, lastSearchEndpointWithCategory, searchValue, '', '', 'categories');
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const credentials = { email, token };
      const endpoint = `${SERVER_ADDRESS}/api/v1/search-stations`;
      searchInputRef.current.blur();
      setSearchFocused(false);

      asyncSearchStations(credentials, endpoint, [], null, searchValue, '', '', 'keywords');
      asyncSearchStations(credentials, endpoint, [], null, searchValue, '', '', 'tags');
      asyncSearchStations(credentials, endpoint, [], null, searchValue, '', '', 'categories');
    }
  }

  return (
    <Container
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
      ref={containerRef}
      onScroll={handleScroll}
      searchVisible={scrollDir === 'up' && !selecting}
      id='list'
      fromIndex={fromIndex}
    >
      <Header
        onClick={(evt) => evt.preventDefault()}
        searchVisible={(scrollDir === 'up' && !selecting)}
      >
        {
          true && (
            <Tabs>
              <Tab
                active={innerActiveTab === 0 ? 1 : 0}
                onClick={() => setInnerActiveTab(0)}
              >
                Nome
              </Tab>
              <Tab
                active={innerActiveTab === 1 ? 1 : 0}
                onClick={() => setInnerActiveTab(1)}
              >
                Tag
              </Tab>
              <Tab
                active={innerActiveTab === 2 ? 1 : 0}
                onClick={() => setInnerActiveTab(2)}
              >
                Categoria
              </Tab>
            </Tabs>
          )
        }
        <Row>
          <ButtonBack onClick={handleBack}>
            <Icon
              icon={searchValue ? closeOutlined : fromIndex ? magnifyingGlass : roundArrowBack}
              width={24}
              height={24}
              color={theme.iconTintColor.inactive}
            />
          </ButtonBack>
          <SearchForm onSubmit={handleSearch}>
            <SearchInput
              ref={searchInputRef}
              placeholder={innerActiveTab === 0 ? 'Pesquisar painéis' : innerActiveTab === 1 ? '@tagdopainel' : 'Categoria do painel'}
              value={searchValue}
              onChange={(evt) => setSearchValue(evt.target.value)}
              onFocus={(evt) => setSearchFocused(true)}
              onKeyDown={handleKeyDown}
            />
          </SearchForm>
          {
            SpeechRecognition.browserSupportsSpeechRecognition() && (
              <ButtonSearch onClick={openVoiceSearch}>
                <Icon
                  icon={roundMic}
                  width={24}
                  height={24}
                  color={theme.iconTintColor.inactive}
                />
              </ButtonSearch>
            )
          }
        </Row>
      </Header>

      <ContentWrapper>
        { searchFocused &&
          <AutoCompleteList
            searchTerms={innerActiveTab === 0 ? stationsDataAtomic.names : innerActiveTab === 1 ? stationsDataAtomic.uniquenames : stationsDataAtomic.categories }
            historyData={searchStationsHistory}
            handleSearch={handleSearch}
            editSearchTerm={editSearchTerm}
            searchValue={searchValue}
            activeTab={innerActiveTab}
          />
        }
        {
          SpeechRecognition.browserSupportsSpeechRecognition() && (
            <VoiceSearchDrawer
              anchor='bottom'
              open={voiceSearchDrawer}
              onClose={toggleDrawer(false)}
            >
              <ButtonCloseDrawer onClick={toggleDrawer(false)}>
                <Icon
                  icon={roundClose}
                  width={24}
                  height={24}
                  color={theme.iconTintColor.inactive}
                />
              </ButtonCloseDrawer>
              <DrawerSubtitle>{listening ? 'Ouvindo...' : 'Pesquise por voz'}</DrawerSubtitle>
              <Transcript>{transcript}</Transcript>
              <ButtonDrawerAction
                onClick={toggleVoiceSearch}
                listening={listening ? 1 : 0}
              >
                <Icon
                  icon={roundMic}
                  width={32}
                  height={32}
                  color={listening ? theme.text.primary : '#fff'}
                />
              </ButtonDrawerAction>
            </VoiceSearchDrawer>
          )
        }
        { // SECTION FOR SHOWING RESULTS ORDERED BY NAME
          searchResults.length > 0 && (
            <>
              <StationsList>
                <StationsListHeader>{`${searchResults.length} resultados`}</StationsListHeader>
                  <InfiniteScroll
                    dataLength={searchResults.length} //This is important field to render the next data
                    next={getStationData}
                    scrollableTarget={'list'}
                    hasMore={(searchEndpoint !== null && searchEndpoint !== "null")}
                    loader={ searchLoading && <LoadingProgress><CircularProgress /></LoadingProgress>}
                    endMessage={
                      <EndOfResults>
                        Fim dos Resultados
                      </EndOfResults>
                    }
                    // below props only if you need pull down functionality
                    refreshFunction={refresh}
                    pullDownToRefresh={ size.width < 1024 ? true : false}
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: 'center', color: '#fff', fontSize: '14px' }}>&#8595; arraste para atualizar</h3>
                    }
                    releaseToRefreshContent={
                      <h3 style={{ textAlign: 'center', color: '#fff', fontSize: '14px' }}>&#8593; solte para atualizar</h3>
                    }
                  >
                  {
                    searchResults.map((station, index) => (
                      <SearchStationsItem
                        key={index}
                        station={station}
                        toggleSelectStation={toggleSelectStation}
                        goToStation={goToStation}
                        selecting={selecting}
                        checked={selectedStations.some(item => item.id === station.id)}
                        filter={innerActiveTab === 0 ? 'keywords' : innerActiveTab === 1 ? 'tags' : 'categories'}
                      />
                    ))
                  }
                  </InfiniteScroll>
              </StationsList>

              { !fromIndex &&
              <ButtonEnableMultiselect
                onClick={() => { updateSelecting(true); setSearching({ ...searching, active: true, componentMode: true });  }}
                hide={selecting ? 1 : 0}
                title={'Habilitar seleção'}
              >
                <MDIcon
                  path={mdiOrderBoolAscendingVariant}
                  size='24px'
                  color='#fff'
                />
              </ButtonEnableMultiselect>
              }

              { !fromIndex && size.width >= 1024 &&
                <>
                  <ButtonEnableMultiselect
                    onClick={selectedStations.length ? handleOpenStationsPrice : () => false}
                    hide={selecting ? 0 : 1}
                    title={'Prosseguir para o envio'}
                  >
                    <Badge badgeContent={selectedStations.length} color='error'>
                      <MDIcon
                        path={mdiCheckUnderline}
                        size='24px'
                        color='#fff'
                      />
                    </Badge>
                  </ButtonEnableMultiselect>
                  <ButtonDeskDisableMultiselect
                    onClick={cancelSelecting}
                    hide={selecting ? 0 : 1}
                    title={'Desabilitar seleção'}
                  >
                    <MDIcon
                      path={mdiClose}
                      size='18px'
                      color='#fff'
                    />
                  </ButtonDeskDisableMultiselect>
                </>
              }
              { size.width < 1024 &&
                <SelectedStationsBottomBar
                  show={selecting}
                >
                  <ButtonCloseBottomBar onClick={cancelSelecting}>
                    <Icon
                      icon={roundClose}
                      width={24}
                      height={24}
                      color={theme.iconTintColor.active}
                    />
                  </ButtonCloseBottomBar>
                  <SelectedStationsCount inactive={selectedStations.length === 0}>{selectedStations.length === 0 ? 'Nenhum painel selecionado' : (selectedStations.length > 1 ? `${selectedStations.length} selecionados` : '1 painel selecionado')}</SelectedStationsCount>
                  <SelectedStationsAction
                    onClick={selectedStations.length ? handleOpenStationsPrice : () => false}
                  >
                    <Icon
                      icon={roundArrowForward}
                      width={24}
                      height={24}
                      color={theme.iconTintColor.active}
                    />
                  </SelectedStationsAction>
                </SelectedStationsBottomBar>
              }
            </>
          )
        }
        {
          searchResults.length === 0 && searchLoading && (
            <>
              <StationsList>
                <StationsListHeader>{'0 resultados'}</StationsListHeader>
                <AnimatePresence exitBeforeEnter={true}>
                  <StationsLoader />
                </AnimatePresence>
              </StationsList>
            </>
          )
        }
        {
          searchResults.length === 0 && !searchLoading && (
            <>
              <StationsList>
                <StationsListHeader>{'0 resultados'}</StationsListHeader>
              </StationsList>
            </>
          )
        }
      </ContentWrapper>
    </Container>
  );
}


const mapStateToProps = state => {
  return ({
    searchStations: state.StationsReducer.searchStations.data,
    lastSearchEndpoint: state.StationsReducer.searchStations.endpoint,
    loadingStations: state.StationsReducer.searchStations.loading,

    searchStationsWithTag: state.StationsReducer.searchStationsWithTag.data,
    lastSearchEndpointWithTag: state.StationsReducer.searchStationsWithTag.endpoint,
    loadingStationsWithTag: state.StationsReducer.searchStationsWithTag.loading,

    searchStationsWithCategory: state.StationsReducer.searchStationsWithCategory.data,
    lastSearchEndpointWithCategory: state.StationsReducer.searchStationsWithCategory.endpoint,
    loadingStationsWithCategory: state.StationsReducer.searchStationsWithCategory.loading,

    stationsDataAtomic: state.StationsReducer.stations.data,
    searchStationsHistory: state.StationsReducer.searchStationsHistory.reverse(),
    selectedStations: state.StationsReducer.selectedStations,
    selecting: state.StationsReducer.selecting,
    email: state.AuthenticationReducer.memberData.data.attributes.email,
    token: state.AuthenticationReducer.token
  })
};

export default connect(mapStateToProps, { asyncSearchStations, asyncGetAllStations, selectStations, updateViewOrigin, updateSelecting, updateOrigin })(SearchStations);
