import styled from 'styled-components';
import { ButtonBase, Drawer } from '@material-ui/core';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};

  .MuiPaper-root,.MuiMenu-paper,.MuiPopover-paper,.MuiPaper-elevation2,.MuiPaper-rounded {
    left: 50% !important;
  }

  @media screen and (min-width: 1024px) {
    position: relative;
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  background: transparent;
  display: flex;
  align-items: center;
  padding: 0 10px;
  z-index: 55;
  background: ${props => props.theme.colors.background};
  box-shadow: ${props => props.theme.title === 'light' ? '0px 4px 10px rgba(204, 204, 204, 0.3)' : 'none'};
`;

export const BackButton = styled(ButtonBase)`
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: transparent !important;
`;

export const HeaderTitle = styled.p`
  color: ${props => props.theme.text.primary};
  font-weight: 700;
  text-align: center;
  margin: auto;
  flex: 1;
`;

export const GhostButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  pointer-events: none;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 15px 0;
`;

export const ProfilePictureContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: url(${props => props.avatar});
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 15px;
`;

export const ButtonChangePicture = styled(ButtonBase)`
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: ${props => props.theme.colors.primary} !important;
`;

export const ProfileName = styled.p`
  font-weight: 700;
  color: ${props => props.theme.text.primary};
  font-size: 1.125rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (min-width: 1024px) {
    height: auto !important;
  }
`;

export const Heading = styled.p`
  font-weight: 700;
  color: ${props => props.theme.text.primary};
  padding-left: 20px;
  height: 50px;
  display: flex;
  align-items: center;
`;

export const Button = styled(ButtonBase)`
  width: 100% !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0 20px !important;
  justify-content: flex-start !important;
`;

export const ButtonText = styled.p`
  color: ${props => props.theme.text.primary};
  margin-left: 10px;
`;

export const PixIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

export const CustomDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    display: flex !important;
    flex-direction: column !important;
    background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background} !important;
    padding: 20px 20px 80px 20px;
    height: 100% !important;
    position: relative !important;
    overflow: hidden auto !important;
  }

  @media screen and (min-width: 1024px) {

    .MuiSnackbar-anchorOriginBottomCenter {
      bottom: 100px;
      position: absolute;
    }

    .MuiDrawer-paper {
      top: 0 !important;
      flex: 1 0 auto !important;
      height: 100% !important;
      display: flex !important;
      width: 500px !important;
      position: fixed !important;

      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-track {
        background: ${props => props.theme.title === 'dark' ? props.theme.colors.background : props.theme.colors.background};
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.text.secondary};
        border-radius: 5px;
      }
    }
  }
`;

export const DrawerHeading = styled.p`
  font-weight: 700;
  color: ${props => props.theme.text.primary};
`;

export const DrawerSubheading = styled.p`
  color: ${props => props.theme.text.secondary};
  margin: 5px 0 15px 0;
  margin-bottom: ${props => props.addingData ? '35px' : '15px'};
`;

export const EmptyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const EmptyIllustration = styled.img`
  width: 40%;
  height: 150px;
  object-fit: contain;
  align-self: center;
  margin-top: -80px;
`;

export const EmptyMessage = styled.p`
  color: ${props => props.theme.text.primary};
  text-align: center;
`;

export const DrawerFooter = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  background: ${props => props.theme.title === 'dark' ? props.theme.colors.secondary : props.theme.colors.background};

  @media screen and (min-width: 1024px) {
    position: relative;
  }
`;

export const DrawerActionButton = styled(ButtonBase)`
  font-weight: 700 !important;
  background: ${props => props.theme.colors.primary} !important;
  color: #fff !important;
  border-radius: 5px !important;
  height: 40px;
  flex: 1;

  &:first-of-type {
    margin-right: 15px !important;
    background: ${props => props.theme.colors.secondary} !important;
    color: ${props => props.theme.text.primary} !important;
    border: 1px solid ${props => props.theme.separator} !important;
  }
`;

export const DrawerMain = styled.div`
  display: flex;
  flex-direction: column;
  transform: ${props => props.hide ? 'scale(.95)' : 'scale(1)'};
  opacity: ${props => props.hide ? 0 : 1};
  pointer-events: ${props => props.hide ? 'none' : 'auto'};
  transition: all .3s cubic-bezier(.785,.135,.15,.86);
  flex: 1;
`;

export const DrawerFormAdd = styled.div`
  position: absolute;
  top: 85px;
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  transform: ${props => props.show ? 'translateX(0)' : 'translateX(100%)'};
  opacity: ${props => props.show ? 1 : 0};
  pointer-events: ${props => props.show ? 'auto' : 'none'};
  transition: all .3s cubic-bezier(.785,.135,.15,.86);

  .MuiInputBase-input, .MuiFilledInput-input {
  }
`;

export const DrawerList = styled(motion.div)`
  flex: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 15px 0;
`;

export const DrawerListItem = styled(motion.div)`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 20px;

  .MuiInputBase-input, .MuiFilledInput-input {
    padding-right: 60px;
    flex: 1;
  }
`;

export const DrawerListItemButtons = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  margin-right: 5px;
`;

export const DrawerListItemButton = styled(ButtonBase)`
  width: 40px;
  height: 40px !important;
  border-radius: 50% !important;
  margin-left: auto !important;
`;

export const SnackbarUndoButton = styled(ButtonBase)`
  color: ${props => props.theme.colors.primary} !important;
  border-radius: 5px !important;
  font-size: .975rem !important;
  font-weight: 600 !important;
  padding: 8px !important;
`;

export const CardForm = styled.form`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

export const CardFormRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const CardContainer = styled(ButtonBase)`
  margin-bottom: 30px !important;
  border-radius: 5px !important;
`;
