import React, { useContext, useState, useEffect } from 'react';

import Icon from '@mdi/react';
import { mdiChevronRight } from '@mdi/js';
import { ThemeContext } from 'styled-components';
import { signOut } from '../../store/actions/AuthenticationActions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../context/Theme';
import useWindowSize from '../../utils/hooks/useWindowSize';
import { SERVER_ADDRESS } from '../../constraints/types';
import dark from '../../styles/themes/dark';
import light from '../../styles/themes/light';
import BottomSheet from '../../components/BottomSheet';
import About from '../../components/About';
import Help from '../../components/Help';
import Switch from '../../components/Switch';
import { useSearching } from '../../context/Searching';

import { Container, Title, Fields, Field, Label, Select, LogoutField, Menu, Option, MenuOverlay } from './styles';

const Settings = ({ signOut }) => {
  const currTheme = useContext(ThemeContext);
  const { theme, setTheme } = useTheme();
  const [ showMenu, setShowMenu ] = useState(false);
  const [ language, setLanguage ] = useState('');
  const [ bottomSheetShow, setBottomSheetShow ] = useState(false);
  const [ option, setOption ] = useState('');
  const { searching, setSearching } = useSearching();
  const size = useWindowSize();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setSearching({ ...searching, active: false, componentMode: false });
  }, []);

  useEffect(() => {
    if (size.width < 1024) {
      if (!language) {
        if (i18n.language === 'pt-BR') {
          setLanguage("Português");
        } else if (i18n.language === 'en') {
          setLanguage("English");
        } else if (i18n.language === 'ru') {
          setLanguage("Pусский");
        } else if (i18n.language === 'pl') {
          setLanguage("Polskie");
        }
      }
    }
  }, [i18n.language, language, size.width])

  const handleLogout = evt => {
    const confirmLogout = window.confirm(t('menu.signout.confirm'));

    if (confirmLogout) {
      signOut();
    }
    else {
      evt.preventDefault();
      return;
    }
  }

  const handleLanguageChange = evt => {
    evt.stopPropagation();
    i18n.changeLanguage(evt.target.id);
    setLanguage(evt.target.innerText);
    setShowMenu(false);
  }

  const openBottomSheet = (option) => {
    setOption(option);
    setBottomSheetShow(true);
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  return (
    <Container
      className='noSelect'
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
    >
      { size.width < 1024 && (
        <BottomSheet
          show={bottomSheetShow}
          onClose={() => setBottomSheetShow(false)}
        >
          { option === 'about' && <About setBottomSheetShow={setBottomSheetShow} /> }
          { option === 'help' && <Help setBottomSheetShow={setBottomSheetShow} /> }
        </BottomSheet>
      ) }

      <MenuOverlay onClick={() => setShowMenu(false)} showMenu={showMenu} />
      <Title>{t('menu.preferences.label')}</Title>
      <Fields>
        <Field>
          <Label>{t('menu.preferences.theme')}</Label>
          <Switch
            checked={theme.title === 'dark' ? true : false}
            onChange={(checked) => setTheme(checked ? dark : light)}
          />
        </Field>
        <Field onClick={() => setShowMenu(true)}>
          <Label>{t('menu.preferences.idiom')}</Label>
          <Select id='languageSelect'>{language}</Select>
          <Menu className={showMenu ? 'context-menu show' : 'context-menu'}>
            <Option onClick={handleLanguageChange} id="pt-BR">
              <img src='./assets/brazil-flag.svg' alt='Bandeira do Brasil' />
              <span>Português</span>
            </Option>
            <Option onClick={handleLanguageChange} id="en">
              <img src='./assets/us-flag.svg' alt='Bandeira dos EUA' />
              <span>English</span>
            </Option>
            <Option onClick={handleLanguageChange} id="ru">
              <img src='./assets/russian-flag.svg' alt='Bandeira da Rússia' />
              <span>Pусский</span>
            </Option>
            <Option onClick={handleLanguageChange} id="pl">
              <img src='./assets/poland-flag.svg' alt='Bandeira da Polônia' />
              <span>Polskie</span>
            </Option>
          </Menu>
        </Field>
        <Field onClick={() => openBottomSheet('about')}>
          <Label>{t('menu.about.label')}</Label>
          <Icon
            path={mdiChevronRight}
            size='18px'
            color={currTheme.iconTintColor.inactive}
          />
        </Field>
        <Field onClick={() => openBottomSheet('help')}>
          <Label>{t('menu.help.label')}</Label>
          <Icon
            path={mdiChevronRight}
            size='18px'
            color={currTheme.iconTintColor.inactive}
          />
        </Field>
        <LogoutField
          rel='nofollow'
          data-method='delete'
          href={`${SERVER_ADDRESS}/mi-logout`}
          onClick={handleLogout}
        >
          {t('menu.signout.label')}
        </LogoutField>
      </Fields>
    </Container>
  );
}

export default connect(null, { signOut })(Settings);
