import { storage } from '../../firebase';
import _ from 'lodash';

import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import {
  UPLOAD_START,
  UPLOAD_PROGRESS,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  SERVER_ADDRESS,
  FORCE_TOKEN,
  REMOVE_FROM_STACK,
  DELETE_FROM_BUCKET,
  DELETE_FROM_BUCKET_SUCCESS,
  DELETE_FROM_BUCKET_ERROR,
  UPDATE_DELETE_FROM_BUCKET,
  LIST_FOLDER,
  LIST_FOLDER_SUCCESS,
  LIST_FOLDER_ERROR,
  SENT_THUMB
} from '../../constraints/types';

import {
  development
} from '../../constraints/utils';

export const fileUpload = (file, name, uniqueName, size, type, uploadStack, uploadData) => {
  return dispatch => {
    // extraindo a extensÃ£o do arquivo separando o conteÃºdo das barras -> contentType "image/jpeg"
    if (type) {
      var ext = type.split('/')[1];
      if (ext === 'jpeg') ext = 'jpg';
    }

    // Gerando um nome Único para o arquivo e combinando com a extensÃ£o extraÃ­da (se houver)
    let storageName;
    (type) ? storageName = `${uniqueName}.${ext}` : storageName = uniqueName;
    (uploadData.isThumb) ? storageName = `thumb_${storageName}` : storageName = storageName;
    // definindo o caminho do arquivo no storage / definindo
    // os atributos 'meta' da imagem como tipo, tamanho e nome

    let directoryPath = type ? type.split('/')[0] : 'misc';
    if (!directoryPath) {
      directoryPath = 'misc';
    }
    const refPath = development ? `dev${uploadData.folder}/${uploadData.key}/${directoryPath}/${storageName}` : `${uploadData.folder}/${uploadData.key}/${directoryPath}/${storageName}`;
    const metadata = { contentType: type, size, name };

    // inicializando um arquivo no local com apenas 1 byte
    var bytes = new Uint8Array([0x48]);
      storage.ref(refPath).put(bytes).then(function(snapshot) {
    });
    var fileUID = uuidv4();
    // informaÃ§Ãµes do upload a ser feito na pilha de upload
    const deepItem = {
      fileUID,
      file,
      name,
      size,
      type,
      progress: 0,
      state: 'waiting',
      stationId: uploadData.key
    }

    uploadStack.push(deepItem);

    var uploadToken = '';
    uploadToken = uuidv4();
    dispatch({ type: UPLOAD_START, payload: uploadStack, uploadToken });
    // mÃ©todo de upload para o fbase storage com listener de progresso
    var index = -1;
    storage
      .ref(refPath)
      .put(file, metadata)
      .on(
        'state_changed',
        snapshot => {
          var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

          index = _.findIndex(
            uploadStack, {
              stationId: uploadData.key,
              fileUID
            });

          if (uploadStack) {
            uploadStack[index].progress = progress;
            uploadStack[index].state = snapshot.state;
          }
          uploadToken = uuidv4();
          dispatch({ type: UPLOAD_PROGRESS, payload: uploadStack, uploadToken });
        },
        error => {
          index = _.findIndex(
            uploadStack, {
              stationId: uploadData.key,
              fileUID
            });
          if (uploadStack) {
            uploadStack[index].progress = -1;
            uploadStack[index].state = error;
          }
          uploadToken = uuidv4();

          dispatch({ type: UPLOAD_ERROR, payload: uploadStack, uploadToken });
          alert('Ocorreu um problema ao enviar', 'Por favor, tente novamente');
        },
        () => {

          storage.ref(refPath).put(file, metadata).snapshot.ref.getDownloadURL().then(function(downloadURL) {
            if (uploadData.isThumb) dispatch({ type: SENT_THUMB, payload: downloadURL });
          });

          index = _.findIndex(
            uploadStack, {
              stationId: uploadData.key,
              fileUID
            });

          uploadStack[index].state = 'success';
          dispatch({ type: UPLOAD_SUCCESS, payload: uploadStack, uploadToken: uuidv4() });
          uploadStack.splice(index, 1);
          dispatch({ type: UPLOAD_SUCCESS, payload: uploadStack, uploadToken: uuidv4() });
        }
      );
  }
};

export const forceToken = () => ({
    type: FORCE_TOKEN,
    uploadToken: uuidv4()
});

export const removeFromStack = (stationId, uploadStack) => {
  const currentUploads = _.filter(uploadStack, (val) => {
    if (val.stationId == stationId) return val
  });

  const currentSuccessUploads = _.filter(uploadStack, (val) => {
    if ((val.stationId == stationId) && (val.state == 'success')) return val
  });

  if (currentUploads.length === currentSuccessUploads.length) {
    _.remove(uploadStack, { stationId });
  }
  return ({ type: REMOVE_FROM_STACK, payload: uploadStack, uploadToken: uuidv4() });
}

export const listFolder = (stationFiles, folder, userId) => {
  return dispatch => {
    // Retornando o objeto stationFiles com as informaÃ§Ãµes jÃ¡ existentes, porÃ©m com loading = true
    const newStationFiles = {
      loading: true,
      error: false,
      data: stationFiles.data
    }
    dispatch({ type: LIST_FOLDER, payload: newStationFiles, folder: folder });
    // Criando uma referÃªncia para a pasta do usuÃ¡rio
    const RTDBUrl = development ? `dev${folder}/${userId}` : `${folder}/${userId}`;

    var listRef = storage.ref(RTDBUrl);
    var files = [];
    var hasFile = false;
    // Encontrando todas as subpastas
    listRef.listAll().then(function(res) {
      res.prefixes.forEach(function(folderRef) {
        //stationFiles.data = [];
        // Percorrendo recursivamente as pastas e listado os arquivos
        folderRef.listAll().then(function(subItens) {
          subItens.items.forEach(function(itemRef, index) {
            hasFile = true;
            itemRef.getMetadata().then(function(metadata) {
              itemRef.getDownloadURL().then(function(url) {
                files.push({ ...metadata, url });
                // !metadata.name.startsWith("thumb_")
                // filtering to prevent getting thumb files
                const filesFiltered = _.filter(files, function(metadata) { return !metadata.name.startsWith("thumb_"); });

                const newStationFiles = {
                  loading: false,
                  error: false,
                  data: filesFiltered
                }
                dispatch({ type: LIST_FOLDER_SUCCESS, payload: newStationFiles, folder: folder });
              });
            });
          });
        });
      });
      if (!hasFile) {
        const newStationFiles = {
          loading: false,
          error: false,
          data: stationFiles.data
        }
        dispatch({ type: LIST_FOLDER_SUCCESS, payload: newStationFiles, folder: folder });
      }
    }).catch(function(error) {
      // Retornando o objeto stationFiles com as informaÃ§Ãµes antigas porÃ©m com o retorno do erro
      const newStationFiles = {
        loading: false,
        error: error,
        data: stationFiles.data
      }
      dispatch({ type: LIST_FOLDER_ERROR, payload: newStationFiles, folder: folder });
      // Ocorreu um erro ao obter a lista de arquivos
    });
  }
}

export const deleteFromBucket = (item, folder, userId) => {
  return dispatch => {
    dispatch({ type: DELETE_FROM_BUCKET, folder: folder });
    let directoryPath = item.contentType ? item.contentType.split('/')[0] : 'misc';
    if (!directoryPath) {
      directoryPath = 'misc';
    }
    // Create a reference to the file to delete
    const refPath = development ? `dev${folder}/${userId}/${directoryPath}/${item.name}` : `${folder}/${userId}/${directoryPath}/${item.name}`;

    var fileRef = storage.ref(refPath);

    // Delete the file
    fileRef.delete()
    .then(function() {
      dispatch({ type: DELETE_FROM_BUCKET_SUCCESS, payload: item, folder: folder });
      // File deleted successfully
    })
    .catch(function(error) {
      const deleteFromBucket = {
        item: item,
        loading: false,
        error: error
      }
      const errorMessage = JSON.parse(error.serverResponse_);
      alert(errorMessage.error.message);

      dispatch({ type: DELETE_FROM_BUCKET_ERROR, payload: deleteFromBucket, folder: folder });
      // Uh-oh, an error occurred!
    });
  }
}

export const updateDeleteFromBucket = (deleteFromBucket) => {
  return({
    type: UPDATE_DELETE_FROM_BUCKET,
    payload: deleteFromBucket
  });
}
