import React, { useContext, useEffect, useState } from 'react';

import { Icon as IconifyIcon } from '@iconify/react';
import roundDashboard from '@iconify/icons-ic/round-dashboard';
import mapIcon from '@iconify-icons/akar-icons/map';
import roundSettings from '@iconify/icons-ic/round-settings';

import Icon from '@mdi/react';
import { mdiMonitorMultiple, mdiMapSearchOutline } from '@mdi/js';
import { ThemeContext } from 'styled-components';
import useActiveTab from '../../utils/hooks/useActiveTab';
import { useSearching } from '../../context/Searching';
import { useHistory, useLocation } from 'react-router-dom';

import { Container, NavButtons, Button, ButtonText } from './styles';

const BottomBar = () => {
  const theme = useContext(ThemeContext);
  const { activeTab } = useActiveTab();
  const history = useHistory();
  const location = useLocation();
  const { searching } = useSearching();
  const [ hide, setHide ] = useState(false);

  const locationsToShow = ['/', '/stations', '/panels', '/settings'];

  useEffect(() => {
    if (locationsToShow.includes(location.pathname)) {
      setHide(false);
    }
    else {
      setHide(true);
    }
  }, [location.pathname, locationsToShow]);

  function preventContextMenu (evt) {
    evt.preventDefault();

    return false;
  }

  function handleTabPress (tab) {
    switch (tab) {
      case 0:
        history.replace('/');
        break;
      case 1:
        history.replace('/panels');
        break;
      case 2:
        history.replace('/stations');
        break;
      case 3:
        history.replace('/settings');
        break;
      default:
        return;
    }
  }

  return (
    <Container
      searching={(searching.active || searching.mapMode) && !searching.componentMode}
      className={hide ? 'hide'  : ''}
    >
      <NavButtons>
        <Button onClick={() => handleTabPress(0)} onContextMenu={preventContextMenu} active={activeTab === 0 ? 1 : 0}>
          <IconifyIcon
            icon={roundDashboard}
            width={22}
            height={22}
          />
          <ButtonText active={activeTab === 0}>Início</ButtonText>
        </Button>
        <Button onClick={() => handleTabPress(1)} onContextMenu={preventContextMenu} active={activeTab === 1 ? 1 : 0}>
          <Icon
            path={mdiMonitorMultiple}
            size='22px'
          />
          <ButtonText active={activeTab === 1}>Painéis</ButtonText>
        </Button>
        <Button onClick={() => handleTabPress(2)} onContextMenu={preventContextMenu} active={activeTab === 2 ? 1 : 0}>
          <Icon
            path={mdiMapSearchOutline}
            size='22px'
          />
          <ButtonText active={activeTab === 2}>Mapa</ButtonText>
        </Button>
        <Button onClick={() => handleTabPress(3)} onContextMenu={preventContextMenu} active={activeTab === 3 ? 1 : 0}>
          <IconifyIcon
            icon={roundSettings}
            width={22}
            height={22}
          />
          <ButtonText active={activeTab === 3}>Preferências</ButtonText>
        </Button>
      </NavButtons>
    </Container>
  );
}

export default BottomBar;
