import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';

import _ from 'lodash';
import MDIcon from '@mdi/react';
import {
  mdiImageEditOutline,
  mdiMonitorScreenshot,
  mdiPencilOutline,
  mdiAlertCircle,
  mdiCheckboxMarkedCircle,
  mdiBlockHelper,
  mdiMessageAlertOutline,
  mdiDeleteOffOutline,
  mdiSync,
  mdiDotsHorizontal,
  mdiAlarmLightOutline,
  mdiNumeric,
  mdiCogSyncOutline
} from '@mdi/js';
import { Icon, InlineIcon } from '@iconify/react';
import roundArrowBack from '@iconify/icons-ic/round-arrow-back';
import roundChevronRight from '@iconify/icons-ic/round-chevron-right';
import roundCalendarToday from '@iconify/icons-ic/round-calendar-today';
import roundAccessTime from '@iconify/icons-ic/round-access-time';
import outlineLocationOn from '@iconify/icons-ic/outline-location-on';
import roundCampaign from '@iconify/icons-ic/round-campaign';
import roundRemoveRedEye from '@iconify/icons-ic/round-remove-red-eye';
import { useHistory, useParams } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { connect } from 'react-redux';
import Lottie from 'react-lottie';

import { useSpring, useTransform, AnimatePresence } from 'framer-motion';
import { ThemeContext } from 'styled-components';

import CloseIcon from '@material-ui/icons/Close';

import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Snackbar,
  SnackbarContent,
  CircularProgress,
  Modal,
  Backdrop,
  makeStyles
} from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

import { TimePicker } from '@material-ui/pickers';
import { format, parse } from 'date-fns';

import Gallery from '../../components/Gallery';
import ReportStation from '../../components/ReportStation';
import DeleteStation from '../../components/DeleteStation';
import DeleteAdsFromStation from '../../components/DeleteAdsFromStation';
import useWindowSize from '../../utils/hooks/useWindowSize';
import {
  getStation,
  getCategories,
  checkUniquename,
  selectStations,
  editStation,
  updateEditStation,
  updateSendReport,
  updateSyncPlayer,
  syncPlayer,
  updateDeleteStation,
  blockStation,
  updateBlockStation,
  unblockStation,
  updateUnblockStation
} from '../../store/actions/StationsActions';

import { updateOrigin } from '../../store/actions/AdsActions';
import ViewAdsAtStation from '../../pages/ViewAdsAtStation';
import { SERVER_ADDRESS } from '../../constraints/types';

import errorAnimation from '../../assets/lotties/error-face.json';

import {
  Container,
  Header,
  BackButton,
  BackButtonText,
  HeaderTitle,
  EditImageBtn,
  OptionsButton,
  ActivateAlert,
  ImageContainer,
  Image, Overlay,
  Content,
  Title,
  Tag,
  Row,
  Separator,
  SecondaryText,
  DescriptionTitle,
  Description,
  LocationTitle,
  Location,
  OwnerContainer,
  OwnerImage,
  OwnerName,
  DetailsTitle,
  Details,
  Footer,
  BtnRealTime,
  RightButton,
  BtnEdit,
  CustomDrawer,
  DrawerContainer,
  DrawerFooter,
  DrawerBtnActionLeft,
  DrawerBtnActionRight,
  DesktopImage,
  StationDetails,
  DescriptionContainer,
  AdsCarousel,
  AdsCarouselContainer,
  Spinner,
  CircleLoader,
  TagStatusBadge,
  TagContainer,
  OptionsMenu,
  CustomListItemIcon,
  CustomListItemText,
  CustomListItemTextSmall,
  CustomListItemWrapper,
  IconWrapper,
  BlockAlert
} from './styles';

import { Page, PageTitle, BtnFeedback, Pages } from '../AddStation/styles';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(37 234 255 / 10%)'
  },
  paper: {
    width: '1000px',
    height: '80%'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Imagens de carregamento utilizadas quando está buscando os dados da estação
const loadingImgPathDark = require('../../assets/images/loading_image_animated_dark.gif');

const loadingImgPathLight = require('../../assets/images/loading_image_animated_light.gif');

const Station = ({ email, token, station, getStation, categories, getCategories, checkUniquename, uniquename, selectStations, selectedStations, updateOrigin, viewOrigin, editStation, editingStation, updateEditStation, reportStationData, updateSendReport, syncPlayerData, syncPlayer, updateSyncPlayer, updateDeleteStation, blockStation, blockStationData, unblockStationData, updateBlockStation, unblockStation, updateUnblockStation, stationActivation }) => {
  const { t } = useTranslation();
  const size = useWindowSize();
  const classes = useStyles();
  const contentRef = useRef();
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const params = useParams();

  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [ stepIncomplete, setStepIncomplete ] = useState(true);
  const [ stepIncompleteB, setStepIncompleteB ] = useState(false);
  const [lightBox, setLightBox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [ height, setHeight ] = useState(350);
  const [ editing, setEditing ] = useState(false);
  const [ editingDataType, setEditingDataType ] = useState('');
  const [ galleryMode, setGalleryMode ] = useState(false);
  const [ newTag, setNewTag ] = useState({ editing: false, tag: ''});
  const [ tagError, setTagError ] = useState(false);
  const [ tagErrorMessage, setTagErrorMessage ] = useState('');
  const [ nameError, setNameError ] = useState(false);
  const [ nameErrorMessage, setNameErrorMessage ] = useState('');
  const [ inchesError, setInchesError ] = useState(false);
  const [ inchesErrorMessage, setInchesErrorMessage ] = useState('');
  const [ reportIsOpen, setReportIsOpen ] = useState(false);
  const [ deleteIsOpen, setDeleteIsOpen ] = useState(false);
  const [ deleteAdsIsOpen, setDeleteAdsIsOpen ] = useState(false);
  const [ stationData, setStationData ] = useState({
    name: '',
    tag: '',
    avatar: '',
    images: [''],
    lightBoxImages: [''],
    weekDaysBegin: '',
    weekDaysEnd: '',
    periodBegin: new Date(),
    periodEnd: new Date(),
    description: '',
    category: '',
    locale: '',
    ownerName: '',
    ownerImage: null,
    inches: null,
    resolution: null,
    owner: true,
    active: true,
    blocked: true
  });

  const y = useSpring(0, { mass: 0.05, bounceDamping: 50, stiffness: 20, damping: 300, ease: [0.6, 0.05, -0.01, 0.99] });
  const opacity = useTransform(y, [0, -size.height + 360], [0, 1]);
  const darkBackground = useTransform(y, [0, -250, -260], ['rgba(35,38,38,0)', 'rgba(35,38,38,0)', 'rgba(35,38,38,1)']);
  const lightBackground = useTransform(y, [0, -250, -260], ['rgba(255,255,255,0)', 'rgba(255,255,255,0)', 'rgba(255,255,255,1)']);
  const boxShadow = useTransform(y, [0, -250, -260], ['0px 4px 10px rgba(204, 204, 204, 0)', '0px 4px 10px rgba(204, 204, 204, 0)', '0px 4px 10px rgba(204, 204, 204, 0.3)'])
  const translateY = useTransform(y, [0, -315, -320], ['translateY(15px)', 'translateY(15px)', 'translateY(0)']);
  const titleOpacity = useTransform(y, [0, -315, -320], [0, 0, 1]);

  // Chamada do endpoint para buscar os dados da estação, na montagem do componente
  useEffect(() => {
    // resetando dados de operações com o painel
    updateSyncPlayer({ loading: false, error: false, success: false });
    updateSendReport({ loading: false, error: false, sent: false });
    updateDeleteStation({ loading: false, error: false, success: false });
    updateBlockStation({ loading: false, error: false, success: false });
    updateUnblockStation({ loading: false, error: false, success: false });
    
    const credentials = { email, token };
    // params.id é o parâmetro enviado via GET na URL
    getStation(credentials, params.id, station);
    getCategories(credentials, categories);
  }, []);

  // função chamada na alteração dos dados da estação (recebida pelo redux)
  useEffect(() => {
    // Se não estiver carregando e não conter erros, significa que buscou os dados da estação com sucesso
    if (station !== undefined && !station.loading && !station.error) {
      if (station.data.hasOwnProperty('relationships') && station.data.hasOwnProperty('attributes')) {

        // pegando as informações importantes para ficar menos verboso
        const stData = station.data.attributes;
        const stRelated = station.data.relationships["professional-company"].data;
        // definição de fato dos dados da estação via state
        setStationData({
          name: stData.name,
          tag: stData.uniquename,
          avatar: `${SERVER_ADDRESS}${stData["avatar-original"]}`,
          images: stData["image-stations"],
          lightBoxImages: [{ id: -1, storage_ref: `${SERVER_ADDRESS}${stData["avatar-original"]}` }, ...stData["image-stations"]],
          weekDaysBegin: stData["week-day-begin"],
          weekDaysEnd: stData["week-day-end"],
          periodBegin: parse(stData["open-start"], 'HH:mm', new Date()),
          periodEnd: parse(stData["open-end"], 'HH:mm', new Date()),
          description: stData["promotional-text"],
          category: 1,
          locale: stData.address,
          ownerImage: `${SERVER_ADDRESS}${stRelated["avatar-medium"]}`,
          ownerName: stRelated.name,
          inches: stData.inches,
          resolution: stData.dimensions,
          owner: stData["owner?"],
          active: (stData.status === 'AC' || station.data.id === '1') ? true : false,
          blocked: (stData.status === 'DU' && station.data.id !== '1') ? true : false,
        });
      }
    }
  }, [station, stationData.owner, history.location.state, blockStationData.success, unblockStationData.success, stationActivation]);

  useEffect(() => {
    setHeight(contentRef.current.offsetHeight);
  }, [size.height]);

  useEffect(() => {
    if (newTag.tag === '' && newTag.editing) {
      setTagError(true);
      setTagErrorMessage(t('form.addStation.tag.empty'));
      setStepIncomplete(true);
      return;
    }
    if (newTag.tag === tag) {
      setTagError(false);
      setTagErrorMessage('');
      if (!nameError) setStepIncomplete(false);
    } else {
      if (!uniquename.isUnique && !uniquename.loading) {
        setTagError(true);
        setTagErrorMessage(t('form.addStation.tag.error'));
        setStepIncomplete(true);
      } else {
        setTagError(false);
        setTagErrorMessage('');
  
        if (uniquename.loading ||!uniquename.isUnique) setStepIncomplete(true);
        if (!uniquename.loading && uniquename.isUnique && !nameError) setStepIncomplete(false);
      }
    }
  }, [uniquename]);

  const handleOpenGallery = () => {
    if (!menuOpen) {
      setGalleryMode(true);

      setTimeout(() => y.set(size.height - 360), 10);
    }
  }

  const debounceFn = useCallback(_.debounce(q => checkTagDuplicity(q), 500), []);

  const checkTagDuplicity = value => {
    const credentials = { email, token }
    checkUniquename(credentials, value);
  }

  const handleBackButton = () => {
    if (galleryMode) {
      setGalleryMode(false);
      y.set(0);
    }
    else {
      history.goBack();
    }
  }
  const viewAdsOnStation = () => {
    history.push(`/view-ads/${station.data.id}`);
  }

  const openProfessionalCompany = () => {
    const ppSlug = station.data.relationships["professional-company"].data.slug;

    document.location.replace(`${SERVER_ADDRESS}/profissional/perfil/${ppSlug}`);
  }

  const handleTagChange = evt => {
    if (evt.target.value === '') {
      evt.preventDefault();
      setNewTag({ editing: true, tag: evt.target.value});
      setTagError(true);
      setTagErrorMessage(t('form.addStation.tag.empty'));
      setStepIncomplete(true);

      return;
    }
    else if (!/^[a-zA-Z0-9]+$/.test(evt.target.value)) {
      evt.preventDefault();

      return;
    }
    else {
      setNewTag({ editing: true, tag: evt.target.value});
      debounceFn(evt.target.value);
    }
  }

  const renderWeekDays = (weekDay) => {
    const day = t(`week.${weekDay}`);

    // Capitalize first letter of day, independent of language
    return `${day.substring(0, 3).charAt(0).toUpperCase()}${day.substring(0, 3).slice(1).toLowerCase()}`;
  }

  const handleEditField = (evt, field) => {
    if (evt.target) {
      if (field === 'name' && evt.target.value === '') {
        setNameError(true);
        setNameErrorMessage('O nome não pode ficar vazio');
        setStepIncomplete(true);
      } else {
        setNameError(false);
        setNameErrorMessage('');
        if (!tagError) setStepIncomplete(false);
      }
      if (field === 'inches' && evt.target.value === '') {
        setInchesError(true);
        setInchesErrorMessage('Informe as polegadas do televisor');
        setStepIncompleteB(true);
      } else {
        setInchesError(false);
        setInchesErrorMessage('');
        setStepIncompleteB(false);
      }
      setStationData({ ...stationData, [field]: evt.target.value });
    }
    else {
      setStationData({ ...stationData, [field]: evt });
    }
  }

  const renderEditingContent = () => {
    switch (editingDataType) {
      case 'title':
        return (
          <>
            <TextField
              label={t('form.addStation.name.label')}
              variant='filled'
              value={name}
              helperText={nameError ? nameErrorMessage : ''}
              error={nameError}
              onChange={(evt) => handleEditField(evt, 'name')}
              fullWidth
            />

            <FormControl variant="filled" fullWidth style={{ marginTop: 15 }}>
              <InputLabel id='category-label'>{ t('form.addStation.category.label') }</InputLabel>
              <Select
                labelId='category-label'
                id='category'
                value={category}
                onChange={(evt) => handleEditField(evt, 'category')}
              >
                {
                  categories.data.map((item, i) => (
                    <MenuItem
                      value={item.id}
                      key={i}
                    >
                      {item.attributes.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <TagContainer>
              <TextField
                label={t('form.addStation.tag.label')}
                placeholder='meupainel'
                variant='filled'
                value={newTag.editing ? newTag.tag : tag}
                onChange={handleTagChange}
                fullWidth
                helperText={tagError ? tagErrorMessage : t('form.addStation.tag.description')}
                error={tagError}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <p style={{ color: theme.text.secondary }}>@</p>
                    </InputAdornment>
                  ),
                }}
                style={{ marginTop: 15 }}
              />
              <AnimatePresence exitBeforeEnter>
                {
                  uniquename.loading ? (
                    <Spinner
                      variants={spinnerVariants}
                      initial='initial'
                      animate='visible'
                      exit='initial'
                    >
                      <CircleLoader cx="15" cy="15" r="10" stroke={theme.colors.primary} fill="none" />
                    </Spinner>
                  ) : (
                    <TagStatusBadge
                      variants={tagBadgeVariants}
                      initial='initial'
                      animate='visible'
                      exit='initial'
                    >
                      <MDIcon
                        path={tagError ? mdiAlertCircle : mdiCheckboxMarkedCircle}
                        size='24px'
                        color={tagError ? theme.colors.error : theme.colors.primary}
                      />
                    </TagStatusBadge>
                  )
                }
              </AnimatePresence>
            </TagContainer>
            <TextField
              label={t('form.addStation.promotionalText.label')}
              variant='filled'
              value={description}
              onChange={(evt) => handleEditField(evt.target.value, 'description')}
              fullWidth
              helperText={t('form.addStation.promotionalText.title')}
              multiline
              rowsMax={15}
            />
          </>
        );
      case 'content':
        return (
          <>
            <FormControl variant="filled" fullWidth>
              <InputLabel id='start-day-label'>{t('form.addStation.operation.days.begin')}</InputLabel>
              <Select
                labelId='start-day-label'
                id='weeksDayBegin'
                value={weekDaysBegin}
                onChange={(evt) => handleEditField(evt, 'weekDaysBegin')}
              >
                <MenuItem value='mon'>{t('week.mon')}</MenuItem>
                <MenuItem value='tue'>{t('week.tue')}</MenuItem>
                <MenuItem value='wed'>{t('week.wed')}</MenuItem>
                <MenuItem value='thu'>{t('week.thu')}</MenuItem>
                <MenuItem value='fri'>{t('week.fri')}</MenuItem>
                <MenuItem value='sat'>{t('week.sat')}</MenuItem>
                <MenuItem value='sun'>{t('week.sun')}</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="filled" fullWidth style={{ marginTop: 15 }}>
              <InputLabel id='end-day-label'>{t('form.addStation.operation.days.end')}</InputLabel>
              <Select
                labelId='end-day-label'
                id='weeksDayEnd'
                value={weekDaysEnd}
                onChange={(evt) => handleEditField(evt, 'weekDaysEnd')}
              >
                <MenuItem value='mon'>{t('week.mon')}</MenuItem>
                <MenuItem value='tue'>{t('week.tue')}</MenuItem>
                <MenuItem value='wed'>{t('week.wed')}</MenuItem>
                <MenuItem value='thu'>{t('week.thu')}</MenuItem>
                <MenuItem value='fri'>{t('week.fri')}</MenuItem>
                <MenuItem value='sat'>{t('week.sat')}</MenuItem>
                <MenuItem value='sun'>{t('week.sun')}</MenuItem>
              </Select>
            </FormControl>
            <TimePicker
              fullWidth
              style={{ marginTop: 15 }}
              inputVariant='filled'
              ampm={false}
              showTodayButton={true}
              todayLabel={t('form.addStation.timepicker.now')}
              minutesStep={5}
              name='periodBegin'
              label={t('form.addStation.periodBegin.label')}
              value={periodBegin}
              placeholder='12:00'
              invalidDateMessage={t('form.addStation.timepicker.error')}
              mask="__:__"
              okLabel='Ok'
              cancelLabel={t('form.addStation.timepicker.cancel')}
              onChange={(value) => handleEditField(value, 'periodBegin')}
            />
            <TimePicker
              fullWidth
              style={{ marginTop: 15 }}
              inputVariant='filled'
              ampm={false}
              name='periodEnd'
              minutesStep={5}
              label={t('form.addStation.periodEnd.label')}
              value={periodEnd}
              placeholder='18:00'
              invalidDateMessage={t('form.addStation.timepicker.error')}
              mask="__:__"
              okLabel='Ok'
              cancelLabel={t('form.addStation.timepicker.cancel')}
              onChange={(value) => handleEditField(value, 'periodEnd')}
            />
            <TextField
              label={t('form.addStation.inches.label')}
              type='number'
              variant='filled'
              value={inches}
              onChange={(evt) => handleEditField(evt, 'inches')}
              helperText={inchesError ? inchesErrorMessage : ''}
              error={inchesError}
              fullWidth
              inputProps={{ min: '1', max: '120', step: '1' }}
              style={{ marginTop: 15 }}
            />
          </>
        );
      case 'description':
        return (
          <TextField
            label={t('form.addStation.promotionalText.label')}
            variant='filled'
            value={description}
            onChange={(evt) => handleEditField(evt.target.value, 'description')}
            fullWidth
            helperText={t('form.addStation.promotionalText.title')}
            multiline
            rowsMax={15}
          />
        )
      default:
        return <p>Nada a editar</p>
    }
  }

  const handleSaveEdits = () => {
    if (editingDataType === 'title') {
      const stationData = {
        id: station.data.id,
        name,
        category,
        tag: newTag.tag ? newTag.tag : tag,
        description: description
      }
      editStation({ email, token }, stationData, editingDataType);
    }
    else if (editingDataType === 'content') {
      const stationData = {
        id: station.data.id,
        periodBegin: format(periodBegin, 'HH:mm'),
        periodEnd: format(periodEnd, 'HH:mm'),
        weekDaysBegin,
        weekDaysEnd,
        inches
      }
      editStation({ email, token }, stationData, editingDataType);
    }
    else if (editingDataType === 'description') {
      const stationData = {
        id: station.data.id,
        description: description
      }
      editStation({ email, token }, stationData, editingDataType);
    }
    toggleDrawer(false)();
  }

  const recordButtonPosition = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  }

  let closeMenu = () => {
    setMenuOpen(false);
  }

  const handleEditImages = (evt) => {
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();

    setMenuOpen(false);
    if (opacity.get() < 1) {
      history.push({
        pathname: '/manage-station-images/0',
        stImages: images,
        stId: station.data.id,
        avatar: avatar
      });
    }
  }

  const renderOptionsMenuOwner = () => (
      <OptionsMenu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleEditImages}>
          <CustomListItemIcon>
            <IconWrapper>
              <MDIcon
                path={mdiImageEditOutline}
                size='24px'
                color={theme.text.primary}
              />
            </IconWrapper>
          </CustomListItemIcon>
          <CustomListItemWrapper>
            <CustomListItemText>
              Alterar imagens
            </CustomListItemText>
            <CustomListItemTextSmall>
              Alterar imagem de capa e adicionais
            </CustomListItemTextSmall>
          </CustomListItemWrapper>
        </MenuItem>
        <MenuItem onClick={handleDeleteAds}>
          <CustomListItemIcon>
            <IconWrapper>
              <MDIcon
                path={mdiCogSyncOutline}
                size='24px'
                color={theme.text.primary}
              />
            </IconWrapper>
          </CustomListItemIcon>
          <CustomListItemWrapper>
            <CustomListItemText>
              Gerenciar anúncios
            </CustomListItemText>
            <CustomListItemTextSmall>
              Você pode remover seus anúncios
            </CustomListItemTextSmall>
          </CustomListItemWrapper>
        </MenuItem>
        <MenuItem onClick={handleSyncPlayer}>
          <CustomListItemIcon>
            <IconWrapper>
              <MDIcon
                path={mdiSync}
                size='24px'
                color={theme.text.primary}
              />
            </IconWrapper>
          </CustomListItemIcon>
          <CustomListItemWrapper>
            <CustomListItemText>
              Sincronizar player
            </CustomListItemText>
            <CustomListItemTextSmall>
              Algum anúncio não está sendo exibido
            </CustomListItemTextSmall>
          </CustomListItemWrapper>
        </MenuItem>
        <MenuItem onClick={goToStationActivation}>
          <CustomListItemIcon>
            <IconWrapper>
              <MDIcon
                path={mdiNumeric}
                size='24px'
                color={theme.text.primary}
              />
            </IconWrapper>
          </CustomListItemIcon>
          <CustomListItemWrapper>
            <CustomListItemText>
              Código de ativação
            </CustomListItemText>
            <CustomListItemTextSmall>
              Não compartilhe o código com ninguém
            </CustomListItemTextSmall>
          </CustomListItemWrapper>
        </MenuItem>
        <MenuItem onClick={confirmBlockStation}>
          <CustomListItemIcon>
            <IconWrapper>
              <MDIcon
                path={blocked ? mdiAlarmLightOutline : mdiBlockHelper}
                size='24px'
                color={theme.text.primary}
              />
            </IconWrapper>
          </CustomListItemIcon>
          <CustomListItemWrapper>
            <CustomListItemText>
              { blocked && 'Desbloquear painel'}
              { !blocked && 'Bloquear painel'}
            </CustomListItemText>
            <CustomListItemTextSmall>
              { blocked && 'Ativar painel para anúncios' }
              { !blocked && 'Não permitir novos anúncios' }
            </CustomListItemTextSmall>
          </CustomListItemWrapper>
        </MenuItem>
        <MenuItem onClick={handleDeleteStation}>
          <CustomListItemIcon>
            <IconWrapper>
              <MDIcon
                path={mdiDeleteOffOutline}
                size='24px'
                color={theme.text.primary}
              />
            </IconWrapper>
          </CustomListItemIcon>
          <CustomListItemWrapper>
            <CustomListItemText>
              Excluir painel
            </CustomListItemText>
            <CustomListItemTextSmall>
              Desativar e excluir painel
            </CustomListItemTextSmall>
          </CustomListItemWrapper>
        </MenuItem>
      </OptionsMenu>
  );

  const renderOptionsMenuVisitor = () => (
    <OptionsMenu
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={handleReportStation}>
        <CustomListItemIcon>
          <IconWrapper>
            <MDIcon
              path={mdiMessageAlertOutline}
              size='24px'
              color={theme.text.primary}
            />
          </IconWrapper>
        </CustomListItemIcon>
        <CustomListItemWrapper>
          <CustomListItemText>
            Reportar painel
          </CustomListItemText>
          <CustomListItemTextSmall>
            O painel está inadequado
          </CustomListItemTextSmall>
        </CustomListItemWrapper>
      </MenuItem>
    </OptionsMenu>
);

  const handleSyncPlayer = () => {
    syncPlayer({ email, token }, station);
    setMenuOpen(false);
  }

  const handleReportStation = () => {
    setReportIsOpen(true);
  }

  const confirmBlockStation = () => {
      setMenuOpen(false);
      setOpen(true);
  }

  const cancelBlockStation = () => {
      setOpen(false);
  }

  const handleBlockStation = () => {
    setOpen(false);
    blockStation({ email, token }, station);
  }

  const handleDeleteStation = () => {
    setDeleteIsOpen(true);
    setMenuOpen(false);
  }

  const handleDeleteAds = () => {
    setDeleteAdsIsOpen(true);
    setMenuOpen(false);
  }

  const openEditStationData = (dataType) => {
    setEditingDataType(dataType);
    toggleDrawer(true)();
  }

  const toggleDrawer = state => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setEditing(state);
  }

  const goToAddService = () => {
    selectStations([station.data]);
    updateOrigin(false);
    history.push('/announce/0');
  }

  const goToStationActivation = () => {
    const { uniquename, 'cd-station' : codStation } = station.data.attributes;

    history.push({
      pathname: `/station/${uniquename}/activation`,
      codStation,
      station
    });
  }

  const confirmUnblockStation = () => {
    if (stationData.owner) {
      setOpen(true);
    }
  }

  const handleUnblockStation = () => {
    if (stationData.owner) {
      setOpen(false);
      unblockStation({ email, token }, station);
    }
  }

  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    }
  }

  // destructuring assignment para a renderização dos dados de forma menos verbosa
  const { name, tag, images, weekDaysBegin, weekDaysEnd, periodBegin, periodEnd, description, locale, ownerName, category, inches, owner, active, blocked, ownerImage, avatar, lightBoxImages } = stationData;

  // se não estiver carregando e conter erros, significa que houve uma falha ao buscar os dados da estação
  // neste caso apenas renderiza que houve um erro e um botão para atualizar a página
  if (station !== undefined && !station.loading && station.error) {
    return (
      <Pages ref={contentRef}>
        <Page style={{ paddingTop: '80px' }}>
          <PageTitle style={{ textAlign: 'center' }}>{t('form.loadStation.error')}</PageTitle>
          <Lottie
            options={errorOptions}
            isClickToPauseDisabled={true}
            width={180}
            height={180}
            style={{ margin: '15px auto' }}
          />
          <BtnFeedback onClick={() => history.replace('/')}>{t('form.loadStation.retry')}</BtnFeedback>
        </Page>
      </Pages>
    )
  }

  const variants = {
    initial: { opacity: 0, scale: 1, y: 50, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    visible: { opacity: 1, scale: 1, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
    hidden: { opacity: 0, scale: 0.97, y: 0, transition: { duration: .15, easing: 'cubic-bezier(.785,.135,.15,.86)' } },
  }

  const spinnerVariants = {
    initial: { opacity: 0, transition: { duration: .15 } },
    visible: { opacity: 1, transition: { duration: .15 } },
  }

  const tagBadgeVariants = {
    initial: { opacity: 0, transition: { duration: .15 } },
    visible: { opacity: 1, transition: { duration: .15 } },
  }

  return (
    <Container
      variants={variants}
      initial='initial'
      animate='visible'
      exit='hidden'
    >
      {
        !active && !blocked && !station.loading && (
          <ActivateAlert
            className='noSelect'
            title={t('viewStation.activate')}
            onClick={goToStationActivation}
          >
            {t('viewStation.needsActivation')}
          </ActivateAlert>
        )
      }
      {
        !active && blocked && !station.loading && (
          <BlockAlert
            className='noSelect'
            title={'Bloquear painel'}
            onClick={confirmUnblockStation}
          >
            <MDIcon
              path={mdiBlockHelper}
              size='18px'
              color={'#4a3e00'}
              style={{ marginRight: '5px' }}
            />
             Painel bloqueado
          </BlockAlert>
        )
      }

      <Header {...{ active }} moveUp={galleryMode} style={{ background: theme.title === 'dark' ? darkBackground : lightBackground, boxShadow: theme.title === 'light' ? boxShadow : 'none' }}>
        <BackButton onClick={handleBackButton}>
          <Icon
            icon={roundArrowBack}
            width={24}
            height={24}
            color={theme.text.primary}
          />
        </BackButton>
        { size.width >= 1024 && <BackButtonText onClick={handleBackButton} className='noSelect'>Voltar</BackButtonText> }
        { /* Nome da estação exibida no título (header) */}
        <HeaderTitle style={{ transform: translateY, opacity: titleOpacity }}>{(station !== undefined && station.loading) ? t('viewStation.loading') : name}</HeaderTitle>
        { size.width < 1024 && !galleryMode &&
          <OptionsButton
            onClick={recordButtonPosition}
            active={active ? 1 : 0}
          >
            <MDIcon
              path={mdiDotsHorizontal}
              size='24px'
              color={theme.text.primary}
            />
          </OptionsButton>
        }
      </Header>
      { /* Primeira imagem exibida ao visualizar a estação */}
      {
        station !== undefined && size.width < 1024 && (
          <ImageContainer {...{ active }} onClick={!station.loading ? handleOpenGallery : null}>
            {
              owner && !galleryMode && !station.loading && !reportIsOpen && !deleteIsOpen && !deleteAdsIsOpen && (
                <>
                  { renderOptionsMenuOwner() }
                </>
              )
            }
            {
              !owner && !galleryMode && !station.loading && !reportIsOpen && !deleteIsOpen && !deleteAdsIsOpen && (
                <>
                  { renderOptionsMenuVisitor() }
                </>
              )
            }
            <Image bg={station.loading ? (theme.title === 'dark' ? loadingImgPathDark : loadingImgPathLight) : avatar } />
            <Overlay style={{ opacity }} />
          </ImageContainer>
        )
      }
      {
        station !== undefined && size.width >= 1024 && (
          <ImageContainer
            active={false}
            numberOfImages={images.length}
          >
            {
              owner && !galleryMode && !station.loading && (
                <>
                  <EditImageBtn onClick={recordButtonPosition} active={active ? 1 : 0}>
                    <MDIcon
                      path={mdiDotsHorizontal}
                      size='24px'
                      color={theme.text.primary}
                    />
                  </EditImageBtn>
                  { renderOptionsMenuOwner() }
                </>
              )
            }
            {
              !owner && !galleryMode && !station.loading && (
                <>
                  <EditImageBtn onClick={recordButtonPosition} active={active ? 1 : 0}>
                    <MDIcon
                      path={mdiDotsHorizontal}
                      size='24px'
                      color={theme.text.primary}
                    />
                  </EditImageBtn>
                  { renderOptionsMenuVisitor() }
                </>
              )
            }
            {
              [{ id: -1, storage_ref: avatar }, ...images].map((img, index) => {
                if (index <= 4) {
                  return (
                    <DesktopImage
                      bg={(img.storage_ref ? img.storage_ref : img)}
                      key={index}
                      index={index}
                      onClick={() => { setLightBox(true); setPhotoIndex(index); }}
                    />
                  )
                }
                else {
                  return null;
                }
              })
            }
          </ImageContainer>
        )
      }
      {
        station !== undefined && station.loading && size.width >= 1024 ? (
          <DescriptionContainer>
            <DescriptionTitle>
              {t('viewStation.promotional')}
              <InlineIcon icon={roundCampaign} width={24} height={24} color={theme.text.primary} />
            </DescriptionTitle>
            <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}>
              <Description>
                <Skeleton />
                <Skeleton width={'60%'} />
              </Description>
            </SkeletonTheme>
          </DescriptionContainer>
        ) : (
          description && description.trim() && size.width >= 1024 && (
            <DescriptionContainer>
              <DescriptionTitle>
                <InlineIcon icon={roundCampaign} width={24} height={24} color={theme.text.primary} />
                {
                  owner && (
                    <BtnEdit onClick={() => openEditStationData('description')}>
                      { ((editingStation.loading && editingStation.editingDataType !== 'description') || !editingStation.loading) &&
                        <MDIcon
                          path={mdiPencilOutline}
                          size='24px'
                          color={theme.text.primary}
                        />
                      }
                      { (editingStation.loading && editingStation.editingDataType === 'description') &&
                        <CircularProgress />
                      }
                    </BtnEdit>
                  )
                }
              </DescriptionTitle>
              <Description>{description}</Description>
            </DescriptionContainer>
          )
        )
      }

      { /* Galeria contendo todas as imagens da estação */}
      <Gallery
        show={galleryMode}
        images={[{ id: -1, storage_ref: avatar }, ...images]}
      />

      <Content
        ref={contentRef}
        dragElastic={0.05}
        dragConstraints={{ top: -height + (size.height - 350), bottom: 0 }}
        drag={size.width < 1024 ? 'y' : false}
        dragMomentum={false}
        style={{ y }}
        active={active}
        className='noSelect'
      >
        { /* Nome do painel exibido abaixo da imagem */}
        <Row>
          {
            station !== undefined && station.loading && (
              <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}><Title><Skeleton width={300} /></Title></SkeletonTheme>
            )
          }
          {
            station !== undefined && !station.loading && (
              <Title>{name}</Title>
            )
          }
          {
            station !== undefined && owner && (
              <BtnEdit onClick={() => openEditStationData('title')}>
                { ((editingStation.loading && editingStation.editingDataType !== 'title') || !editingStation.loading) &&
                  <MDIcon
                    path={mdiPencilOutline}
                    size='24px'
                    color={theme.text.primary}
                  />
                }
                { (editingStation.loading && editingStation.editingDataType === 'title') &&
                  <CircularProgress />
                }
              </BtnEdit>
            )
          }
        </Row>
        {
          station !== undefined && station.loading ? (
            <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}><Tag><Skeleton width={150} /></Tag></SkeletonTheme>
          ) : (
            <Tag>@{(newTag.editing) ? (uniquename.isUnique && newTag.tag) ? newTag.tag : tag : tag}</Tag>
          )
        }
        <Separator />
        <Row>
          <InlineIcon
            icon={roundCalendarToday}
            width={18}
            height={18}
            color={theme.text.secondary}
          />
          { /* Dias de funcionamento da estação (seg -> sab) */}
          {
            station !== undefined && station.loading ? (
              <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}><SecondaryText><Skeleton width={70} /></SecondaryText></SkeletonTheme>
            ) : (
              <SecondaryText>{`${renderWeekDays(weekDaysBegin)} - ${renderWeekDays(weekDaysEnd)}`}</SecondaryText>
            )
          }
          <InlineIcon
            icon={roundAccessTime}
            width={18}
            height={18}
            color={theme.text.secondary}
            style={{ marginLeft: 15 }}
          />
          { /* Período de funcionamento da estação (HH:MM -> HH:MM) */}
          {
            station !== undefined && station.loading && (
              <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}><SecondaryText><Skeleton width={70} /></SecondaryText></SkeletonTheme>
            )
          }
          {
            station !== undefined && !station.loading && (
              <SecondaryText>{`${format(periodBegin, 'HH:mm')}h - ${format(periodEnd, 'HH:mm')}h`}</SecondaryText>
            )
          }
          <div style={{ flexBasis: '100%', height: 0 }} />
          <MDIcon
            path={mdiMonitorScreenshot}
            size='18px'
            color={theme.text.secondary}
            style={{ marginTop: 15 }}
          />
          { /* Polegadas do painel */}
          {
            station !== undefined && station.loading && (
              <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}><SecondaryText style={{ marginTop: 15 }}><Skeleton width={85} /></SecondaryText></SkeletonTheme>
            )
          }
          {
            station !== undefined && !station.loading && (
              <SecondaryText style={{ marginTop: 15 }}>{`${inches}''`}</SecondaryText>
            )
          }
          {
            owner && (
              <BtnEdit
                onClick={() => openEditStationData('content')}
                style={{ position: 'absolute', top: -10, right: 0 }}
              >
                { ((editingStation.loading && editingStation.editingDataType !== 'content') || !editingStation.loading) &&
                  <MDIcon
                    path={mdiPencilOutline}
                    size='24px'
                    color={theme.text.primary}
                  />
                }
                { editingStation.loading && editingStation.editingDataType === 'content' &&
                  <CircularProgress />
                }
              </BtnEdit>
            )
          }
        </Row>
        <Separator />
        { /* Texto promocional, chamada para anunciar (vantagens) */}
        <Row>
          {
            station !== undefined && station.loading && size.width < 1024 ? (
              <>
                <DescriptionTitle>
                  {t('viewStation.promotional')}
                  <InlineIcon icon={roundCampaign} width={24} height={24} color={theme.text.primary} />
                </DescriptionTitle>
                <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}>
                  <Description>
                    <Skeleton />
                    <Skeleton width={'60%'} />
                  </Description>
                </SkeletonTheme>
              </>
            ) : (
              description && description.trim() && size.width < 1024 && (
                <DescriptionContainer>
                  <DescriptionTitle>
                    <InlineIcon icon={roundCampaign} width={24} height={24} color={theme.text.primary} />
                    {
                      owner && (
                        <BtnEdit onClick={() => openEditStationData('description')}>
                        { ((editingStation.loading && editingStation.editingDataType !== 'description') || !editingStation.loading) &&
                          <MDIcon
                            path={mdiPencilOutline}
                            size='24px'
                            color={theme.text.primary}
                          />
                        }
                        { (editingStation.loading && editingStation.editingDataType === 'description') &&
                          <CircularProgress />
                        }
                        </BtnEdit>
                      )
                    }
                  </DescriptionTitle>
                  <Description>{description}</Description>
                </DescriptionContainer>
              )
            )
          }
        </Row>
        { /* Localização / endereço da estação */}
        <LocationTitle>{t('viewStation.locale')}</LocationTitle>
        {
          station !== undefined && station.loading && (
            <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}>
              <Location>
                <InlineIcon icon={outlineLocationOn} width={18} height={18} color={theme.text.secondary} />
                <Skeleton width={'50%'} />
              </Location>
            </SkeletonTheme>
          )
        }
        {
          station !== undefined && !station.loading && (
            <Location>
              <InlineIcon icon={outlineLocationOn} width={18} height={18} color={theme.text.secondary} />
              {locale}
            </Location>
          )
        }
        { /* Perfil profissional proprietário da estação */}
        {
          station !== undefined && station.loading ? (
            <OwnerContainer>
              <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}>
                <p><Skeleton circle={true} height={50} width={50} /></p>
              </SkeletonTheme>
            </OwnerContainer>
          ) : (
            <OwnerContainer onClick={openProfessionalCompany}>
              <OwnerImage src={ownerImage} />
              <OwnerName>{ownerName}</OwnerName>
              <Icon icon={roundChevronRight} width={24} height={24} color={theme.text.primary} />
            </OwnerContainer>
          )
        }
        { /* Detalhes do painel (polegadas da TV e resolução) */}
        {
          station !== undefined && station.loading ? (
            <StationDetails>
              <DetailsTitle>{t('viewStation.details')}</DetailsTitle>
              <SkeletonTheme color={theme.skeleton.color} highlightColor={theme.skeleton.highlightColor}>
                <Details>
                  <Skeleton count={7} />
                </Details>
              </SkeletonTheme>
            </StationDetails>
          ) : false
        }
        {
          station !== undefined && size.width >= 1024 && (
            <Footer>
            {
              (station.loading) &&
              <RightButton disabled={true}>
                { t('viewStation.loading') }
              </RightButton>
            }
            {
              (!active && !blocked && !station.loading) &&
              <RightButton onClick={goToStationActivation}>
                { t('viewStation.activate') }
              </RightButton>
            }
            {
              (!active && blocked && !station.loading) &&
              <RightButton onClick={confirmUnblockStation}>
                Painel bloqueado
              </RightButton>
            }
            {
              (active && viewOrigin && !station.loading) &&
              <RightButton onClick={goToAddService}>
                { t('viewStation.announce') }
              </RightButton>
            }
            {
              (selectedStations.some(item => item.id == station.data.id) && active && !viewOrigin && !station.loading) &&
              <RightButton onClick={handleBackButton}>
                { t('viewStation.selected') }
              </RightButton>
            }
            {
              (!selectedStations.some(item => item.id == station.data.id) && active && !viewOrigin && !station.loading) &&
              <RightButton onClick={goToAddService}>
                { t('viewStation.announce') }
              </RightButton>
            }
            </Footer>
          )
        }
      </Content>
      { station !== undefined && size.width >= 1024 && !station.loading &&
        
        <AdsCarouselContainer>
          <Title>Anúncios em exibição no painel</Title>
          <AdsCarousel>
           <ViewAdsAtStation />
          </AdsCarousel>
        </AdsCarouselContainer>
      }
      {
        station !== undefined && size.width < 1024 && (
          <Footer hide={editing || galleryMode}>
            <BtnRealTime active={active ? 1 : 0} onClick={viewAdsOnStation} >
              <Icon icon={roundRemoveRedEye} width={22} height={22} color={theme.text.primary} />
            </BtnRealTime>
            {
              (station.loading) &&
              <RightButton disabled={true}>
                { t('viewStation.loading') }
              </RightButton>
            }
            {
              (!active && !blocked && !station.loading) &&
              <RightButton onClick={goToStationActivation}>
                { t('viewStation.activate') }
              </RightButton>
            }
            {
              (!active && blocked && !station.loading) &&
              <RightButton onClick={confirmUnblockStation}>
                Painel bloqueado
              </RightButton>
            }
            {
              (active && viewOrigin && !station.loading) &&
              <RightButton onClick={goToAddService}>
                { t('viewStation.announce') }
              </RightButton>
            }
            {
              (selectedStations.some(item => item.id == station.data.id) && active && !viewOrigin && !station.loading) &&
              <RightButton onClick={handleBackButton}>
                { t('viewStation.selected') }
              </RightButton>
            }
            {
              (!selectedStations.some(item => item.id == station.data.id) && active && !viewOrigin && !station.loading) &&
              <RightButton onClick={goToAddService}>
                { t('viewStation.announce') }
              </RightButton>
            }
          </Footer>
        )
      }
      { size.width >= 1024 && lightBox && (
        <Lightbox
          mainSrc={lightBoxImages[photoIndex].storage_ref}
          nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length]}
          prevSrc={lightBoxImages[(photoIndex + images.length - 1) % lightBoxImages.length]}
          onCloseRequest={() => setLightBox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + lightBoxImages.length - 1) % lightBoxImages.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % lightBoxImages.length)
          }
        />
      )}
      <CustomDrawer
        anchor='bottom'
        open={editing}
        onClose={toggleDrawer(false)}
      >
        <DrawerContainer>
          { renderEditingContent() }
        </DrawerContainer>
        <DrawerFooter>
          <DrawerBtnActionLeft
            onClick={toggleDrawer(false)}
          >
            Cancelar
          </DrawerBtnActionLeft>
          <DrawerBtnActionRight
            onClick={handleSaveEdits}
            disabled={(editingDataType === 'title') ? stepIncomplete : stepIncompleteB}
          >
            Salvar
          </DrawerBtnActionRight>
        </DrawerFooter>
      </CustomDrawer>
      <Snackbar
        key={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        color='primary'
        open={(!editingStation.loading && editingStation.finish)}
        autoHideDuration={4000}
        onClose={() => updateEditStation({ loading: false, error: false, finish: false, editingDataType: editingDataType })}
        onExit={() => updateEditStation({ loading: false, error: false, finish: false, editingDataType: editingDataType })}
      >
        <SnackbarContent
          style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
          message={ editingStation.error ? 'Erro ao atualizar o painel' : 'Painel atualizado com sucesso' }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => updateEditStation({ loading: false, error: false, finish: false, editingDataType: editingDataType })}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
      <Snackbar
        key={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        color='primary'
        open={(!reportStationData.loading && reportStationData.sent)}
        autoHideDuration={4000}
        onClose={() => updateSendReport({ loading: false, error: false, sent: false })}
        onExit={() => updateSendReport({ loading: false, error: false, sent: false })}
      >
        <SnackbarContent
          style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
          message={ reportStationData.error ? 'Erro ao enviar report' : 'Informações enviadas' }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => updateSendReport({ loading: false, error: false, sent: false })}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
      <Snackbar
        key={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        color='primary'
        open={(!syncPlayerData.loading && (syncPlayerData.success || syncPlayerData.error))}
        autoHideDuration={4000}
        onClose={() => updateSyncPlayer({ loading: false, error: false, success: false })}
        onExit={() => updateSyncPlayer({ loading: false, error: false, success: false })}
      >
        <SnackbarContent
          style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
          message={ syncPlayerData.error ? 'Sincronização já agendada' : 'Sincronização no próximo ciclo' }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => updateSyncPlayer({ loading: false, error: false, success: false })}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
      <Snackbar
        key={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        color='primary'
        open={(!blockStationData.loading && (blockStationData.success || blockStationData.error))}
        autoHideDuration={4000}
        onClose={() => updateBlockStation({ loading: false, error: false, success: false })}
        onExit={() => updateBlockStation({ loading: false, error: false, success: false })}
      >
        <SnackbarContent
          style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
          message={ blockStationData.error ? 'Erro ao bloquear, tente novamente' : 'Painel bloqueado com sucesso' }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => updateBlockStation({ loading: false, error: false, success: false })}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
      <Snackbar
        key={4}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        color='primary'
        open={(!unblockStationData.loading && (unblockStationData.success || unblockStationData.error))}
        autoHideDuration={4000}
        onClose={() => updateUnblockStation({ loading: false, error: false, success: false })}
        onExit={() => updateUnblockStation({ loading: false, error: false, success: false })}
      >
        <SnackbarContent
          style={{ backgroundColor: theme.colors.background, color: theme.text.primary, fontSize: '16px' }}
          message={ unblockStationData.error ? 'Erro ao desbloquear, tente novamente' : 'Painel desbloqueado com sucesso' }
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => updateUnblockStation({ loading: false, error: false, success: false })}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
      { size.width < 1024 && reportIsOpen && <ReportStation setReportIsOpen={setReportIsOpen} station={station} />}
      { size.width >= 1024 && reportIsOpen &&
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={true}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <ReportStation setReportIsOpen={setReportIsOpen} station={station} />
        </Modal>
      }
      { size.width < 1024 && deleteIsOpen &&
        <DeleteStation
          setDeleteIsOpen={setDeleteIsOpen}
          credentials={{ email, token }}
          station={station}
          blockStation={blockStation}
          blocked={blocked}
        />
      }
      { size.width >= 1024 && deleteIsOpen &&
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={true}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <DeleteStation
            setDeleteIsOpen={setDeleteIsOpen}
            credentials={{ email, token }}
            station={station}
            blockStation={blockStation}
            blocked={blocked}
          />
        </Modal>
      }
      { size.width < 1024 && deleteAdsIsOpen &&
        <DeleteAdsFromStation
          setDeleteAdsIsOpen={setDeleteAdsIsOpen}
          credentials={{ email, token }}
          station={station}
          blockStation={blockStation}
          blocked={blocked}
        />
      }
      { size.width >= 1024 && deleteAdsIsOpen &&
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={true}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <DeleteAdsFromStation
            setDeleteAdsIsOpen={setDeleteAdsIsOpen}
            credentials={{ email, token }}
            station={station}
            blockStation={blockStation}
            blocked={blocked}
          />
        </Modal>
      }
      <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={cancelBlockStation}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
      >
          <DialogTitle id="alert-dialog-slide-title">
            { blocked && 'Desbloquear painel' }
            { !blocked && 'Confirmar bloqueio' }
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            { blocked && 'Ao desbloquear você pode ativá-lo novamente para voltar a receber anúncios.' }
            { !blocked && 'Após bloquear o painel você terá que reativá-lo novamente caso queira que o painel volte a receber anúncios.' }
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={cancelBlockStation} color="primary">
              Cancelar
          </Button>
          <Button onClick={blocked ? handleUnblockStation : handleBlockStation} color="primary">
              Confirmar
          </Button>
          </DialogActions>
      </Dialog>
      { /* BACKDROP PARA SOBREPOR A TELA ENQUANTO ESTIVER ENVIANDO O REPORT */}
      <Backdrop
        style={{ zIndex: 999, color: theme.colors.primary }}
        open={reportStationData.loading || unblockStationData.loading || (!deleteIsOpen && !deleteAdsIsOpen && blockStationData.loading)}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

const mapStateToProps = state => ({
  email: state.AuthenticationReducer.memberData.data.attributes.email,
  token: state.AuthenticationReducer.token,
  station: state.StationsReducer.viewStation,
  categories: state.StationsReducer.categories,
  viewOrigin: state.StationsReducer.viewOrigin,
  selectedStations: state.StationsReducer.selectedStations,
  uniquename: state.StationsReducer.uniquename,
  editingStation: state.StationsReducer.editStation,
  reportStationData: state.StationsReducer.sendReport,
  syncPlayerData: state.StationsReducer.syncPlayer,
  blockStationData: state.StationsReducer.blockStation,
  unblockStationData: state.StationsReducer.unblockStation,
  stationActivation: state.StationsReducer.stationActivation
});

export default connect(mapStateToProps, { getStation, getCategories, checkUniquename, selectStations, updateOrigin, editStation, updateEditStation, updateSendReport, syncPlayer, updateSyncPlayer, updateDeleteStation, updateBlockStation, blockStation, unblockStation, updateUnblockStation })(Station);
