import {
  SAVE_CAMPAIGN,
  SET_APPROVED,
  REMOVE_APPROVED,
  SET_PAID
} from '../../constraints/types';

export const saveCampaign = (campaign, userId) => ({
  type: SAVE_CAMPAIGN,
  payload: campaign,
  userId: userId
});

export const setApproved = (announceData) => ({
  type: SET_APPROVED,
  payload: announceData
});

export const setPaid = (announceData) => ({
  type: SET_PAID,
  payload: announceData
});

export const removeApproved = (announceData) => ({
  type: REMOVE_APPROVED,
  payload: announceData
});
